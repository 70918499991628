"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Tone = require("tone");
const THREE = require("three");
const loglevel_1 = require("loglevel");
class SongLoader {
    constructor() {
        this.songs = new Map();
        this.players = new Map();
        this.samplers = new Map();
        this._completion = 0;
        this.clock = new THREE.Clock();
    }
    get isCompleted() {
        return (this._completion == 1);
    }
    get completion() {
        return this._completion;
    }
    getKeys() {
        let keys = [...this.songs.keys()];
        keys = keys.filter(item => !item.includes('FeverMode'));
        keys.sort();
        return keys;
    }
    getSong(key) {
        let song = this.songs.get(key);
        if (!song) {
            loglevel_1.default.error(`SongLoader: getSong - key not found ${key}`);
            throw `SongLoader: getSong - key not found ${key}`;
        }
        else {
            return song;
        }
    }
    getPlayer(key) {
        let player = this.players.get(key);
        if (!player) {
            loglevel_1.default.error(`SongLoader: getPlayer - key not found ${key}`);
            throw `SongLoader: getPlayer - key not found ${key}`;
        }
        else {
            return player;
        }
    }
    getSampler(key) {
        let sampler = this.samplers.get(key);
        if (!sampler) {
            loglevel_1.default.error(`SongLoader: getSampler - key not found ${key}`);
            throw `SongLoader: getSampler - key not found ${key}`;
        }
        else {
            return sampler;
        }
    }
    async load(contentData) {
        loglevel_1.default.info('SongLoader: load - loading songs');
        this._completion = 0;
        this.clock.start();
        if (contentData.songs) {
            for (let song of contentData.songs) {
                if (song.key && song.fileName) {
                    const player = new Tone.Player({
                        url: song.fileName,
                        onload: () => {
                            const songData = {
                                key: song.key,
                                player: player,
                                bpm: song.bpm,
                                emoji: song.emoji,
                                scale: song.scale,
                                remix: song.remix,
                                genre: song.genre || "",
                                rating: song.rating || 0
                            };
                            this.songs.set(song.key, songData);
                        }
                    });
                }
                else {
                    console.log(`SongLoader: load - invalid <song> - key: ${song.key}  -  fileName: ${song.fileName}`);
                }
            }
        }
        if (contentData.tonePlayers) {
            for (let tonePlayer of contentData.tonePlayers) {
                if (tonePlayer.key && tonePlayer.fileName) {
                    const player = new Tone.Player({
                        url: tonePlayer.fileName,
                        onload: () => {
                            const tonePlayerData = {
                                key: tonePlayer.key,
                                player: player
                            };
                            this.players.set(tonePlayer.key, tonePlayerData);
                        }
                    });
                }
                else {
                    console.log(`SongLoader: load - invalid <player> - key: ${tonePlayer.key}  -  fileName: ${tonePlayer.fileName}`);
                }
            }
        }
        if (contentData.samplers) {
            for (let samplerContent of contentData.samplers) {
                if (samplerContent.key && samplerContent.samplesMap) {
                    const sampler = new Tone.Sampler({
                        urls: samplerContent.samplesMap,
                        onload: () => {
                            const samplerData = {
                                key: samplerContent.key,
                                sampler: sampler
                            };
                            this.samplers.set(samplerContent.key, samplerData);
                        }
                    });
                }
                else {
                    console.log(`SongLoader: load - invalid <sampler> - key: ${samplerContent.key}`);
                }
            }
        }
        await Tone.loaded();
        this._completion = 1;
        this.clock.stop();
        loglevel_1.default.info(`SongLoader: load - songs loaded - ${this.clock.getElapsedTime().toFixed(3)}s`);
    }
}
exports.default = SongLoader;
