"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const Tone = require("tone");
const TWEEN = require("@tweenjs/tween.js");
const loglevel_1 = require("loglevel");
const stinger_player_1 = require("./stinger_player");
class SoundModel extends THREE.EventDispatcher {
    constructor() {
        super();
        this.currentSongData = null;
        this.isPlaying = false;
        this.musicPending = false;
        loglevel_1.default.debug('SoundModel: constructor');
        this.musicChannel = new Tone.Channel().toDestination();
        this.sfxChannel = new Tone.Channel().toDestination();
        Tone.Transport.bpm.value = 120;
        Tone.Transport.on('start', this.onStart.bind(this));
        Tone.Transport.on('stop', this.onStop.bind(this));
        this.stingerPlayer = new stinger_player_1.default(this.sfxChannel);
    }
    reset() {
    }
    initStingers(contentModel) {
        // should be called after all content loaded
        this.stingerPlayer.initSamplers(contentModel);
    }
    start() {
        const globalContext = Tone.getContext();
        if (globalContext.state == 'suspended') {
            loglevel_1.default.info(`SoundManager: start`);
            Tone.start();
        }
    }
    onStart() {
        this.isPlaying = true;
        this.musicPending = false;
    }
    onStop() {
        this.isPlaying = false;
    }
    isSongPlaying() {
        return this.isPlaying;
    }
    getCurrentSong() {
        if (this.currentSongData) {
            return this.currentSongData.key;
        }
        return null;
    }
    getBPM() {
        if (this.currentSongData) {
            return this.currentSongData.bpm;
        }
        return 120;
    }
    isSongInFeverMode() {
        if (this.currentSongData) {
            return this.currentSongData.remix;
        }
        return false;
    }
    setSong(songData, offset) {
        if (!this.currentSongData || songData.key != this.currentSongData.key) {
            this.stop();
            this.currentSongData = songData;
            Tone.Transport.bpm.value = songData.bpm;
            songData.player.connect(this.musicChannel);
            songData.player.volume.value = 0;
            songData.player.loop = true;
            this.stingerPlayer.setSong(songData);
            this.play(offset);
        }
    }
    play(offset) {
        if (this.currentSongData) { // && !this.musicPending ) {
            loglevel_1.default.info(`SoundManager: play - ${this.currentSongData.key}`);
            this.musicPending = true;
            this.currentSongData.player.sync().start(0);
            Tone.Transport.start("+0", offset);
        }
    }
    stop() {
        if (this.currentSongData) {
            loglevel_1.default.debug(`SoundManager: stop - ${this.currentSongData.key}`);
            this.currentSongData.player.stop();
            this.currentSongData.player.unsync();
        }
        if (Tone.Transport.state == "started") {
            Tone.Transport.stop();
        }
        this.isPlaying = false;
    }
    muteSong() {
        this.musicChannel.mute = true;
    }
    unmuteSong() {
        this.musicChannel.mute = false;
    }
    unmuteAndFadeIn() {
        this.musicChannel.volume.value = -100;
        this.musicChannel.mute = false;
        new TWEEN.Tween(this.musicChannel.volume)
            .to({ value: SoundModel.MUSIC_VOLUME }, 1500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
    }
    playSFX(playerData) {
        playerData.player.connect(this.sfxChannel);
        playerData.player.start();
    }
    playGemSFX(gemType) {
        this.stingerPlayer.playGemStinger(gemType);
    }
    playDanceSFX(gemType, danceCount) {
        this.stingerPlayer.playDanceStinger(gemType, danceCount);
    }
    getFeverKey() {
        const currentKey = this.currentSongData?.key;
        const feverKey = `${currentKey?.substring(0, 2)} FeverMode`;
        return feverKey;
    }
    transitionToFeverSong(feverSongData) {
        // fade the current song out
        const player = this.currentSongData.player;
        let duration = Tone.Transport.toSeconds("1m") * 1000;
        new TWEEN.Tween(player.volume)
            .to({ value: -20 }, duration)
            .start()
            .onComplete(() => { player.stop(); });
        // set fever song to come in
        window.setTimeout(() => { this.setSong(feverSongData); }, duration);
        // Tone.Transport.schedule( (time) => {
        //     this.setSong( feverSongData );
        // }, Tone.Transport.nextSubdivision( "1m" ) );
    }
    transitionFromFeverSong(songData) {
        if (!this.isSongInFeverMode()) {
            return;
        }
        // fade the fever song out
        const player = this.currentSongData.player;
        let duration = 500;
        new TWEEN.Tween(player.volume)
            .to({ value: -20 }, duration)
            .start()
            .onComplete(() => { player.stop(); });
        // set original song to come in
        window.setTimeout(() => { this.setSong(songData); }, duration);
    }
    startScoreDing() {
        this.stingerPlayer.startScoreDing();
    }
    stopScoreDing() {
        this.stingerPlayer.stopScoreDing();
    }
    playCheatStinger() {
        this.stingerPlayer.playCheatStinger();
    }
}
exports.default = SoundModel;
SoundModel.MUSIC_VOLUME = 0;
