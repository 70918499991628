"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwipeEvents = exports.MovementDirection = exports.SwipeDirection = void 0;
const THREE = require("three");
const loglevel_1 = require("loglevel");
var SwipeDirection;
(function (SwipeDirection) {
    SwipeDirection["None"] = "None";
    SwipeDirection["Up"] = "Up";
    SwipeDirection["Down"] = "Down";
    SwipeDirection["Left"] = "Left";
    SwipeDirection["Right"] = "Right";
    SwipeDirection["UpRight"] = "UpRight";
    SwipeDirection["UpLeft"] = "UpLeft";
    SwipeDirection["DownRight"] = "DownRight";
    SwipeDirection["DownLeft"] = "DownLeft";
})(SwipeDirection = exports.SwipeDirection || (exports.SwipeDirection = {}));
var MovementDirection;
(function (MovementDirection) {
    MovementDirection["None"] = "None";
    MovementDirection["Left"] = "Left";
    MovementDirection["Right"] = "Right";
})(MovementDirection = exports.MovementDirection || (exports.MovementDirection = {}));
var SwipeEvents;
(function (SwipeEvents) {
    SwipeEvents["Start"] = "start";
    SwipeEvents["Swipe"] = "swipe";
    SwipeEvents["OnDown"] = "ondown";
    SwipeEvents["OnMove"] = "onmove";
    SwipeEvents["OnUp"] = "onup";
})(SwipeEvents = exports.SwipeEvents || (exports.SwipeEvents = {}));
class SwipeModel extends THREE.EventDispatcher {
    constructor() {
        super();
        this.isSwiping = false;
        this.direction = SwipeDirection.None;
        this.dragLength = 50;
        this.diagonalDelta = 25;
        this.marginOffset = 0;
        this.pointerPos = new THREE.Vector2(0, 0);
        this.pointerDown = new THREE.Vector2(0, 0);
        this.pointerPosition = new THREE.Vector2(0, 0);
        this.onTouchStart = (evt) => {
            loglevel_1.default.debug('SwipeModel: onTouchStart');
            this.dispatchEvent({ type: SwipeEvents.Start });
            const pointerX = evt.touches[0].clientX - this.margin;
            const pointerY = evt.touches[0].clientY;
            this.pointerPosition.set(pointerX, pointerY);
            this.dispatchEvent({ type: SwipeEvents.OnDown, position: this.pointerPosition });
            if (!this.isSwiping) {
                this.isSwiping = true;
                this.pointerDown.set(pointerX, pointerY);
                this.pointerPos.set(pointerX, pointerY);
            }
            evt.preventDefault();
        };
        this.onTouchMove = (evt) => {
            loglevel_1.default.trace('SwipeModel: onTouchMove');
            const pointerX = evt.touches[0].clientX - this.margin;
            const pointerY = evt.touches[0].clientY;
            this.pointerPosition.set(pointerX, pointerY);
            this.dispatchEvent({ type: SwipeEvents.OnMove, position: this.pointerPosition });
            if (this.isSwiping) {
                this.pointerPos.set(pointerX, pointerY);
            }
            evt.preventDefault();
        };
        this.onTouchEnd = (evt) => {
            loglevel_1.default.debug('SwipeModel: onTouchEnd');
            this.dispatchEvent({ type: SwipeEvents.OnUp });
            if (this.isSwiping) {
                this.isSwiping = false;
            }
            evt.preventDefault();
        };
        this.onMouseDown = (evt) => {
            if (evt.button == 0) {
                loglevel_1.default.debug('SwipeModel: onMouseDown');
                this.dispatchEvent({ type: SwipeEvents.Start });
                const pointerX = evt.clientX - this.margin;
                const pointerY = evt.clientY;
                this.pointerPosition.set(pointerX, pointerY);
                this.dispatchEvent({ type: SwipeEvents.OnDown, position: this.pointerPosition });
                if (!this.isSwiping) {
                    this.isSwiping = true;
                    this.pointerDown.set(pointerX, pointerY);
                    this.pointerPos.set(pointerX, pointerY);
                }
            }
        };
        this.onMouseMove = (evt) => {
            const pointerX = evt.clientX - this.margin;
            const pointerY = evt.clientY;
            if (evt.button == 0) {
                loglevel_1.default.trace('SwipeModel: onMouseMove');
                this.pointerPosition.set(pointerX, pointerY);
                this.dispatchEvent({ type: SwipeEvents.OnMove, position: this.pointerPosition });
            }
            if (this.isSwiping) {
                this.pointerPos.set(pointerX, pointerY);
            }
        };
        this.onMouseUp = (evt) => {
            if (evt.button == 0) {
                loglevel_1.default.debug('SwipeModel: onMouseUp');
                this.dispatchEvent({ type: SwipeEvents.OnUp });
                if (this.isSwiping) {
                    this.isSwiping = false;
                }
            }
        };
        loglevel_1.default.debug('SwipeModel: constructor');
    }
    set margin(value) {
        loglevel_1.default.debug(`SwipeModel: set margin - ${value}`);
        this.marginOffset = value;
    }
    get margin() {
        return this.marginOffset;
    }
    addPointerListeners() {
        loglevel_1.default.debug('SwipeModel: addPointerListeners');
        document.addEventListener('touchstart', this.onTouchStart, false);
        document.addEventListener('touchmove', this.onTouchMove, false);
        document.addEventListener('touchend', this.onTouchEnd, false);
        document.addEventListener('mousedown', this.onMouseDown, false);
        document.addEventListener('mousemove', this.onMouseMove, false);
        document.addEventListener('mouseup', this.onMouseUp, false);
    }
    removePointerListeners() {
        loglevel_1.default.debug('SwipeModel: removePointerListeners');
        document.removeEventListener('touchstart', this.onTouchStart);
        document.removeEventListener('touchmove', this.onTouchMove);
        document.removeEventListener('touchend', this.onTouchEnd);
        document.removeEventListener('mousedown', this.onMouseDown);
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    }
    update() {
        const swipeResult = this.updateSwipe();
        if (swipeResult != null) {
            loglevel_1.default.info(`SwipeModel - swipe: ${swipeResult.direction}`);
            this.dispatchEvent({
                type: SwipeEvents.Swipe,
                direction: swipeResult.direction,
                movement: swipeResult.movement
            });
        }
    }
    updateSwipe() {
        if (this.direction !== SwipeDirection.None) {
            let result = { x: 0, y: 0, direction: this.direction, movement: MovementDirection.None };
            this.direction = SwipeDirection.None;
            return result;
        }
        if (!this.isSwiping)
            return null;
        const distance = this.pointerPos.distanceTo(this.pointerDown);
        if (distance < this.dragLength) {
            return null;
        }
        this.isSwiping = false;
        let direction = SwipeDirection.None;
        let movement = MovementDirection.None;
        const deltaX = this.pointerPos.x - this.pointerDown.x;
        const deltaY = this.pointerPos.y - this.pointerDown.y;
        let result = {
            x: this.pointerDown.x,
            y: this.pointerDown.y,
            direction: SwipeDirection.Up,
            movement: MovementDirection.None
        };
        const deltaXabs = Math.abs(deltaX);
        const deltaYabs = Math.abs(deltaY);
        if (deltaXabs > (this.dragLength - this.diagonalDelta) && deltaYabs > (this.dragLength - this.diagonalDelta)) {
            if (deltaX > 0 && deltaY > 0) {
                direction = SwipeDirection.DownRight;
                movement = MovementDirection.Left;
            }
            else if (deltaX > 0 && deltaY < 0) {
                direction = SwipeDirection.UpRight;
                movement = MovementDirection.Left;
            }
            else if (deltaX < 0 && deltaY > 0) {
                direction = SwipeDirection.DownLeft;
                movement = MovementDirection.Right;
            }
            else if (deltaX < 0 && deltaY < 0) {
                direction = SwipeDirection.UpLeft;
                movement = MovementDirection.Right;
            }
        }
        else if (deltaXabs > this.dragLength || deltaYabs > this.dragLength) {
            if (deltaXabs > deltaYabs) {
                if (deltaX > 0) {
                    direction = SwipeDirection.Right;
                    movement = MovementDirection.Left;
                }
                else if (deltaX < 0) {
                    direction = SwipeDirection.Left;
                    movement = MovementDirection.Right;
                }
            }
            else {
                if (deltaY > 0) {
                    direction = SwipeDirection.Down;
                }
                else if (deltaY < 0) {
                    direction = SwipeDirection.Up;
                }
            }
        }
        if (direction !== SwipeDirection.None) {
            result['direction'] = direction;
            result['movement'] = movement;
            return result;
        }
        return null;
    }
}
exports.default = SwipeModel;
