"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const loglevel_1 = require("loglevel");
const play_mode_1 = require("./play_mode");
const move_model_1 = require("../../model/move_model");
const game_over_mode_1 = require("./game_over_mode");
class FailMode extends play_mode_1.PlayModeBase {
    constructor(playController) {
        super(playController);
        this.onMoveCompleted = (event) => {
            this.removeEvents();
            this.playController.mode = new game_over_mode_1.default(this.playController);
            this.playController.soundModel.unmuteAndFadeIn();
        };
        loglevel_1.default.info('PlayMode: FailMode');
        this.addEvents();
        this.playController.levelModel.stopWalking();
        this.playController.levelModel.playerModel.fall();
        this.playController.moveModel.fallDown();
        this.views.influencerView.faceCamera();
        this.views.crowdView.failGame();
        const recordScratch = this.playController.contentModel.getTonePlayer('needle-scratch');
        this.playController.soundModel.muteSong();
        this.playController.soundModel.playSFX(recordScratch);
    }
    addEvents() {
        this.playController.moveModel.addEventListener(move_model_1.MoveEvents.MoveCompleted, this.onMoveCompleted);
    }
    removeEvents() {
        this.playController.moveModel.removeEventListener(move_model_1.MoveEvents.MoveCompleted, this.onMoveCompleted);
    }
    onScreenResize() {
        this.views.scoreView.onScreenResize();
        this.views.logoView.onScreenResize();
    }
    render(gl) {
        this.playController.levelModel.update();
        gl.clear();
        gl.render(this.views.backgroundView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.discoRoadView, this.playController.camera3d);
        gl.render(this.views.influencerView, this.playController.camera3d);
        gl.render(this.views.crowdView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.logoView, this.playController.camera2d);
    }
}
exports.default = FailMode;
