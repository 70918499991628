"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const loglevel_1 = require("loglevel");
const content_model_1 = require("../model/content_model");
const level_model_1 = require("../model/level_model");
const screen_model_1 = require("../model/screen_model");
const score_model_1 = require("../model/score_model");
const swipe_model_1 = require("../model/swipe_model");
const TWEEN = require("@tweenjs/tween.js");
const move_model_1 = require("../model/move_model");
const main_menu_mode_1 = require("./playmodes/main_menu_mode");
const background_view_1 = require("../view/background_view");
const disco_road_1 = require("../view/disco_road");
const influencer_1 = require("../view/influencer");
const main_menu_view_1 = require("../view/main_menu_view");
const swipe_to_begin_view_1 = require("../view/swipe_to_begin_view");
const score_view_1 = require("../view/score_view");
const hud_view_1 = require("../view/hud_view");
const crowd_view_1 = require("../view/crowd_view");
const game_over_view_1 = require("../view/game_over_view");
const sound_model_1 = require("../model/sound_model");
const logo_view_1 = require("../view/logo_view");
const stats_module_1 = require("three/examples/jsm/libs/stats.module");
const ftue_view_1 = require("../view/ftue_view");
const move_unlock_view_1 = require("../view/move_unlock_view");
const game_camera_1 = require("./game_camera");
const camera_mode_1 = require("./camera_mode");
const fever_model_1 = require("../model/fever_model");
const fever_view_1 = require("../view/fever_view");
const fever_road_view_1 = require("../view/fever_road_view");
const cheat_model_1 = require("../model/cheat_model");
let fpsStats = undefined;
if (BUILD_TYPE != "production") {
    fpsStats = stats_module_1.default();
    fpsStats.domElement.style.opacity = '0.667';
    fpsStats.domElement.style.top = '';
    fpsStats.domElement.style.left = '';
    fpsStats.domElement.style.bottom = '0px';
    fpsStats.domElement.style.right = '0px';
    document.body.appendChild(fpsStats.dom);
}
class PlayController {
    constructor(gl, contentModel, screenModel) {
        this.gl = gl;
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        this.camera2d = new THREE.OrthographicCamera(0, this.screenModel.width, this.screenModel.height, 0, 0, 1);
        this.gameCamera = new game_camera_1.default(this.screenModel);
        this.levelModel = new level_model_1.default();
        this.swipeModel = new swipe_model_1.default();
        this.scoreModel = new score_model_1.default();
        this.moveModel = new move_model_1.default();
        this.soundModel = new sound_model_1.default();
        this.feverModel = new fever_model_1.default();
        this.cheatModel = new cheat_model_1.default();
        this.hasShownFTUE = false;
        this.cameraMode = camera_mode_1.CameraMode.Main;
        this.onAnimation = () => {
            if (fpsStats) {
                fpsStats.update();
            }
            this.swipeModel.update();
            // update tween for all play modes
            TWEEN.update();
            this.mode.render(this.gl);
        };
        this.onWindowResize = () => {
            loglevel_1.default.debug('PlayController: onWindowResize');
            let canvasDimensions = screen_model_1.default.getCanvasDimensions(window.innerWidth, window.innerHeight);
            this.screenModel.width = this.camera2d.right = canvasDimensions.width;
            this.screenModel.height = this.camera2d.top = canvasDimensions.height;
            this.screenModel.margin = canvasDimensions.margin;
            this.gl.domElement.style.marginLeft = `${canvasDimensions.margin}px`;
            this.camera2d.updateProjectionMatrix();
            this.gameCamera.onWindowResize();
            this.gl.setSize(this.screenModel.width, this.screenModel.height);
            // update for pointer events
            this.swipeModel.margin = canvasDimensions.margin;
            this.mode.onScreenResize();
        };
        this.onKeyDown = (evt) => {
            if (BUILD_TYPE != "production") {
                let cameraUpdated = false;
                switch (evt.key) {
                    case 'a':
                        this.gameCamera.incrementZ(-0.5);
                        cameraUpdated = true;
                        break;
                    case 'z':
                        this.gameCamera.incrementZ(0.5);
                        cameraUpdated = true;
                        break;
                    case 's':
                        this.gameCamera.incrementY(0.5);
                        cameraUpdated = true;
                        break;
                    case 'x':
                        this.gameCamera.incrementY(-0.5);
                        cameraUpdated = true;
                        break;
                    case 'd':
                        this.gameCamera.incrementXRotation(1);
                        cameraUpdated = true;
                        break;
                    case 'c':
                        this.gameCamera.incrementXRotation(-1);
                        cameraUpdated = true;
                        break;
                    case 'f':
                        this.gameCamera.incrementFOV(5);
                        cameraUpdated = true;
                        break;
                    case 'v':
                        this.gameCamera.incrementFOV(-5);
                        cameraUpdated = true;
                        break;
                    case '?':
                        this.gameCamera.logParams();
                        break;
                }
                if (cameraUpdated) {
                    this.views.backgroundView.setCameraMode(this.cameraMode, this.camera3d);
                }
            }
        };
        loglevel_1.default.debug('PlayController: constructor');
        this.swipeModel.addPointerListeners();
        this.gl.setAnimationLoop(this.onAnimation);
        window.addEventListener('resize', this.onWindowResize);
        if (BUILD_TYPE != "production") {
            document.addEventListener('keydown', this.onKeyDown.bind(this));
        }
        this.views = this.createViews();
        this.setCameraMode(camera_mode_1.CameraMode.Main);
        this.mode = new main_menu_mode_1.default(this);
        this.onWindowResize();
    }
    createViews() {
        return {
            backgroundView: new background_view_1.default(this.contentModel, this.camera3d),
            crowdView: new crowd_view_1.default(this.contentModel, this.moveModel, this.gl),
            discoRoadView: new disco_road_1.default(this.gl, this.contentModel, this.levelModel),
            feverRoadView: new fever_road_view_1.default(this.gl, this.contentModel, this.camera3d),
            feverView: new fever_view_1.default(this.contentModel, this.screenModel, this.feverModel, this.soundModel),
            ftueView: new ftue_view_1.default(this.contentModel, this.screenModel),
            gameOverView: new game_over_view_1.default(this.contentModel, this.screenModel, this.soundModel, this.camera2d),
            hudView: new hud_view_1.default(this.contentModel, this.screenModel, this.scoreModel),
            influencerView: new influencer_1.default(this.contentModel, this.levelModel.playerModel, this.moveModel, this.gl),
            logoView: new logo_view_1.default(this.contentModel, this.screenModel),
            mainMenuView: new main_menu_view_1.default(this.contentModel, this.screenModel, this.camera2d),
            moveUnlockView: new move_unlock_view_1.default(this.contentModel, this.screenModel, this.moveModel),
            scoreView: new score_view_1.default(this.contentModel, this.screenModel, this.scoreModel),
            swipeToBeginView: new swipe_to_begin_view_1.default(this.contentModel, this.screenModel, this.soundModel),
        };
    }
    destructor() {
        loglevel_1.default.debug('PlayController: destructor');
        window.removeEventListener('resize', this.onWindowResize);
        this.swipeModel.removePointerListeners();
    }
    get camera3d() {
        return this.gameCamera.camera;
    }
    resetGame() {
        loglevel_1.default.info('PlayController: resetGame');
        this.scoreModel.reset();
        this.levelModel.reset();
        this.moveModel.reset();
        this.cheatModel.reset();
    }
    setCameraMode(mode) {
        this.cameraMode = mode;
        this.gameCamera.setCameraMode(this.cameraMode);
        this.views.backgroundView.setCameraMode(this.cameraMode, this.camera3d);
    }
    setLightingMode(mode) {
        // notify objects for lighting mode changes
        this.views.influencerView.setLightingMode(mode);
        this.views.crowdView.setLightingMode(mode);
        this.views.discoRoadView.setLightingMode(mode);
        this.views.feverRoadView.setLightingMode(mode);
    }
    onLoadingComplete(stage) {
        switch (stage) {
            case content_model_1.ContentStage.Main:
                // initialize things that need initializing after main is loaded
                this.views.influencerView.loadAnims();
                break;
            case content_model_1.ContentStage.Game:
                // initialize things that need initializing after game is loaded
                this.views.influencerView.loadAnims();
                this.views.discoRoadView.initializeObjectPools();
                this.views.crowdView.createFollowers();
                this.soundModel.initStingers(this.contentModel);
                break;
        }
    }
    updateBPM(bpm) {
        // update the bpm of whatever needs it
        this.moveModel.setBPM(bpm);
        this.levelModel.setBPM(bpm);
        this.views.crowdView.setBPM(bpm);
    }
}
exports.default = PlayController;
