"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const TWEEN = require("@tweenjs/tween.js");
class SongRatingHeart extends THREE.Group {
    constructor(contentModel) {
        super();
        this.heartWidth = 0;
        this.heartHeight = 0;
        this.isShown = false;
        this.contentModel = contentModel;
        this.greySprite = this.createSprite('song_rating_heart', 0x444444);
        this.add(this.greySprite);
        this.heartSprite = this.createSprite('song_rating_heart', 0xff0000);
        this.heartSprite.visible = false;
        this.add(this.heartSprite);
    }
    get width() {
        return this.heartWidth;
    }
    get height() {
        return this.heartHeight;
    }
    reset() {
        this.heartSprite.visible = false;
        this.isShown = false;
    }
    show(color) {
        if (!this.isShown) {
            this.isShown = true;
            this.heartSprite.visible = true;
            this.heartSprite.scale.setX(0.1 * this.heartWidth);
            this.heartSprite.scale.setY(0.1 * this.heartHeight);
            new TWEEN.Tween(this.heartSprite.scale)
                .to({ x: this.heartWidth, y: this.heartHeight }, SongRatingHeart.SHOW_DURATION)
                .easing(TWEEN.Easing.Elastic.Out)
                .start();
            let material = this.heartSprite.material;
            material.opacity = 0.4;
            material.color = color;
            new TWEEN.Tween(this.heartSprite.material)
                .to({ opacity: 1 }, SongRatingHeart.SHOW_DURATION)
                .easing(TWEEN.Easing.Cubic.Out)
                .start();
        }
    }
    createSprite(key, color) {
        const texture = this.contentModel.getTexture(key);
        const material = new THREE.SpriteMaterial({ map: texture, color: color });
        this.heartWidth = material.map.image.width;
        this.heartHeight = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, 0.5);
        sprite.scale.set(this.heartWidth, this.heartHeight, 1);
        sprite.renderOrder = 5;
        return sprite;
    }
}
exports.default = SongRatingHeart;
SongRatingHeart.SHOW_DURATION = 750;
