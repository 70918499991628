"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerState = void 0;
const THREE = require("three");
const loglevel_1 = require("loglevel");
const TWEEN = require("@tweenjs/tween.js");
const level_model_1 = require("./level_model");
const random_1 = require("./random");
var PlayerState;
(function (PlayerState) {
    PlayerState[PlayerState["Dancing"] = 0] = "Dancing";
    PlayerState[PlayerState["Falling"] = 1] = "Falling";
    PlayerState[PlayerState["Celebrating"] = 2] = "Celebrating";
})(PlayerState = exports.PlayerState || (exports.PlayerState = {}));
const playerSkinNames = [
    "green",
    "mauve",
    "blue",
    "purple",
    "coral",
];
const skinNames = [
    "gal-base",
    "follower1",
    "follower2",
    "follower3",
    "follower4",
    "follower5",
    "follower6",
    "follower7",
    "follower8",
    "follower9",
    "follower10",
    "follower11",
    "follower12",
    "follower13",
    "follower14",
];
class PlayerModel {
    constructor() {
        this.position = new THREE.Vector3();
        this.state = PlayerState.Dancing;
        this.currentLane = 2;
        this.playerSkin = playerSkinNames[0];
        this.skinIndex = 0;
        this.isMoving = false;
        this.moveTween = null;
        this.obstaclePosition = new THREE.Vector3(0, 0, 0);
        this.followerSkins = [];
    }
    reset() {
        loglevel_1.default.debug('PlayerModel: reset');
        this.position.setX(0);
        this.position.setZ(0);
        this.isMoving = false;
        this.currentLane = 2;
        this.state = PlayerState.Dancing;
        this.followerSkins = [];
    }
    setObstaclePosition(pos) {
        loglevel_1.default.debug(`PlayerModel: setObstaclePosition - (${pos.x.toFixed(2)}, ${pos.z.toFixed(2)})`);
        this.obstaclePosition.copy(pos);
    }
    fall() {
        this.state = PlayerState.Falling;
        if (this.moveTween) {
            this.moveTween.stop();
            this.moveTween = null;
        }
        new TWEEN.Tween(this.position)
            .to({ x: this.obstaclePosition.x, z: this.obstaclePosition.z - 3 }, PlayerModel.MOVE_DURATION)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
    }
    celebrate() {
        this.state = PlayerState.Celebrating;
    }
    getState() {
        return this.state;
    }
    moveRight() {
        if (this.isMoving) {
            return;
        }
        if (this.currentLane == level_model_1.default.NUM_LANES - 1) {
            return;
        }
        this.currentLane++;
        loglevel_1.default.debug(`PlayerModel: moveRight - lane = ${this.currentLane}`);
        const laneX = level_model_1.default.getXFromLane(this.currentLane);
        this.moveTo(laneX);
    }
    moveLeft() {
        if (this.isMoving) {
            return;
        }
        if (this.currentLane == 0) {
            return;
        }
        this.currentLane--;
        loglevel_1.default.debug(`PlayerModel: moveLeft - lane = ${this.currentLane}`);
        const laneX = level_model_1.default.getXFromLane(this.currentLane);
        this.moveTo(laneX);
    }
    moveTo(x) {
        loglevel_1.default.debug(`PlayerModel: moveTo - x = ${x}`);
        this.isMoving = true;
        this.moveTween = new TWEEN.Tween(this.position)
            .to({ x: x }, PlayerModel.MOVE_DURATION)
            .easing(TWEEN.Easing.Cubic.InOut)
            .start()
            .onComplete(this.onMoveComplete.bind(this));
    }
    onMoveComplete() {
        loglevel_1.default.debug(`PlayerModel: moveComplete`);
        this.isMoving = false;
        this.moveTween = null;
    }
    getSkinName() {
        return this.playerSkin;
    }
    getSkinIndex() {
        return this.skinIndex;
    }
    getRandomFollowerSkin() {
        if (this.followerSkins.length === 0) {
            this.followerSkins = [...skinNames];
            random_1.Random.shuffleArray(this.followerSkins);
        }
        let skin = this.followerSkins.pop();
        if (!skin) {
            skin = "follower1";
        }
        return skin;
    }
    incrementPlayerSkin() {
        this.skinIndex++;
        this.skinIndex %= playerSkinNames.length;
        this.playerSkin = playerSkinNames[this.skinIndex];
    }
    update() {
    }
}
exports.default = PlayerModel;
PlayerModel.MOVE_DURATION = 200;
