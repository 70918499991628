"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FTUEDisplayMode = void 0;
const THREE = require("three");
const TWEEN = require("@tweenjs/tween.js");
const log = require("loglevel");
const ftue_text_1 = require("./ftue_text");
const gem_model_1 = require("../model/gem_model");
var FTUEDisplayMode;
(function (FTUEDisplayMode) {
    FTUEDisplayMode[FTUEDisplayMode["Collect"] = 0] = "Collect";
    FTUEDisplayMode[FTUEDisplayMode["Unique"] = 1] = "Unique";
    FTUEDisplayMode[FTUEDisplayMode["Find"] = 2] = "Find";
})(FTUEDisplayMode = exports.FTUEDisplayMode || (exports.FTUEDisplayMode = {}));
class FTUEView extends THREE.Scene {
    constructor(contentModel, screenModel) {
        super();
        this.clock = new THREE.Clock();
        this.mode = FTUEDisplayMode.Collect;
        this.ftueGroupCollect = null;
        this.ftueGroupGemMoves = null;
        this.ftueGroupFind = null;
        this.ftueGroupTap = null;
        this.uniqueGem = null;
        this.openWedges = [];
        this.filledWedges = [];
        this.wedgeTimer = 0;
        this.currentWedge = 0;
        this.currentGemType = gem_model_1.GemType.Red;
        this.findGem = null;
        this.findWedges = [];
        this.findArrows = [];
        this.scaleTweens = [];
        this.opacityTweens = [];
        this.tapTween = null;
        log.debug("FTUEView: constructor");
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        const backgroundMaterial = new THREE.SpriteMaterial({ color: 0x182735, opacity: 0.85 });
        this.backgroundSprite = new THREE.Sprite(backgroundMaterial);
        this.backgroundSprite.center.set(0.0, 0.0);
        this.add(this.backgroundSprite);
        this.ftueGroupTap = new THREE.Group();
        const tapText = new ftue_text_1.default(this.contentModel, 0x222222);
        tapText.fontSize = 14;
        tapText.hasDropShadow = false;
        tapText.setText("TAP TO CONTINUE");
        this.ftueGroupTap.add(tapText);
        this.add(this.ftueGroupTap);
        this.ftueGroupTap.visible = false;
        const material = tapText.material;
        if (material && !Array.isArray(material)) {
            material.opacity = 0.3;
            this.tapTween = new TWEEN.Tween(material)
                .to({ opacity: 0.9 }, 500)
                .repeat(Infinity)
                .yoyo(true)
                .easing(TWEEN.Easing.Quadratic.InOut)
                .start();
        }
        this.onBeforeRender = this.preRender.bind(this);
        this.onScreenResize();
    }
    get ftueMode() {
        return this.mode;
    }
    reset() {
        if (!this.ftueGroupCollect) {
            this.ftueGroupCollect = this.createCollectGroup();
            this.add(this.ftueGroupCollect);
            this.ftueGroupCollect.visible = false;
        }
        if (!this.ftueGroupGemMoves) {
            this.ftueGroupGemMoves = this.createGemMovesGroup();
            this.add(this.ftueGroupGemMoves);
        }
        if (!this.ftueGroupFind) {
            this.ftueGroupFind = this.createFindGroup();
            this.add(this.ftueGroupFind);
        }
        this.currentGemType = gem_model_1.GemType.Red;
        this.setWedgeColor(this.currentGemType);
        this.onScreenResize();
    }
    shutdown() {
        for (let tween of this.scaleTweens) {
            tween.stop();
        }
        this.scaleTweens = [];
        for (let tween of this.opacityTweens) {
            tween.stop();
        }
        this.opacityTweens = [];
        if (this.tapTween) {
            this.tapTween.stop();
        }
        this.tapTween = null;
    }
    setFTUEMode(mode) {
        this.mode = mode;
        switch (mode) {
            case FTUEDisplayMode.Collect:
                this.ftueGroupCollect.visible = true;
                this.ftueGroupGemMoves.visible = false;
                this.ftueGroupFind.visible = false;
                break;
            case FTUEDisplayMode.Unique:
                this.ftueGroupCollect.visible = false;
                this.ftueGroupGemMoves.visible = true;
                this.ftueGroupFind.visible = false;
                this.currentWedge = 0;
                this.currentGemType = gem_model_1.GemType.Red;
                this.setWedgeColor(this.currentGemType);
                this.wedgeTimer = FTUEView.WEDGE_LONG_DELAY;
                for (let wedge of this.filledWedges) {
                    wedge.visible = false;
                }
                break;
            case FTUEDisplayMode.Find:
                this.ftueGroupCollect.visible = false;
                this.ftueGroupGemMoves.visible = false;
                this.ftueGroupFind.visible = true;
                this.currentGemType = gem_model_1.GemType.Red;
                this.setWedgeColor(this.currentGemType);
                for (let tween of this.scaleTweens) {
                    tween.start();
                }
                for (let tween of this.opacityTweens) {
                    tween.start();
                }
                break;
        }
        this.ftueGroupTap.visible = false;
    }
    showTapToContinue() {
        this.ftueGroupTap.visible = true;
    }
    onScreenResize() {
        log.debug("FTUEView: onScreenResize");
        // background
        this.backgroundSprite.scale.set(this.screenModel.width, this.screenModel.height * 0.4, 1.0);
        this.backgroundSprite.position.setY(this.screenModel.height * 0.075);
        // groupscale actual width and height design is 375 x 812
        const groupScale = this.screenModel.calculateScale(1.0, 375, 0.4, 324);
        // collect group
        this.ftueGroupCollect?.scale.set(groupScale, groupScale, 1.0);
        this.ftueGroupCollect?.position.setX(this.screenModel.width / 2);
        this.ftueGroupCollect?.position.setY(this.screenModel.height * (0.075 + 0.2));
        // gemMoves group
        this.ftueGroupGemMoves?.scale.set(groupScale, groupScale, 1.0);
        this.ftueGroupGemMoves?.position.setX(this.screenModel.width / 2);
        this.ftueGroupGemMoves?.position.setY(this.screenModel.height * (0.075 + 0.2));
        // find group
        this.ftueGroupFind?.scale.set(groupScale, groupScale, 1.0);
        this.ftueGroupFind?.position.setX(this.screenModel.width / 2);
        this.ftueGroupFind?.position.setY(this.screenModel.height * (0.075 + 0.2));
        // tap group
        this.ftueGroupTap?.scale.set(groupScale, groupScale, 1.0);
        this.ftueGroupTap?.position.setX(this.screenModel.width / 2);
        this.ftueGroupTap?.position.setY(this.screenModel.height * 0.045);
    }
    ;
    createCollectGroup() {
        const group = new THREE.Group();
        const collectText = new ftue_text_1.default(this.contentModel, 0xffb300);
        collectText.fontSize = 20;
        collectText.setText("COLLECT GEMS");
        collectText.position.setY(120);
        group.add(collectText);
        const unlockText = new ftue_text_1.default(this.contentModel, 0xffffff);
        unlockText.fontSize = 16;
        unlockText.setText("TO UNLOCK UNIQUE DANCE MOVES");
        unlockText.position.setY(90);
        group.add(unlockText);
        const redGem = this.createSprite('ftue-red', 0.5);
        redGem.position.setY(-20);
        redGem.position.setX(-125);
        group.add(redGem);
        const blueGem = this.createSprite('ftue-blue', 0.5);
        blueGem.position.setY(-20);
        blueGem.position.setX(-35);
        group.add(blueGem);
        const purpleGem = this.createSprite('ftue-purple', 0.5);
        purpleGem.position.setY(-20);
        purpleGem.position.setX(55);
        group.add(purpleGem);
        const greenGem = this.createSprite('ftue-green', 0.5);
        greenGem.position.setY(-20);
        greenGem.position.setX(130);
        group.add(greenGem);
        return group;
    }
    createGemMovesGroup() {
        const group = new THREE.Group();
        const eachGemText = new ftue_text_1.default(this.contentModel, 0xffffff);
        eachGemText.fontSize = 16;
        eachGemText.setText("EACH GEM HAS");
        eachGemText.position.setX(-75);
        eachGemText.position.setY(120);
        group.add(eachGemText);
        const uniqueText = new ftue_text_1.default(this.contentModel, 0xffb300);
        uniqueText.fontSize = 20;
        uniqueText.setText("8 UNIQUE");
        uniqueText.position.setX(70);
        uniqueText.position.setY(120);
        group.add(uniqueText);
        const movesText = new ftue_text_1.default(this.contentModel, 0xffb300);
        movesText.fontSize = 20;
        movesText.setText("DANCE MOVES!");
        movesText.position.setY(90);
        group.add(movesText);
        const gemY = -40;
        this.uniqueGem = this.createSprite('ftue-red', 0.4);
        this.uniqueGem.position.setY(gemY);
        group.add(this.uniqueGem);
        for (let i = 0; i < 8; i++) {
            let wedge = this.addWedge(i * 45, 'chaos-wedge-empty');
            wedge.position.setY(gemY);
            group.add(wedge);
            this.openWedges.push(wedge);
        }
        for (let i = 0; i < 8; i++) {
            let wedge = this.addWedge(i * -45, 'chaos-wedge');
            wedge.position.setY(gemY);
            group.add(wedge);
            this.filledWedges.push(wedge);
        }
        return group;
    }
    createFindGroup() {
        const group = new THREE.Group();
        const eachGemText = new ftue_text_1.default(this.contentModel, 0xffb300);
        eachGemText.fontSize = 20;
        eachGemText.setText("FIND ALL 32 DANCE MOVES!");
        eachGemText.position.setY(120);
        group.add(eachGemText);
        const movesText = new ftue_text_1.default(this.contentModel, 0xffffff);
        movesText.fontSize = 16;
        movesText.setText("SWIPE IN ALL DIRECTIONS");
        movesText.position.setY(90);
        group.add(movesText);
        const gemY = -40;
        for (let i = 0; i < 8; i++) {
            let wedge = this.addWedge(i * 45, 'chaos-wedge-empty');
            wedge.position.setY(gemY);
            group.add(wedge);
            this.findWedges.push(wedge);
        }
        for (let i = 0; i < 8; i++) {
            let arrow = this.addArrow(i * 45, 'ftue-arrow');
            arrow.position.setY(gemY);
            group.add(arrow);
            this.findArrows.push(arrow);
            const scale = arrow.scale.y;
            arrow.scale.setY(scale * 0.01);
            const scaleTween = new TWEEN.Tween(arrow.scale)
                .repeatDelay(400)
                .to({ y: scale * 1.15 }, 1000)
                .repeat(Infinity)
                .easing(TWEEN.Easing.Exponential.Out);
            if (i == 0) {
                scaleTween.onRepeat(() => {
                    window.setTimeout(this.incrementGemType.bind(this), 400);
                });
            }
            this.scaleTweens.push(scaleTween);
            arrow.material.opacity = 0.1;
            const opacityTween = new TWEEN.Tween(arrow.material)
                .repeatDelay(400)
                .to({ opacity: 1.0 }, 1000)
                .repeat(Infinity)
                .easing(TWEEN.Easing.Quadratic.Out);
            this.opacityTweens.push(opacityTween);
        }
        this.findGem = this.createSprite('ftue-red', 0.4);
        this.findGem.position.setY(gemY);
        group.add(this.findGem);
        return group;
    }
    createSprite(key, scale) {
        const texture = this.contentModel.getTexture(key);
        const material = new THREE.SpriteMaterial({ map: texture });
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, 0.5);
        sprite.scale.set(width * scale, height * scale, 1);
        return sprite;
    }
    addWedge(angle, textureKey) {
        const texture = this.contentModel.getTexture(textureKey);
        const material = new THREE.SpriteMaterial({ map: texture });
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, -1.1);
        sprite.position.set(0, 0, 0);
        sprite.scale.set(width * 0.27, height * 0.27, 1);
        sprite.material.rotation = angle * THREE.MathUtils.DEG2RAD;
        return sprite;
    }
    addArrow(angle, textureKey) {
        const texture = this.contentModel.getTexture(textureKey);
        const material = new THREE.SpriteMaterial({ map: texture, opacity: 0.8 });
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, -0.4);
        sprite.position.set(0, 0, 0);
        sprite.scale.set(width * 0.25, height * 0.25, 1);
        sprite.material.rotation = angle * THREE.MathUtils.DEG2RAD;
        return sprite;
    }
    setWedgeColor(gemType) {
        let color = 0xffffff;
        switch (gemType) {
            case gem_model_1.GemType.Red:
                color = 0xd15244;
                this.uniqueGem.material.map = this.contentModel.getTexture('ftue-red');
                this.findGem.material.map = this.contentModel.getTexture('ftue-red');
                break;
            case gem_model_1.GemType.Green:
                color = 0x67c98e;
                this.uniqueGem.material.map = this.contentModel.getTexture('ftue-green');
                this.findGem.material.map = this.contentModel.getTexture('ftue-green');
                break;
            case gem_model_1.GemType.Blue:
                color = 0x4accff;
                this.uniqueGem.material.map = this.contentModel.getTexture('ftue-blue');
                this.findGem.material.map = this.contentModel.getTexture('ftue-blue');
                break;
            case gem_model_1.GemType.Purple:
                color = 0xab46cc;
                this.uniqueGem.material.map = this.contentModel.getTexture('ftue-purple');
                this.findGem.material.map = this.contentModel.getTexture('ftue-purple');
                break;
        }
        const width = this.uniqueGem.material.map.image.width;
        const height = this.uniqueGem.material.map.image.height;
        this.uniqueGem?.scale.set(width * 0.4, height * 0.4, 1.0);
        this.findGem?.scale.set(width * 0.4, height * 0.4, 1.0);
        const wedgeColor = new THREE.Color(color);
        for (let wedge of this.openWedges) {
            wedge.material.color = wedgeColor;
        }
        for (let wedge of this.filledWedges) {
            wedge.material.color = wedgeColor;
        }
        for (let wedge of this.findWedges) {
            wedge.material.color = wedgeColor;
        }
    }
    incrementGemType() {
        switch (this.currentGemType) {
            case gem_model_1.GemType.Red:
                this.currentGemType = gem_model_1.GemType.Green;
                break;
            case gem_model_1.GemType.Green:
                this.currentGemType = gem_model_1.GemType.Blue;
                break;
            case gem_model_1.GemType.Blue:
                this.currentGemType = gem_model_1.GemType.Purple;
                break;
            case gem_model_1.GemType.Purple:
                this.currentGemType = gem_model_1.GemType.Red;
                break;
        }
        this.setWedgeColor(this.currentGemType);
    }
    preRender(gl, scene, camera, renderTarget) {
        const dt = this.clock.getDelta();
        if (this.mode == FTUEDisplayMode.Unique) {
            if (this.wedgeTimer > 0) {
                this.wedgeTimer -= dt;
                if (this.wedgeTimer <= 0) {
                    if (this.currentWedge < this.filledWedges.length) {
                        this.filledWedges[this.currentWedge].visible = true;
                        this.currentWedge++;
                        if (this.currentWedge < this.filledWedges.length) {
                            this.wedgeTimer = FTUEView.WEDGE_SHORT_DELAY;
                        }
                        else {
                            this.wedgeTimer = FTUEView.WEDGE_LONG_DELAY;
                        }
                    }
                    else {
                        this.wedgeTimer = FTUEView.WEDGE_LONG_DELAY;
                        this.currentWedge = 0;
                        for (let wedge of this.filledWedges) {
                            wedge.visible = false;
                        }
                        this.incrementGemType();
                    }
                }
            }
        }
    }
}
exports.default = FTUEView;
FTUEView.WEDGE_LONG_DELAY = 0.5;
FTUEView.WEDGE_SHORT_DELAY = 0.1;
