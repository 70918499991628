"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const move_data_1 = require("../model/move_data");
const swipe_model_1 = require("../model/swipe_model");
class MoveTrackerView extends THREE.Scene {
    constructor(moveType, iconKey, contentModel, screenModel, scoreModel) {
        super();
        this.moveMap = new Map();
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        this.scoreModel = scoreModel;
        this.moveType = moveType;
        this.indicatorGroup = new THREE.Group();
        this.add(this.indicatorGroup);
        this.indicatorGroup.position.set(this.screenModel.safeArea.min.x + 20, this.screenModel.safeArea.max.y - 20, 0);
        this.indicatorGroup.scale.set(MoveTrackerView.UNSELECTED_SCALE, MoveTrackerView.UNSELECTED_SCALE, 1);
        const circle = this.addIconSprite(iconKey);
        this.indicatorGroup.add(circle);
        this.moveMap.set(swipe_model_1.SwipeDirection.Up, {
            emptySprite: this.addWedge(0, 'chaos-wedge-empty-small'),
            sprite: this.addWedge(0, 'chaos-wedge-small'),
            completed: false
        });
        this.moveMap.set(swipe_model_1.SwipeDirection.UpLeft, {
            emptySprite: this.addWedge(45, 'chaos-wedge-empty-small'),
            sprite: this.addWedge(45, 'chaos-wedge-small'),
            completed: false
        });
        this.moveMap.set(swipe_model_1.SwipeDirection.Left, {
            emptySprite: this.addWedge(90, 'chaos-wedge-empty-small'),
            sprite: this.addWedge(90, 'chaos-wedge-small'),
            completed: false
        });
        this.moveMap.set(swipe_model_1.SwipeDirection.DownLeft, {
            emptySprite: this.addWedge(135, 'chaos-wedge-empty-small'),
            sprite: this.addWedge(135, 'chaos-wedge-small'),
            completed: false
        });
        this.moveMap.set(swipe_model_1.SwipeDirection.Down, {
            emptySprite: this.addWedge(180, 'chaos-wedge-empty-small'),
            sprite: this.addWedge(180, 'chaos-wedge-small'),
            completed: false
        });
        this.moveMap.set(swipe_model_1.SwipeDirection.DownRight, {
            emptySprite: this.addWedge(-135, 'chaos-wedge-empty-small'),
            sprite: this.addWedge(-135, 'chaos-wedge-small'),
            completed: false
        });
        this.moveMap.set(swipe_model_1.SwipeDirection.Right, {
            emptySprite: this.addWedge(-90, 'chaos-wedge-empty-small'),
            sprite: this.addWedge(-90, 'chaos-wedge-small'),
            completed: false
        });
        this.moveMap.set(swipe_model_1.SwipeDirection.UpRight, {
            emptySprite: this.addWedge(-45, 'chaos-wedge-empty-small'),
            sprite: this.addWedge(-45, 'chaos-wedge-small'),
            completed: false
        });
        this.updateMoveWedges();
    }
    addIconSprite(textureKey) {
        const texture = this.contentModel.getTexture(textureKey);
        const material = new THREE.SpriteMaterial({ map: texture });
        material.color = new THREE.Color(MoveTrackerView.getColor(this.moveType));
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, 0.5);
        sprite.position.set(0, 0, 0);
        sprite.scale.set((width * 1.25), (height * 1.25), 1);
        return sprite;
    }
    addWedge(angle, textureKey) {
        const texture = this.contentModel.getTexture(textureKey);
        const material = new THREE.SpriteMaterial({ map: texture, color: MoveTrackerView.getColor(this.moveType) });
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, -1.1);
        sprite.position.set(0, 0, 0);
        sprite.scale.set(width * 0.52, height * 0.52, 1);
        sprite.material.rotation = angle * THREE.MathUtils.DEG2RAD;
        this.indicatorGroup.add(sprite);
        return sprite;
    }
    reset(gemLevel) {
        this.setGemLevel(gemLevel);
        this.setSelected(false);
        for (const [direction, trackerData] of this.moveMap) {
            trackerData.completed = false;
        }
        this.updateMoveWedges();
    }
    setGemLevel(gemLevel) {
    }
    setPosition(x, y) {
        this.indicatorGroup.position.set(x, y, 0);
    }
    static getColor(moveType) {
        switch (moveType) {
            case move_data_1.MoveType.Red:
                return 0xc62a07;
            case move_data_1.MoveType.Green:
                return 0x10a56a;
            case move_data_1.MoveType.Blue:
                return 0x1db8ef;
            case move_data_1.MoveType.Purple:
                return 0xa54bd3;
        }
        return 0xffffff;
    }
    completeMove(direction) {
        let trackerData = this.moveMap.get(direction);
        if (trackerData) {
            trackerData.completed = true;
            this.updateMoveWedges();
        }
    }
    setSelected(selected) {
        if (selected) {
            this.indicatorGroup.scale.set(MoveTrackerView.SELECTED_SCALE, MoveTrackerView.SELECTED_SCALE, 1);
        }
        else {
            this.indicatorGroup.scale.set(MoveTrackerView.UNSELECTED_SCALE, MoveTrackerView.UNSELECTED_SCALE, 1);
        }
    }
    updateMoveWedges() {
        for (const [direction, trackerData] of this.moveMap) {
            trackerData.sprite.visible = trackerData.completed;
        }
    }
}
exports.default = MoveTrackerView;
MoveTrackerView.UNSELECTED_SCALE = 0.65;
MoveTrackerView.SELECTED_SCALE = 0.8;
