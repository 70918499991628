"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const three_1 = require("three");
const camera_mode_1 = require("../controller/camera_mode");
const RIGHT_VEC = new THREE.Vector3(.98, 0, 0);
class BackgroundView extends THREE.Scene {
    constructor(contentModel, camera) {
        super();
        this.clock = new THREE.Clock();
        this.contentModel = contentModel;
        const geometry = new THREE.BufferGeometry();
        const indices = [];
        const vertices = [];
        const uvs = [];
        const size = 20;
        // segments and internalSegments must be 
        // even number integers
        const segments = 16;
        const internalSegments = 8;
        const curvature = -3;
        const halfSize = size / 2;
        const segmentSize = size / segments;
        const maxVec = new THREE.Vector2(halfSize, halfSize);
        const maxLen = maxVec.length();
        const segmentStartIndex = segments / 2 - internalSegments / 2;
        const segmentEndIndex = segments / 2 + internalSegments / 2;
        const internalSegmentSize = (internalSegments / segments) * size;
        const internalHalfSize = internalSegmentSize / 2;
        // generate vertices and uv data for a curved grid geometry
        for (let yIndex = 0; yIndex <= segments; yIndex++) {
            const y = (yIndex * segmentSize) - halfSize;
            for (let xIndex = 0; xIndex <= segments; xIndex++) {
                const x = (xIndex * segmentSize) - halfSize;
                const vec = new THREE.Vector2(x, y);
                const z = Math.cos(vec.length() / maxLen) * curvature;
                vertices.push(x, -y, z);
                let u = 0;
                let v = 0;
                if (xIndex <= segmentStartIndex || xIndex >= segmentEndIndex) {
                    u = 0;
                }
                else {
                    u = 1 - Math.abs(x / internalHalfSize);
                }
                if (yIndex <= segmentStartIndex || yIndex >= segmentEndIndex) {
                    v = 0;
                }
                else {
                    v = 1 - Math.abs(y / internalHalfSize);
                }
                uvs.push(u, v);
            }
        }
        // generate indices (data for element array buffer)
        for (let i = 0; i < segments; i++) {
            for (let j = 0; j < segments; j++) {
                const a = i * (segments + 1) + (j + 1);
                const b = i * (segments + 1) + j;
                const c = (i + 1) * (segments + 1) + j;
                const d = (i + 1) * (segments + 1) + (j + 1);
                // generate two faces (triangles) per iteration
                indices.push(a, b, d); // face one
                indices.push(b, c, d); // face two
            }
        }
        // set geometry indices and attributes
        geometry.setIndex(indices);
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
        // create the material
        const material = new THREE.MeshBasicMaterial({
            map: this.contentModel.getTexture('skyback'),
            //wireframe: true
        });
        // create the mesh
        this.mesh = new THREE.Mesh(geometry, material);
        this.add(this.mesh);
        this.setCameraMode(camera_mode_1.CameraMode.Main, camera);
        // speed of background rotation
        this.rotationSpeed = 17;
        this.onBeforeRender = this.preRender.bind(this);
    }
    setCameraMode(cameraMode, camera) {
        switch (cameraMode) {
            case camera_mode_1.CameraMode.Main:
                this.repositionToCamera(camera, 2.5, 65);
                this.resetMaterial();
                break;
            case camera_mode_1.CameraMode.Game:
                this.repositionToCamera(camera, 2.75, -73);
                break;
            case camera_mode_1.CameraMode.Fail:
                this.repositionToCamera(camera, 5, 10);
                break;
            case camera_mode_1.CameraMode.GameOver:
                this.repositionToCamera(camera, 4, 40);
                this.resetMaterial();
                break;
            case camera_mode_1.CameraMode.Fever:
                {
                    this.repositionToCamera(camera, 4, 46);
                    const material = this.mesh.material;
                    material.color = new THREE.Color(0x111111);
                }
                break;
        }
    }
    resetMaterial() {
        const material = this.mesh.material;
        material.color = new THREE.Color(0xffffff);
    }
    repositionToCamera(camera, moveDistance, rotOffset) {
        // get the camera's position and direction vector
        let camPos = camera.position.clone();
        let camDir = new THREE.Vector3();
        camera.getWorldDirection(camDir);
        // start the object at the camera, then
        // away down the direction of sight
        camDir.multiplyScalar(moveDistance);
        camDir.applyAxisAngle(RIGHT_VEC, rotOffset * three_1.MathUtils.DEG2RAD);
        camPos.add(camDir);
        this.mesh.position.set(camPos.x, camPos.y, camPos.z);
        // rotate it towards the camera
        let targetQuat = camera.quaternion.clone();
        this.mesh.quaternion.rotateTowards(targetQuat, Math.PI);
    }
    preRender(gl, scene, camera, renderTarget) {
        // pre-rotate the mesh by the rotation speed
        const dt = this.clock.getDelta();
        const angleIncrement = (this.rotationSpeed * THREE.MathUtils.DEG2RAD * dt);
        this.mesh.rotateZ(angleIncrement);
    }
}
exports.default = BackgroundView;
