"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const three_1 = require("three");
const loglevel_1 = require("loglevel");
const content_model_1 = require("../model/content_model");
class LoadProgressView extends THREE.Scene {
    constructor(content, screen) {
        super();
        this.content = content;
        this.screen = screen;
        this.logoWidth = 0;
        this.logoHeight = 0;
        this.barWidthPercent = 0.8;
        this.barHeightPercent = 0.02;
        this.logoGroup = new THREE.Group();
        this.add(this.logoGroup);
        this.loadLogo();
        this.progressBox = this.getProgressBox();
        this.progressBarBackground = this.createProgressBackground();
        this.add(this.progressBarBackground);
        this.progressBar = this.createProgressBar();
        this.add(this.progressBar);
        this.onBeforeRender = this.preRender.bind(this);
    }
    createProgressBackground() {
        const backgroundGeometry = new THREE.BufferGeometry();
        backgroundGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(18), 3));
        const barBackgroundMaterial = new THREE.MeshBasicMaterial({
            color: 0x222222,
        });
        const progressBarBackground = new THREE.Mesh(backgroundGeometry, barBackgroundMaterial);
        const box = this.progressBox;
        const position = progressBarBackground.geometry.attributes.position;
        position.setXY(0, box.min.x, box.min.y);
        position.setXY(1, box.min.x, box.max.y);
        position.setXY(2, box.max.x, box.min.y);
        position.setXY(3, box.min.x, box.max.y);
        position.setXY(4, box.max.x, box.max.y);
        position.setXY(5, box.max.x, box.min.y);
        position.needsUpdate = true;
        return progressBarBackground;
    }
    createProgressBar() {
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(18), 3));
        const material = new THREE.MeshBasicMaterial({
            color: 0xFF9833,
        });
        return new THREE.Mesh(geometry, material);
    }
    getProgressBox() {
        const safeArea = this.screen.safeArea;
        let width = safeArea.max.x - safeArea.min.x;
        width *= this.barWidthPercent;
        let centerX = (safeArea.min.x + safeArea.max.x) / 2;
        let height = safeArea.max.y - safeArea.min.y;
        height *= this.barHeightPercent;
        let centerY = 7 * (safeArea.min.y + safeArea.max.y) / 8;
        const box = new THREE.Box2(new three_1.Vector2(centerX - width / 2, centerY - height / 2), new three_1.Vector2(centerX + width / 2, centerY + height / 2));
        return box;
    }
    async loadLogo() {
        const loader = new THREE.TextureLoader();
        try {
            let texture = await loader.loadAsync('texture/LogoSmall.png');
            texture.flipY = false;
            const spriteMaterial = new THREE.SpriteMaterial({
                map: texture,
                side: THREE.DoubleSide,
            });
            this.logoWidth = spriteMaterial.map.image.width;
            this.logoHeight = spriteMaterial.map.image.height;
            const logoSprite = new THREE.Sprite(spriteMaterial);
            logoSprite.center.set(0.5, 0.5);
            logoSprite.scale.set(this.logoWidth, this.logoHeight, 1);
            logoSprite.name = "logosprite";
            this.logoGroup.add(logoSprite);
            this.screenResize();
        }
        catch (error) {
            loglevel_1.default.error(`LoadProgressView: loadLogo - unable to load logo`, error);
        }
    }
    screenResize() {
        const logoScale = this.screen.calculateScale(0.8, this.logoWidth, 0.5, this.logoHeight);
        this.logoGroup.position.set(this.screen.width / 2, this.screen.height / 2, 0);
        this.logoGroup.scale.set(logoScale, logoScale, 1);
        const box = this.progressBox;
        const position = this.progressBarBackground.geometry.attributes.position;
        position.setXY(0, box.min.x, box.min.y);
        position.setXY(1, box.min.x, box.max.y);
        position.setXY(2, box.max.x, box.min.y);
        position.setXY(3, box.min.x, box.max.y);
        position.setXY(4, box.max.x, box.max.y);
        position.setXY(5, box.max.x, box.min.y);
        position.needsUpdate = true;
    }
    cleanUp() {
        let sprite = this.getObjectByName("logosprite");
        if (sprite) {
            this.logoGroup.remove(sprite);
            let material = sprite.material;
            if (material) {
                let texture = material.map;
                texture?.dispose();
                material.map = null;
            }
        }
        this.remove(this.progressBar);
        this.progressBar.geometry.dispose();
        this.remove(this.progressBarBackground);
        this.progressBarBackground.geometry.dispose();
    }
    preRender(gl, scene, camera, renderTarget) {
        const box = this.progressBox;
        const progress = (box.max.x - box.min.x) * (1 - this.content.getProgress(content_model_1.ContentStage.Preload));
        const position = this.progressBar.geometry.attributes.position;
        position.setXY(0, box.min.x, box.min.y);
        position.setXY(1, box.min.x, box.max.y);
        position.setXY(2, box.max.x - progress, box.min.y);
        position.setXY(3, box.min.x, box.max.y);
        position.setXY(4, box.max.x - progress, box.max.y);
        position.setXY(5, box.max.x - progress, box.min.y);
        position.needsUpdate = true;
    }
}
exports.default = LoadProgressView;
