"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoveMode = exports.MoveEvents = void 0;
const THREE = require("three");
const loglevel_1 = require("loglevel");
const dance_mixer_1 = require("../view/dance_mixer");
const gem_model_1 = require("./gem_model");
const move_data_1 = require("./move_data");
const swipe_model_1 = require("./swipe_model");
const random_1 = require("./random");
var MoveEvents;
(function (MoveEvents) {
    MoveEvents["MoveStarted"] = "movestarted";
    MoveEvents["MoveCompleted"] = "moveconpleted";
    MoveEvents["UniqueMove"] = "uniquemove";
    MoveEvents["MoveSetComplete"] = "movesetcomplete";
})(MoveEvents = exports.MoveEvents || (exports.MoveEvents = {}));
var MoveMode;
(function (MoveMode) {
    MoveMode[MoveMode["Disabled"] = 0] = "Disabled";
    MoveMode[MoveMode["Gameplay"] = 1] = "Gameplay";
    MoveMode[MoveMode["Fever"] = 2] = "Fever";
})(MoveMode = exports.MoveMode || (exports.MoveMode = {}));
const attractMoves = [
    { moveType: move_data_1.MoveType.Red, direction: swipe_model_1.SwipeDirection.UpLeft },
    { moveType: move_data_1.MoveType.Purple, direction: swipe_model_1.SwipeDirection.DownLeft },
    { moveType: move_data_1.MoveType.Purple, direction: swipe_model_1.SwipeDirection.UpLeft },
    { moveType: move_data_1.MoveType.Green, direction: swipe_model_1.SwipeDirection.UpRight },
    { moveType: move_data_1.MoveType.Blue, direction: swipe_model_1.SwipeDirection.UpRight },
    { moveType: move_data_1.MoveType.Blue, direction: swipe_model_1.SwipeDirection.Down },
];
const randomMoveTypes = [move_data_1.MoveType.Red, move_data_1.MoveType.Green, move_data_1.MoveType.Blue, move_data_1.MoveType.Purple];
const randomSwipeDirections = [swipe_model_1.SwipeDirection.Up, swipe_model_1.SwipeDirection.UpLeft, swipe_model_1.SwipeDirection.Left, swipe_model_1.SwipeDirection.DownLeft,
    swipe_model_1.SwipeDirection.Down, swipe_model_1.SwipeDirection.DownRight, swipe_model_1.SwipeDirection.Right, swipe_model_1.SwipeDirection.UpRight];
class MoveModel extends THREE.EventDispatcher {
    constructor() {
        super();
        this.moveType = move_data_1.MoveType.None;
        this.mode = MoveMode.Disabled;
        this.moveMaps = new Map();
        this.playerDanceMixer = null;
        this.currentMove = null;
        this.onMoveStart = (event) => {
            loglevel_1.default.debug(`MoveModel: onMoveStart  move = ${this.currentMove?.name}`);
            // move started
            this.dispatchEvent({
                type: MoveEvents.MoveStarted,
                move: this.currentMove
            });
            if (this.currentMove) {
                // check to see if this is 
                // a unique move
                let moveMap = this.moveMaps.get(this.currentMove.type);
                if (moveMap) {
                    if (!moveMap.get(this.currentMove.direction)) {
                        moveMap.set(this.currentMove.direction, true);
                        loglevel_1.default.debug(`MoveModel: onMoveStart - unique move`);
                        this.dispatchEvent({
                            type: MoveEvents.UniqueMove,
                            move: this.currentMove,
                            count: moveMap.size
                        });
                        // check to see if move set is complete
                        if (moveMap.size == 8) {
                            loglevel_1.default.debug(`MoveModel: onMoveStart - move set complete`);
                            loglevel_1.default.info(`Move Set Complete: ${this.currentMove.type} `);
                            this.dispatchEvent({
                                type: MoveEvents.MoveSetComplete,
                                move: this.currentMove
                            });
                        }
                    }
                }
            }
        };
        this.onMoveComplete = (event) => {
            loglevel_1.default.debug(`MoveModel: onMoveComplete  move = ${this.currentMove?.name}`);
            this.dispatchEvent({
                type: MoveEvents.MoveCompleted,
                move: this.currentMove
            });
        };
        loglevel_1.default.debug('MoveModel: constructor');
        this.moveMaps.set(move_data_1.MoveType.Red, new Map());
        this.moveMaps.set(move_data_1.MoveType.Green, new Map());
        this.moveMaps.set(move_data_1.MoveType.Blue, new Map());
        this.moveMaps.set(move_data_1.MoveType.Purple, new Map());
    }
    get moveMode() {
        return this.mode;
    }
    set moveMode(mode) {
        loglevel_1.default.debug(`MoveModel: set - ${mode} `);
        this.mode = mode;
        this.playerDanceMixer?.setCanInterrupt(this.mode == MoveMode.Fever);
    }
    reset() {
        loglevel_1.default.debug('MoveModel: reset');
        this.mode = MoveMode.Disabled;
        this.currentMove = null;
        this.moveType = move_data_1.MoveType.None;
        for (let [moveType, moveSet] of this.moveMaps) {
            moveSet.clear();
        }
    }
    setPlayerDanceMixer(mixer) {
        loglevel_1.default.debug(`MoveModel: setPlayerDanceMixer`);
        this.playerDanceMixer = mixer;
        this.playerDanceMixer.addEventListener(dance_mixer_1.DanceMixerEvents.MoveStart, this.onMoveStart);
        this.playerDanceMixer.addEventListener(dance_mixer_1.DanceMixerEvents.MoveComplete, this.onMoveComplete);
    }
    setBPM(bpm) {
        if (this.playerDanceMixer) {
            this.playerDanceMixer.setBPM(bpm);
        }
    }
    setMoveType(gemType) {
        loglevel_1.default.debug(`MoveModel: setMoveType  gemType = ${gemType}`);
        this.moveType = MoveModel.gemTypeToMoveType(gemType);
    }
    getCompletedMoves(moveType) {
        const moves = [];
        let moveMap = this.moveMaps.get(moveType);
        moves.push(moveMap.has(swipe_model_1.SwipeDirection.Up));
        moves.push(moveMap.has(swipe_model_1.SwipeDirection.UpLeft));
        moves.push(moveMap.has(swipe_model_1.SwipeDirection.Left));
        moves.push(moveMap.has(swipe_model_1.SwipeDirection.DownLeft));
        moves.push(moveMap.has(swipe_model_1.SwipeDirection.Down));
        moves.push(moveMap.has(swipe_model_1.SwipeDirection.DownRight));
        moves.push(moveMap.has(swipe_model_1.SwipeDirection.Right));
        moves.push(moveMap.has(swipe_model_1.SwipeDirection.UpRight));
        return moves;
    }
    static moveTypeToGemType(moveType) {
        switch (moveType) {
            case move_data_1.MoveType.Red:
                return gem_model_1.GemType.Red;
                break;
            case move_data_1.MoveType.Green:
                return gem_model_1.GemType.Green;
                break;
            case move_data_1.MoveType.Blue:
                return gem_model_1.GemType.Blue;
                break;
            case move_data_1.MoveType.Purple:
                return gem_model_1.GemType.Purple;
                break;
        }
        return gem_model_1.GemType.None;
    }
    static gemTypeToMoveType(gemType) {
        switch (gemType) {
            case gem_model_1.GemType.Red:
                return move_data_1.MoveType.Red;
                break;
            case gem_model_1.GemType.Green:
                return move_data_1.MoveType.Green;
                break;
            case gem_model_1.GemType.Blue:
                return move_data_1.MoveType.Blue;
                break;
            case gem_model_1.GemType.Purple:
                return move_data_1.MoveType.Purple;
                break;
        }
        return move_data_1.MoveType.None;
    }
    canDoMove() {
        if (this.mode == MoveMode.Disabled) {
            return false;
        }
        if (!this.playerDanceMixer) {
            return false;
        }
        if (this.mode == MoveMode.Gameplay && this.moveType == move_data_1.MoveType.None) {
            return false;
        }
        return this.playerDanceMixer.canTrigger();
    }
    doMove(swipeDirection) {
        if (this.canDoMove()) {
            const move = this.getMove(this.moveType, swipeDirection);
            if (move && this.playerDanceMixer) {
                this.currentMove = move;
                loglevel_1.default.debug(`MoveModel: doMove  direction = ${swipeDirection}  moveName = ${move.name} `);
                loglevel_1.default.info(`Starting Move: ${move.name} `);
                this.playerDanceMixer.trigger(move.name);
                return move;
            }
        }
        return null;
    }
    triggerAttractMove() {
        const moveIndex = random_1.Random.next(attractMoves.length);
        const move = this.getMove(attractMoves[moveIndex].moveType, attractMoves[moveIndex].direction);
        if (move && this.playerDanceMixer) {
            this.playerDanceMixer.trigger(move.name);
        }
    }
    fallDown() {
        this.mode = MoveMode.Disabled;
        if (this.playerDanceMixer) {
            this.playerDanceMixer.playFall();
        }
    }
    getMove(moveType, swipeDirection) {
        let move = move_data_1.default.find(moveItem => moveItem.type == moveType && moveItem.direction == swipeDirection);
        return move;
    }
    getRandomMove() {
        const moveType = randomMoveTypes[random_1.Random.next(randomMoveTypes.length)];
        const swipeDir = randomSwipeDirections[random_1.Random.next(randomSwipeDirections.length)];
        return this.getMove(moveType, swipeDir);
    }
    getIconData(gemType) {
        const iconMap = new Map();
        const moveType = MoveModel.gemTypeToMoveType(gemType);
        for (const move of move_data_1.default) {
            if (move.type == moveType) {
                iconMap.set(move.direction, move.iconKey);
            }
        }
        return iconMap;
    }
    getCompletedSets() {
        let completed = [];
        for (const [moveType, map] of this.moveMaps) {
            if (map.size == 8) {
                completed.push(MoveModel.moveTypeToGemType(moveType));
            }
        }
        return completed;
    }
}
exports.default = MoveModel;
