"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const log = require("loglevel");
const THREE = require("three");
const content_model_1 = require("../../model/content_model");
const swipe_model_1 = require("../../model/swipe_model");
const ftue_view_1 = require("../../view/ftue_view");
const camera_mode_1 = require("../camera_mode");
const lighting_mode_1 = require("../lighting_mode");
const play_mode_1 = require("./play_mode");
const swipe_to_begin_mode_1 = require("./swipe_to_begin_mode");
class FTUEMode extends play_mode_1.PlayModeBase {
    constructor(playController) {
        super(playController);
        this.gameStageLoaded = false;
        this.tapDelay = FTUEMode.TAP_DELAY;
        this.onPointerDown = (event) => {
            log.debug("FTUEMode: onPointerDown");
            if (this.tapDelay <= 0) {
                switch (this.views.ftueView.ftueMode) {
                    case ftue_view_1.FTUEDisplayMode.Collect:
                        this.views.ftueView.setFTUEMode(ftue_view_1.FTUEDisplayMode.Unique);
                        this.tapDelay = FTUEMode.TAP_DELAY;
                        break;
                    case ftue_view_1.FTUEDisplayMode.Unique:
                        this.views.ftueView.setFTUEMode(ftue_view_1.FTUEDisplayMode.Find);
                        this.tapDelay = FTUEMode.TAP_DELAY;
                        break;
                    case ftue_view_1.FTUEDisplayMode.Find:
                        this.removeEvents();
                        this.views.ftueView.shutdown();
                        this.playController.mode = new swipe_to_begin_mode_1.default(this.playController);
                        break;
                }
            }
        };
        log.info('PlayMode: FTUEMode');
        this.clock = new THREE.Clock();
        this.views.ftueView.reset();
        this.views.ftueView.setFTUEMode(ftue_view_1.FTUEDisplayMode.Collect);
        this.playController.setCameraMode(camera_mode_1.CameraMode.Game);
        this.playController.setLightingMode(lighting_mode_1.LightingMode.Game);
        this.views.scoreView.reset();
        this.views.logoView.reset();
        this.views.logoView.danceMode();
        this.views.discoRoadView.gameMode();
        this.gameStageLoaded = this.playController.contentModel.isStageLoaded(content_model_1.ContentStage.Game);
        this.addEvents();
        this.playController.hasShownFTUE = true;
    }
    addEvents() {
        this.playController.swipeModel.addEventListener(swipe_model_1.SwipeEvents.OnDown, this.onPointerDown);
    }
    removeEvents() {
        this.playController.swipeModel.removeEventListener(swipe_model_1.SwipeEvents.OnDown, this.onPointerDown);
    }
    onScreenResize() {
        this.views.hudView.onScreenResize();
        this.views.scoreView.onScreenResize();
        this.views.logoView.onScreenResize();
        this.views.ftueView.onScreenResize();
    }
    render(gl) {
        if (!this.gameStageLoaded) {
            const gameLoaded = this.playController.contentModel.isStageLoaded(content_model_1.ContentStage.Game);
            if (gameLoaded) {
                this.gameStageLoaded = true;
                if (this.views.ftueView.ftueMode == ftue_view_1.FTUEDisplayMode.Find && this.tapDelay <= 0) {
                    this.views.ftueView.showTapToContinue();
                }
                // initialize things that need initializing after game is loaded
                this.playController.onLoadingComplete(content_model_1.ContentStage.Game);
            }
        }
        const dt = this.clock.getDelta();
        if (this.tapDelay > 0) {
            this.tapDelay -= dt;
            if (this.tapDelay <= 0) {
                if (this.views.ftueView.ftueMode == ftue_view_1.FTUEDisplayMode.Collect ||
                    this.views.ftueView.ftueMode == ftue_view_1.FTUEDisplayMode.Unique) {
                    this.views.ftueView.showTapToContinue();
                }
                else {
                    if (this.gameStageLoaded) {
                        this.views.ftueView.showTapToContinue();
                    }
                }
            }
        }
        gl.clear();
        gl.render(this.views.backgroundView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.discoRoadView, this.playController.camera3d);
        gl.render(this.views.influencerView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.scoreView, this.playController.camera2d);
        gl.render(this.views.hudView, this.playController.camera2d);
        gl.render(this.views.logoView, this.playController.camera2d);
        gl.render(this.views.ftueView, this.playController.camera2d);
    }
}
exports.default = FTUEMode;
FTUEMode.TAP_DELAY = 2.0;
