"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
class ScoreTextView extends THREE.Group {
    constructor(contentModel, textColor, shadowColor) {
        super();
        this.textWidth = 181;
        this.textHeight = 32;
        this.textSize = 32;
        this.textColor = 0xffffff;
        this.shadowColor = 0xffffff;
        this.shadowTextOffset = 3;
        this.score = 0;
        this.text = null;
        this.shadowText = null;
        this.contentModel = contentModel;
        this.textColor = textColor;
        this.shadowColor = shadowColor;
        this.setScore(0);
    }
    get width() {
        return this.textWidth;
    }
    set width(value) {
        this.textWidth = value;
    }
    get height() {
        return this.textHeight;
    }
    set height(value) {
        this.textHeight = value;
    }
    get fontSize() {
        return this.textSize;
    }
    set fontSize(value) {
        this.textSize = value;
    }
    get shadowOffset() {
        return this.shadowTextOffset;
    }
    set shadowOffset(value) {
        this.shadowTextOffset = value;
    }
    reset() {
    }
    setScore(score) {
        this.score = score;
        this.updateText();
    }
    updateText() {
        if (this.text != null) {
            this.remove(this.text);
            this.text.geometry.dispose();
            if (this.text.material && !Array.isArray(this.text.material)) {
                this.text.material.dispose();
            }
            this.text = null;
        }
        if (this.shadowText != null) {
            this.remove(this.shadowText);
            this.shadowText.geometry.dispose();
            if (this.shadowText.material && !Array.isArray(this.shadowText.material)) {
                this.shadowText.material.dispose();
            }
            this.shadowText = null;
        }
        const scoreText = this.score.toString();
        this.shadowText = this.createText(scoreText, this.shadowColor);
        this.shadowText.translateY(-this.shadowOffset);
        this.shadowText.translateX(this.shadowOffset);
        this.add(this.shadowText);
        this.text = this.createText(scoreText, this.textColor);
        this.add(this.text);
    }
    createText(points, color) {
        const geometry = new THREE.TextGeometry(points, {
            font: this.contentModel.getFont('gamefont'),
            size: this.textSize,
        });
        geometry.computeBoundingBox();
        const material = new THREE.MeshBasicMaterial({
            opacity: 1,
            side: THREE.DoubleSide,
            transparent: true,
            color: color
        });
        let text = new THREE.Mesh(geometry, material);
        let center = new THREE.Vector3();
        geometry.boundingBox.getCenter(center);
        text.scale.setX(0.75);
        text.position.x -= ((center.x * 0.75) * 2);
        text.position.y -= (center.y * 2);
        return text;
    }
}
exports.default = ScoreTextView;
