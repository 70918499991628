"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const road_data_1 = require("../road_data");
// 10 Beat level in TikTok - Disco Loco 3d - Level Design
const Level05 = {
    length: 168,
    ratings: [180000, 360000, 540000, 720000, 900000, 1080000],
    roadData: [
        { type: road_data_1.RoadItemType.PickupBlack, line: 8, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 8, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 9, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 10, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 10, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 11, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 11, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 12, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 12, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 13, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 13, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 14, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 14, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 15, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 15, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 16, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 18, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 18, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 19, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 20, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 20, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 21, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 21, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 22, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 22, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 23, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 23, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 24, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 24, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 25, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 25, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 26, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 28, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 28, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 29, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 30, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 30, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 31, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 31, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 32, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 32, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 33, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 33, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 34, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 34, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 35, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 35, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 36, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 38, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 38, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 39, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 40, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 40, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 41, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 41, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 42, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 42, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 43, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 43, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 44, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 44, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 45, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 45, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 46, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 49, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 50, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 50, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 51, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 51, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 51, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 52, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 52, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 53, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 53, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 54, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 54, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 54, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 55, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 55, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 56, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 59, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 60, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 60, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 61, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 61, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 61, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 62, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 62, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 63, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 63, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 64, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 64, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 64, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 65, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 65, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 66, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 69, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 70, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 70, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 71, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 71, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 71, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 72, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 72, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 73, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 73, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 74, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 74, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 74, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 75, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 75, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 76, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 79, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 80, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 80, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 81, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 81, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 81, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 82, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 82, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 83, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 83, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 84, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 84, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 84, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 85, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 85, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 86, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 88, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 88, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 89, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 90, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 90, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 91, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 91, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 92, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 92, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 92, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 92, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 93, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 93, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 94, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 94, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 95, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 95, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 96, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 98, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 98, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 99, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 100, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 100, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 101, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 101, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 102, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 102, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 102, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 102, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 103, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 103, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 104, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 104, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 105, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 105, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 106, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 108, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 108, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 109, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 110, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 110, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 111, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 111, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 112, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 112, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 112, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 112, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 113, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 113, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 114, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 114, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 115, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 115, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 116, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 118, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 118, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 119, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 120, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 120, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 121, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 121, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 122, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 122, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 122, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 122, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 123, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 123, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 124, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 124, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 125, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 125, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 126, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 128, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 128, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 128, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 128, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 129, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 130, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 130, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 131, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 131, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 131, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 132, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 132, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 133, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 133, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 134, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 134, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 135, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 135, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 136, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 138, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 138, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 138, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 138, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 139, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 140, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 140, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 141, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 141, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 142, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 142, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 142, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 142, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 143, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 143, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 144, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 144, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 145, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 145, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 146, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 148, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 148, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 148, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 148, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 149, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 150, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 150, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 151, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 151, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 151, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 152, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 152, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 152, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 152, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 153, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 153, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 154, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 154, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 155, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 155, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 156, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 158, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 158, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 158, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 158, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 159, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 160, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 160, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 161, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 161, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 161, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 162, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 162, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 162, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 162, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 163, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 163, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 163, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 163, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 164, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 164, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 165, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 165, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 166, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 4 },
    ]
};
exports.default = Level05;
