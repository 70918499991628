"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const game_over_progress_1 = require("./game_over_progress");
const game_over_star_1 = require("./game_over_star");
const ftue_text_1 = require("./ftue_text");
const NUM_STARS = 5;
class ScoreRatingView extends THREE.Group {
    constructor(contentModel) {
        super();
        this.ratingWidth = 300;
        this.ratingHeight = 150;
        this.groupCreated = false;
        this.progressBar = null;
        this.scoreText = null;
        this.stars = [];
        this.contentModel = contentModel;
    }
    get width() {
        return this.ratingWidth;
    }
    get height() {
        return this.ratingHeight;
    }
    reset() {
        if (!this.groupCreated) {
            this.groupCreated = true;
            this.progressBar = new game_over_progress_1.default();
            this.progressBar.renderOrder = 4;
            this.add(this.progressBar);
            this.scoreText = new ftue_text_1.default(this.contentModel, 0xffffff, 0x7a51a7);
            this.scoreText.shadowOffset = 2;
            this.scoreText.fontSize = 37;
            this.scoreText.vertical = ftue_text_1.TextJustification.Bottom;
            this.scoreText.setText('8888888');
            this.scoreText.position.setY(54);
            this.add(this.scoreText);
            const progressWidth = this.progressBar.width;
            const starXStart = -(progressWidth * 0.5);
            const starXOffset = progressWidth / 6;
            const starHeights = [0.2, 0.28, 0.34, 0.28, 0.2];
            const starXPos = [-100, -65, 0, 65, 100];
            const starYBase = 80;
            const starYPos = [0, 42, 64, 42, 0];
            for (let starIndex = 0; starIndex < NUM_STARS; starIndex++) {
                const star = new game_over_star_1.default(this.contentModel);
                star.scale.set(starHeights[starIndex], starHeights[starIndex], 1);
                star.position.setX(starXPos[starIndex]);
                star.position.setY(starYBase + starYPos[starIndex]);
                this.stars.push(star);
                this.add(star);
            }
        }
        this.progressBar?.reset();
        this.scoreText?.setText('0');
        for (let star of this.stars) {
            star.reset();
        }
    }
    setScore(score) {
        this.scoreText?.setText(score.toString());
    }
    setProgress(progress) {
        this.progressBar?.setProgress(progress);
    }
    showStar(starIndex) {
        this.stars[starIndex].show();
    }
}
exports.default = ScoreRatingView;
