"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeverViewEvents = void 0;
const THREE = require("three");
const TWEEN = require("@tweenjs/tween.js");
const Tone = require("tone");
const log = require("loglevel");
const fever_move_icon_1 = require("./fever_move_icon");
const swipe_model_1 = require("../model/swipe_model");
const fever_banner_1 = require("./fever_banner");
const gem_model_1 = require("../model/gem_model");
var FeverViewEvents;
(function (FeverViewEvents) {
    FeverViewEvents["EnableMove"] = "enablemove";
    FeverViewEvents["DisableMove"] = "disablemove";
})(FeverViewEvents = exports.FeverViewEvents || (exports.FeverViewEvents = {}));
const orbitRed = new THREE.Color(0xff4e1e);
const orbitGreen = new THREE.Color(0x1fffa9);
const orbitBlue = new THREE.Color(0x1fd7ff);
const orbitPurple = new THREE.Color(0x9e1fff);
const orbitWhite = new THREE.Color(0xffffff);
const orbitOpacity = .75;
class FeverView extends THREE.Scene {
    constructor(contentModel, screenModel, feverModel, soundModel) {
        super();
        this.clock = new THREE.Clock();
        this.targetSprite = null;
        this.iconsCreated = false;
        this.moveData = [];
        this.orbitLeftSprite = null;
        this.orbitRightSprite = null;
        this.onEnableMove = (event) => {
            // bubble up to controller mode
            this.dispatchEvent({
                type: FeverViewEvents.EnableMove,
                direction: event.direction,
            });
        };
        this.onDisableMove = (event) => {
            // bubble up to controller mode
            this.dispatchEvent({
                type: FeverViewEvents.DisableMove,
                direction: event.direction,
            });
        };
        log.debug("FeverView: constructor");
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        this.feverModel = feverModel;
        this.soundModel = soundModel;
        this.moveIcons = new Map();
        this.orbitLeftGroup = new THREE.Group();
        this.add(this.orbitLeftGroup);
        this.orbitRightGroup = new THREE.Group();
        this.add(this.orbitRightGroup);
        this.hudGroup = new THREE.Group();
        this.add(this.hudGroup);
        this.moveGroup = new THREE.Group();
        this.add(this.moveGroup);
        this.banner = new fever_banner_1.default(this.contentModel);
        this.add(this.banner);
        this.onBeforeRender = this.preRender.bind(this);
        this.onScreenResize();
    }
    reset() {
        if (!this.iconsCreated) {
            this.iconsCreated = true;
            this.targetSprite = this.createSprite("chaos-circle-selected", 1);
            this.targetSprite.material.opacity = 0.0;
            this.hudGroup.add(this.targetSprite);
            this.createOrbitGroup(this.orbitLeftGroup, true);
            this.createOrbitGroup(this.orbitRightGroup, false);
            const xOffset = this.screenModel.width * 0.45;
            const yOffset = this.screenModel.height * 0.35;
            this.moveIcons.set(swipe_model_1.SwipeDirection.Right, this.createMoveIcon(swipe_model_1.SwipeDirection.Right, (xOffset * 0.8), 0));
            this.moveIcons.set(swipe_model_1.SwipeDirection.Left, this.createMoveIcon(swipe_model_1.SwipeDirection.Left, (-xOffset * 0.8), 0));
            this.moveIcons.set(swipe_model_1.SwipeDirection.Up, this.createMoveIcon(swipe_model_1.SwipeDirection.Up, 0, (yOffset * 0.65)));
            this.moveIcons.set(swipe_model_1.SwipeDirection.Down, this.createMoveIcon(swipe_model_1.SwipeDirection.Down, 0, (-yOffset * 0.65)));
            this.moveIcons.set(swipe_model_1.SwipeDirection.UpRight, this.createMoveIcon(swipe_model_1.SwipeDirection.UpRight, (xOffset * 0.8), (yOffset * 0.65)));
            this.moveIcons.set(swipe_model_1.SwipeDirection.DownRight, this.createMoveIcon(swipe_model_1.SwipeDirection.DownRight, (xOffset * 0.8), (-yOffset * 0.65)));
            this.moveIcons.set(swipe_model_1.SwipeDirection.UpLeft, this.createMoveIcon(swipe_model_1.SwipeDirection.UpLeft, (-xOffset * 0.8), (yOffset * 0.65)));
            this.moveIcons.set(swipe_model_1.SwipeDirection.DownLeft, this.createMoveIcon(swipe_model_1.SwipeDirection.DownLeft, (-xOffset * 0.8), (-yOffset * 0.65)));
        }
        this.onScreenResize();
    }
    resetIcons(iconData) {
        for (const dir of Object.values(swipe_model_1.SwipeDirection)) {
            if (dir != swipe_model_1.SwipeDirection.None) {
                this.moveIcons.get(dir)?.reset(iconData.get(dir));
            }
        }
    }
    createMoveIcon(direction, xOffset, yOffset) {
        const moveIcon = new fever_move_icon_1.default(direction, new THREE.Vector2(xOffset, yOffset), this.contentModel);
        this.moveGroup.add(moveIcon);
        moveIcon.updateScale(this.screenModel);
        moveIcon.addEventListener(FeverViewEvents.EnableMove, this.onEnableMove);
        moveIcon.addEventListener(FeverViewEvents.DisableMove, this.onDisableMove);
        return moveIcon;
    }
    onScreenResize() {
        log.debug("FeverView: onScreenResize");
        this.moveGroup?.position.setX(this.screenModel.width / 2);
        this.moveGroup?.position.setY(this.screenModel.height / 2);
        const hudScale = this.screenModel.calculateScale(0.225, 72, 0.0825, 72);
        this.hudGroup?.scale.set(hudScale, hudScale, 1.0);
        this.hudGroup?.position.setX(this.screenModel.width / 2);
        this.hudGroup?.position.setY(this.screenModel.height / 2);
        this.banner.position.setX(this.screenModel.width / 2);
        this.banner.position.setY(this.screenModel.height / 4);
        this.banner.updateScale(this.screenModel);
        const orbitSize = 0.25;
        const orbitX = 0.2;
        const orbitY = 0.95;
        const orbitScale = this.screenModel.calculateWidthScale(orbitSize, 128);
        this.orbitRightGroup.scale.set(orbitScale, orbitScale, 1.0);
        this.orbitRightGroup.position.setX(this.screenModel.width * (1 - orbitX));
        this.orbitRightGroup.position.setY(this.screenModel.height * orbitY);
        this.orbitLeftGroup.scale.set(orbitScale, orbitScale, 1.0);
        this.orbitLeftGroup.position.setX(this.screenModel.width * orbitX);
        this.orbitLeftGroup.position.setY(this.screenModel.height * orbitY);
    }
    ;
    createSprite(key, scale) {
        const texture = this.contentModel.getTexture(key);
        const material = new THREE.SpriteMaterial({ map: texture });
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, 0.5);
        sprite.scale.set(width * scale, height * scale, 1);
        return sprite;
    }
    setMoveData(moveData) {
        this.moveData = moveData;
    }
    leadIn() {
        const bannerDuration = this.banner.getDisplayDuration();
        const halfDelayMS = bannerDuration * 0.5;
        new TWEEN.Tween(this.targetSprite.material)
            .to({ opacity: 0.4 }, halfDelayMS)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
        this.updateOrbitColor();
    }
    start() {
        this.banner.display(this.feverModel.getFeverText());
        const announce = this.contentModel.getTonePlayer(this.feverModel.getAnnouceKey());
        const announceDelay = this.banner.getDisplayDuration() / 2;
        window.setTimeout(() => { this.soundModel.playSFX(announce); }, announceDelay);
        const now = Tone.Transport.now();
        const nextBeat = Tone.Transport.nextSubdivision("4n");
        const beatSeconds = Tone.Transport.toSeconds("4n");
        const bannerDuration = this.banner.getDisplayDuration() * 0.001;
        let beatDelay = nextBeat - now;
        while (beatDelay < bannerDuration) {
            beatDelay += beatSeconds;
        }
        this.updateOrbitColor();
        log.info(`FeverView: start - beatDelay: ${beatDelay}`);
        window.setTimeout(this.startIcons.bind(this), beatDelay * 1000);
    }
    startIcons() {
        for (const moveData of this.moveData) {
            const moveIcon = this.moveIcons.get(moveData.swipeDirection);
            if (!moveIcon) {
                throw `FeverView: setupIconTween - icon not found direction - ${moveData.swipeDirection}`;
            }
            moveIcon.setupTween(moveData);
        }
    }
    completeMove(direction) {
        const moveIcon = this.moveIcons.get(direction);
        if (!moveIcon) {
            throw "FeverView: completeMove invalid direction";
        }
        moveIcon.swipeAway();
    }
    leadOut() {
        const bannerDuration = this.banner.getDisplayDuration();
        const halfDelayMS = bannerDuration * 0.5;
        new TWEEN.Tween(this.targetSprite.material)
            .to({ opacity: 0.0 }, halfDelayMS)
            .easing(TWEEN.Easing.Cubic.In)
            .start();
    }
    createOrbitGroup(group, left) {
        let hotspotTextureKey = "fever-orbit-hotspot";
        let hotspotXOffset = 7;
        let hotspotYOffset = 3;
        let colorTextureKey = "fever-orbit";
        if (left) {
            hotspotTextureKey += "-l";
            colorTextureKey += "-l";
        }
        else {
            hotspotTextureKey += "-r";
            colorTextureKey += "-r";
            hotspotXOffset *= -1;
        }
        const hotspotSprite = this.createSprite(hotspotTextureKey, 0.5);
        hotspotSprite.name = "hotspot";
        hotspotSprite.position.setX(hotspotXOffset);
        hotspotSprite.position.setY(hotspotYOffset);
        const hotspotMaterial = hotspotSprite.material;
        hotspotMaterial.blending = THREE.AdditiveBlending;
        hotspotMaterial.color = orbitWhite;
        group.add(hotspotSprite);
        const colorspotSprite = this.createSprite(colorTextureKey, 1.0);
        colorspotSprite.name = "colorspot";
        const colorMaterial = colorspotSprite.material;
        colorMaterial.blending = THREE.AdditiveBlending;
        colorMaterial.color = orbitGreen;
        group.add(colorspotSprite);
    }
    updateOrbitColor() {
        let color = orbitWhite;
        switch (this.feverModel.currentSet) {
            case gem_model_1.GemType.Red:
                {
                    color = orbitRed;
                }
                break;
            case gem_model_1.GemType.Green:
                {
                    color = orbitGreen;
                }
                break;
            case gem_model_1.GemType.Blue:
                {
                    color = orbitBlue;
                }
                break;
            case gem_model_1.GemType.Purple:
                {
                    color = orbitPurple;
                }
                break;
        }
        const leftSpot = this.orbitLeftGroup.getObjectByName("colorspot");
        if (leftSpot) {
            const material = leftSpot.material;
            material.color = color;
        }
        const rightSpot = this.orbitRightGroup.getObjectByName("colorspot");
        if (rightSpot) {
            const material = rightSpot.material;
            material.color = color;
        }
    }
    preRender(gl, scene, camera, renderTarget) {
        const dt = this.clock.getDelta();
        for (let [dir, icon] of this.moveIcons) {
            icon.update();
        }
    }
}
exports.default = FeverView;
