"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const log = require("loglevel");
const cheat_model_1 = require("../../model/cheat_model");
const content_model_1 = require("../../model/content_model");
const swipe_model_1 = require("../../model/swipe_model");
const main_menu_view_1 = require("../../view/main_menu_view");
const camera_mode_1 = require("../camera_mode");
const lighting_mode_1 = require("../lighting_mode");
const fever_mode_1 = require("./fever_mode");
const ftue_mode_1 = require("./ftue_mode");
const play_mode_1 = require("./play_mode");
const swipe_to_begin_mode_1 = require("./swipe_to_begin_mode");
class MainMenuMode extends play_mode_1.PlayModeBase {
    constructor(playController) {
        super(playController);
        this.loadingMainContent = false;
        this.mainStageLoaded = false;
        this.gameStageLoaded = false;
        this.timerID = null;
        this.onPointerDown = (event) => {
            log.debug("MainMenuMode: onPointerDown");
            this.playController.soundModel.start();
            this.views.mainMenuView.onPointerDown(event.position);
        };
        this.onPointerMove = (event) => {
            this.views.mainMenuView.onPointerMove(event.position);
        };
        this.onPointerUp = (event) => {
            log.debug("MainMenuMode: onPointerUp");
            this.views.mainMenuView.onPointerUp();
            this.playController.soundModel.start();
        };
        this.onPlayPressed = () => {
            log.info("MainMenuMode: onPlayPressed");
            if (this.playController.cheatModel.isCheatActive(cheat_model_1.Cheat.FeverMode)) {
                // all of game mode content needs to be loaded to
                // jump straight to fever
                if (this.playController.contentModel.isStageLoaded(content_model_1.ContentStage.Game)) {
                    this.onFeverPressed();
                    return;
                }
            }
            this.views.influencerView.danceMode();
            this.removeEvents();
            if (this.playController.hasShownFTUE) {
                this.playController.mode = new swipe_to_begin_mode_1.default(this.playController);
            }
            else {
                this.playController.mode = new ftue_mode_1.default(this.playController);
            }
            this.playController.soundModel.start();
            this.cancelAttract();
        };
        this.onColorPressed = () => {
            log.info("MainMenuMode: onColorPressed");
            this.playController.levelModel.playerModel.incrementPlayerSkin();
            this.views.influencerView.updateSkin();
            this.views.mainMenuView.setPlayerColor(this.playController.levelModel.playerModel.getSkinIndex());
            this.playController.cheatModel.addColorButton();
        };
        this.onSongPressed = () => {
            log.info("MainMenuMode: onSongPressed");
            const songKeys = this.playController.contentModel.getSongKeys();
            const currentSong = this.playController.soundModel.getCurrentSong();
            if (currentSong) {
                let songIndex = songKeys.indexOf(currentSong);
                songIndex++;
                songIndex %= songKeys.length;
                const songKey = songKeys[songIndex];
                this.views.mainMenuView.setSong(songKey);
                const songData = this.playController.contentModel.getSong(songKey);
                if (songData) {
                    this.playController.soundModel.setSong(songData);
                    this.playController.levelModel.loadLevel(songKey);
                    this.playController.updateBPM(songData.bpm);
                }
            }
            this.playController.cheatModel.addSongButton();
        };
        this.onFeverPressed = () => {
            log.info("MainMenuMode: onFeverPressed");
            this.views.influencerView.danceMode();
            this.removeEvents();
            this.playController.mode = new fever_mode_1.default(this.playController, true);
            this.playController.soundModel.start();
            this.cancelAttract();
        };
        this.onTOSPressed = () => {
            log.info("MainMenuMode: onTOSPressed");
            const tosElement = document.getElementById('tos');
            if (tosElement) {
                const tosFrame = document.getElementById('tos-frame');
                if (tosFrame) {
                    tosFrame.src = "https://www.zynga.com/legal/terms-of-service";
                }
                tosElement.hidden = false;
            }
        };
        this.onSwipe = (event) => {
            this.playController.cheatModel.addSwipe(event.direction);
        };
        this.onCheat = (event) => {
            if (event.cheat == cheat_model_1.Cheat.FeverMode) {
                // all of game mode content needs to be loaded to
                // jump straight to fever
                if (this.playController.contentModel.isStageLoaded(content_model_1.ContentStage.Game)) {
                    this.playController.soundModel.playCheatStinger();
                }
                else {
                    this.playController.cheatModel.deactivateCheat(cheat_model_1.Cheat.FeverMode);
                }
            }
        };
        log.info('PlayMode: MainMenuMode');
        let songKey = "";
        if (this.playController.soundModel.isSongInFeverMode()) {
            const feverKey = this.playController.soundModel.getCurrentSong();
            const songIndex = feverKey?.substring(0, 2);
            songKey = this.playController.contentModel.getSongFromIndex(songIndex);
            const songData = this.playController.contentModel.getSong(songKey);
            if (songData) {
                //this.playController.soundModel.setSong( songData );
                this.playController.soundModel.transitionFromFeverSong(songData);
                this.playController.updateBPM(songData.bpm);
            }
        }
        else {
            // start up a song if not playing
            songKey = this.playController.soundModel.getCurrentSong();
            if (!songKey) {
                const songKeys = this.playController.contentModel.getSongKeys();
                songKey = songKeys[0];
                const songData = this.playController.contentModel.getSong(songKey);
                if (songData) {
                    this.playController.soundModel.setSong(songData);
                    this.playController.updateBPM(songData.bpm);
                }
            }
        }
        this.playController.setCameraMode(camera_mode_1.CameraMode.Main);
        this.playController.setLightingMode(lighting_mode_1.LightingMode.Main);
        this.views.logoView.reset();
        this.views.mainMenuView.reset();
        this.views.mainMenuView.setPlayerColor(this.playController.levelModel.playerModel.getSkinIndex());
        this.views.mainMenuView.setSong(songKey);
        this.addEvents();
        this.views.influencerView.mainMenuMode();
        this.views.discoRoadView.reset();
        this.views.discoRoadView.mainMenuMode();
        this.mainStageLoaded = this.playController.contentModel.isStageLoaded(content_model_1.ContentStage.Main);
        this.gameStageLoaded = this.playController.contentModel.isStageLoaded(content_model_1.ContentStage.Game);
        if (!this.mainStageLoaded) {
            this.loadingMainContent = true;
            this.views.mainMenuView.setPreloadMode();
            this.playController.contentModel.loadContent(content_model_1.ContentStage.Main);
        }
        else {
            this.views.mainMenuView.setLoadedMode();
            this.startAttract();
        }
        const closeTOSElement = document.getElementById('tos-close');
        const tosElement = document.getElementById('tos');
        if (closeTOSElement && tosElement) {
            closeTOSElement.onclick = () => { tosElement.hidden = true; };
            closeTOSElement.ontouchstart = () => { tosElement.hidden = true; };
        }
    }
    addEvents() {
        this.playController.swipeModel.addEventListener(swipe_model_1.SwipeEvents.OnDown, this.onPointerDown);
        this.playController.swipeModel.addEventListener(swipe_model_1.SwipeEvents.OnMove, this.onPointerMove);
        this.playController.swipeModel.addEventListener(swipe_model_1.SwipeEvents.OnUp, this.onPointerUp);
        this.playController.swipeModel.addEventListener(swipe_model_1.SwipeEvents.Swipe, this.onSwipe);
        this.playController.cheatModel.addEventListener(cheat_model_1.CheatEvents.CheatActivated, this.onCheat);
        this.views.mainMenuView.addEventListener(main_menu_view_1.MainMenuEvents.PlayPressed, this.onPlayPressed);
        this.views.mainMenuView.addEventListener(main_menu_view_1.MainMenuEvents.ColorPressed, this.onColorPressed);
        this.views.mainMenuView.addEventListener(main_menu_view_1.MainMenuEvents.SongPressed, this.onSongPressed);
        this.views.mainMenuView.addEventListener(main_menu_view_1.MainMenuEvents.FeverPressed, this.onFeverPressed);
        this.views.mainMenuView.addEventListener(main_menu_view_1.MainMenuEvents.TOSPressed, this.onTOSPressed);
    }
    removeEvents() {
        this.playController.swipeModel.removeEventListener(swipe_model_1.SwipeEvents.OnDown, this.onPointerDown);
        this.playController.swipeModel.removeEventListener(swipe_model_1.SwipeEvents.OnMove, this.onPointerMove);
        this.playController.swipeModel.removeEventListener(swipe_model_1.SwipeEvents.OnUp, this.onPointerUp);
        this.playController.swipeModel.removeEventListener(swipe_model_1.SwipeEvents.Swipe, this.onSwipe);
        this.playController.cheatModel.removeEventListener(cheat_model_1.CheatEvents.CheatActivated, this.onCheat);
        this.views.mainMenuView.removeEventListener(main_menu_view_1.MainMenuEvents.PlayPressed, this.onPlayPressed);
        this.views.mainMenuView.removeEventListener(main_menu_view_1.MainMenuEvents.ColorPressed, this.onColorPressed);
        this.views.mainMenuView.removeEventListener(main_menu_view_1.MainMenuEvents.SongPressed, this.onSongPressed);
        this.views.mainMenuView.removeEventListener(main_menu_view_1.MainMenuEvents.FeverPressed, this.onFeverPressed);
        this.views.mainMenuView.removeEventListener(main_menu_view_1.MainMenuEvents.TOSPressed, this.onTOSPressed);
    }
    onScreenResize() {
        this.views.mainMenuView.onScreenResize();
        this.views.logoView.onScreenResize();
    }
    startAttract() {
        log.debug(`MainMenuMode: startAttract`);
        this.timerID = window.setTimeout(this.attractMode.bind(this), MainMenuMode.ATTRACT_DELAY);
    }
    cancelAttract() {
        log.debug(`MainMenuMode: cancelAttract`);
        if (this.timerID) {
            window.clearTimeout(this.timerID);
            this.timerID = null;
        }
    }
    attractMode() {
        log.debug('MainMenuMode: attractMode');
        this.playController.moveModel.triggerAttractMove();
        this.timerID = window.setTimeout(this.attractMode.bind(this), MainMenuMode.ATTRACT_DELAY);
    }
    render(gl) {
        if (this.loadingMainContent) {
            if (this.playController.contentModel.isStageLoaded(content_model_1.ContentStage.Main)) {
                this.loadingMainContent = false;
                log.info("MainMenuMode: game content loaded");
                this.views.mainMenuView.setLoadingComplete();
                // initialize things that need initializing after main is loaded
                this.playController.onLoadingComplete(content_model_1.ContentStage.Main);
                // start attract
                this.startAttract();
                // start loading the rest of the assets
                this.playController.contentModel.loadContent(content_model_1.ContentStage.Game);
            }
        }
        if (!this.gameStageLoaded) {
            const gameLoaded = this.playController.contentModel.isStageLoaded(content_model_1.ContentStage.Game);
            if (gameLoaded) {
                this.gameStageLoaded = true;
                // initialize things that need initializing after game is loaded
                this.playController.onLoadingComplete(content_model_1.ContentStage.Game);
            }
        }
        gl.clear();
        gl.render(this.views.backgroundView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.discoRoadView, this.playController.camera3d);
        gl.render(this.views.influencerView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.mainMenuView, this.playController.camera2d);
        gl.render(this.views.logoView, this.playController.camera2d);
    }
}
exports.default = MainMenuMode;
MainMenuMode.ATTRACT_DELAY = 5000;
