"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const loglevel_1 = require("loglevel");
class ScreenModel {
    constructor(width, height, safeAreaPercent) {
        loglevel_1.default.debug(`ScreenModel: constructor  width = ${width}  height = ${height}  safe% = ${safeAreaPercent}`);
        this.screenMargin = 0;
        this.screenWidth = width;
        this.screenHeight = height;
        this.safeAreaPercent = safeAreaPercent;
        // this.width = Math.trunc(width);
        // this.height = Math.trunc(height);
    }
    get width() {
        return this.screenWidth;
    }
    get height() {
        return this.screenHeight;
    }
    set width(value) {
        loglevel_1.default.debug(`ScreenModel: set width width = ${value}`);
        this.screenWidth = value;
    }
    set height(value) {
        loglevel_1.default.debug(`ScreenModel: set height height = ${value}`);
        this.screenHeight = value;
    }
    set margin(value) {
        loglevel_1.default.debug(`ScreenModel: set margin margin = ${value}`);
        this.screenMargin = value;
    }
    get margin() {
        return this.screenMargin;
    }
    get safeArea() {
        const min = new THREE.Vector2();
        const max = new THREE.Vector2();
        min.x = Math.trunc(this.width * this.safeAreaPercent);
        min.y = Math.trunc(this.height * this.safeAreaPercent);
        max.x = this.width - min.x;
        max.y = this.height - min.y;
        return new THREE.Box2(min, max);
    }
    position2D(position3D, camera) {
        const temp = position3D.clone();
        temp.project(camera);
        const halfWidth = this.width >> 1;
        const halfHeight = this.height >> 1;
        return new THREE.Vector2(temp.x * halfWidth + halfWidth, temp.y * -halfHeight + halfHeight);
    }
    calculateScale(widthPercent, actualWidth, heightPercent, actualHeight) {
        const widthScale = this.calculateWidthScale(widthPercent, actualWidth);
        const heightScale = this.calculateHeightScale(heightPercent, actualHeight);
        return Math.min(widthScale, heightScale);
    }
    calculateWidthScale(widthPercent, actualWidth) {
        const desiredWidth = this.screenWidth * widthPercent;
        const widthScale = desiredWidth / actualWidth;
        return widthScale;
    }
    calculateHeightScale(heightPercent, actualHeight) {
        const desiredHeight = this.screenHeight * heightPercent;
        const heightScale = desiredHeight / actualHeight;
        return heightScale;
    }
    static getCanvasDimensions(windowWidth, windowHeight) {
        let aspectRatio = windowHeight / windowWidth;
        let targetWidth = windowWidth;
        let targetHeight = windowHeight;
        let offsetMargin = 0;
        if (aspectRatio < ScreenModel.MIN_ASPECT_RATIO) {
            const newWidth = Math.floor(targetHeight / ScreenModel.ADJUSTED_ASPECT_RATIO);
            offsetMargin = Math.floor((targetWidth - newWidth) / 2);
            targetWidth = newWidth;
            aspectRatio = ScreenModel.ADJUSTED_ASPECT_RATIO;
        }
        return {
            width: targetWidth,
            height: targetHeight,
            aspectRatio: aspectRatio,
            margin: offsetMargin
        };
    }
}
exports.default = ScreenModel;
ScreenModel.MIN_ASPECT_RATIO = 1.3;
ScreenModel.ADJUSTED_ASPECT_RATIO = 1.5;
