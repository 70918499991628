"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const loglevel_1 = require("loglevel");
class FollowerModel {
    constructor(levelModel) {
        this.followerData = [];
        this.levelModel = levelModel;
        loglevel_1.default.debug('FollowerModel: constructor');
    }
    reset() {
        loglevel_1.default.debug('FollowerModel: reset');
        this.followerData = [];
    }
    getNewFollower(moveKey) {
        const followerIndex = this.followerData.length;
        let newFollower = {
            index: followerIndex,
            skinName: this.levelModel.playerModel.getRandomFollowerSkin(),
            moveKey: moveKey,
            position: this.levelModel.getFollowerPosition(followerIndex)
        };
        this.followerData.push(newFollower);
        loglevel_1.default.debug(`FollowerModel: getNewFollower  moveKey = ${moveKey}  index = ${newFollower.index}`);
        return newFollower;
    }
}
exports.default = FollowerModel;
