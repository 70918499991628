"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const log = require("loglevel");
const object3d_pool_1 = require("./object3d_pool");
const three_1 = require("three");
//
// Obstacle Pool
//
class ObstaclePool {
    constructor() {
        this.pool = null;
        this._initialized = false;
        this.obstacleGroup = null;
        this.obstacleScale = 7.0;
        this.obstacleMaterial = null;
        this.obstacleTexture = null;
        this.obstacleMetalness = null;
        this.obstacleRoughness = null;
        this.feverTexture = null;
        this.feverMetalness = null;
        this.feverRoughness = null;
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    initialize(contentModel, initialSize = 80) {
        if (!this._initialized) {
            this._initialized = true;
            this.obstacleGroup = this.setupAssets(contentModel);
            log.info(`ObstaclePool: initialize - initialSize: ${initialSize}`);
            this.pool = new object3d_pool_1.default(this.createObstacle.bind(this));
            this.pool.setDescription("Obstacle");
            this.pool.grow(initialSize);
        }
    }
    use() {
        if (this.pool) {
            //return this.pool.getFree();
            return this.pool.use();
        }
        return null;
    }
    recycle(meshPoolObject) {
        meshPoolObject.setRotationFromEuler(new three_1.Euler(0, 180 * three_1.MathUtils.DEG2RAD, 0));
        this.pool?.recycle(meshPoolObject);
    }
    setGameTextures() {
        if (this.obstacleMaterial) {
            this.obstacleMaterial.map = this.obstacleTexture;
            this.obstacleMaterial.metalnessMap = this.obstacleMetalness;
            this.obstacleMaterial.roughnessMap = this.obstacleRoughness;
        }
    }
    setFeverTextures() {
        if (this.obstacleMaterial) {
            this.obstacleMaterial.map = this.feverTexture;
            this.obstacleMaterial.metalnessMap = this.feverMetalness;
            this.obstacleMaterial.roughnessMap = this.feverRoughness;
        }
    }
    createObstacle() {
        if (!this.obstacleGroup) {
            throw ("ObstaclePool: createObstacle - obstacle group is null");
        }
        return this.obstacleGroup.clone();
    }
    setupAssets(contentModel) {
        // main textures
        this.obstacleTexture = this.loadTexture('obstacle', true, contentModel);
        this.obstacleMetalness = this.loadTexture('obstacle-metalness', true, contentModel);
        this.obstacleRoughness = this.loadTexture('obstacle-roughness', true, contentModel);
        // material
        this.obstacleMaterial = new THREE.MeshStandardMaterial({
            map: this.obstacleTexture,
            metalness: 1,
            metalnessMap: this.obstacleMetalness,
            roughness: 1,
            roughnessMap: this.obstacleRoughness,
        });
        // fever textures
        this.feverTexture = this.loadTexture('obstacle-fever', true, contentModel);
        this.feverMetalness = this.loadTexture('obstacle-fever-metalness', true, contentModel);
        this.feverRoughness = this.loadTexture('obstacle-fever-roughness', true, contentModel);
        let objectGroup;
        const obstacle = contentModel.getMesh('obstacle');
        obstacle.scene.traverse(model => {
            if (model instanceof THREE.Mesh) {
                const obstacleMesh = model.clone();
                const obstacleScale = this.obstacleScale;
                objectGroup = new THREE.Group();
                objectGroup.scale.set(obstacleScale, obstacleScale, obstacleScale);
                if (this.obstacleMaterial) {
                    obstacleMesh.material = this.obstacleMaterial;
                }
                objectGroup.add(obstacleMesh);
            }
        });
        objectGroup.setRotationFromEuler(new three_1.Euler(0, 180 * three_1.MathUtils.DEG2RAD, 0));
        //        objectGroup!.rotateY( 180 * MathUtils.DEG2RAD );
        return objectGroup;
    }
    loadTexture(key, setEncoding, contentModel) {
        const texture = contentModel.getTexture(key);
        if (!texture) {
            throw `ObstaclePool: loadTexture - texture ${key} not found`;
        }
        if (setEncoding && texture.encoding != THREE.sRGBEncoding) {
            texture.encoding = THREE.sRGBEncoding;
            texture.needsUpdate = true;
        }
        texture.flipY = false;
        return texture;
    }
}
exports.default = ObstaclePool;
