"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraMode = void 0;
var CameraMode;
(function (CameraMode) {
    CameraMode[CameraMode["Main"] = 0] = "Main";
    CameraMode[CameraMode["Game"] = 1] = "Game";
    CameraMode[CameraMode["Fail"] = 2] = "Fail";
    CameraMode[CameraMode["GameOver"] = 3] = "GameOver";
    CameraMode[CameraMode["Fever"] = 4] = "Fever";
})(CameraMode = exports.CameraMode || (exports.CameraMode = {}));
