"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOSButton = exports.SongButton = exports.ColorButton = exports.PlayButton = void 0;
const THREE = require("three");
const button_view_1 = require("./button_view");
const ftue_text_1 = require("./ftue_text");
class PlayButton extends button_view_1.default {
    constructor(contentModel) {
        super('play-button', 'play-base', contentModel);
    }
    onPointerDown() {
        this.scale.set(1.05, 1.05, 1);
        this.position.setX(this.width - (this.width * 1.06));
    }
    onPointerUp() {
        this.scale.set(1, 1, 1);
    }
}
exports.PlayButton = PlayButton;
class ColorButton extends button_view_1.default {
    constructor(contentModel) {
        super('color-button', 'color-base', contentModel);
        this.baseUp = new THREE.Color(0x544b6b);
        this.baseDown = new THREE.Color(0xf5ecff);
        this.cycleUp = new THREE.Color(0x332750);
        this.cycleDown = new THREE.Color(0xb4a9e1);
        this.colorUp = new THREE.Color(0xfbec9a);
        this.colorDown = new THREE.Color(0xffffea);
        const buttonBase = this.createSprite('main-button-base', this.baseUp.getHex(), 0.8);
        buttonBase.position.setX(this.width * 0.8);
        this.baseMaterial = buttonBase.material;
        this.add(buttonBase);
        const cycle1 = this.createSprite('main_button-cycle-outfit', this.cycleUp.getHex(), 0.8);
        cycle1.position.setX(this.width * 0.8);
        this.cycleMaterial = cycle1.material;
        this.add(cycle1);
        const colorIcon = this.createSprite('icon-outfit', this.colorUp.getHex(), 0.8);
        colorIcon.position.setX(this.width * 0.5);
        this.colorMaterial = colorIcon.material;
        this.add(colorIcon);
    }
    onPointerDown() {
        this.baseMaterial.color = this.baseDown;
        this.cycleMaterial.color = this.cycleDown;
        this.colorMaterial.color = this.colorDown;
    }
    onPointerUp() {
        this.baseMaterial.color = this.baseUp;
        this.cycleMaterial.color = this.cycleUp;
        this.colorMaterial.color = this.colorUp;
    }
}
exports.ColorButton = ColorButton;
class SongButton extends button_view_1.default {
    constructor(contentModel) {
        super('audio-button', 'color-base', contentModel);
        this.baseUp = new THREE.Color(0x544b6b);
        this.baseDown = new THREE.Color(0xf5ecff);
        this.cycleUp = new THREE.Color(0x332750);
        this.cycleDown = new THREE.Color(0xb4a9e1);
        this.colorUp = new THREE.Color(0xfbec9a);
        this.colorDown = new THREE.Color(0xffffea);
        const buttonBase = this.createSprite('main-button-base', this.baseUp.getHex(), 0.8);
        buttonBase.position.setX(this.width * 0.8);
        this.baseMaterial = buttonBase.material;
        this.add(buttonBase);
        const cycle1 = this.createSprite('main_button-cycle-song', this.cycleUp.getHex(), 0.8);
        cycle1.position.setX(this.width * 0.8);
        this.cycleMaterial = cycle1.material;
        this.add(cycle1);
        const colorIcon = this.createSprite('icon-audio', this.colorUp.getHex(), 0.8);
        colorIcon.position.setX(this.width * 0.5);
        this.colorMaterial = colorIcon.material;
        this.add(colorIcon);
    }
    onPointerDown() {
        this.baseMaterial.color = this.baseDown;
        this.cycleMaterial.color = this.cycleDown;
        this.colorMaterial.color = this.colorDown;
    }
    onPointerUp() {
        this.baseMaterial.color = this.baseUp;
        this.cycleMaterial.color = this.cycleUp;
        this.colorMaterial.color = this.colorUp;
    }
}
exports.SongButton = SongButton;
class TOSButton extends button_view_1.default {
    constructor(contentModel) {
        super('tos-button', '', contentModel);
        const material = new THREE.SpriteMaterial({
            color: 0x000000,
            opacity: 0.1
        });
        let backgroundSprite = new THREE.Sprite(material);
        backgroundSprite.scale.set(420, 60, 1);
        backgroundSprite.center.set(0, 0.5);
        backgroundSprite.position.set(210, 0, 0);
        backgroundSprite.name = "TOS";
        this.add(backgroundSprite);
        this.tosText = new ftue_text_1.default(this.contentModel, 0xaaaaaa);
        this.tosText.position.setX(225);
        this.tosText.position.setY(0);
        this.tosText.horizontal = ftue_text_1.TextJustification.Left;
        this.tosText.setText("TERMS OF SERVICE");
        this.tosText.setName("TOS");
        this.add(this.tosText);
    }
    onPointerDown() {
        this.tosText.color = 0xeeeeee;
    }
    onPointerUp() {
        this.tosText.color = 0xaaaaaa;
    }
}
exports.TOSButton = TOSButton;
