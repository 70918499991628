"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const TWEEN = require("@tweenjs/tween.js");
const log = require("loglevel");
const move_data_1 = require("../model/move_data");
const swipe_model_1 = require("../model/swipe_model");
const ftue_text_1 = require("./ftue_text");
const arrowAngles = new Map([
    [swipe_model_1.SwipeDirection.Up, 0],
    [swipe_model_1.SwipeDirection.UpLeft, 45],
    [swipe_model_1.SwipeDirection.Left, 90],
    [swipe_model_1.SwipeDirection.DownLeft, 135],
    [swipe_model_1.SwipeDirection.Down, 180],
    [swipe_model_1.SwipeDirection.DownRight, 225],
    [swipe_model_1.SwipeDirection.Right, 270],
    [swipe_model_1.SwipeDirection.UpRight, 315],
    [swipe_model_1.SwipeDirection.None, 0],
]);
class MoveUnlockView extends THREE.Scene {
    constructor(contentModel, screenModel, moveModel) {
        super();
        this.trackerGroup = null;
        this.gemSprite = null;
        this.arrowSprite = null;
        this.openWedges = [];
        this.filledWedges = [];
        this.faders = [];
        this.fadeOpacity = 0;
        this.arrowScale = 1;
        this.moveNameGroup = null;
        this.moveItemsGroup = null;
        this.moveNameText = null;
        this.moveIcon = null;
        this.moveQueue = [];
        this.showingMove = false;
        log.debug("MoveUnlockView: constructor");
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        this.moveModel = moveModel;
        this.onBeforeRender = this.preRender.bind(this);
        this.onScreenResize();
    }
    reset() {
        if (!this.moveNameGroup) {
            this.moveNameGroup = this.createMoveNameGroup();
            this.add(this.moveNameGroup);
            this.moveNameGroup.visible = false;
        }
        if (!this.trackerGroup) {
            this.trackerGroup = this.createTrackerGroup();
            this.add(this.trackerGroup);
            this.trackerGroup.visible = false;
        }
        this.setWedgeColor(move_data_1.MoveType.Blue);
        this.setFadersOpacity(0);
        this.onScreenResize();
    }
    shutdown() {
    }
    onScreenResize() {
        log.debug("MoveUnlockView: onScreenResize");
        // groupscale actual width and height design is 375 x 812
        const groupScale = this.screenModel.calculateScale(1.0, 375, 0.4, 324);
        // tracker group
        this.trackerGroup?.scale.set(groupScale, groupScale, 1.0);
        this.trackerGroup?.position.setX(this.screenModel.width / 2);
        this.trackerGroup?.position.setY(this.screenModel.height * 0.22);
        // name group
        this.moveNameGroup?.scale.set(groupScale, groupScale, 1.0);
        this.moveNameGroup?.position.setX(this.screenModel.width / 2);
        this.moveNameGroup?.position.setY(this.screenModel.height * 0.38);
    }
    ;
    queueMove(moveData) {
        this.moveQueue.push(moveData);
    }
    showMove(moveData) {
        this.showingMove = true;
        this.setFadersOpacity(0);
        this.trackerGroup.visible = true;
        this.setWedgeColor(moveData.type);
        this.setArrowAngle(moveData.direction);
        this.setCompletedWedges(this.moveModel.getCompletedMoves(moveData.type));
        const fadeIn = new TWEEN.Tween(this)
            .to({ fadeOpacity: 1 }, 250)
            .easing(TWEEN.Easing.Cubic.Out)
            .onUpdate(() => { this.setFadersOpacity(this.fadeOpacity); });
        const fadeOut = new TWEEN.Tween(this)
            .delay(500)
            .to({ fadeOpacity: 0 }, 250)
            .easing(TWEEN.Easing.Cubic.In)
            .onUpdate(() => { this.setFadersOpacity(this.fadeOpacity); })
            .onComplete(() => {
            this.trackerGroup.visible = false;
        });
        fadeIn.chain(fadeOut).start();
        const scale = this.arrowScale;
        this.arrowSprite.scale.setY(scale * 0.5);
        const arrowTween = new TWEEN.Tween(this.arrowSprite.scale)
            .repeatDelay(250)
            .to({ y: scale * 1.15 }, 500)
            .easing(TWEEN.Easing.Cubic.InOut)
            .start();
        this.updateNameGroup(moveData);
        this.moveNameGroup.visible = true;
        this.moveItemsGroup?.position.setX(-this.screenModel.width * 2);
        const nameIn = new TWEEN.Tween(this.moveItemsGroup.position)
            .to({ x: 0 }, 500)
            .easing(TWEEN.Easing.Back.Out);
        const nameOut = new TWEEN.Tween(this.moveItemsGroup.position)
            .to({ x: this.screenModel.width * 2 }, 500)
            .delay(250)
            .easing(TWEEN.Easing.Back.In)
            .onComplete(() => {
            this.moveNameGroup.visible = false;
            this.showingMove = false;
        });
        nameIn.chain(nameOut).start();
    }
    setFadersOpacity(opacity) {
        for (const sprite of this.faders) {
            sprite.material.opacity = opacity;
        }
    }
    setArrowAngle(swipeDir) {
        let angle = arrowAngles.get(swipeDir);
        this.arrowSprite.material.rotation = angle * THREE.MathUtils.DEG2RAD;
    }
    setCompletedWedges(completeMoves) {
        for (let i = 0; i < this.filledWedges.length; i++) {
            this.filledWedges[i].visible = completeMoves[i];
        }
    }
    createTrackerGroup() {
        const group = new THREE.Group();
        const gradientSprite = this.createSprite('unlock-gradient', 1.75);
        gradientSprite.material.color = new THREE.Color(0xcccccc);
        group.add(gradientSprite);
        this.faders.push(gradientSprite);
        this.gemSprite = this.createSprite('ftue-red', 0.4);
        group.add(this.gemSprite);
        this.faders.push(this.gemSprite);
        for (let i = 0; i < 8; i++) {
            let wedge = this.addWedge(i * 45, 'chaos-wedge-empty');
            group.add(wedge);
            this.openWedges.push(wedge);
            this.faders.push(wedge);
        }
        for (let i = 0; i < 8; i++) {
            let wedge = this.addWedge(i * 45, 'chaos-wedge');
            group.add(wedge);
            this.filledWedges.push(wedge);
            wedge.visible = false;
            this.faders.push(wedge);
        }
        this.arrowSprite = this.addArrow(0, 'ftue-arrow');
        group.add(this.arrowSprite);
        this.faders.push(this.arrowSprite);
        this.arrowScale = this.arrowSprite.scale.y;
        return group;
    }
    createMoveNameGroup() {
        const group = new THREE.Group();
        this.moveItemsGroup = new THREE.Group();
        group.add(this.moveItemsGroup);
        this.moveNameText = new ftue_text_1.default(this.contentModel, 0xffffff);
        this.moveNameText.fontSize = 24;
        this.moveNameText.horizontal = ftue_text_1.TextJustification.Left;
        this.moveNameText.setText("DIAMOND");
        this.moveNameText.shadowOffset = 2;
        this.moveItemsGroup.add(this.moveNameText);
        const iconScale = 0.67;
        this.moveIcon = this.createSprite('emoji-salsa', iconScale);
        this.moveIcon.center.set(0, 0.5);
        this.moveItemsGroup.add(this.moveIcon);
        // center icon and text horizontally
        const textWidth = this.moveNameText.textWidth;
        const iconWidth = this.moveIcon.material.map.image.width * iconScale;
        const spacing = 4;
        const totalWidth = iconWidth + spacing + textWidth;
        this.moveIcon.position.setX(-totalWidth / 2);
        this.moveNameText.position.setX(this.moveIcon.position.x + iconWidth + spacing);
        return group;
    }
    createSprite(key, scale) {
        const texture = this.contentModel.getTexture(key);
        const material = new THREE.SpriteMaterial({ map: texture });
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, 0.5);
        sprite.scale.set(width * scale, height * scale, 1);
        return sprite;
    }
    addWedge(angle, textureKey) {
        const texture = this.contentModel.getTexture(textureKey);
        const material = new THREE.SpriteMaterial({ map: texture });
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, -1.1);
        sprite.position.set(0, 0, 0);
        sprite.scale.set(width * 0.27, height * 0.27, 1);
        sprite.material.rotation = angle * THREE.MathUtils.DEG2RAD;
        return sprite;
    }
    addArrow(angle, textureKey) {
        const texture = this.contentModel.getTexture(textureKey);
        const material = new THREE.SpriteMaterial({ map: texture, opacity: 0.8 });
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, -0.4);
        sprite.position.set(0, 0, 0);
        sprite.scale.set(width * 0.25, height * 0.25, 1);
        sprite.material.rotation = angle * THREE.MathUtils.DEG2RAD;
        return sprite;
    }
    setWedgeColor(moveType) {
        let color = this.getColor(moveType);
        switch (moveType) {
            case move_data_1.MoveType.Red:
                this.gemSprite.material.map = this.contentModel.getTexture('ftue-red');
                break;
            case move_data_1.MoveType.Green:
                this.gemSprite.material.map = this.contentModel.getTexture('ftue-green');
                break;
            case move_data_1.MoveType.Blue:
                this.gemSprite.material.map = this.contentModel.getTexture('ftue-blue');
                break;
            case move_data_1.MoveType.Purple:
                this.gemSprite.material.map = this.contentModel.getTexture('ftue-purple');
                break;
        }
        const width = this.gemSprite.material.map.image.width;
        const height = this.gemSprite.material.map.image.height;
        this.gemSprite?.scale.set(width * 0.4, height * 0.4, 1.0);
        const wedgeColor = new THREE.Color(color);
        for (let wedge of this.openWedges) {
            wedge.material.color = wedgeColor;
        }
        for (let wedge of this.filledWedges) {
            wedge.material.color = wedgeColor;
        }
    }
    getColor(moveType) {
        let color = 0xffffff;
        switch (moveType) {
            case move_data_1.MoveType.Red:
                color = 0xd15244;
                break;
            case move_data_1.MoveType.Green:
                color = 0x67c98e;
                break;
            case move_data_1.MoveType.Blue:
                color = 0x4accff;
                break;
            case move_data_1.MoveType.Purple:
                color = 0xab46cc;
                break;
        }
        return color;
    }
    updateNameGroup(moveData) {
        this.moveNameText.setText(moveData.commonName);
        this.moveNameText.color = this.getColor(moveData.type);
        const iconScale = 0.67;
        const iconKey = moveData.iconKey;
        this.moveIcon.material.map = this.contentModel.getTexture(iconKey);
        const width = this.moveIcon.material.map.image.width;
        const height = this.moveIcon.material.map.image.height;
        this.moveIcon?.scale.set(width * iconScale, height * iconScale, 1.0);
        // center icon and text horizontally
        const textWidth = this.moveNameText.textWidth;
        const iconWidth = this.moveIcon.material.map.image.width * iconScale;
        const spacing = 4;
        const totalWidth = iconWidth + spacing + textWidth;
        this.moveIcon.position.setX(-totalWidth / 2);
        this.moveNameText.position.setX(this.moveIcon.position.x + iconWidth + spacing);
    }
    preRender(gl, scene, camera, renderTarget) {
        //        const dt = this.clock.getDelta();
        if (this.moveQueue.length > 0 && !this.showingMove) {
            const move = this.moveQueue.shift();
            if (move) {
                this.showMove(move);
            }
        }
    }
}
exports.default = MoveUnlockView;
