"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
class LoadingProgressBar extends THREE.Group {
    constructor() {
        super();
        this.barWidth = 300;
        this.barHeight = 10;
        this.progress = 0;
        const progressBackground = this.createProgressBar(0x444444);
        this.add(progressBackground);
        this.progressBar = this.createProgressBar(0xff9833);
        this.progressBar.center.set(0, 0.5);
        this.progressBar.position.setX(-this.barWidth / 2);
        this.add(this.progressBar);
        this.updateProgress();
    }
    get width() {
        return this.barWidth;
    }
    get height() {
        return this.barHeight;
    }
    reset() {
        this.progress = 0;
        this.updateProgress();
    }
    setProgress(progress) {
        this.progress = THREE.MathUtils.clamp(progress, 0, 1);
        this.updateProgress();
    }
    createProgressBar(barColor) {
        const material = new THREE.SpriteMaterial({ color: barColor });
        const progressBar = new THREE.Sprite(material);
        progressBar.scale.set(this.barWidth, this.barHeight, 1);
        return progressBar;
    }
    updateProgress() {
        this.progressBar.scale.setX(this.progress * this.barWidth);
    }
}
exports.default = LoadingProgressBar;
