"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScoreEvents = void 0;
const THREE = require("three");
const loglevel_1 = require("loglevel");
const gem_model_1 = require("./gem_model");
const move_model_1 = require("./move_model");
var ScoreEvents;
(function (ScoreEvents) {
    ScoreEvents["GemLevelChanged"] = "gemlevelchanged";
    ScoreEvents["GemTypeChanged"] = "gemtypechanged";
    ScoreEvents["ScoreChanged"] = "scorechanged";
})(ScoreEvents = exports.ScoreEvents || (exports.ScoreEvents = {}));
class ScoreModel extends THREE.EventDispatcher {
    constructor() {
        super();
        this.gemLevel = [0, 0, 0, 0];
        this.gemType = gem_model_1.GemType.None;
        this.points = 0;
        loglevel_1.default.debug('ScoreModel: constructor');
    }
    reset() {
        loglevel_1.default.debug('ScoreModel: reset');
        this.points = 0;
        for (let i = 0; i < this.gemLevel.length; i++) {
            this.gemLevel[i] = 0;
        }
    }
    getGemLevel(gemType) {
        let level = this.gemLevel[gemType];
        if (level == 0) {
            level = 1;
        }
        return level;
    }
    gemPoints(gemType) {
        loglevel_1.default.debug(`ScoreModel: gemPoints  gemType = ${gemType}`);
        const oldLevel = this.getGemLevel(gemType);
        this.gemLevel[gemType] += 1;
        const newLevel = this.getGemLevel(gemType);
        if (newLevel != oldLevel) {
            loglevel_1.default.debug(`ScoreModel: gemPoints - gemLevelChanged  gemType = ${gemType}  gemLevel = ${newLevel}`);
            this.dispatchEvent({
                type: ScoreEvents.GemLevelChanged,
                level: newLevel,
                gemType: gemType
            });
        }
        if (gemType != this.gemType) {
            this.gemType = gemType;
            loglevel_1.default.debug(`ScoreModel: gemPoints - gemTypeChanged  gemType = ${gemType}`);
            this.dispatchEvent({
                type: ScoreEvents.GemTypeChanged,
                gemType: this.gemType
            });
        }
        const points = (ScoreModel.GEM_SCORE * newLevel);
        return points;
    }
    tallyGemPoints(gems) {
        loglevel_1.default.debug(`ScoreModel: tallyGemPoints`);
        let points = 0;
        for (const gem of gems) {
            points += this.gemPoints(gem);
        }
        this.points += points;
        loglevel_1.default.debug(`ScoreModel: tallyGemPoints - scoreChanged awarded = ${points} score = ${this.points}`);
        this.dispatchEvent({
            type: ScoreEvents.ScoreChanged,
            score: this.points
        });
        return points;
    }
    tallyMovePoints(move) {
        loglevel_1.default.debug(`ScoreModel: tallyMovePoints move = ${move.name}`);
        const gemType = move_model_1.default.moveTypeToGemType(move.type);
        const points = move.score * this.getGemLevel(gemType);
        this.points += points;
        loglevel_1.default.debug(`ScoreModel: tallyMovePoints - scoreChanged awarded = ${points} score = ${this.points}`);
        this.dispatchEvent({
            type: ScoreEvents.ScoreChanged,
            score: this.points
        });
        return points;
    }
    tallyMoveSetPoints(move) {
        loglevel_1.default.debug(`ScoreModel: tallyMoveSetPoints moveSet = ${move.type}`);
        const gemType = move_model_1.default.moveTypeToGemType(move.type);
        const points = ScoreModel.MOVE_SET_COMPLETE_SCORE * this.getGemLevel(gemType);
        this.points += points;
        loglevel_1.default.debug(`ScoreModel: tallyMoveSetPoints - scoreChanged awarded = ${points} score = ${this.points}`);
        this.dispatchEvent({
            type: ScoreEvents.ScoreChanged,
            score: this.points
        });
        return points;
    }
    tallyFeverPoints(numCompletedMoves) {
        const points = ScoreModel.FEVER_MOVE_SCORE * numCompletedMoves;
        this.points += points;
        this.dispatchEvent({
            type: ScoreEvents.ScoreChanged,
            score: this.points
        });
        return points;
    }
}
exports.default = ScoreModel;
ScoreModel.GEM_SCORE = 10;
ScoreModel.MOVE_SET_COMPLETE_SCORE = 5000;
ScoreModel.FEVER_MOVE_SCORE = 1000;
