"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoadItemType = void 0;
var RoadItemType;
(function (RoadItemType) {
    RoadItemType[RoadItemType["None"] = -1] = "None";
    RoadItemType[RoadItemType["PickupRed"] = 0] = "PickupRed";
    RoadItemType[RoadItemType["PickupGreen"] = 1] = "PickupGreen";
    RoadItemType[RoadItemType["PickupBlue"] = 2] = "PickupBlue";
    RoadItemType[RoadItemType["PickupPurple"] = 3] = "PickupPurple";
    RoadItemType[RoadItemType["PickupBlack"] = 4] = "PickupBlack";
})(RoadItemType = exports.RoadItemType || (exports.RoadItemType = {}));
// song levels
const level00_1 = require("./levels/level00");
const level01_1 = require("./levels/level01");
const level02_1 = require("./levels/level02");
const level03_1 = require("./levels/level03");
const level04_1 = require("./levels/level04");
const level05_1 = require("./levels/level05");
const level06_1 = require("./levels/level06");
const level07_1 = require("./levels/level07");
let RoadData = new Map([
    ["00 Fever", level00_1.default],
    ["01 Trance Break", level01_1.default],
    ["02 Bassline Strut", level02_1.default],
    ["03 Queso Fundido", level06_1.default],
    ["04 Get Down", level04_1.default],
    ["05 B-boy Stance", level07_1.default],
    ["06 Ocean Breeze", level03_1.default],
    ["07 La Maracas", level05_1.default],
]);
exports.default = RoadData;
