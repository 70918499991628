"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const three_1 = require("three");
const boot_controller_1 = require("./controller/boot_controller");
const screen_model_1 = require("./model/screen_model");
const loglevel_1 = require("loglevel");
// Setup webgl
const gl = new three_1.WebGLRenderer({
    antialias: true,
});
gl.autoClear = false;
gl.setPixelRatio(window.devicePixelRatio);
gl.setSize(window.innerWidth, window.innerHeight);
// Display the webgl "canvas" on the full page
document.body.style.margin = '0';
document.body.style.overscrollBehavior = 'none';
document.body.style.overflow = 'hidden';
document.body.style.position = 'fixed';
document.body.style.touchAction = 'none';
document.body.style.height = '100%';
document.body.style.backgroundColor = '#271f39';
document.body.appendChild(gl.domElement);
loglevel_1.default.setLevel(loglevel_1.default.levels.INFO);
console.log(`%c   Disco Loco 3D Version: ${DL3D_VERSION} - ${BUILD_TYPE}  `, "color: yellow; background-color: purple; font-style: italic;");
// Boot and run our game
let canvasDimensions = screen_model_1.default.getCanvasDimensions(window.innerWidth, window.innerHeight);
const screen = new screen_model_1.default(canvasDimensions.width, canvasDimensions.height, 0.05); // Maybe target 750x1344
screen.margin = canvasDimensions.margin;
new boot_controller_1.default(gl, screen);
