"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GemType = void 0;
const TWEEN = require("@tweenjs/tween.js");
var GemType;
(function (GemType) {
    GemType[GemType["Red"] = 0] = "Red";
    GemType[GemType["Green"] = 1] = "Green";
    GemType[GemType["Blue"] = 2] = "Blue";
    GemType[GemType["Purple"] = 3] = "Purple";
    GemType[GemType["Obstacle"] = 4] = "Obstacle";
    GemType[GemType["None"] = 5] = "None";
})(GemType = exports.GemType || (exports.GemType = {}));
var GemState;
(function (GemState) {
    GemState[GemState["Ready"] = 0] = "Ready";
    GemState[GemState["Collecting"] = 1] = "Collecting";
    GemState[GemState["Collected"] = 2] = "Collected";
    GemState[GemState["Sinking"] = 3] = "Sinking";
    GemState[GemState["Sunk"] = 4] = "Sunk";
})(GemState || (GemState = {}));
class GemModel {
    constructor(gemType, position) {
        this.isVisible = true;
        this.yPos = 0;
        this.isGemType = false;
        this.gemType = gemType;
        this.position = position.clone();
        this.originalPosition = this.position.clone();
        this.state = GemState.Ready;
        switch (this.gemType) {
            case GemType.Red:
            case GemType.Blue:
            case GemType.Green:
            case GemType.Purple:
                this.isGemType = true;
                break;
        }
    }
    get hasCollected() {
        return this.state == GemState.Collected;
    }
    get isCollecting() {
        return this.state == GemState.Collecting;
    }
    get isSinking() {
        return this.state == GemState.Sinking;
    }
    get hasSunk() {
        return this.state == GemState.Sunk;
    }
    get isReady() {
        return this.state == GemState.Ready;
    }
    get isGem() {
        return this.isGemType;
    }
    get isObstacle() {
        return !this.isGemType;
    }
    reset() {
        this.position = this.originalPosition.clone();
        this.isVisible = true;
        this.state = GemState.Ready;
    }
    collect() {
        if (this.gemType == GemType.Obstacle) {
            this.state = GemState.Collected;
        }
        else {
            this.yPos = this.position.y;
            this.state = GemState.Collecting;
            new TWEEN.Tween(this)
                .to({ yPos: GemModel.MAX_Y }, GemModel.UP_DURATION)
                .easing(TWEEN.Easing.Cubic.In)
                .start()
                .onComplete((thisGem) => {
                thisGem.state = GemState.Collected;
                thisGem.isVisible = false;
            })
                .onUpdate((thisGem) => { thisGem.position.setY(this.yPos); });
        }
    }
    sink() {
        this.yPos = this.position.y;
        this.state = GemState.Collecting;
        new TWEEN.Tween(this)
            .to({ yPos: GemModel.MIN_Y }, GemModel.UP_DURATION)
            .easing(TWEEN.Easing.Cubic.In)
            .start()
            .onComplete((thisGem) => {
            thisGem.state = GemState.Sunk;
            thisGem.isVisible = false;
        })
            .onUpdate((thisGem) => { thisGem.position.setY(this.yPos); });
    }
}
exports.default = GemModel;
GemModel.MAX_Y = 15;
GemModel.MIN_Y = -4;
GemModel.UP_DURATION = 500;
GemModel.DOWN_DURATION = 1000;
