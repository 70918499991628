"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentStage = void 0;
const THREE = require("three");
const content_data_1 = require("./content_data");
const loglevel_1 = require("loglevel");
const texture_loader_1 = require("./content_loaders/texture_loader");
const data_texture_loader_1 = require("./content_loaders/data_texture_loader");
const font_loader_1 = require("./content_loaders/font_loader");
const mesh_loader_1 = require("./content_loaders/mesh_loader");
const animation_loader_1 = require("./content_loaders/animation_loader");
const song_loader_1 = require("./content_loaders/song_loader");
var ContentStage;
(function (ContentStage) {
    ContentStage[ContentStage["None"] = 0] = "None";
    ContentStage[ContentStage["Preload"] = 1] = "Preload";
    ContentStage[ContentStage["Main"] = 2] = "Main";
    ContentStage[ContentStage["Game"] = 3] = "Game";
})(ContentStage = exports.ContentStage || (exports.ContentStage = {}));
var ContentPercents;
(function (ContentPercents) {
    ContentPercents[ContentPercents["textures"] = 0.25] = "textures";
    ContentPercents[ContentPercents["dataTextures"] = 0.05] = "dataTextures";
    ContentPercents[ContentPercents["fonts"] = 0.05] = "fonts";
    ContentPercents[ContentPercents["animations"] = 0.25] = "animations";
    ContentPercents[ContentPercents["meshes"] = 0.2] = "meshes";
    ContentPercents[ContentPercents["songs"] = 0.2] = "songs";
})(ContentPercents || (ContentPercents = {}));
;
class ContentModel {
    constructor() {
        this.textureLoader = new texture_loader_1.default();
        this.dataTextureLoader = new data_texture_loader_1.default();
        this.fontLoader = new font_loader_1.default();
        this.meshLoader = new mesh_loader_1.default();
        this.animationLoader = new animation_loader_1.default();
        this.songLoader = new song_loader_1.default();
        this.currentStage = ContentStage.None;
        this.contentData = null;
        this.stageCompletion = new Map();
        this.stageCompletion.set(ContentStage.Preload, {
            animationCompletion: 0,
            dataTextureCompletion: 0,
            fontCompletion: 0,
            meshCompletion: 0,
            songCompletion: 0,
            textureCompletion: 0,
        });
        this.stageCompletion.set(ContentStage.Main, {
            animationCompletion: 0,
            dataTextureCompletion: 0,
            fontCompletion: 0,
            meshCompletion: 0,
            songCompletion: 0,
            textureCompletion: 0,
        });
        this.stageCompletion.set(ContentStage.Game, {
            animationCompletion: 0,
            dataTextureCompletion: 0,
            fontCompletion: 0,
            meshCompletion: 0,
            songCompletion: 0,
            textureCompletion: 0,
        });
        this.clock = new THREE.Clock();
    }
    async loadContent(stage) {
        switch (stage) {
            case ContentStage.Preload:
                loglevel_1.default.info('ContentModel: load - <<<Preload>>>');
                this.contentData = content_data_1.contentPreload;
                break;
            case ContentStage.Main:
                loglevel_1.default.info('ContentModel: load - <<<Main>>>');
                this.contentData = content_data_1.contentMain;
                break;
            case ContentStage.Game:
                loglevel_1.default.info('ContentModel: load - <<<Game>>>');
                this.contentData = content_data_1.contentGame;
                break;
        }
        this.currentStage = stage;
        if (this.contentData) {
            this.clock.start();
            this.textureLoader.load(this.contentData);
            this.dataTextureLoader.load(this.contentData);
            this.fontLoader.load(this.contentData);
            this.animationLoader.load(this.contentData);
            this.meshLoader.load(this.contentData);
            this.songLoader.load(this.contentData);
        }
    }
    getTexture(key) {
        return this.textureLoader.getTexture(key);
    }
    getDataTexture(key) {
        return this.dataTextureLoader.getDataTexture(key);
    }
    getFont(key) {
        return this.fontLoader.getFont(key);
    }
    getMesh(key) {
        return this.meshLoader.getMesh(key);
    }
    getAnimation(key) {
        return this.animationLoader.getAnimation(key);
    }
    getAnimationKeys() {
        return this.animationLoader.getKeys();
    }
    getSong(key) {
        return this.songLoader.getSong(key);
    }
    getTonePlayer(key) {
        return this.songLoader.getPlayer(key);
    }
    getSampler(key) {
        return this.songLoader.getSampler(key);
    }
    getSongKeys() {
        return this.songLoader.getKeys();
    }
    getSongFromIndex(index) {
        const songKeys = this.getSongKeys();
        for (const key of songKeys) {
            if (key.includes(index)) {
                return key;
            }
        }
        throw `ContentModel: getSongFromIndex - invalid index: ${index}`;
    }
    updateStageCompletion() {
        if (this.currentStage != ContentStage.None) {
            const stageCompletion = this.stageCompletion.get(this.currentStage);
            if (stageCompletion) {
                stageCompletion.textureCompletion = this.textureLoader.completion;
                stageCompletion.dataTextureCompletion = this.dataTextureLoader.completion;
                stageCompletion.fontCompletion = this.fontLoader.completion;
                stageCompletion.meshCompletion = this.meshLoader.completion;
                stageCompletion.animationCompletion = this.animationLoader.completion;
                stageCompletion.songCompletion = this.songLoader.completion;
            }
        }
    }
    isStageLoaded(stage) {
        this.updateStageCompletion();
        const stageCompletion = this.stageCompletion.get(stage);
        if (stageCompletion) {
            const isComplete = (stageCompletion.textureCompletion == 1 &&
                stageCompletion.dataTextureCompletion == 1 &&
                stageCompletion.fontCompletion == 1 &&
                stageCompletion.animationCompletion == 1 &&
                stageCompletion.meshCompletion == 1 &&
                stageCompletion.songCompletion == 1);
            if (isComplete && this.clock.running) {
                this.clock.stop();
                loglevel_1.default.info(`ContentModel: stage load complete: <<<${this.clock.getElapsedTime().toFixed(3)}s>>>`);
            }
            return isComplete;
        }
        else {
            loglevel_1.default.error(`ContentModel: isStageLoaded - invalid stage ${stage}`);
            return false;
        }
    }
    /**
     * A number inclusively between 0 and 1.
     */
    getProgress(stage) {
        let completion = 0;
        this.updateStageCompletion();
        const stageCompletion = this.stageCompletion.get(stage);
        if (stageCompletion) {
            completion += stageCompletion.textureCompletion * ContentPercents.textures;
            completion += stageCompletion.dataTextureCompletion * ContentPercents.dataTextures;
            completion += stageCompletion.fontCompletion * ContentPercents.fonts;
            completion += stageCompletion.animationCompletion * ContentPercents.animations;
            completion += stageCompletion.meshCompletion * ContentPercents.meshes;
            completion += stageCompletion.songCompletion * ContentPercents.songs;
        }
        else {
            loglevel_1.default.error(`ContentModel: getProgress - invalid stage ${stage}`);
        }
        return completion;
    }
}
exports.default = ContentModel;
