"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainMenuEvents = void 0;
const THREE = require("three");
const TWEEN = require("@tweenjs/tween.js");
const log = require("loglevel");
const content_model_1 = require("../model/content_model");
const main_menu_buttons_1 = require("./main_menu_buttons");
const loading_progress_bar_1 = require("./loading_progress_bar");
const song_view_1 = require("./song_view");
var MainMenuEvents;
(function (MainMenuEvents) {
    MainMenuEvents["PlayPressed"] = "playpressed";
    MainMenuEvents["ColorPressed"] = "colorpressed";
    MainMenuEvents["SongPressed"] = "songpressed";
    MainMenuEvents["FeverPressed"] = "feverpressed";
    MainMenuEvents["TOSPressed"] = "tospressed";
})(MainMenuEvents = exports.MainMenuEvents || (exports.MainMenuEvents = {}));
var LoadMode;
(function (LoadMode) {
    LoadMode[LoadMode["Preload"] = 0] = "Preload";
    LoadMode[LoadMode["Loading"] = 1] = "Loading";
    LoadMode[LoadMode["Loaded"] = 2] = "Loaded";
})(LoadMode || (LoadMode = {}));
class MainMenuView extends THREE.Scene {
    constructor(contentModel, screenModel, camera) {
        super();
        this.raycaster = new THREE.Raycaster();
        this.isPointerDown = false;
        this.pointerPosition = new THREE.Vector2(0, 0);
        this.downButtonName = "";
        this.downButtonGroup = null;
        this.loadMode = LoadMode.Preload;
        this.clock = new THREE.Clock();
        this.spamDelay = 0;
        this.zyngaWidth = 0;
        this.zyngaHeight = 0;
        this.onBeforeRender = (gl, scene, camera, renderTarget) => {
            const dt = this.clock.getDelta();
            if (this.spamDelay > 0) {
                this.spamDelay -= dt;
            }
            if (this.loadMode == LoadMode.Preload) {
                const progress = this.contentModel.getProgress(content_model_1.ContentStage.Main);
                this.progressBar.setProgress(progress);
            }
            if (this.songViewGroup) {
                this.songViewGroup.update(dt);
            }
        };
        log.debug("MainMenuView: constructor");
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        this.camera = camera;
        this.buttonGroup = new THREE.Group();
        this.buttonGroup.position.setY(this.screenModel.safeArea.min.y);
        this.add(this.buttonGroup);
        this.playGroup = new main_menu_buttons_1.PlayButton(this.contentModel);
        this.playGroup.position.setY(1300);
        this.buttonGroup.add(this.playGroup);
        this.colorGroup = new main_menu_buttons_1.ColorButton(this.contentModel);
        this.colorGroup.position.setY(975);
        this.colorGroup.position.setX(-200);
        this.buttonGroup.add(this.colorGroup);
        this.songGroup = new main_menu_buttons_1.SongButton(this.contentModel);
        this.songGroup.position.setX(-200);
        this.songGroup.position.setY(650);
        this.buttonGroup.add(this.songGroup);
        this.tosGroup = new main_menu_buttons_1.TOSButton(this.contentModel);
        this.tosGroup.position.setX(-200);
        this.tosGroup.position.setY(-75);
        this.buttonGroup.add(this.tosGroup);
        this.versionGroup = new THREE.Group();
        const versionText = this.createVersionText();
        this.versionGroup.add(versionText);
        this.add(this.versionGroup);
        this.progressBar = new loading_progress_bar_1.default();
        this.add(this.progressBar);
        this.zyngaGroup = this.createZyngaGroup();
        this.add(this.zyngaGroup);
        this.songViewGroup = new song_view_1.default(this.contentModel, this.screenModel);
        this.add(this.songViewGroup);
        this.onScreenResize();
    }
    reset() {
        this.spamDelay = 0;
        this.onScreenResize();
    }
    setProgressBarVisible(visible) {
        this.progressBar.visible = visible;
    }
    setPlayerColor(colorIndex) {
        //this.colorGroup.setColor( colorIndex );
    }
    setSong(songKey) {
        //this.songGroup.setSong( songKey );
        this.songViewGroup.setSong(songKey);
    }
    setPreloadMode() {
        this.loadMode = LoadMode.Preload;
        this.progressBar.visible = true;
        this.onScreenResize();
    }
    setLoadedMode() {
        this.loadMode = LoadMode.Loaded;
        this.progressBar.visible = false;
        this.onScreenResize();
    }
    setLoadingComplete() {
        this.progressBar.visible = false;
        this.loadMode = LoadMode.Loading;
        new TWEEN.Tween(this.buttonGroup.position)
            .to({ x: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.Out)
            .start()
            .onComplete(() => { this.setLoadedMode(); });
    }
    onPointerDown(pointerPosition) {
        log.trace(`MainMenuView: onPointerDown - (${pointerPosition.x.toFixed(2)}, ${pointerPosition.y.toFixed(2)})`);
        if (!this.isPointerDown && this.spamDelay <= 0) {
            this.isPointerDown = true;
            this.pointerPosition.x = (pointerPosition.x / this.screenModel.width) * 2 - 1;
            this.pointerPosition.y = -((pointerPosition.y / this.screenModel.height) * 2 - 1);
            log.trace(`MainMenuView: cast - (${this.pointerPosition.x.toFixed(2)}, ${this.pointerPosition.y.toFixed(2)})`);
            this.raycaster.setFromCamera(this.pointerPosition, this.camera);
            const intersects = this.raycaster.intersectObject(this.buttonGroup, true);
            if (intersects.length > 0) {
                let intersection = intersects[0];
                let object = intersection.object;
                this.downButtonName = object.name;
                log.debug(`MainMenuView: onPointerDown - object: ${object.name}`);
                switch (this.downButtonName) {
                    case this.playGroup.buttonName:
                        this.downButtonGroup = this.playGroup;
                        break;
                    case this.colorGroup.buttonName:
                        this.downButtonGroup = this.colorGroup;
                        break;
                    case this.songGroup.buttonName:
                        this.downButtonGroup = this.songGroup;
                        break;
                    case "TOS":
                        this.downButtonGroup = this.tosGroup;
                        break;
                }
                this.downButtonGroup?.onPointerDown();
            }
        }
    }
    onPointerMove(pointerPosition) {
        if (this.isPointerDown) {
            this.pointerPosition.x = (pointerPosition.x / this.screenModel.width) * 2 - 1;
            this.pointerPosition.y = -((pointerPosition.y / this.screenModel.height) * 2 - 1);
        }
    }
    onPointerUp() {
        if (this.isPointerDown) {
            this.isPointerDown = false;
            this.spamDelay = MainMenuView.SPAM_DELAY_DURATION;
            this.raycaster.setFromCamera(this.pointerPosition, this.camera);
            const intersects = this.raycaster.intersectObject(this.buttonGroup, true);
            if (intersects.length > 0) {
                let intersection = intersects[0];
                let object = intersection.object;
                if (this.downButtonName == object.name) {
                    log.debug(`MainMenuView: onPointerUp press - object: ${object.name}`);
                    if (this.downButtonGroup != null) {
                        this.downButtonGroup.onPointerUp();
                        this.downButtonGroup = null;
                    }
                    switch (this.downButtonName) {
                        case this.playGroup.buttonName:
                            this.dispatchEvent({ type: MainMenuEvents.PlayPressed });
                            break;
                        case this.colorGroup.buttonName:
                            this.dispatchEvent({ type: MainMenuEvents.ColorPressed });
                            break;
                        case this.songGroup.buttonName:
                            this.dispatchEvent({ type: MainMenuEvents.SongPressed });
                            break;
                        case "TOS":
                            this.dispatchEvent({ type: MainMenuEvents.TOSPressed });
                            break;
                    }
                }
            }
            this.downButtonName = "";
            if (this.downButtonGroup != null) {
                this.downButtonGroup.onPointerUp();
                this.downButtonGroup = null;
            }
        }
    }
    createVersionText() {
        const version = `${DL3D_VERSION}`;
        const geometry = new THREE.TextGeometry(version, {
            font: this.contentModel.getFont('numbersfont'),
            size: 10,
        });
        const material = new THREE.MeshBasicMaterial({
            opacity: 0.67,
            side: THREE.DoubleSide,
            transparent: true,
            color: 0x888888
        });
        let text = new THREE.Mesh(geometry, material);
        return text;
    }
    onScreenResize() {
        log.debug("MainMenuView: onScreenResize");
        const buttonsScale = this.screenModel.calculateScale(0.45, this.playGroup.width, 0.09, this.playGroup.height);
        this.buttonGroup.scale.set(buttonsScale, buttonsScale, 1.0);
        this.buttonGroup.position.setY(this.screenModel.safeArea.min.y);
        const versionScale = this.screenModel.calculateHeightScale(0.02, 15);
        this.versionGroup.scale.set(versionScale, versionScale, 1.0);
        this.versionGroup.position.setX(this.screenModel.width * 0.02);
        this.versionGroup.position.setY(this.screenModel.height * 0.98);
        if (this.loadMode == LoadMode.Preload) {
            this.buttonGroup.position.setX(-(this.screenModel.width * 0.5));
        }
        else if (this.loadMode == LoadMode.Loaded) {
            this.buttonGroup.position.setX(0);
        }
        const progressScaleX = this.screenModel.calculateWidthScale(0.6, this.progressBar.width);
        const progressScaleY = this.screenModel.calculateHeightScale(0.01, this.progressBar.height);
        this.progressBar.scale.set(progressScaleX, progressScaleY, 1);
        this.progressBar.position.set(this.screenModel.width * 0.5, 0.025 * this.screenModel.height, 0);
        const zyngaScale = this.screenModel.calculateScale(0.135, this.zyngaWidth, 0.15, this.zyngaHeight);
        this.zyngaGroup.scale.set(zyngaScale, zyngaScale, 1.0);
        this.zyngaGroup.position.setX(this.screenModel.width);
        this.zyngaGroup.position.setY(this.screenModel.height * 0.92);
        const songViewScaleY = this.screenModel.calculateHeightScale(0.32, this.songViewGroup.songViewHeight);
        this.songViewGroup.scale.set(songViewScaleY, songViewScaleY, 1.0);
        this.songViewGroup.position.setX(this.screenModel.width * 0.0);
        this.songViewGroup.position.setY(this.screenModel.height * 0.65);
    }
    ;
    createZyngaGroup() {
        const texture = this.contentModel.getTexture('zynga-logo');
        const material = new THREE.SpriteMaterial({
            map: texture,
        });
        this.zyngaWidth = material.map.image.width;
        this.zyngaHeight = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.scale.set(this.zyngaWidth, this.zyngaHeight, 1);
        sprite.center.set(1, 1);
        const group = new THREE.Group();
        group.add(sprite);
        return group;
    }
}
exports.default = MainMenuView;
MainMenuView.SPAM_DELAY_DURATION = 0.15;
