"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const three_1 = require("three");
const lighting_mode_1 = require("../controller/lighting_mode");
const random_1 = require("../model/random");
const obstacle_pool_1 = require("./obstacle_pool");
function flip(a0, a1, a2, a3, a4) {
    return [
        a4,
        a3,
        a2,
        a1,
        a0,
    ];
}
function pattern(input) {
    return input.split(' ')
        .map(chr => {
        return random_1.Random.next(16);
        switch (chr) {
            case '0': return 9;
            case '1': return 10;
            case 'B': return 4;
            case 'b': return 0;
            case 'C': return 7;
            case 'c': return 3;
            case 'G': return 5;
            case 'g': return 1;
            case 'O': return 12;
            case 'o': return 8;
            case 'P': return 15;
            case 'p': return 11;
            case 'R': return 13;
            case 'r': return 14;
            case 'Y': return 6;
            case 'y': return 2;
            default: throw new Error();
        }
    });
}
const roadLightRed = new THREE.Color(0xfaae9d); //fff4e5 0xffcccc
const roadLightGreen = new THREE.Color(0xaffad9); //0xd8ffcc
const roadLightBlue = new THREE.Color(0xbff1ff); //0xccf2ff
const roadLightPurple = new THREE.Color(0xccb8ff); //0xdaccff
const ambientRed = new THREE.Color(0xe6a1a1); //ffbfbf alt
const ambientGreen = new THREE.Color(0x5b7e3f);
const ambientBlue = new THREE.Color(0x3f427e);
const ambientPurple = new THREE.Color(0x4b3f7e);
class FeverRoadView extends THREE.Scene {
    //wallLight: THREE.PointLight;
    constructor(gl, contentModel, camera) {
        super();
        this.patternIndex = 0;
        this.clock = new THREE.Clock();
        this.roadObjects = [];
        this.speakerGroup = new THREE.Group();
        this.patternAnim = new THREE.AnimationMixer(this);
        this.lastPatternIndex = -1;
        this.ambientLight = null;
        this.gl = gl;
        this.contentModel = contentModel;
        this.camera = camera;
        this.fog = new THREE.Fog(0x03001d, -5, 55);
        this.patternAnim.clipAction(new THREE.AnimationClip(undefined, undefined, [new THREE.NumberKeyframeTrack('.patternIndex', [
                0 * FeverRoadView.PATTERN_TIME,
                1 * FeverRoadView.PATTERN_TIME,
                2 * FeverRoadView.PATTERN_TIME,
                3 * FeverRoadView.PATTERN_TIME,
                4 * FeverRoadView.PATTERN_TIME,
                5 * FeverRoadView.PATTERN_TIME,
                6 * FeverRoadView.PATTERN_TIME,
                7 * FeverRoadView.PATTERN_TIME,
                8 * FeverRoadView.PATTERN_TIME,
                9 * FeverRoadView.PATTERN_TIME,
            ], [
                0,
                1,
                2,
                1,
                3,
                2,
                4,
                0,
                2,
                3,
            ], THREE.InterpolateDiscrete)])).play();
        const size2 = FeverRoadView.PATTERN_SIZE * FeverRoadView.PATTERN_SIZE;
        const roadGeometry = new THREE.BufferGeometry();
        roadGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(18 * FeverRoadView.PATTERNS * size2), 3));
        roadGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(12 * FeverRoadView.PATTERNS * size2), 2, true));
        roadGeometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(18 * FeverRoadView.PATTERNS * size2), 3));
        let roadTexture = this.contentModel.getTexture('square-fever');
        if (roadTexture) {
            roadTexture.flipY = false;
            roadTexture.needsUpdate = true;
        }
        this.road = new THREE.Mesh(roadGeometry, new THREE.MeshStandardMaterial({
            map: roadTexture,
            side: THREE.DoubleSide,
            metalness: 0.35,
            roughness: 0.6,
            color: 0xffffff,
        }));
        this.road.material.map.minFilter = THREE.NearestFilter;
        this.road.material.map.magFilter = THREE.NearestFilter;
        // this.road.scale.set(2, 1, 2);
        this.road.position.setY(-1);
        this.road.rotateX(-0.025);
        this.add(this.road);
        //ambient turned off for the moment 
        //this.wallLight = new THREE.PointLight( ambientRed, 0 );
        //this.add( this.wallLight );
        //this.wallLight.position.set( 0, 18, -12 );
        this.roadLight = new THREE.PointLight(roadLightRed, 0.85); //.55
        this.roadLight.position.set(0, 3, 4); //0, 4, 4  alt front
        this.add(this.roadLight);
        this.onBeforeRender = this.preRender.bind(this);
    }
    reset() {
        for (const roadObject of this.roadObjects) {
            // remove the mesh from the group
            this.speakerGroup.remove(roadObject);
            // recycle the objects
            obstacle_pool_1.default.Instance.recycle(roadObject);
        }
        this.roadObjects = [];
        obstacle_pool_1.default.Instance.setGameTextures();
    }
    createFeverItems() {
        obstacle_pool_1.default.Instance.setFeverTextures();
        this.addSidewaysSpeaker(0, false);
        this.addSidewaysSpeaker(1, false);
        this.addSidewaysSpeaker(2, true);
        this.addStandingSpeaker(2.135, 0.5);
        this.addStandingSpeaker(2.135, 3.17);
        this.addStandingSpeaker(2.135, 5.84);
        this.addStandingSpeaker(3.735, -0.5);
        this.addStandingSpeaker(3.735, 2.17);
        this.addStandingSpeaker(3.735, 4.84);
        this.addStandingSpeaker(-2.135, 0.5);
        this.addStandingSpeaker(-2.135, 3.17);
        this.addStandingSpeaker(-2.135, 5.84);
        this.addStandingSpeaker(-3.735, -0.5);
        this.addStandingSpeaker(-3.735, 2.17);
        this.addStandingSpeaker(-3.735, 4.84);
        this.add(this.speakerGroup);
        // adjust position, rotation and scale of speaker group
        this.speakerGroup.position.setY(1);
        this.speakerGroup.position.setZ(-6.2);
        this.speakerGroup.rotation.set(-32 * three_1.MathUtils.DEG2RAD, 0, 0);
        this.speakerGroup.scale.set(1.45, 1.45, 1.45);
    }
    addSidewaysSpeaker(row, inverted) {
        const speakerWidth = 2.67;
        const speakerHeight = 1.6;
        const speakerDepth = 1.8;
        const obstacle = obstacle_pool_1.default.Instance.use();
        if (obstacle) {
            let xPos = speakerWidth * 0.5;
            let yPos = (speakerHeight * 0.3) + (speakerHeight * row);
            let zPos = speakerDepth * -0.2;
            let zRotation = 90;
            if (inverted) {
                zRotation *= -1;
                xPos -= speakerWidth;
            }
            obstacle.visible = true;
            obstacle.rotateY(180 * three_1.MathUtils.DEG2RAD);
            obstacle.rotateZ(zRotation * three_1.MathUtils.DEG2RAD);
            obstacle.position.set(xPos, yPos, zPos);
            this.speakerGroup.add(obstacle);
            this.roadObjects.push(obstacle);
        }
        else {
            throw ('FeverRoadView: Unable to obtain obstacle mesh group');
        }
    }
    addStandingSpeaker(xPos, yPos) {
        const obstacle = obstacle_pool_1.default.Instance.use();
        if (obstacle) {
            const speakerDepth = 2.5;
            let zPos = speakerDepth * -0.8;
            obstacle.visible = true;
            obstacle.rotateY(180 * three_1.MathUtils.DEG2RAD);
            obstacle.position.set(xPos, yPos, zPos);
            this.speakerGroup.add(obstacle);
            this.roadObjects.push(obstacle);
        }
        else {
            throw ('FeverRoadView: Unable to obtain obstacle mesh group');
        }
    }
    setLightingMode(mode) {
        switch (mode) {
            case lighting_mode_1.LightingMode.FeverRed:
                this.roadLight.color = roadLightRed;
                if (this.ambientLight) {
                    this.ambientLight.color = ambientRed;
                }
                break;
            case lighting_mode_1.LightingMode.FeverGreen:
                this.roadLight.color = roadLightGreen;
                if (this.ambientLight) {
                    this.ambientLight.color = ambientGreen;
                }
                break;
            case lighting_mode_1.LightingMode.FeverBlue:
                this.roadLight.color = roadLightBlue;
                if (this.ambientLight) {
                    this.ambientLight.color = ambientBlue;
                }
                break;
            case lighting_mode_1.LightingMode.FeverPurple:
                this.roadLight.color = roadLightPurple;
                if (this.ambientLight) {
                    this.ambientLight.color = ambientPurple;
                }
                break;
        }
    }
    updateRoadGeometry() {
        const pattern = FeverRoadView.PATTERN[this.patternIndex];
        const position = this.road.geometry.attributes.position;
        const texture = this.road.geometry.attributes.uv;
        const normal = this.road.geometry.attributes.normal;
        const roadSquareSize = 1.2;
        const numPatternsMoveBack = 4;
        const offsetX = FeverRoadView.PATTERN_SIZE * -0.5 * roadSquareSize;
        let i = 0;
        for (let p = 0; p < FeverRoadView.PATTERNS; ++p) {
            const offsetZ = p * FeverRoadView.PATTERN_SIZE * roadSquareSize
                - FeverRoadView.PATTERN_SIZE
                - numPatternsMoveBack * FeverRoadView.PATTERN_SIZE * roadSquareSize;
            for (let z = 0; z < FeverRoadView.PATTERN_SIZE; ++z) {
                const patternRow = pattern[z];
                for (let x = 0; x < FeverRoadView.PATTERN_SIZE; ++x) {
                    const minX = x * roadSquareSize + offsetX;
                    const maxX = minX + roadSquareSize;
                    const minZ = z * roadSquareSize + offsetZ;
                    const maxZ = minZ + roadSquareSize;
                    const tile = patternRow[x];
                    const minU = FeverRoadView.PATTERN_MIN_U[tile] - ((x === 0) ? 0.01 : 0);
                    const maxU = FeverRoadView.PATTERN_MAX_U[tile] + ((x === 4) ? 0.01 : 0);
                    const minV = FeverRoadView.PATTERN_MIN_V[tile] + 0.01;
                    const maxV = FeverRoadView.PATTERN_MAX_V[tile] - 0.01;
                    position.setXYZ(i, minX, 0, minZ);
                    normal.setXYZ(i, 0, 1, 0);
                    texture.setXY(i++, minU, minV);
                    position.setXYZ(i, minX, 0, maxZ);
                    normal.setXYZ(i, 0, 1, 0);
                    texture.setXY(i++, minU, maxV);
                    position.setXYZ(i, maxX, 0, minZ);
                    normal.setXYZ(i, 0, 1, 0);
                    texture.setXY(i++, maxU, minV);
                    position.setXYZ(i, minX, 0, maxZ);
                    normal.setXYZ(i, 0, 1, 0);
                    texture.setXY(i++, minU, maxV);
                    position.setXYZ(i, maxX, 0, maxZ);
                    normal.setXYZ(i, 0, 1, 0);
                    texture.setXY(i++, maxU, maxV);
                    position.setXYZ(i, maxX, 0, minZ);
                    normal.setXYZ(i, 0, 1, 0);
                    texture.setXY(i++, maxU, minV);
                }
            }
        }
        position.needsUpdate = true;
        texture.needsUpdate = true;
        normal.needsUpdate = true;
    }
    updateRoadTexture() {
        const pattern = FeverRoadView.PATTERN[this.patternIndex];
        const texture = this.road.geometry.attributes.uv;
        let i = 0;
        for (let p = 0; p < FeverRoadView.PATTERNS; ++p) {
            for (let z = 0; z < FeverRoadView.PATTERN_SIZE; ++z) {
                const patternRow = pattern[z];
                for (let x = 0; x < FeverRoadView.PATTERN_SIZE; ++x) {
                    const tile = patternRow[x];
                    const minU = FeverRoadView.PATTERN_MIN_U[tile] - ((x === 0) ? 0.01 : 0);
                    const maxU = FeverRoadView.PATTERN_MAX_U[tile] + ((x === 4) ? 0.01 : 0);
                    const minV = FeverRoadView.PATTERN_MIN_V[tile] + 0.01;
                    const maxV = FeverRoadView.PATTERN_MAX_V[tile] - 0.01;
                    texture.setXY(i++, minU, minV);
                    texture.setXY(i++, minU, maxV);
                    texture.setXY(i++, maxU, minV);
                    texture.setXY(i++, minU, maxV);
                    texture.setXY(i++, maxU, maxV);
                    texture.setXY(i++, maxU, minV);
                }
            }
        }
        texture.needsUpdate = true;
    }
    preRender(gl, scene, camera, renderTarget) {
        const dt = this.clock.getDelta();
        this.patternAnim.update(dt);
        if (this.patternIndex != this.lastPatternIndex) {
            if (this.lastPatternIndex == -1) {
                // update everything the first time
                this.updateRoadGeometry();
            }
            else {
                // just update the texture uvs
                this.updateRoadTexture();
            }
            this.lastPatternIndex = this.patternIndex;
        }
    }
}
exports.default = FeverRoadView;
FeverRoadView.PATTERN = [
    [
        [14, 4, 14, 4, 0],
        [4, 14, 4, 14, 0],
        [14, 4, 14, 4, 0],
        [4, 14, 4, 14, 0],
        [0, 0, 0, 0, 0],
    ],
    [
        [11, 3, 11, 3, 0],
        [3, 11, 3, 11, 0],
        [11, 3, 11, 3, 0],
        [3, 11, 3, 11, 0],
        [0, 0, 0, 7, 0],
    ],
    [
        [12, 13, 12, 13, 0],
        [13, 12, 13, 12, 0],
        [12, 13, 12, 13, 0],
        [13, 12, 13, 12, 0],
        [0, 0, 0, 7, 0],
    ],
    [
        [6, 7, 6, 7, 0],
        [7, 6, 7, 6, 0],
        [6, 7, 6, 7, 0],
        [7, 6, 7, 6, 0],
        [0, 0, 0, 7, 0],
    ],
    [
        [0, 11, 0, 11, 0],
        [11, 0, 11, 0, 0],
        [0, 11, 0, 11, 0],
        [11, 0, 11, 0, 0],
        [0, 0, 0, 0, 0],
    ],
    // flip(
    //     pattern('0 0 0 0 0'),
    //     pattern('0 1 1 1 0'),
    //     pattern('0 1 r 1 0'),
    //     pattern('0 1 1 1 0'),
    //     pattern('0 0 0 0 0'),
    // ),
];
FeverRoadView.PATTERN_MIN_U = [
    0.00, 0.25, 0.50, 0.75,
    0.00, 0.25, 0.50, 0.75,
    0.00, 0.25, 0.50, 0.75,
    0.00, 0.25, 0.50, 0.75,
];
FeverRoadView.PATTERN_MAX_U = [
    0.25, 0.50, 0.75, 1.00,
    0.25, 0.50, 0.75, 1.00,
    0.25, 0.50, 0.75, 1.00,
    0.25, 0.50, 0.75, 1.00,
];
FeverRoadView.PATTERN_MIN_V = [
    0.75, 0.75, 0.75, 0.75,
    0.50, 0.50, 0.50, 0.50,
    0.25, 0.25, 0.25, 0.25,
    0.00, 0.00, 0.00, 0.00,
];
FeverRoadView.PATTERN_MAX_V = [
    1.00, 1.00, 1.00, 1.00,
    0.75, 0.75, 0.75, 0.75,
    0.50, 0.50, 0.50, 0.50,
    0.25, 0.25, 0.25, 0.25,
];
FeverRoadView.PATTERN_SIZE = 4;
FeverRoadView.PATTERN_TIME = 0.75;
FeverRoadView.PATTERNS = 7;
