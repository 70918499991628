"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const TWEEN = require("@tweenjs/tween.js");
class SwipeToBeginView extends THREE.Scene {
    constructor(contentModel, screenModel, soundModel) {
        super();
        this.clock = new THREE.Clock();
        this.countdownGroup = new THREE.Group();
        this.finger = null;
        this.dot = null;
        this.dotWidth = 0;
        this.dotHeight = 0;
        this.fadeItems = [];
        this.fadeOpacity = 1;
        this.swipeBlocks = [];
        this.isFading = false;
        this.spritesLoaded = false;
        this.onScreenResize = () => {
            this.countdownGroup.position.set(this.screenModel.width / 2, this.screenModel.height / 2, 0);
            this.swipeGroup.position.setX(this.screenModel.width / 2);
            this.swipeGroup.position.setY(this.screenModel.safeArea.min.y + 0);
            const swipeScale = this.screenModel.calculateScale(0.20, this.dotWidth, 0.10, this.dotHeight);
            this.swipeGroup.scale.set(swipeScale, swipeScale, 1.0);
        };
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        this.soundModel = soundModel;
        this.swipeGroup = new THREE.Group();
        this.swipeGroup.position.setX(this.screenModel.width / 2);
        this.add(this.swipeGroup);
        this.dotGroup = new THREE.Group();
        this.swipeGroup.add(this.dotGroup);
        const three = this.createTextGroup('3');
        const two = this.createTextGroup('2');
        const one = this.createTextGroup('1');
        const dance = this.createTextGroup('DANCE!');
        this.countdownGroup.add(three, two, one, dance);
        this.countdownGroup.visible = false;
        this.add(this.countdownGroup);
        this.onScreenResize();
    }
    reset() {
        if (!this.spritesLoaded) {
            this.spritesLoaded = true;
            this.arrow = this.createSprite('swipe-bar');
            this.arrow.center.set(0.5, 1);
            this.arrow.position.setY(500.0);
            this.swipeGroup.add(this.arrow);
            this.fadeItems.push(this.arrow);
            this.dot = this.createSprite('swipe-dot');
            this.dotWidth = (this.dot.material.map.image.width * 1.55);
            this.dotHeight = (this.dot.material.map.image.height * 1.55);
            this.dot.center.set(0.5, 0.0);
            this.dot.renderOrder = 2;
            this.dotGroup.add(this.dot);
            this.fadeItems.push(this.dot);
            this.finger = this.createSprite('swipe-finger');
            this.finger.center.set(0.1, 1.0);
            this.finger.position.setY(this.dotHeight / 2.15);
            this.finger.renderOrder = 3;
            this.dotGroup.add(this.finger);
            this.fadeItems.push(this.finger);
        }
        this.dotGroup.position.setX(0);
        this.dotGroup.position.setY(275);
        this.swipeGroup.visible = true;
        for (const item of this.fadeItems) {
            item.material.opacity = 1;
        }
        this.swipeTween = this.createSwipeTween();
        this.swipeTween.start();
        this.isFading = false;
        this.onScreenResize();
    }
    createSwipeTween() {
        let tweenCenterToRight = new TWEEN.Tween(this.dotGroup.position) //midset
            .to({ x: 500 }, 1000)
            .easing(TWEEN.Easing.Sinusoidal.InOut);
        let tweenRightToCenter = new TWEEN.Tween(this.dotGroup.position) //endpop
            .to({ x: 0 }, 1000)
            .easing(TWEEN.Easing.Circular.Out);
        let tweenCenterToLeft = new TWEEN.Tween(this.dotGroup.position) //midset
            .to({ x: -500 }, 1000)
            .easing(TWEEN.Easing.Sinusoidal.InOut);
        let tweenLeftToCenter = new TWEEN.Tween(this.dotGroup.position) //endpop
            .to({ x: 0 }, 1000)
            .easing(TWEEN.Easing.Circular.Out);
        tweenCenterToRight.chain(tweenRightToCenter);
        tweenRightToCenter.chain(tweenCenterToLeft);
        tweenCenterToLeft.chain(tweenLeftToCenter);
        tweenLeftToCenter.chain(tweenCenterToRight);
        return tweenCenterToRight;
    }
    createTextGroup(text) {
        let group = new THREE.Group();
        const font = this.contentModel.getFont('gamefont');
        const material = new THREE.MeshBasicMaterial({
            opacity: 0,
            side: THREE.DoubleSide,
            transparent: true,
            color: 0xffb300,
        });
        const geometry = new THREE.TextGeometry(text, {
            font: font,
            size: 42,
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.renderOrder = 2;
        mesh.name = "text";
        const shadowMaterial = new THREE.MeshBasicMaterial({
            opacity: 0,
            side: THREE.DoubleSide,
            transparent: true,
            color: 0x7a51a7,
        });
        const shadowMesh = new THREE.Mesh(geometry, shadowMaterial);
        shadowMesh.renderOrder = 1;
        shadowMesh.name = "shadow";
        mesh.geometry.computeBoundingBox();
        const width = mesh.geometry.boundingBox.max.x - mesh.geometry.boundingBox.min.x;
        const height = mesh.geometry.boundingBox.max.y - mesh.geometry.boundingBox.min.y;
        mesh.position.set(width / -2, height / -2, 0);
        shadowMesh.position.set((width / -2) + 3, (height / -2) - 3, 0);
        group.add(shadowMesh);
        group.add(mesh);
        group.scale.set(0.75, 1, 1);
        group.name = text;
        return group;
    }
    fadeOut() {
        this.isFading = true;
        this.swipeTween?.pause;
        this.fadeOpacity = 1;
        if (this.swipeTween) {
            this.swipeTween.stop();
            this.swipeTween.stopChainedTweens();
            this.swipeTween = undefined;
        }
        new TWEEN.Tween(this)
            .to({ fadeOpacity: 0 }, 500)
            .easing(TWEEN.Easing.Cubic.InOut)
            .onUpdate((thisObject) => {
            for (const item of thisObject.fadeItems) {
                if (item.material.opacity > thisObject.fadeOpacity) {
                    item.material.opacity = thisObject.fadeOpacity;
                }
            }
        })
            .onComplete((thisObject) => {
            thisObject.swipeGroup.visible = false;
        })
            .start();
        //this.countdown.play();
        this.playCountdown();
    }
    playCountdown() {
        this.countdownGroup.scale.set(0.5, 0.5, 1);
        this.countdownGroup.visible = true;
        this.countdownThree();
    }
    countdownThree() {
        const player = this.contentModel.getTonePlayer("three");
        this.soundModel.playSFX(player);
        const threeGroup = this.countdownGroup.getObjectByName("3");
        const threeText = threeGroup.getObjectByName("text");
        const threeShadow = threeGroup.getObjectByName("shadow");
        new TWEEN.Tween(threeText.material)
            .to({ opacity: 1 }, SwipeToBeginView.COUNTDOWN_DURATION)
            .easing(TWEEN.Easing.Circular.InOut)
            .repeat(1)
            .repeatDelay(SwipeToBeginView.COUNTDOWN_DURATION)
            .yoyo(true)
            .start();
        new TWEEN.Tween(threeShadow.material)
            .to({ opacity: 1 }, SwipeToBeginView.COUNTDOWN_DURATION)
            .easing(TWEEN.Easing.Circular.InOut)
            .repeat(1)
            .repeatDelay(SwipeToBeginView.COUNTDOWN_DURATION)
            .yoyo(true)
            .start()
            .onComplete(() => { this.countdownTwo(); });
        new TWEEN.Tween(this.countdownGroup.scale)
            .to({ x: 1.25, y: 1.25 }, SwipeToBeginView.COUNTDOWN_DURATION)
            .easing(TWEEN.Easing.Exponential.InOut)
            .repeat(1)
            .repeatDelay(SwipeToBeginView.COUNTDOWN_DURATION)
            .yoyo(true)
            .start();
    }
    countdownTwo() {
        const player = this.contentModel.getTonePlayer("two");
        this.soundModel.playSFX(player);
        const twoGroup = this.countdownGroup.getObjectByName("2");
        const twoText = twoGroup.getObjectByName("text");
        const twoShadow = twoGroup.getObjectByName("shadow");
        new TWEEN.Tween(twoText.material)
            .to({ opacity: 1 }, SwipeToBeginView.COUNTDOWN_DURATION)
            .easing(TWEEN.Easing.Circular.InOut)
            .repeat(1)
            .repeatDelay(SwipeToBeginView.COUNTDOWN_DURATION)
            .yoyo(true)
            .start();
        new TWEEN.Tween(twoShadow.material)
            .to({ opacity: 1 }, SwipeToBeginView.COUNTDOWN_DURATION)
            .easing(TWEEN.Easing.Circular.InOut)
            .repeat(1)
            .repeatDelay(SwipeToBeginView.COUNTDOWN_DURATION)
            .yoyo(true)
            .start()
            .onComplete(() => { this.countdownOne(); });
        new TWEEN.Tween(this.countdownGroup.scale)
            .to({ x: 1.25, y: 1.25 }, SwipeToBeginView.COUNTDOWN_DURATION)
            .easing(TWEEN.Easing.Exponential.InOut)
            .repeat(1)
            .repeatDelay(SwipeToBeginView.COUNTDOWN_DURATION)
            .yoyo(true)
            .start();
    }
    countdownOne() {
        const player = this.contentModel.getTonePlayer("one");
        this.soundModel.playSFX(player);
        const oneGroup = this.countdownGroup.getObjectByName("1");
        const oneText = oneGroup.getObjectByName("text");
        const oneShadow = oneGroup.getObjectByName("shadow");
        new TWEEN.Tween(oneText.material)
            .to({ opacity: 1 }, SwipeToBeginView.COUNTDOWN_DURATION)
            .easing(TWEEN.Easing.Circular.InOut)
            .repeat(1)
            .repeatDelay(SwipeToBeginView.COUNTDOWN_DURATION)
            .yoyo(true)
            .start();
        new TWEEN.Tween(oneShadow.material)
            .to({ opacity: 1 }, SwipeToBeginView.COUNTDOWN_DURATION)
            .easing(TWEEN.Easing.Circular.InOut)
            .repeat(1)
            .repeatDelay(SwipeToBeginView.COUNTDOWN_DURATION)
            .yoyo(true)
            .start()
            .onComplete(() => { this.countdownDance(); });
        new TWEEN.Tween(this.countdownGroup.scale)
            .to({ x: 1.25, y: 1.25 }, SwipeToBeginView.COUNTDOWN_DURATION)
            .easing(TWEEN.Easing.Exponential.InOut)
            .repeat(1)
            .repeatDelay(SwipeToBeginView.COUNTDOWN_DURATION)
            .yoyo(true)
            .start();
    }
    countdownDance() {
        const player = this.contentModel.getTonePlayer("dance");
        this.soundModel.playSFX(player);
        const danceGroup = this.countdownGroup.getObjectByName("DANCE!");
        const danceText = danceGroup.getObjectByName("text");
        const danceShadow = danceGroup.getObjectByName("shadow");
        new TWEEN.Tween(danceText.material)
            .to({ opacity: 1 }, SwipeToBeginView.COUNTDOWN_DURATION)
            .easing(TWEEN.Easing.Bounce.InOut)
            .repeat(1)
            .repeatDelay(1000)
            .yoyo(true)
            .start();
        new TWEEN.Tween(danceShadow.material)
            .to({ opacity: 1 }, SwipeToBeginView.COUNTDOWN_DURATION)
            .easing(TWEEN.Easing.Bounce.InOut)
            .repeat(1)
            .repeatDelay(1000)
            .yoyo(true)
            .start()
            .onComplete(() => { this.countdownGroup.visible = false; });
        new TWEEN.Tween(this.countdownGroup.scale)
            .to({ x: 1.25, y: 1.25 }, SwipeToBeginView.COUNTDOWN_DURATION)
            .easing(TWEEN.Easing.Elastic.InOut)
            .repeat(1)
            .repeatDelay(1000)
            .yoyo(true)
            .start();
    }
    createSprite(textureKey) {
        const texture = this.contentModel.getTexture(textureKey);
        const material = new THREE.SpriteMaterial({ map: texture });
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.scale.set(width, height, 1);
        return sprite;
    }
}
exports.default = SwipeToBeginView;
SwipeToBeginView.COUNTDOWN_DURATION = 250;
