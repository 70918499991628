"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const log = require("loglevel");
const TWEEN = require("@tweenjs/tween.js");
class LogoView extends THREE.Scene {
    constructor(contentModel, screenModel) {
        super();
        this.logoWidth = 0;
        this.logoHeight = 0;
        log.debug("GameOverView: constructor");
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        this.logoGroup = new THREE.Group();
        this.add(this.logoGroup);
        this.logoSprite = this.createLogoSprite();
        this.logoGroup.add(this.logoSprite);
        this.onScreenResize();
    }
    reset() {
        this.onScreenResize();
    }
    createLogoSprite() {
        const texture = this.contentModel.getTexture("disco-logo");
        const material = new THREE.SpriteMaterial({ map: texture });
        this.logoWidth = material.map.image.width;
        this.logoHeight = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, 0.95);
        //sprite.position.set( this.screenModel.width / 2, this.screenModel.height, 0 );
        sprite.scale.set(this.logoWidth, this.logoHeight, 1);
        return sprite;
    }
    onScreenResize() {
        log.debug("MainMenuView: onScreenResize");
        const logoScale = this.screenModel.calculateScale(0.75, this.logoWidth, 0.4, this.logoHeight);
        this.logoGroup.scale.set(logoScale, logoScale, 1);
        this.logoGroup.position.set(this.screenModel.width / 2, this.screenModel.height, 0);
    }
    ;
    danceMode() {
        new TWEEN.Tween(this.logoSprite.scale)
            .to({ x: this.logoWidth * 0.75, y: this.logoWidth * 0.75 }, 1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
    }
    menuMode() {
        this.logoSprite.material.map = this.contentModel.getTexture("disco-logo");
        new TWEEN.Tween(this.logoSprite.scale)
            .to({ x: this.logoWidth, y: this.logoWidth }, 1000)
            .easing(TWEEN.Easing.Cubic.In)
            .start();
    }
    feverMode() {
        this.logoSprite.material.map = this.contentModel.getTexture("fever-logo");
        new TWEEN.Tween(this.logoSprite.scale)
            .to({ x: this.logoWidth * 0.667, y: this.logoWidth * 0.667 }, 1000)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();
    }
}
exports.default = LogoView;
