"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cheat = exports.CheatEvents = void 0;
const loglevel_1 = require("loglevel");
const THREE = require("three");
const swipe_model_1 = require("./swipe_model");
var CheatEvents;
(function (CheatEvents) {
    CheatEvents["CheatActivated"] = "cheatactivated";
})(CheatEvents = exports.CheatEvents || (exports.CheatEvents = {}));
var CheatInput;
(function (CheatInput) {
    CheatInput[CheatInput["Up"] = 0] = "Up";
    CheatInput[CheatInput["UpRight"] = 1] = "UpRight";
    CheatInput[CheatInput["Right"] = 2] = "Right";
    CheatInput[CheatInput["DownRight"] = 3] = "DownRight";
    CheatInput[CheatInput["Down"] = 4] = "Down";
    CheatInput[CheatInput["DownLeft"] = 5] = "DownLeft";
    CheatInput[CheatInput["Left"] = 6] = "Left";
    CheatInput[CheatInput["UpLeft"] = 7] = "UpLeft";
    CheatInput[CheatInput["Color"] = 8] = "Color";
    CheatInput[CheatInput["Song"] = 9] = "Song";
})(CheatInput || (CheatInput = {}));
var Cheat;
(function (Cheat) {
    Cheat[Cheat["FeverMode"] = 0] = "FeverMode";
})(Cheat = exports.Cheat || (exports.Cheat = {}));
;
const FeverCheatSequence = [
    CheatInput.Up,
    CheatInput.Up,
    CheatInput.Down,
    CheatInput.Down,
    CheatInput.Left,
    CheatInput.Right,
    CheatInput.Left,
    CheatInput.Right,
    CheatInput.Song,
    CheatInput.Color,
];
const Cheats = [
    { name: "Fever", type: Cheat.FeverMode, sequence: FeverCheatSequence },
];
class CheatModel extends THREE.EventDispatcher {
    constructor() {
        super();
        this.currentSequence = [];
        this.MAX_SEQUENCE_LENGTH = 12;
        loglevel_1.default.debug('CheatModel: constructor');
        this.actives = new Set();
    }
    reset() {
        loglevel_1.default.debug('CheatModel: reset');
        this.actives.clear();
    }
    isCheatActive(cheat) {
        return this.actives.has(cheat);
    }
    deactivateCheat(cheat) {
        if (this.actives.has(cheat)) {
            let cheatData = this.getCheatData(cheat);
            if (cheatData) {
                loglevel_1.default.info(`CheatModel: Cheat deactivated: ${cheatData.name}`);
            }
            this.actives.delete(cheat);
        }
    }
    addSwipe(swipe) {
        switch (swipe) {
            case swipe_model_1.SwipeDirection.Up:
                this.addInput(CheatInput.Up);
                break;
            case swipe_model_1.SwipeDirection.UpRight:
                this.addInput(CheatInput.UpRight);
                break;
            case swipe_model_1.SwipeDirection.Right:
                this.addInput(CheatInput.Right);
                break;
            case swipe_model_1.SwipeDirection.DownRight:
                this.addInput(CheatInput.DownRight);
                break;
            case swipe_model_1.SwipeDirection.Down:
                this.addInput(CheatInput.Down);
                break;
            case swipe_model_1.SwipeDirection.DownLeft:
                this.addInput(CheatInput.DownLeft);
                break;
            case swipe_model_1.SwipeDirection.Left:
                this.addInput(CheatInput.Left);
                break;
            case swipe_model_1.SwipeDirection.UpLeft:
                this.addInput(CheatInput.UpLeft);
                break;
        }
    }
    addColorButton() {
        this.addInput(CheatInput.Color);
    }
    addSongButton() {
        this.addInput(CheatInput.Song);
    }
    getCheatData(cheatType) {
        for (let cheat of Cheats) {
            if (cheat.type == cheatType) {
                return cheat;
            }
        }
        return undefined;
    }
    addInput(input) {
        this.currentSequence.push(input);
        while (this.currentSequence.length > this.MAX_SEQUENCE_LENGTH) {
            this.currentSequence.shift();
        }
        this.checkForCheats();
    }
    checkForCheats() {
        for (const cheat of Cheats) {
            const cheatSize = cheat.sequence.length;
            if (this.currentSequence.length >= cheatSize) {
                const sliceStart = this.currentSequence.length - cheatSize;
                const checkSequence = this.currentSequence.slice(sliceStart);
                const same = checkSequence.every((value, index) => value === cheat.sequence[index]);
                if (same) {
                    if (!this.isCheatActive(cheat.type)) {
                        loglevel_1.default.info(`CheatModel: Cheat activated: ${cheat.name}`);
                        this.actives.add(cheat.type);
                        this.dispatchEvent({
                            type: CheatEvents.CheatActivated,
                            cheat: cheat.type,
                            name: cheat.name
                        });
                    }
                }
            }
        }
    }
}
exports.default = CheatModel;
