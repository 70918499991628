"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const log = require("loglevel");
const object3d_pool_1 = require("./object3d_pool");
const gem_model_1 = require("../model/gem_model");
//
// Gem Pool
//
class GemPool {
    constructor() {
        this._initialized = false;
        this.pools = null;
        this.redGemGroup = null;
        this.greenGemGroup = null;
        this.blueGemGroup = null;
        this.purpleGemGroup = null;
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    initialize(contentModel, gl, initialSize = 64) {
        if (!this._initialized) {
            this._initialized = true;
            this.setupAssets(contentModel, gl);
            log.info(`GemPool: initialize - initialSize: ${initialSize} gems each`);
            this.pools = new Map();
            this.pools.set(gem_model_1.GemType.Red, this.createPool(gem_model_1.GemType.Red, initialSize));
            this.pools.set(gem_model_1.GemType.Green, this.createPool(gem_model_1.GemType.Green, initialSize));
            this.pools.set(gem_model_1.GemType.Blue, this.createPool(gem_model_1.GemType.Blue, initialSize));
            this.pools.set(gem_model_1.GemType.Purple, this.createPool(gem_model_1.GemType.Purple, initialSize));
        }
    }
    createPool(gemType, initialSize) {
        const pool = new object3d_pool_1.default(this.createGem.bind(this, gemType));
        pool.setDescription(`GemType: ${gemType}`);
        pool.grow(initialSize);
        return pool;
    }
    // get rid of this null
    use(gemType) {
        const gemPool = this.pools?.get(gemType);
        if (gemPool) {
            return gemPool.use();
        }
        return null;
    }
    recycle(gemType, gemObject) {
        const gemPool = this.pools?.get(gemType);
        if (gemPool) {
            return gemPool.recycle(gemObject);
        }
    }
    createGem(gemType) {
        switch (gemType) {
            case gem_model_1.GemType.Red:
                if (!this.redGemGroup) {
                    throw ("GemPool: createGem - red gem group is null");
                }
                return this.redGemGroup.clone();
            case gem_model_1.GemType.Green:
                if (!this.greenGemGroup) {
                    throw ("GemPool: createGem - green gem group is null");
                }
                return this.greenGemGroup.clone();
            case gem_model_1.GemType.Blue:
                if (!this.blueGemGroup) {
                    throw ("GemPool: createGem - blue gem group is null");
                }
                return this.blueGemGroup.clone();
            case gem_model_1.GemType.Purple:
                if (!this.purpleGemGroup) {
                    throw ("GemPool: createGem - purple gem group is null");
                }
                return this.purpleGemGroup.clone();
        }
        throw ("GemPool: createGem - invalid gem type");
    }
    setupAssets(contentModel, gl) {
        let pmremGenerator = new THREE.PMREMGenerator(gl);
        pmremGenerator.compileEquirectangularShader();
        const hdr = pmremGenerator.fromEquirectangular(contentModel.getDataTexture('gem'));
        const gemMaterial = {
            envMap: hdr.texture,
            roughness: 0,
            transparent: false,
            premultipliedAlpha: true,
        };
        const purpleMaterial = new THREE.MeshPhysicalMaterial({
            color: 0x370d47,
            metalness: .1,
            opacity: 1,
            side: THREE.DoubleSide,
            envMapIntensity: 7,
            ...gemMaterial,
        });
        const greenMaterial = purpleMaterial.clone();
        const redMaterial = purpleMaterial.clone();
        const blueMaterial = purpleMaterial.clone();
        greenMaterial.color = new THREE.Color(0x14260b);
        redMaterial.color = new THREE.Color(0x450003);
        blueMaterial.color = new THREE.Color(0x092e47);
        this.redGemGroup = this.setupGemObject('gem-red', redMaterial, contentModel);
        this.greenGemGroup = this.setupGemObject('gem-green', greenMaterial, contentModel);
        this.blueGemGroup = this.setupGemObject('gem-blue', blueMaterial, contentModel);
        this.purpleGemGroup = this.setupGemObject('gem-purple', purpleMaterial, contentModel);
    }
    setupGemObject(meshKey, material, contentModel) {
        const gemScale = 5.5;
        let obj;
        const gem = contentModel.getMesh(meshKey);
        gem.scene.traverse(model => {
            if (model instanceof THREE.Mesh) {
                const front = model.clone();
                obj = new THREE.Group();
                obj.scale.set(gemScale, gemScale, gemScale);
                front.material = material.clone();
                obj.add(front);
                /*
                const debugCircle = new Mesh(new CircleGeometry());
                debugCircle.scale.set(1/s, 1/s, 1/s);
                debugCircle.translateY(-19.9);
                debugCircle.rotateX(MathUtils.degToRad(-90));
                obj.add(debugCircle);
                */
            }
        });
        return obj;
    }
}
exports.default = GemPool;
