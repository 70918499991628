"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
class GameOverProgress extends THREE.Group {
    constructor() {
        super();
        this.barWidth = 300;
        this.barHeight = 10;
        this.progress = 0;
        const progressOutline = this.createProgressBar(0xfbbc38);
        progressOutline.scale.set(this.barWidth + 2, this.barHeight + 2, 1);
        this.add(progressOutline);
        const progressBackground = this.createProgressBar(0x222222);
        this.add(progressBackground);
        this.progressBar = this.createProgressBar(0xff9833);
        this.progressBar.center.set(0, 0.5);
        this.progressBar.position.setX(-this.barWidth / 2);
        this.add(this.progressBar);
        const tickMaterial = new THREE.SpriteMaterial({
            color: 0xfbe56d
        });
        const tickXStart = -(this.barWidth * 0.5);
        const tickXOffset = this.barWidth / 6;
        for (let tickIndex = 0; tickIndex < 5; tickIndex++) {
            const tick = new THREE.Sprite(tickMaterial);
            tick.renderOrder = 5;
            const tickX = tickXStart + (tickXOffset * (tickIndex + 1));
            tick.position.setX(tickX);
            tick.scale.set(1, this.barHeight, 1);
            this.add(tick);
        }
        this.updateProgress();
    }
    get width() {
        return this.barWidth;
    }
    get height() {
        return this.barHeight;
    }
    reset() {
        this.progress = 0;
        this.updateProgress();
    }
    setProgress(progress) {
        this.progress = THREE.MathUtils.clamp(progress, 0, 1);
        this.updateProgress();
    }
    createProgressBar(barColor) {
        const material = new THREE.SpriteMaterial({ color: barColor });
        const progressBar = new THREE.Sprite(material);
        progressBar.scale.set(this.barWidth, this.barHeight, 1);
        return progressBar;
    }
    updateProgress() {
        this.progressBar.scale.setX(this.progress * this.barWidth);
    }
}
exports.default = GameOverProgress;
