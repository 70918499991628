"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
class ButtonView extends THREE.Group {
    constructor(name, textureKey, contentModel) {
        super();
        this.buttonSprite = null;
        this.buttonWidth = 0;
        this.buttonHeight = 0;
        this._name = "";
        this.contentModel = contentModel;
        this._name = name;
        if (textureKey != '') {
            const texture = this.contentModel.getTexture(textureKey);
            const material = new THREE.SpriteMaterial({ map: texture });
            this.buttonWidth = material.map.image.width;
            this.buttonHeight = material.map.image.height;
            this.buttonSprite = new THREE.Sprite(material);
            this.buttonSprite.name = name;
            this.buttonSprite.center.set(0.0, 0.5);
            this.buttonSprite.scale.set(this.buttonWidth, this.buttonHeight, 1);
            this.add(this.buttonSprite);
        }
    }
    get width() {
        return this.buttonWidth;
    }
    get height() {
        return this.buttonHeight;
    }
    get buttonName() {
        return this._name;
    }
    createSprite(textureKey, color, scale) {
        const texture = this.contentModel.getTexture(textureKey);
        const material = new THREE.SpriteMaterial({
            map: texture,
            color: color,
        });
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.scale.set(width * scale, height * scale, 1);
        sprite.center.set(0.5, 0.5);
        return sprite;
    }
    onPointerDown() {
    }
    onPointerUp() {
    }
}
exports.default = ButtonView;
ButtonView.X_OFFSET = 50;
