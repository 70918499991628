"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const loglevel_1 = require("loglevel");
const TWEEN = require("@tweenjs/tween.js");
const score_text_view_1 = require("./score_text_view");
class ScoreView extends THREE.Scene {
    constructor(contentModel, screenModel, scoreModel) {
        super();
        this.previousPoints = 0;
        this.scoreTextGroup = null;
        this.assetsCreated = false;
        this.toastMaterial = null;
        this.TOAST_SIDE_DURATION = 500;
        this.TOAST_UP_DURATION = 200;
        this.POINTS_TEXT_SIZE = 20;
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        this.scoreModel = scoreModel;
        loglevel_1.default.debug('ScoreView: constructor');
    }
    reset() {
        loglevel_1.default.debug('ScoreView: reset');
        if (!this.assetsCreated) {
            this.assetsCreated = true;
            this.scoreTextGroup = new score_text_view_1.default(this.contentModel, 0xffb300, 0x543e6b); //0x7a51a7
            this.scoreTextGroup.shadowOffset = 3;
            this.add(this.scoreTextGroup);
            this.toastMaterial = new THREE.MeshBasicMaterial({
                side: THREE.DoubleSide,
                transparent: true,
            });
        }
        this.previousPoints = 0;
        this.updatePoints();
        this.onScreenResize();
    }
    // position is the screen-space position of the player
    awardPoints(points, position) {
        loglevel_1.default.debug(`ScoreView: awardPoints ${points}`);
        if (this.previousPoints < this.scoreModel.points) {
            this.previousPoints = this.scoreModel.points;
            this.updatePoints();
        }
        const text = this.createToastText(points);
        if (text) {
            let halfTextWidth = (text.geometry.boundingBox.max.x - text.geometry.boundingBox.min.x) * 0.5;
            text.position.set(position.x - halfTextWidth, this.screenModel.height - position.y, 0);
            this.add(text);
            const tweenToSide = new TWEEN.Tween(text.position)
                .to({ x: this.screenModel.width * 0.9 - halfTextWidth, y: this.screenModel.height * 0.9 }, this.TOAST_SIDE_DURATION)
                .easing(TWEEN.Easing.Cubic.InOut);
            const tweenUp = new TWEEN.Tween(text.position)
                .to({ x: this.screenModel.width * 0.9 - halfTextWidth, y: this.screenModel.height * 0.95 }, this.TOAST_UP_DURATION)
                .easing(TWEEN.Easing.Cubic.In)
                .onComplete(() => {
                this.remove(text);
                text.geometry.dispose();
                text.material.dispose();
            });
            tweenToSide.chain(tweenUp);
            tweenToSide.start();
            new TWEEN.Tween(text.material)
                .delay(this.TOAST_UP_DURATION)
                .to({ opacity: 0.2 }, this.TOAST_SIDE_DURATION)
                .easing(TWEEN.Easing.Cubic.In)
                .start();
        }
    }
    updatePoints() {
        if (this.scoreTextGroup) {
            this.scoreTextGroup.setScore(this.scoreModel.points);
        }
    }
    createToastText(points) {
        if (!this.toastMaterial) {
            return undefined;
        }
        const geometry = new THREE.TextGeometry(points.toString(), {
            font: this.contentModel.getFont('gamefont'),
            size: this.POINTS_TEXT_SIZE,
        });
        geometry.computeBoundingBox();
        const text = new THREE.Mesh(geometry, this.toastMaterial.clone());
        return text;
    }
    fadeOut() {
    }
    onScreenResize() {
        if (this.scoreTextGroup) {
            const scoreTextScale = this.screenModel.calculateScale(0.3, this.scoreTextGroup.width, 0.05, this.scoreTextGroup.height);
            this.scoreTextGroup.scale.set(scoreTextScale, scoreTextScale, 1);
            this.scoreTextGroup.position.set(this.screenModel.width * 0.985, this.screenModel.height * 0.99, 0);
        }
    }
}
exports.default = ScoreView;
