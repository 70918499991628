"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contentGame = exports.contentMain = exports.contentPreload = void 0;
//
// First stage - Preload
//
exports.contentPreload = {
    "textures": [
        { "key": "skyback", "fileName": "texture/SkyBack.jpg" },
        { "key": "zynga-logo", "fileName": "texture/zynga_large.png" },
        { "key": "square", "fileName": "texture/disco-square.png" },
        { "key": "square-fever", "fileName": "texture/disco-square-FEVER.png" },
        { "key": "gal-green-color", "fileName": "texture/discogal_green_color.png" },
        { "key": "gal-green-metalness", "fileName": "texture/discogal_green_metallic.png" },
        { "key": "gal-green-roughness", "fileName": "texture/discogal_green_roughness.png" },
        // common
        { "key": "disco-logo", "fileName": "texture/Menu_Logo.png" },
        // main menu
        { "key": "play-base", "fileName": "texture/UI_Main_Button_Play_base.png" },
        { "key": "color-base", "fileName": "texture/UI_Main_Button_Color_base.png" },
        { "key": "main-button-base", "fileName": "texture/UI_Main_Button_base.png" },
        { "key": "icon-outfit", "fileName": "texture/UI_Main_Icon_outfit_swap.png" },
        { "key": "icon-audio", "fileName": "texture/UI_Main_Icon_audio_swap.png" },
        { "key": "main_button-cycle-outfit", "fileName": "texture/UI_Main_Button_cycle_arrow copy.png" },
        { "key": "main_button-cycle-song", "fileName": "texture/UI_Main_Button_cycle_arrow copy.png" },
        { "key": "record", "fileName": "texture/record_darkcenter.png" },
        { "key": "star", "fileName": "texture/star.png" },
        { "key": "star-empty", "fileName": "texture/star_empty.png" },
        { "key": "icon_song_00", "fileName": "texture/UI_emoji_disco_disco.png" },
        { "key": "song_rating_heart", "fileName": "texture/tracker_icon_red.png" },
        { "key": "small-white", "fileName": "texture/smallwhite.png" },
    ],
    "dataTextures": [
        { "key": "dancer-hdr", "fileName": "texture/dancer.hdr" },
    ],
    "fonts": [
        // http://gero3.github.io/facetype.js/
        // "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!-.x "
        { "key": "gamefont", "fileName": "font/gamefont.json" },
        // "0123456789,. "
        { "key": "numbersfont", "fileName": "font/numbersfont.json" },
    ],
    "meshes": [
        { "key": "discogal", "fileName": "model/character_female_base.glb" },
    ],
    "animations": [
        { "key": "idlebounce", "fileName": "animation/char_anim_idle_stroll.glb" },
    ],
    "songs": [
        { "key": "00 Fever", "fileName": "audio/Fever.mp3", "bpm": 128, "emoji": "icon_song_00", "scale": "Dm", "remix": false, "rating": 1, "genre": "DISCO" },
    ],
};
//
// Second stage - Main
//
exports.contentMain = {
    "textures": [
        { "key": "gal-mauve-color", "fileName": "texture/discogal_mauve_color.png" },
        { "key": "gal-mauve-metalness", "fileName": "texture/discogal_mauve_metallic.png" },
        { "key": "gal-mauve-roughness", "fileName": "texture/discogal_mauve_roughness.png" },
        { "key": "gal-blue-color", "fileName": "texture/discogal_blue_color.png" },
        { "key": "gal-blue-metalness", "fileName": "texture/discogal_blue_metallic.png" },
        { "key": "gal-blue-roughness", "fileName": "texture/discogal_blue_roughness.png" },
        { "key": "gal-purple-color", "fileName": "texture/discogal_purple_color.png" },
        { "key": "gal-purple-metalness", "fileName": "texture/discogal_purple_metallic.png" },
        { "key": "gal-purple-roughness", "fileName": "texture/discogal_purple_roughness.png" },
        { "key": "gal-coral-color", "fileName": "texture/discogal_coral_color.png" },
        { "key": "gal-coral-metalness", "fileName": "texture/discogal_coral_metallic.png" },
        { "key": "gal-coral-roughness", "fileName": "texture/discogal_coral_roughness.png" },
        { "key": "chaos-wedge", "fileName": "texture/UI_common_wedge_solid.png" },
        { "key": "chaos-wedge-empty", "fileName": "texture/UI_common_wedge_outline.png" },
        // ftue
        { "key": "ftue-red", "fileName": "texture/redgem.png" },
        { "key": "ftue-green", "fileName": "texture/greengem.png" },
        { "key": "ftue-blue", "fileName": "texture/bluegem.png" },
        { "key": "ftue-purple", "fileName": "texture/purplegem.png" },
        { "key": "ftue-arrow", "fileName": "texture/ftue-arrow.png" },
        // move unlock
        { "key": "unlock-gradient", "fileName": "texture/move-unlock-gradient-dark.png" },
        { "key": "icon_song_01", "fileName": "texture/UI_emoji_hiphop_lucky.png" },
        { "key": "icon_song_02", "fileName": "texture/UI_emoji_jazz_jazz.png" },
        { "key": "icon_song_03", "fileName": "texture/UI_emoji_salsa_kiss.png" },
        { "key": "icon_song_04", "fileName": "texture/UI_emoji_disco_unicorn.png" },
        { "key": "icon_song_05", "fileName": "texture/UI_emoji_hiphop_hiphop.png" },
        { "key": "icon_song_06", "fileName": "texture/UI_emoji_jazz_fish.png" },
        { "key": "icon_song_07", "fileName": "texture/UI_emoji_salsa_salsa.png" },
    ],
    // animations for main menu/ftue
    // idles and any attract animations
    "animations": [
        { "key": "idle", "fileName": "animation/char_anim_idle_stroll.glb" },
        { "key": "redUpLeft", "fileName": "animation/char_anim_salsa_4beat_01.glb" },
        { "key": "greenUpRight", "fileName": "animation/char_anim_hiphop_4beat_03_R.glb" },
        { "key": "blueDown", "fileName": "animation/char_anim_jazz_2beat_01_L.glb" },
        { "key": "blueUpRight", "fileName": "animation/char_anim_jazz_4beat_01.glb" },
        { "key": "purpleUpLeft", "fileName": "animation/char_anim_disco_4beat_02.glb" },
        { "key": "purpleDownLeft", "fileName": "animation/char_anim_disco_4beat_03.glb" },
        //idle style selects
        { "key": "waitIdle", "fileName": "animation/char_anim_hiphop_4beat_bboy.glb" },
    ],
    "songs": [
        { "key": "01 Trance Break", "fileName": "audio/Trance_Break.mp3", "bpm": 128, "emoji": "icon_song_01", "scale": "C#m", "remix": false, "rating": 2, "genre": "HIP HOP" },
        { "key": "02 Bassline Strut", "fileName": "audio/Bassline_Strut.mp3", "bpm": 112, "emoji": "icon_song_02", "scale": "Cm", "remix": false, "rating": 2, "genre": "JAZZ" },
        { "key": "03 Queso Fundido", "fileName": "audio/QuesoFundido.mp3", "bpm": 120, "emoji": "icon_song_03", "scale": "Em", "remix": false, "rating": 3, "genre": "SALSA" },
        { "key": "04 Get Down", "fileName": "audio/Get_Down.mp3", "bpm": 120, "emoji": "icon_song_04", "scale": "Dm", "remix": false, "rating": 3, "genre": "DISCO" },
        { "key": "05 B-boy Stance", "fileName": "audio/B-boy_Stance.mp3", "bpm": 130, "emoji": "icon_song_05", "scale": "F", "remix": false, "rating": 5, "genre": "HIP HOP" },
        { "key": "06 Ocean Breeze", "fileName": "audio/Ocean_Breeze.mp3", "bpm": 100, "emoji": "icon_song_06", "scale": "Am", "remix": false, "rating": 4, "genre": "JAZZ" },
        { "key": "07 La Maracas", "fileName": "audio/La_Maracas.mp3", "bpm": 110, "emoji": "icon_song_07", "scale": "Cm", "remix": false, "rating": 4, "genre": "SALSA" },
    ],
};
//
// Third stage - game
//
exports.contentGame = {
    "textures": [
        { "key": "gal-base", "fileName": "texture/discogal_base.png" },
        { "key": "follower1", "fileName": "texture/disco_follower_01.png" },
        { "key": "follower2", "fileName": "texture/disco_follower_02.png" },
        { "key": "follower3", "fileName": "texture/disco_follower_03.png" },
        { "key": "follower4", "fileName": "texture/disco_follower_04.png" },
        { "key": "follower5", "fileName": "texture/disco_follower_05.png" },
        { "key": "follower6", "fileName": "texture/disco_follower_06.png" },
        { "key": "follower7", "fileName": "texture/disco_follower_07.png" },
        { "key": "follower8", "fileName": "texture/disco_follower_08.png" },
        { "key": "follower9", "fileName": "texture/disco_follower_09.png" },
        { "key": "follower10", "fileName": "texture/disco_follower_10.png" },
        { "key": "follower11", "fileName": "texture/disco_follower_11.png" },
        { "key": "follower12", "fileName": "texture/disco_follower_12.png" },
        { "key": "follower13", "fileName": "texture/disco_follower_13.png" },
        { "key": "follower14", "fileName": "texture/disco_follower_14.png" },
        { "key": "follower-roughness", "fileName": "texture/disco_follower_roughness.png" },
        { "key": "tracker-outline", "fileName": "texture/UI_8way_backplate_outline.png" },
        { "key": "chaos-circle", "fileName": "texture/chaos-cir.png" },
        { "key": "chaos-circle-selected", "fileName": "texture/chaos-cir-selected.png" },
        { "key": "chaos-wedge-empty-small", "fileName": "texture/UI_common_wedge_outline_small.png" },
        { "key": "chaos-wedge-small", "fileName": "texture/UI_common_wedge_solid_small.png" },
        { "key": "tracker-icon-red", "fileName": "texture/tracker_icon_red.png" },
        { "key": "tracker-icon-green", "fileName": "texture/tracker_icon_green.png" },
        { "key": "tracker-icon-blue", "fileName": "texture/tracker_icon_blue.png" },
        { "key": "tracker-icon-purple", "fileName": "texture/tracker_icon_purple.png" },
        // start
        { "key": "swipe-finger", "fileName": "texture/start_touch_hand.png" },
        { "key": "swipe-dot", "fileName": "texture/start_touch_dot.png" },
        { "key": "swipe-bar", "fileName": "texture/start_swipebar.png" },
        // end
        { "key": "replay-button", "fileName": "texture/UI_Main_Button_Replay.png" },
        { "key": "share-button", "fileName": "texture/UI_Main_Button_Share.png" },
        { "key": "obstacle", "fileName": "texture/obstacle_speakerbox_color_blue.jpg" },
        { "key": "obstacle-metalness", "fileName": "texture/obstacle_speakerbox_metallic.png" },
        { "key": "obstacle-roughness", "fileName": "texture/obstacle_speakerbox_roughness.jpg" },
        { "key": "obstacle-fever", "fileName": "texture/obstacle_speakerbox_fever_color_blue.jpg" },
        { "key": "obstacle-fever-metalness", "fileName": "texture/obstacle_speakerbox_fever_metallic.png" },
        { "key": "obstacle-fever-roughness", "fileName": "texture/obstacle_speakerbox_fever_roughness.jpg" },
        { "key": "fever-logo", "fileName": "texture/Fever_Logo.png" },
        //faux orbit lighting 
        { "key": "fever-orbit-l", "fileName": "texture/LightOrbit.png" },
        { "key": "fever-orbit-r", "fileName": "texture/LightOrbitR.png" },
        { "key": "fever-orbit-hotspot-r", "fileName": "texture/LightOrbitR_hotspot.png" },
        { "key": "fever-orbit-hotspot-l", "fileName": "texture/LightOrbitL_hotspot.png" },
        // dance emojis
        { "key": "emoji-salsa", "fileName": "texture/UI_emoji_salsa_salsa.png" },
        { "key": "emoji-rose", "fileName": "texture/UI_emoji_salsa_rose.png" },
        { "key": "emoji-love", "fileName": "texture/UI_emoji_salsa_love.png" },
        { "key": "emoji-kitty", "fileName": "texture/UI_emoji_salsa_kitty.png" },
        { "key": "emoji-kiss", "fileName": "texture/UI_emoji_salsa_kiss.png" },
        { "key": "emoji-heels", "fileName": "texture/UI_emoji_salsa_heels.png" },
        { "key": "emoji-chili", "fileName": "texture/UI_emoji_salsa_chili.png" },
        { "key": "emoji-cherries", "fileName": "texture/UI_emoji_salsa_cherries.png" },
        { "key": "emoji-sweat", "fileName": "texture/UI_emoji_jazz_sweat.png" },
        { "key": "emoji-joy", "fileName": "texture/UI_emoji_jazz_joy.png" },
        { "key": "emoji-jeans", "fileName": "texture/UI_emoji_jazz_jeans.png" },
        { "key": "emoji-jazz", "fileName": "texture/UI_emoji_jazz_jazz.png" },
        { "key": "emoji-fish", "fileName": "texture/UI_emoji_jazz_fish.png" },
        { "key": "emoji-facepalm", "fileName": "texture/UI_emoji_jazz_facepalm.png" },
        { "key": "emoji-diamond", "fileName": "texture/UI_emoji_jazz_diamond.png" },
        { "key": "emoji-cap", "fileName": "texture/UI_emoji_jazz_cap.png" },
        { "key": "emoji-tennis", "fileName": "texture/UI_emoji_hiphop_tennis.png" },
        { "key": "emoji-lucky", "fileName": "texture/UI_emoji_hiphop_lucky.png" },
        { "key": "emoji-leaf", "fileName": "texture/UI_emoji_hiphop_leaf.png" },
        { "key": "emoji-kiwi", "fileName": "texture/UI_emoji_hiphop_kiwi.png" },
        { "key": "emoji-hiphop", "fileName": "texture/UI_emoji_hiphop_hiphop.png" },
        { "key": "emoji-envy", "fileName": "texture/UI_emoji_hiphop_envy.png" },
        { "key": "emoji-dragon", "fileName": "texture/UI_emoji_hiphop_dragon.png" },
        { "key": "emoji-beetle", "fileName": "texture/UI_emoji_hiphop_beetle.png" },
        { "key": "emoji-unicorn", "fileName": "texture/UI_emoji_disco_unicorn.png" },
        { "key": "emoji-rain", "fileName": "texture/UI_emoji_disco_rain.png" },
        { "key": "emoji-passion", "fileName": "texture/UI_emoji_disco_passion.png" },
        { "key": "emoji-octopus", "fileName": "texture/UI_emoji_disco_octopus.png" },
        { "key": "emoji-monster", "fileName": "texture/UI_emoji_disco_monster.png" },
        { "key": "emoji-grapes", "fileName": "texture/UI_emoji_disco_grapes.png" },
        { "key": "emoji-disco", "fileName": "texture/UI_emoji_disco_disco.png" },
        { "key": "emoji-crystal", "fileName": "texture/UI_emoji_disco_crystal.png" },
        { "key": "icon-brite", "fileName": "texture/icon-brite.png" },
    ],
    "dataTextures": [
        { "key": "gem", "fileName": "texture/gem.hdr" },
    ],
    "meshes": [
        { "key": "gem-red", "fileName": "model/gem_cut_heart.glb" },
        { "key": "gem-purple", "fileName": "model/gem_cut_pina.glb" },
        { "key": "gem-green", "fileName": "model/gem_cut_marquise.glb" },
        { "key": "gem-blue", "fileName": "model/gem_cut_round.glb" },
        { "key": "obstacle", "fileName": "model/obstacle_speakerbox.glb" },
    ],
    "animations": [
        { "key": "fall", "fileName": "animation/char_anim_fall.glb" },
        { "key": "redLeft", "fileName": "animation/char_anim_salsa_1beat_01.glb" },
        { "key": "redRight", "fileName": "animation/char_anim_salsa_1beat_02.glb" },
        { "key": "redUp", "fileName": "animation/char_anim_salsa_2beat_02_R.glb" },
        { "key": "redDown", "fileName": "animation/char_anim_salsa_2beat_01.glb" },
        { "key": "redDownRight", "fileName": "animation/char_anim_salsa_4beat_02.glb" },
        { "key": "redDownLeft", "fileName": "animation/char_anim_salsa_4beat_04.glb" },
        { "key": "redUpRight", "fileName": "animation/char_anim_salsa_4beat_03.glb" },
        { "key": "greenLeft", "fileName": "animation/char_anim_hiphop_1beat_01.glb" },
        { "key": "greenRight", "fileName": "animation/char_anim_hiphop_1beat_02_R.glb" },
        { "key": "greenUp", "fileName": "animation/char_anim_hiphop_2beat_03.glb" },
        { "key": "greenDown", "fileName": "animation/char_anim_hiphop_2beat_02.glb" },
        { "key": "greenDownRight", "fileName": "animation/char_anim_hiphop_4beat_02.glb" },
        { "key": "greenDownLeft", "fileName": "animation/char_anim_hiphop_4beat_01_L.glb" },
        { "key": "greenUpLeft", "fileName": "animation/char_anim_hiphop_4beat_04.glb" },
        { "key": "blueLeft", "fileName": "animation/char_anim_jazz_1beat_01_L.glb" },
        { "key": "blueRight", "fileName": "animation/char_anim_jazz_1beat_02.glb" },
        { "key": "blueUp", "fileName": "animation/char_anim_jazz_2beat_02.glb" },
        { "key": "blueUpLeft", "fileName": "animation/char_anim_jazz_4beat_03.glb" },
        { "key": "blueDownRight", "fileName": "animation/char_anim_jazz_4beat_02.glb" },
        { "key": "blueDownLeft", "fileName": "animation/char_anim_jazz_4beat_04.glb" },
        { "key": "purpleLeft", "fileName": "animation/char_anim_disco_1beat_01_L.glb" },
        { "key": "purpleRight", "fileName": "animation/char_anim_disco_1beat_02_R.glb" },
        { "key": "purpleUp", "fileName": "animation/char_anim_disco_2beat_01.glb" },
        { "key": "purpleDown", "fileName": "animation/char_anim_disco_2beat_02.glb" },
        { "key": "purpleDownRight", "fileName": "animation/char_anim_disco_4beat_01_L.glb" },
        { "key": "purpleUpRight", "fileName": "animation/char_anim_disco_4beat_04.glb" },
        //idle style selects
        { "key": "cheer", "fileName": "animation/char_anim_cheer.glb" },
    ],
    "tonePlayers": [
        { "key": "three", "fileName": "audio/three.mp3" },
        { "key": "two", "fileName": "audio/two.mp3" },
        { "key": "one", "fileName": "audio/one.mp3" },
        { "key": "dance", "fileName": "audio/dance.mp3" },
        { "key": "sweet", "fileName": "audio/sweet.mp3" },
        { "key": "groovy", "fileName": "audio/groovy.mp3" },
        { "key": "amazing", "fileName": "audio/amazing.mp3" },
        { "key": "spectacular", "fileName": "audio/spectacular.mp3" },
        { "key": "unbelievable", "fileName": "audio/unbelievable.mp3" },
        { "key": "needle-scratch", "fileName": "audio/needlescratch.mp3" },
        { "key": "fever-transition-1", "fileName": "audio/Fever_Trans_1.mp3" },
        { "key": "fever-transition-2", "fileName": "audio/Fever_Trans_2.mp3" },
        { "key": "fever-transition-3", "fileName": "audio/Fever_Trans_3.mp3" },
        { "key": "fever-transition-4", "fileName": "audio/Fever_Trans_4.mp3" },
        { "key": "fever-transition-5", "fileName": "audio/Fever_Trans_5.mp3" },
        { "key": "hotsauce", "fileName": "audio/HotSauce.mp3" },
        { "key": "breakitdown", "fileName": "audio/BreakItDown.mp3" },
        { "key": "improvise", "fileName": "audio/Improvise.mp3" },
        { "key": "dancefever", "fileName": "audio/DanceFever.mp3" },
    ],
    "samplers": [
        { "key": "strings", samplesMap: {
                'C3': "audio/strings/c3.mp3",
                'G3': "audio/strings/g3.mp3",
                'C4': "audio/strings/c4.mp3",
                'G4': "audio/strings/g4.mp3",
                'C5': "audio/strings/c5.mp3",
            } },
        { "key": "guitar", samplesMap: {
                'C3': "audio/guitar/c3.mp3",
                'G3': "audio/guitar/g3.mp3",
                'C4': "audio/guitar/c4.mp3",
                'G4': "audio/guitar/g4.mp3",
                'C5': "audio/guitar/c5.mp3",
            } },
        { "key": "piano", samplesMap: {
                'C3': "audio/piano/c3.mp3",
                'G3': "audio/piano/g3.mp3",
                'C4': "audio/piano/c4.mp3",
                'G4': "audio/piano/g4.mp3",
                'C5': "audio/piano/c5.mp3",
            } },
        { "key": "drumkit", samplesMap: {
                'C3': "audio/drumkit/clap.mp3",
                'D3': "audio/drumkit/cowbell.mp3",
                'E3': "audio/drumkit/closedhat.mp3",
                'F3': "audio/drumkit/openhat.mp3",
            } },
        { "key": "gembell", samplesMap: {
                'G5': "audio/hits/BellG5.mp3",
                'C6': "audio/hits/BellC6.mp3",
                'G6': "audio/hits/BellG6.mp3",
                'C7': "audio/hits/BellC7.mp3",
                'G7': "audio/hits/BellG7.mp3",
            } },
    ],
    "songs": [
        { "key": "00 FeverMode", "fileName": "audio/Fever_REMIX.mp3", "bpm": 133, "emoji": "song-disco-1", "scale": "Ebm", "remix": true },
        { "key": "01 FeverMode", "fileName": "audio/Trance Break_REMIX.mp3", "bpm": 133, "emoji": "song-hiphop-1", "scale": "Dm", "remix": true },
        { "key": "02 FeverMode", "fileName": "audio/Bassline Strut_REMIX.mp3", "bpm": 117, "emoji": "song-jazz-1", "scale": "C#m", "remix": true },
        { "key": "03 FeverMode", "fileName": "audio/Queso Fundido_REMIX.mp3", "bpm": 125, "emoji": "song-salsa-1", "scale": "Fm", "remix": true },
        { "key": "04 FeverMode", "fileName": "audio/Get Down_REMIX.mp3", "bpm": 125, "emoji": "song-disco-2", "scale": "Ebm", "remix": true },
        { "key": "05 FeverMode", "fileName": "audio/B-boy Stance_REMIX.mp3", "bpm": 135, "emoji": "song-hiphop-2", "scale": "F#", "remix": true },
        { "key": "06 FeverMode", "fileName": "audio/Ocean Breeze_REMIX.mp3", "bpm": 105, "emoji": "song-jazz-2", "scale": "Bbm", "remix": true },
        { "key": "07 FeverMode", "fileName": "audio/La Maracas_REMIX.mp3", "bpm": 115, "emoji": "song-salsa-2", "scale": "C#m", "remix": true },
    ],
};
