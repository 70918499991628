"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameOverEvents = void 0;
const THREE = require("three");
const log = require("loglevel");
const TWEEN = require("@tweenjs/tween.js");
const game_over_buttons_1 = require("./game_over_buttons");
const game_over_rating_1 = require("./game_over_rating");
const score_rating_view_1 = require("./score_rating_view");
var GameOverEvents;
(function (GameOverEvents) {
    GameOverEvents["ReplayPressed"] = "replaypressed";
    GameOverEvents["SharePressed"] = "sharepressed";
})(GameOverEvents = exports.GameOverEvents || (exports.GameOverEvents = {}));
class GameOverView extends THREE.Scene {
    constructor(contentModel, screenModel, soundModel, camera) {
        super();
        this.bannerWidth = 0;
        this.bannerHeight = 0;
        this.raycaster = new THREE.Raycaster();
        this.isPointerDown = false;
        this.pointerPosition = new THREE.Vector2(0, 0);
        this.downButtonName = "";
        this.downButtonGroup = undefined;
        this.spritesCreated = false;
        this.points = 0;
        this.totalScore = 0;
        this.starThreshholds = [];
        this.barScoreMax = 800000;
        this.sfxData = [];
        this.buttonsEnabled = false;
        this.onScreenResize = () => {
            if (!this.spritesCreated) {
                return;
            }
            log.debug('GameOverView: onScreenResize');
            const bannerXScale = this.screenModel.calculateWidthScale(1.0, this.bannerWidth);
            const bannerYScale = this.screenModel.calculateHeightScale(0.22, this.bannerHeight);
            this.bannerGroup.scale.set(bannerXScale, bannerYScale, 1.0);
            this.bannerGroup.position.setX(this.screenModel.width / 2);
            this.bannerGroup.position.setY(this.screenModel.height);
            if (this.replayGroup) {
                const buttonsScale = this.screenModel.calculateScale(0.45, this.replayGroup.width, 0.09, this.replayGroup.height);
                this.buttonGroup.scale.set(buttonsScale, buttonsScale, 1.0);
                this.buttonGroup.position.setY(0.10 * this.screenModel.height);
            }
            const scoreRatingViewScale = this.screenModel.calculateScale(0.8, this.scoreRatingView.width, 0.25, this.scoreRatingView.height);
            this.scoreRatingView.scale.set(scoreRatingViewScale, scoreRatingViewScale, 1.0);
            this.scoreRatingView.position.setX(this.screenModel.width * 0.5);
            this.scoreRatingView.position.setY(this.screenModel.height * 0.28);
            const ratingScale = this.screenModel.calculateHeightScale(0.027, this.ratingText.height);
            this.ratingText.scale.set(ratingScale, ratingScale, 1);
            this.ratingText.position.set(this.screenModel.width * 0.5, this.screenModel.height * 0.6, 0);
        };
        log.debug("GameOverView: constructor");
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        this.soundModel = soundModel;
        this.camera = camera;
        this.bannerGroup = new THREE.Group();
        this.add(this.bannerGroup);
        this.buttonGroup = new THREE.Group();
        this.add(this.buttonGroup);
        this.scoreRatingView = new score_rating_view_1.default(this.contentModel);
        this.add(this.scoreRatingView);
        this.ratingText = new game_over_rating_1.default(this.contentModel);
        this.ratingText.renderOrder = 4;
        this.add(this.ratingText);
        this.onScreenResize();
    }
    reset() {
        if (!this.spritesCreated) {
            this.spritesCreated = true;
            const bannerSprite = this.createBannerSprite();
            this.bannerGroup.add(bannerSprite);
            this.replayGroup = new game_over_buttons_1.ReplayButton(this.contentModel);
            this.replayGroup.position.setY(440);
            this.buttonGroup.add(this.replayGroup);
            this.shareGroup = new game_over_buttons_1.ShareButton(this.contentModel);
            this.buttonGroup.add(this.shareGroup);
            for (let i = 0; i < GameOverView.RATING_KEYS.length; i++) {
                this.sfxData.push(this.contentModel.getTonePlayer(GameOverView.RATING_KEYS[i]));
            }
        }
        this.scoreRatingView.reset();
        this.points = 0;
        this.buttonsEnabled = false;
        this.ratingText.reset();
        this.onScreenResize();
    }
    showShare(show) {
        if (this.shareGroup) {
            if (show) {
                this.shareGroup.position.setX(0);
                this.shareGroup.visible = true;
            }
            else {
                this.shareGroup.position.setX(-1000);
                this.shareGroup.visible = true;
            }
        }
    }
    setLevelRatings(levelRatings) {
        this.starThreshholds = [];
        for (let i = 0; i < 5; i++) {
            this.starThreshholds.push(levelRatings[i]);
            log.info(`LevelRating: ${levelRatings[i]}`);
        }
        this.barScoreMax = levelRatings[5];
        log.info(`BarMax: ${this.barScoreMax}`);
    }
    setScore(points) {
        this.totalScore = points;
        this.scoreRatingView?.setScore(0);
        let prevScore = 0;
        let duration = (this.getNumStars(points) * 1250) + 1000;
        new TWEEN.Tween(this)
            .to({ points: points }, duration)
            .delay(1000)
            .easing(TWEEN.Easing.Cubic.InOut)
            .start()
            .onStart(() => {
            this.soundModel.startScoreDing();
        })
            .onUpdate(() => {
            let score = Math.floor(this.points);
            this.scoreRatingView?.setScore(score);
            if (score > this.barScoreMax) {
                score = this.barScoreMax;
            }
            const percent = score / this.barScoreMax;
            this.scoreRatingView.setProgress(percent);
            for (let starIndex = 0; starIndex < 5; starIndex++) {
                const thresh = this.starThreshholds[starIndex];
                if (prevScore < thresh && score >= thresh) {
                    this.scoreRatingView.showStar(starIndex);
                    this.ratingText.show(starIndex);
                    this.soundModel.playSFX(this.sfxData[starIndex]);
                }
            }
            prevScore = score;
        })
            .onComplete(() => {
            this.buttonsEnabled = true;
            this.soundModel.stopScoreDing();
        });
    }
    getNumStars(points) {
        for (let i = 0; i < this.starThreshholds.length; i++) {
            if (this.starThreshholds[i] > points) {
                return i;
            }
        }
        return 5;
    }
    onPointerDown(pointerPosition) {
        log.trace(`MainMenuView: onPointerDown - (${pointerPosition.x.toFixed(2)}, ${pointerPosition.y.toFixed(2)})`);
        if (!this.isPointerDown && this.buttonsEnabled) {
            this.isPointerDown = true;
            this.pointerPosition.x = (pointerPosition.x / this.screenModel.width) * 2 - 1;
            this.pointerPosition.y = -((pointerPosition.y / this.screenModel.height) * 2 - 1);
            log.trace(`MainMenuView: cast - (${this.pointerPosition.x.toFixed(2)}, ${this.pointerPosition.y.toFixed(2)})`);
            this.raycaster.setFromCamera(this.pointerPosition, this.camera);
            const intersects = this.raycaster.intersectObject(this.buttonGroup, true);
            if (intersects.length > 0) {
                let intersection = intersects[0];
                let object = intersection.object;
                this.downButtonName = object.name;
                log.debug(`MainMenuView: onPointerDown - object: ${object.name}`);
                switch (this.downButtonName) {
                    case this.replayGroup.buttonName:
                        this.downButtonGroup = this.replayGroup;
                        break;
                    case this.shareGroup.buttonName:
                        this.downButtonGroup = this.shareGroup;
                        break;
                }
                this.downButtonGroup?.onPointerDown();
            }
        }
    }
    onPointerMove(pointerPosition) {
        if (this.isPointerDown) {
            this.pointerPosition.x = (pointerPosition.x / this.screenModel.width) * 2 - 1;
            this.pointerPosition.y = -((pointerPosition.y / this.screenModel.height) * 2 - 1);
        }
    }
    onPointerUp() {
        if (this.isPointerDown) {
            this.isPointerDown = false;
            this.raycaster.setFromCamera(this.pointerPosition, this.camera);
            const intersects = this.raycaster.intersectObject(this.buttonGroup, true);
            if (intersects.length > 0) {
                let intersection = intersects[0];
                let object = intersection.object;
                if (this.downButtonName == object.name) {
                    log.debug(`MainMenuView: onPointerUp press - object: ${object.name}`);
                    if (this.downButtonGroup != undefined) {
                        this.downButtonGroup.onPointerUp();
                        this.downButtonGroup = undefined;
                    }
                    switch (this.downButtonName) {
                        case this.replayGroup.buttonName:
                            this.dispatchEvent({ type: GameOverEvents.ReplayPressed });
                            break;
                        case this.shareGroup.buttonName:
                            this.dispatchEvent({ type: GameOverEvents.SharePressed });
                            break;
                    }
                }
            }
            this.downButtonName = "";
            if (this.downButtonGroup != undefined) {
                this.downButtonGroup.onPointerUp();
                this.downButtonGroup = undefined;
            }
        }
    }
    createBannerSprite() {
        const material = new THREE.SpriteMaterial({
            color: 0x543090,
            opacity: 0.85
        });
        this.bannerWidth = 64;
        this.bannerHeight = 64;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, 1.0);
        sprite.scale.set(this.screenModel.width, this.screenModel.height, 1);
        return sprite;
    }
}
exports.default = GameOverView;
GameOverView.RATING_KEYS = ["sweet", "groovy", "amazing", "spectacular", "unbelievable"];
