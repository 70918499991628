"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
class RoadProgressView extends THREE.Group {
    constructor(contentModel, screenModel) {
        super();
        this.barWidth = 10;
        this.barHeight = 300;
        this.progress = 0;
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        this.progressBox = new THREE.Box2(new THREE.Vector2(-this.barWidth, -this.barHeight), new THREE.Vector2(0, 0));
        const progressBackground = this.createProgressBar(0x1c0644);
        this.add(progressBackground);
        this.progressBar = this.createProgressBar(0xfcaa22);
        this.add(this.progressBar);
        this.updateProgress();
    }
    get width() {
        return this.barWidth;
    }
    get height() {
        return this.barHeight;
    }
    reset() {
        this.progress = 0;
        this.updateProgress();
    }
    setProgress(progress) {
        this.progress = THREE.MathUtils.clamp(progress, 0, 1);
        this.updateProgress();
    }
    createProgressBar(barColor) {
        const backgroundGeometry = new THREE.BufferGeometry();
        backgroundGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(18), 3));
        const barBackgroundMaterial = new THREE.MeshBasicMaterial({
            color: barColor,
            side: THREE.DoubleSide
        });
        const progressBarBackground = new THREE.Mesh(backgroundGeometry, barBackgroundMaterial);
        const box = this.progressBox;
        const position = progressBarBackground.geometry.attributes.position;
        position.setXY(0, box.min.x, box.min.y);
        position.setXY(1, box.min.x, box.max.y);
        position.setXY(2, box.max.x, box.min.y);
        position.setXY(3, box.min.x, box.max.y);
        position.setXY(4, box.max.x, box.max.y);
        position.setXY(5, box.max.x, box.min.y);
        position.needsUpdate = true;
        return progressBarBackground;
    }
    updateProgress() {
        this.progressBar.scale.setY(this.progress);
    }
}
exports.default = RoadProgressView;
