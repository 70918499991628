"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const road_data_1 = require("../road_data");
// Vertical Lanes in TikTok - Disco Loco 3d - Level Design
const Level03 = {
    length: 168,
    ratings: [160000, 320000, 480000, 640000, 800000, 960000],
    roadData: [
        { type: road_data_1.RoadItemType.PickupGreen, line: 8, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 9, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 9, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 10, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 10, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 11, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 11, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 13, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 13, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 13, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 15, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 16, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 16, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 17, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 17, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 18, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 18, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 19, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 19, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 20, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 20, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 23, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 23, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 23, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 25, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 25, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 26, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 26, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 27, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 27, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 28, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 28, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 29, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 29, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 30, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 30, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 31, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 31, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 32, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 32, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 33, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 33, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 33, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 33, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 33, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 37, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 37, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 37, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 37, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 39, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 40, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 40, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 41, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 41, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 42, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 42, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 43, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 43, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 43, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 43, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 43, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 44, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 44, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 45, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 45, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 46, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 46, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 47, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 48, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 48, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 50, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 51, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 52, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 53, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 53, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 53, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 53, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 53, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 54, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 55, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 56, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 57, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 58, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 59, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 60, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 61, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 62, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 63, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 63, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 63, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 63, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 63, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 64, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 65, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 66, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 67, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 68, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 69, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 70, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 71, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 72, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 73, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 73, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 73, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 73, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 73, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 74, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 75, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 76, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 77, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 78, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 79, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 80, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 81, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 82, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 83, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 83, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 83, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 83, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 83, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 84, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 85, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 86, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 88, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 88, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 89, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 89, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 90, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 90, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 91, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 91, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 92, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 92, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 93, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 93, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 93, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 93, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 93, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 94, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 94, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 95, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 95, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 96, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 96, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 98, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 98, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 103, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 103, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 103, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 108, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 110, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 110, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 111, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 111, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 112, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 112, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 113, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 113, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 113, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 113, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 113, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 114, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 114, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 115, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 115, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 116, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 116, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 117, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 117, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 118, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 118, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 119, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 119, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 121, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 122, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 123, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 123, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 123, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 123, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 124, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 125, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 126, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 127, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 128, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 131, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 132, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 133, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 133, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 133, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 133, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 134, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 135, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 136, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 137, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 143, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 143, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 143, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 143, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 148, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 153, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 153, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 153, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 153, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 163, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 163, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 163, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 163, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 4 },
    ]
};
exports.default = Level03;
