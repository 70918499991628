"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const road_data_1 = require("../road_data");
// Funnel level in TikTok - Disco Loco 3d - Level Design
const Level02 = {
    length: 168,
    ratings: [80000, 160000, 240000, 320000, 400000, 480000],
    roadData: [
        { type: road_data_1.RoadItemType.PickupBlack, line: 8, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 8, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 8, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 9, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 9, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 10, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 10, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 16, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 16, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 18, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 19, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 19, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 20, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 20, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 24, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 24, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 28, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 28, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 29, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 30, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 30, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 31, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 31, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 34, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 34, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 37, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 38, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 38, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 39, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 39, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 40, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 40, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 41, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 49, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 49, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 49, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 50, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 50, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 51, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 51, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 57, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 57, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 59, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 60, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 60, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 61, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 61, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 65, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 65, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 65, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 69, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 69, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 70, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 71, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 71, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 72, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 72, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 75, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 75, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 78, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 79, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 79, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 80, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 80, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 81, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 81, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 82, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 86, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 90, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 90, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 90, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 91, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 91, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 92, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 92, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 98, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 98, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 100, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 101, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 101, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 102, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 102, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 106, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 106, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 106, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 106, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 110, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 110, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 111, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 112, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 112, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 113, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 113, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 116, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 116, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 116, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 116, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 119, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 120, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 120, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 121, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 121, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 122, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 122, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 123, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 131, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 131, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 131, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 132, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 132, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 133, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 133, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 139, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 139, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 141, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 142, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 142, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 143, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 143, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 147, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 147, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 147, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 147, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 151, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 151, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 152, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 153, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 153, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 154, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 154, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 157, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 157, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 157, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 157, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 160, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 161, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 161, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 162, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 162, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 163, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 163, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 164, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 3 },
    ]
};
exports.default = Level02;
