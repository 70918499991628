"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const lighting_mode_1 = require("../controller/lighting_mode");
const random_1 = require("../model/random");
const follower_view_1 = require("./follower_view");
const colorWhite = new THREE.Color(0xffffff);
const colorRed = new THREE.Color(0xff0000);
const colorGreen = new THREE.Color(0x00ff00);
const colorBlue = new THREE.Color(0x0000ff);
const colorPurple = new THREE.Color(0xff00ff);
const hemiSkyColorFront = new THREE.Color(0x3163e);
const hemiSkyColorWhite = new THREE.Color(0xffffff);
const hemiGroundColorWhite = new THREE.Color(0x333333);
const hemiSkyColorRed = new THREE.Color(0xb31b27);
const hemiGroundColorRed = new THREE.Color(0x73150a);
const hemiSkyColorGreen = new THREE.Color(0x29a687);
const hemiGroundColorGreen = new THREE.Color(0x086b36);
const hemiSkyColorBlue = new THREE.Color(0x074c8c);
const hemiGroundColorBlue = new THREE.Color(0x085e80);
const hemiSkyColorPurple = new THREE.Color(0x680e8c);
const hemiGroundColorPurple = new THREE.Color(0x2a0566);
class CrowdView extends THREE.Scene {
    constructor(contentModel, moveModel, gl) {
        super();
        this.clock = new THREE.Clock();
        this.followerGroup = new THREE.Group();
        this.followers = [];
        this.activeFollowers = [];
        this.followerIndex = 0;
        this.followersCreated = false;
        this.bpm = 120;
        this.canDoMoves = true;
        this.onBeforeRender = (gl, scene, camera, renderTarget) => {
            const dt = this.clock.getDelta();
            for (let follower of this.activeFollowers) {
                follower.update(dt);
            }
        };
        this.contentModel = contentModel;
        this.moveModel = moveModel;
        this.gl = gl;
        this.followerGroup = new THREE.Group();
        this.add(this.followerGroup);
        this.hemisphereLight = new THREE.HemisphereLight(hemiSkyColorWhite, hemiGroundColorWhite, 0.75);
        this.add(this.hemisphereLight);
        this.directionalLight = new THREE.DirectionalLight(colorWhite, 1.0);
        this.directionalLight.position.set(3, 8, -3);
        this.directionalLight.target.position.set(0, 2, 0);
        this.add(this.directionalLight);
        this.add(this.directionalLight.target);
        this.pointLight = new THREE.PointLight(0x3163ee, .75); //.55
        this.pointLight.position.set(15, -3, 8);
        this.add(this.pointLight);
        this.pointLight2 = new THREE.PointLight(0xbdeffe, .75); //.55
        this.pointLight2.position.set(-15, -3, 8);
        this.add(this.pointLight2);
    }
    createFollowers() {
        if (!this.followersCreated) {
            this.followersCreated = true;
            for (let followerIndex = 0; followerIndex < CrowdView.MAX_FOLLOWERS; followerIndex++) {
                const follower = new follower_view_1.default(this.contentModel, this.moveModel, "gal-base", this.gl);
                follower.visible = false;
                follower.setBPM(this.bpm);
                this.followers.push(follower);
            }
        }
    }
    addFollower(followerData, maxVisible) {
        if (this.followerIndex >= CrowdView.MAX_FOLLOWERS) {
            return;
        }
        let view = this.followers[this.followerIndex];
        this.followerIndex++;
        if (this.followerIndex <= maxVisible) {
            view.visible = true;
            const offsetX = random_1.Random.random() * 0.67;
            const offsetZ = random_1.Random.random() * 0.67;
            view.position.setX(followerData.position.x * 2 + offsetX);
            view.position.setZ(followerData.position.y * 2 + offsetZ);
            view.setRotationFromEuler(new THREE.Euler(0, 0, 0));
            this.activeFollowers.push(view);
            this.followerGroup.add(view);
            view.leadIn(followerData.skinName);
        }
        else {
            view.position.setX(followerData.position.x * 2);
            view.position.setZ(followerData.position.y * 2);
            view.setRotationFromEuler(new THREE.Euler(0, 0, 0));
            this.activeFollowers.push(view);
            this.followerGroup.add(view);
            view.holdLeadIn(followerData.skinName);
        }
    }
    addFeverFollower(followerData) {
        if (this.followerIndex >= CrowdView.MAX_FOLLOWERS) {
            return;
        }
        let view = this.followers[this.followerIndex];
        this.followerIndex++;
        view.visible = true;
        const offsetX = random_1.Random.random() * 0.67;
        const offsetZ = random_1.Random.random() * 0.67;
        view.position.setX(followerData.position.x * 2 + offsetX);
        view.position.setZ(followerData.position.y * 2 + offsetZ);
        view.setRotationFromEuler(new THREE.Euler(0, 0, 0));
        this.activeFollowers.push(view);
        this.followerGroup.add(view);
        view.feverLeadIn(followerData.skinName);
    }
    setBPM(bpm) {
        this.bpm = bpm;
        for (let follower of this.followers) {
            follower.setBPM(this.bpm);
        }
    }
    doMove(move) {
        if (this.canDoMoves) {
            for (let follower of this.activeFollowers) {
                follower.doMove(move);
            }
        }
    }
    reset() {
        for (let follower of this.activeFollowers) {
            this.followerGroup.remove(follower);
            follower.reset();
            follower.visible = false;
        }
        this.activeFollowers = [];
        this.followerIndex = 0;
        this.fog = null;
        this.moveLightsToRear();
        this.canDoMoves = true;
    }
    endGame() {
        this.visible = true;
        this.fog = null;
        const playerVec = new THREE.Vector3(0, 0, 1);
        for (let i = 0; i < this.activeFollowers.length; i++) {
            const follower = this.activeFollowers[i];
            if (i < CrowdView.MAX_GAME_OVER_FOLLOWERS) {
                const position = FOLLOWER_GAMEOVER_POSITIONS[i].clone();
                position.multiplyScalar(2);
                follower.position.set(position.x, 0, position.y);
                const followerVec = follower.position.clone();
                followerVec.normalize();
                followerVec.negate();
                var qrot = new THREE.Quaternion();
                qrot.setFromUnitVectors(playerVec, followerVec); // (unit vectors)
                follower.setRotationFromQuaternion(qrot);
                follower.visible = true;
                follower.endGame();
            }
            else {
                follower.setHoldingState();
            }
        }
    }
    failGame() {
        this.fog = null;
        const playerVec = new THREE.Vector3(0, 0, 1);
        for (let i = 0; i < this.activeFollowers.length; i++) {
            const follower = this.activeFollowers[i];
            if (i < CrowdView.MAX_GAME_OVER_FOLLOWERS) {
                follower.position.x *= 0.8;
                const followerVec = follower.position.clone();
                followerVec.normalize();
                followerVec.negate();
                var qrot = new THREE.Quaternion();
                qrot.setFromUnitVectors(playerVec, followerVec); // (unit vectors)
                follower.setRotationFromQuaternion(qrot);
                follower.visible = true;
                follower.failGame();
            }
            else {
                follower.setHoldingState();
            }
        }
    }
    feverMode() {
        this.canDoMoves = false;
        const playerVec = new THREE.Vector3(0, 0, 1);
        this.fog = new THREE.Fog(0x000000, 4, 29);
        for (let i = 0; i < this.activeFollowers.length; i++) {
            const follower = this.activeFollowers[i];
            if (i < FOLLOWER_FEVER_POSITIONS.length) {
                follower.visible = true;
                const position = FOLLOWER_FEVER_POSITIONS[i].clone();
                position.multiplyScalar(2.45);
                follower.position.set(position.x, 0, position.y);
                const followerVec = follower.position.clone();
                followerVec.normalize();
                followerVec.negate();
                var qrot = new THREE.Quaternion();
                qrot.setFromUnitVectors(playerVec, followerVec); // (unit vectors)
                follower.setRotationFromQuaternion(qrot);
                follower.position.setY(-4.25);
                follower.startFever();
            }
            else {
                follower.visible = false;
            }
        }
    }
    setLightingMode(mode) {
        switch (mode) {
            case lighting_mode_1.LightingMode.Game:
                this.moveLightsToRear();
                this.directionalLight.intensity = 1.25;
                this.hemisphereLight.color = hemiSkyColorFront;
                this.hemisphereLight.groundColor = hemiGroundColorWhite;
                this.pointLight.visible = true;
                this.pointLight2.visible = true;
                break;
            case lighting_mode_1.LightingMode.Fail:
                this.moveLightsToFront();
                this.pointLight.visible = false;
                this.pointLight2.visible = false;
                break;
            case lighting_mode_1.LightingMode.GameOver:
                this.moveLightsToFront();
                this.directionalLight.intensity = 2;
                this.hemisphereLight.color = hemiSkyColorFront;
                this.hemisphereLight.groundColor = hemiGroundColorWhite;
                this.pointLight.visible = false;
                this.pointLight2.visible = false;
                break;
            case lighting_mode_1.LightingMode.FeverRed:
                this.moveLightsToFront();
                this.directionalLight.intensity = 1.2;
                this.hemisphereLight.color = hemiSkyColorRed;
                this.hemisphereLight.groundColor = hemiGroundColorRed;
                this.pointLight.visible = false;
                this.pointLight2.visible = false;
                break;
            case lighting_mode_1.LightingMode.FeverGreen:
                this.moveLightsToFront();
                this.directionalLight.intensity = 1.2;
                this.hemisphereLight.color = hemiSkyColorGreen;
                this.hemisphereLight.groundColor = hemiGroundColorGreen;
                this.pointLight.visible = false;
                this.pointLight2.visible = false;
                break;
            case lighting_mode_1.LightingMode.FeverBlue:
                this.moveLightsToFront();
                this.directionalLight.intensity = 1.2;
                this.hemisphereLight.color = hemiSkyColorBlue;
                this.hemisphereLight.groundColor = hemiGroundColorBlue;
                this.pointLight.visible = false;
                this.pointLight2.visible = false;
                break;
            case lighting_mode_1.LightingMode.FeverPurple:
                this.moveLightsToFront();
                this.directionalLight.intensity = 1.2;
                this.hemisphereLight.color = hemiSkyColorPurple;
                this.hemisphereLight.groundColor = hemiGroundColorPurple;
                this.pointLight.visible = false;
                this.pointLight2.visible = false;
                break;
        }
    }
    moveLightsToFront() {
        this.directionalLight.position.set(3, 8, 3);
    }
    moveLightsToRear() {
        this.directionalLight.position.set(3, 8, -3);
    }
}
exports.default = CrowdView;
CrowdView.MAX_FOLLOWERS = 14;
CrowdView.MAX_GAME_OVER_FOLLOWERS = 7;
const FOLLOWER_GAMEOVER_POSITIONS = [
    new THREE.Vector2(-1.0, -1.0),
    new THREE.Vector2(1.0, -1.0),
    new THREE.Vector2(0.0, -2.0),
    new THREE.Vector2(-1.6, 0.0),
    new THREE.Vector2(1.6, 0.0),
    new THREE.Vector2(-1.4, 1.5),
    new THREE.Vector2(1.4, 1.5),
    new THREE.Vector2(-2.0, -2.5),
    new THREE.Vector2(2.0, -2.5),
    new THREE.Vector2(-1.5, -4.0),
    new THREE.Vector2(1.5, -4.0),
    new THREE.Vector2(-0.5, -4.5),
    new THREE.Vector2(0.5, -4.5),
    new THREE.Vector2(-2.0, -5.5),
    new THREE.Vector2(2.0, -5.5),
    new THREE.Vector2(-1.5, -6.0),
    new THREE.Vector2(1.5, -6.0),
    new THREE.Vector2(-0.5, -6.5),
    new THREE.Vector2(0.5, -6.5),
    new THREE.Vector2(-2.0, -7.5),
    new THREE.Vector2(2.0, -7.5),
    new THREE.Vector2(-1.5, -8.0),
    new THREE.Vector2(1.5, -8.0),
    new THREE.Vector2(-0.5, -8.5),
    new THREE.Vector2(0.5, -8.5),
    new THREE.Vector2(-2.0, -9.5),
    new THREE.Vector2(2.0, -9.5),
    new THREE.Vector2(-1.5, -10.0),
    new THREE.Vector2(1.5, -10.0),
    new THREE.Vector2(-0.5, -10.5),
    new THREE.Vector2(0.5, -10.5),
    new THREE.Vector2(-2.0, -11.5),
    new THREE.Vector2(2.0, -11.5),
    new THREE.Vector2(-1.5, -12.0),
    new THREE.Vector2(1.5, -12.0),
    new THREE.Vector2(-0.5, -12.5),
];
const FOLLOWER_FEVER_POSITIONS = [
    new THREE.Vector2(1.5, 0.75),
    new THREE.Vector2(1.5, -0.5),
    new THREE.Vector2(1.5, -1.75),
    new THREE.Vector2(1.5, -3.0),
    new THREE.Vector2(-1.5, 0.75),
    new THREE.Vector2(-1.5, -0.5),
    new THREE.Vector2(-1.5, -1.75),
    new THREE.Vector2(-1.5, -3.0),
    new THREE.Vector2(2.25, 0.25),
    new THREE.Vector2(2.25, -1.25),
    new THREE.Vector2(2.25, -2.5),
    new THREE.Vector2(-2.25, 0.25),
    new THREE.Vector2(-2.25, -1.25),
    new THREE.Vector2(-2.25, -2.5),
];
