"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DanceMixerEvents = exports.TriggerOn = void 0;
const THREE = require("three");
const Tone = require("tone");
const loglevel_1 = require("loglevel");
var TriggerOn;
(function (TriggerOn) {
    TriggerOn["TriggerNow"] = "Now";
    TriggerOn["Trigger16"] = "16n";
    TriggerOn["Trigger8"] = "8n";
    TriggerOn["Trigger4"] = "4n";
    TriggerOn["Trigger2"] = "2n";
    TriggerOn["Trigger1"] = "1n";
})(TriggerOn = exports.TriggerOn || (exports.TriggerOn = {}));
var DanceMixerEvents;
(function (DanceMixerEvents) {
    DanceMixerEvents["MoveStart"] = "movestart";
    DanceMixerEvents["MoveComplete"] = "movecomplete";
})(DanceMixerEvents = exports.DanceMixerEvents || (exports.DanceMixerEvents = {}));
class DanceMixer extends THREE.EventDispatcher {
    constructor(root, content) {
        super();
        this.currentActionName = "";
        this.triggered = false;
        this.scheduledAction = null;
        this.idleAnim = 'idle';
        this.triggerOn = TriggerOn.TriggerNow;
        this.animsLoaded = [];
        this.fallen = false;
        this.canInterrupt = false;
        this.interruptingKey = null;
        this.mixer = new THREE.AnimationMixer(root);
        this.mixer.addEventListener('finished', this.onAnimationFinished.bind(this));
        this.actions = new Map();
        this.content = content;
    }
    addAnims() {
        loglevel_1.default.debug('DanceMixer: addAnims');
        const animKeys = this.content.getAnimationKeys();
        for (let key of animKeys) {
            if (this.animsLoaded.indexOf(key) == -1) {
                let animGLTF = this.content.getAnimation(key);
                if (animGLTF) {
                    let action = this.mixer.clipAction(animGLTF.animations[0]);
                    this.actions.set(key, action);
                    // set clip name so its easy to verify in debug
                    let clip = action.getClip();
                    clip.name = key;
                    this.animsLoaded.push(key);
                }
            }
        }
    }
    setIdle(idleName) {
        loglevel_1.default.debug(`DanceMixer: setIdle - ${idleName}`);
        this.idleAnim = idleName;
    }
    setBPM(bpm) {
        loglevel_1.default.debug(`DanceMixer: setBPM: ${bpm}`);
        this.mixer.timeScale = (bpm / 120);
    }
    setTriggerOn(triggerOn) {
        this.triggerOn = triggerOn;
    }
    setCanInterrupt(canInterrupt) {
        this.canInterrupt = canInterrupt;
    }
    playIdle() {
        loglevel_1.default.debug(`DanceMixer: playIdle`);
        this.fallen = false;
        this.play(this.idleAnim);
    }
    playFall() {
        loglevel_1.default.debug(`DanceMixer: playFall`);
        this.fallen = true;
        // cancel any scheduled action
        this.scheduledAction = null;
        let currentAction = this.actions.get(this.currentActionName);
        if (currentAction) {
            currentAction.fadeOut(0.2);
        }
        const key = 'fall';
        if (this.actions.has(key)) {
            loglevel_1.default.debug(`DanceMixer: play - ${key}`);
            let action = this.actions.get(key);
            if (action) {
                action.reset().fadeIn(0.1).play();
                action.setLoop(THREE.LoopOnce, 1);
                action.clampWhenFinished = true;
                this.currentActionName = key;
                this.triggered = false;
            }
        }
    }
    play(key) {
        let currentAction = this.actions.get(this.currentActionName);
        if (currentAction) {
            currentAction.fadeOut(0.2);
        }
        if (this.actions.has(key)) {
            loglevel_1.default.debug(`DanceMixer: play - ${key}`);
            let action = this.actions.get(key);
            if (action) {
                action.reset().fadeIn(0.1).play();
                action.setLoop(THREE.LoopRepeat, Infinity);
                this.currentActionName = key;
                this.triggered = false;
            }
        }
    }
    playNow(key) {
        let currentAction = this.actions.get(this.currentActionName);
        if (currentAction) {
            currentAction.stop();
        }
        if (this.actions.has(key)) {
            loglevel_1.default.debug(`DanceMixer: playNow - ${key}`);
            let action = this.actions.get(key);
            if (action) {
                action.reset().play();
                action.setLoop(THREE.LoopRepeat, Infinity);
                this.currentActionName = key;
                this.triggered = false;
            }
        }
    }
    canTrigger() {
        if (!this.canInterrupt) {
            return !this.triggered;
        }
        return true;
    }
    trigger(key, extraDelay = 0) {
        loglevel_1.default.debug(`DanceMixer: trigger - ${key}`);
        if (key === this.currentActionName && !this.canInterrupt) {
            return;
        }
        if (this.triggered && !this.canInterrupt) {
            return;
        }
        if (this.triggered && this.canInterrupt) {
            this.interruptingKey = key;
        }
        else {
            this.interruptingKey = null;
        }
        if (this.actions.has(key)) {
            if (key === this.idleAnim) {
                let currentAction = this.actions.get(this.currentActionName);
                if (currentAction) {
                    currentAction.fadeOut(0.2);
                }
                let action = this.actions.get(key);
                if (action) {
                    action.reset().fadeIn(0.1).play();
                    this.currentActionName = key;
                }
            }
            else {
                let delay = 0;
                if (this.triggerOn != TriggerOn.TriggerNow) {
                    if (Tone.Transport.state == "started") {
                        const now = Tone.Transport.now();
                        const next = Tone.Transport.nextSubdivision(this.triggerOn);
                        delay = next - now;
                    }
                }
                delay += extraDelay;
                this.scheduledAction = {
                    delay: delay,
                    currentAction: this.actions.get(this.currentActionName),
                    triggeredKey: key,
                    triggeredAction: this.actions.get(key)
                };
                this.triggered = true;
            }
        }
    }
    triggerScheduledAnimation() {
        if (this.scheduledAction) {
            if (this.scheduledAction.currentAction) {
                this.scheduledAction.currentAction.fadeOut(0.2);
            }
            if (this.scheduledAction.triggeredAction) {
                loglevel_1.default.debug(`DanceMixer: scheduledTrigger - ${this.scheduledAction.triggeredKey}`);
                this.scheduledAction.triggeredAction.reset().fadeIn(0.1).play();
                this.scheduledAction.triggeredAction.setLoop(THREE.LoopOnce, 0);
                this.scheduledAction.triggeredAction.clampWhenFinished = true;
                this.currentActionName = this.scheduledAction.triggeredKey;
                this.dispatchEvent({ type: DanceMixerEvents.MoveStart, key: this.scheduledAction.triggeredKey });
            }
            this.scheduledAction = null;
        }
    }
    onAnimationFinished(event) {
        const clipName = event.action._clip.name;
        loglevel_1.default.debug(`DanceMixer: onAnimationFinished ${clipName}`);
        if (this.fallen) {
            this.fallen = false;
            this.dispatchEvent({ type: DanceMixerEvents.MoveComplete, key: this.currentActionName });
            return;
        }
        if (this.triggered) {
            this.dispatchEvent({ type: DanceMixerEvents.MoveComplete, key: this.currentActionName });
            if (this.interruptingKey != null) {
                if (clipName == this.interruptingKey) {
                    this.triggered = false;
                    this.play(this.idleAnim);
                }
                this.interruptingKey = null;
            }
            else {
                this.triggered = false;
                this.play(this.idleAnim);
            }
        }
    }
    onIdleChanged(event) {
        const playIdle = this.currentActionName == this.idleAnim;
        this.idleAnim = event.idle;
        if (playIdle) {
            this.playIdle();
        }
    }
    update(dt) {
        this.mixer.update(dt);
        if (this.scheduledAction != null) {
            this.scheduledAction.delay -= dt;
            if (this.scheduledAction.delay <= 0) {
                this.triggerScheduledAnimation();
            }
        }
    }
}
exports.default = DanceMixer;
