"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LightingMode = void 0;
var LightingMode;
(function (LightingMode) {
    LightingMode[LightingMode["Main"] = 0] = "Main";
    LightingMode[LightingMode["Game"] = 1] = "Game";
    LightingMode[LightingMode["Fail"] = 2] = "Fail";
    LightingMode[LightingMode["GameOver"] = 3] = "GameOver";
    LightingMode[LightingMode["FeverRed"] = 4] = "FeverRed";
    LightingMode[LightingMode["FeverGreen"] = 5] = "FeverGreen";
    LightingMode[LightingMode["FeverBlue"] = 6] = "FeverBlue";
    LightingMode[LightingMode["FeverPurple"] = 7] = "FeverPurple";
})(LightingMode = exports.LightingMode || (exports.LightingMode = {}));
