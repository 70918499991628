"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const TWEEN = require("@tweenjs/tween.js");
const swipe_model_1 = require("../model/swipe_model");
const fever_view_1 = require("./fever_view");
class FeverMoveIcon extends THREE.Group {
    constructor(swipeDirection, startPos, contentModel) {
        super();
        this.iconSprite = null;
        this.brightSprite = null;
        this.iconScale = 0.5;
        this.iconCreated = false;
        this.swipeDirection = swipe_model_1.SwipeDirection.None;
        this.tweenGroup = new TWEEN.Group();
        this.swipeDirection = swipeDirection;
        this.startPos = startPos;
        this.contentModel = contentModel;
    }
    reset(textureKey) {
        if (!this.iconCreated) {
            this.iconCreated = true;
            this.brightSprite = this.createSprite('icon-brite');
            const brightMaterial = this.brightSprite.material;
            brightMaterial.opacity = 0.0;
            this.add(this.brightSprite);
            this.iconSprite = this.createSprite(textureKey);
            this.iconSprite.material.opacity = 0;
            this.add(this.iconSprite);
        }
        else {
            this.setIconTexture(textureKey);
        }
        this.position.setX(this.startPos.x);
        this.position.setY(this.startPos.y);
    }
    createSprite(key) {
        const texture = this.contentModel.getTexture(key);
        const material = new THREE.SpriteMaterial({ map: texture });
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, 0.5);
        sprite.scale.set(width * this.iconScale, height * this.iconScale, 1);
        return sprite;
    }
    setIconTexture(key) {
        this.iconSprite.material.map = this.contentModel.getTexture(key);
        const width = this.iconSprite.material.map.image.width;
        const height = this.iconSprite.material.map.image.height;
        this.iconSprite?.scale.set(width * this.iconScale, height * this.iconScale, 1.0);
        this.iconSprite.material.opacity = 0;
        this.brightSprite.material.opacity = 0;
    }
    updateScale(screenModel) {
        const iconPixelSize = 72;
        this.iconScale = screenModel.calculateWidthScale(0.10, iconPixelSize);
        if (this.iconSprite) {
            const width = this.iconSprite.material.map.image.width;
            const height = this.iconSprite.material.map.image.height;
            this.iconSprite.scale.set(width * this.iconScale, height * this.iconScale, 1.0);
        }
    }
    setupTween(moveData) {
        this.position.setX(this.startPos.x);
        this.position.setY(this.startPos.y);
        if (!this.iconSprite) {
            throw ('FeverMoveIcon: setup - iconSprite is null');
        }
        this.tweenGroup.removeAll();
        this.visible = true;
        let material = this.iconSprite.material;
        material.opacity = 0;
        let brightMaterial = this.brightSprite.material;
        brightMaterial.opacity = 0;
        let fadeInTween = new TWEEN.Tween(material, this.tweenGroup)
            .to({ opacity: 1 }, moveData.fadeInDuration)
            .delay(moveData.fadeInStart)
            .easing(TWEEN.Easing.Elastic.Out)
            .start();
        let fadeInBright = new TWEEN.Tween(brightMaterial, this.tweenGroup)
            .to({ opacity: 0.8 }, moveData.fadeInDuration)
            .delay(moveData.fadeInStart)
            .easing(TWEEN.Easing.Quadratic.In)
            .start();
        let fadeOutTween = new TWEEN.Tween(material, this.tweenGroup)
            .to({ opacity: 0 }, moveData.fadeOutDuration)
            .easing(TWEEN.Easing.Elastic.In);
        let fadeOutBright = new TWEEN.Tween(brightMaterial, this.tweenGroup)
            .to({ opacity: 0 }, moveData.fadeOutDuration)
            .easing(TWEEN.Easing.Quadratic.Out);
        let moveInTween = new TWEEN.Tween(this.position, this.tweenGroup)
            .to({ x: 0, y: 0 }, moveData.moveInDuration)
            .delay(moveData.fadeInStart + moveData.fadeInDuration)
            .onComplete(() => {
            fadeOutTween.start();
            fadeOutBright.start();
        })
            .start();
        // cheaty tweens to trigger start and end events
        new TWEEN.Tween(this, this.tweenGroup)
            .to({ triggerStartTime: moveData.triggerStart }, moveData.triggerStart)
            .onComplete(() => {
            this.dispatchEvent({
                type: fever_view_1.FeverViewEvents.EnableMove,
                direction: this.swipeDirection,
            });
        })
            .start();
        new TWEEN.Tween(this, this.tweenGroup)
            .to({ triggerEndTime: moveData.triggerEnd }, moveData.triggerEnd)
            .onComplete(() => {
            this.dispatchEvent({
                type: fever_view_1.FeverViewEvents.DisableMove,
                direction: this.swipeDirection,
            });
        })
            .start();
    }
    swipeAway() {
        // stop all tweens in progress
        this.tweenGroup.removeAll();
        // send disable event
        this.dispatchEvent({
            type: fever_view_1.FeverViewEvents.DisableMove,
            direction: this.swipeDirection,
        });
        if (!this.iconSprite) {
            throw ('FeverMoveIcon: setup - iconSprite is null');
        }
        let swipeDuration = 400;
        switch (this.swipeDirection) {
            case swipe_model_1.SwipeDirection.Left:
            case swipe_model_1.SwipeDirection.Right:
                swipeDuration = 250;
                break;
            case swipe_model_1.SwipeDirection.Up:
            case swipe_model_1.SwipeDirection.Down:
                swipeDuration = 300;
                break;
        }
        // move it back to start
        new TWEEN.Tween(this.position, this.tweenGroup)
            .to({ x: this.startPos.x, y: this.startPos.y }, swipeDuration)
            .onComplete(() => { this.visible = false; })
            .start();
        // fade it out
        let material = this.iconSprite.material;
        material.opacity = 1;
        new TWEEN.Tween(material, this.tweenGroup)
            .to({ opacity: 0 }, swipeDuration)
            .easing(TWEEN.Easing.Elastic.InOut)
            .start();
        let brightMaterial = this.brightSprite.material;
        brightMaterial.opacity = 0;
    }
    update() {
        this.tweenGroup.update();
    }
}
exports.default = FeverMoveIcon;
