"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Random = void 0;
const mersennetwister_1 = require("./mersennetwister");
class Random {
    constructor() { }
    //** returns an integer between 0(inclusive) - max(exclusive) */
    static next(max) {
        return Random.randNext(0, max - 1);
    }
    //** returns an integer between min and max (inclusive) */
    static range(min, max) {
        if (min == max) {
            return min;
        }
        return Random.randNext(min, max);
    }
    static randNext(min, max) {
        let range = max - min + 1;
        let rand = this.twister.next(range);
        rand += min;
        return rand;
    }
    //** returns a real between 0 and 1 */ 
    static random() {
        return this.twister.random();
    }
    //** Shuffles and array in place */
    static shuffleArray(array) {
        for (let i = 0; i < array.length; i++) {
            let r = Random.next(i + 1);
            let t = array[i];
            array[i] = array[r];
            array[r] = t;
        }
    }
}
exports.Random = Random;
Random.twister = new mersennetwister_1.default();
