"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const SkeletonUtils_js_1 = require("three/examples/jsm/utils/SkeletonUtils.js");
const dance_mixer_1 = require("./dance_mixer");
const TWEEN = require("@tweenjs/tween.js");
const random_1 = require("../model/random");
const fever_mode_1 = require("../controller/playmodes/fever_mode");
var FollowerState;
(function (FollowerState) {
    FollowerState[FollowerState["LeadIn"] = 0] = "LeadIn";
    FollowerState[FollowerState["Dance"] = 1] = "Dance";
    FollowerState[FollowerState["EndGame"] = 2] = "EndGame";
    FollowerState[FollowerState["Fever"] = 3] = "Fever";
    FollowerState[FollowerState["Holding"] = 4] = "Holding";
})(FollowerState || (FollowerState = {}));
let followerHDR = null;
class FollowerView extends THREE.Scene {
    constructor(contentModel, moveModel, skinName, gl) {
        super();
        this.clock = new THREE.Clock();
        this.state = FollowerState.LeadIn;
        this.enterZ = 0;
        this.timerID = 0;
        this.FADEIN_DURATION = 2000;
        this.LEADIN_DURATION = 1000;
        this.LEADIN_DISTANCE = 20;
        this.DANCE_DELAY = 0.15;
        this.ENDGAME_DELAY = 2000;
        this.FEVER_DELAY = 3000;
        this.contentModel = contentModel;
        this.moveModel = moveModel;
        this.gl = gl;
        this.opacity = 0.0;
        const gltf = this.contentModel.getMesh('discogal');
        this.character = this.add(SkeletonUtils_js_1.SkeletonUtils.clone(gltf.scene));
        let geo = this.character.getObjectByName("geo_discogal1");
        if (geo) {
            let mat = geo.material;
            this.material = mat.clone();
            let texture = this.contentModel.getTexture(skinName);
            if (texture) {
                if (texture.encoding != THREE.sRGBEncoding) {
                    texture.encoding = THREE.sRGBEncoding;
                    texture.needsUpdate = true;
                }
                texture.flipY = false;
            }
            let roughnessTexture = this.contentModel.getTexture(`follower-roughness`);
            if (roughnessTexture) {
                roughnessTexture.flipY = false;
                this.material.roughnessMap = roughnessTexture;
            }
            if (followerHDR == null) {
                let pmremGenerator = new THREE.PMREMGenerator(gl);
                pmremGenerator.compileEquirectangularShader();
                followerHDR = pmremGenerator.fromEquirectangular(contentModel.getDataTexture('gem'));
            }
            if (followerHDR) {
                this.material.envMap = followerHDR.texture;
                this.material.envMapIntensity = 1.53;
                if (fever_mode_1.default) {
                    this.material.envMapIntensity = .3;
                }
            }
            this.material.roughness = .9;
            this.material.metalness = .35;
            this.material.map = texture;
            this.material.transparent = true;
            this.material.opacity = this.opacity;
            geo.material = this.material;
        }
        const s = 4.5;
        this.character.scale.set(s, s, s);
        this.danceMixer = new dance_mixer_1.default(this.character, this.contentModel);
        this.danceMixer.addAnims();
        this.danceMixer.playIdle();
    }
    reset() {
        this.state = FollowerState.Dance;
        if (this.timerID != 0) {
            window.clearTimeout(this.timerID);
            this.timerID = 0;
        }
        this.danceMixer.setIdle('idle');
    }
    setBPM(bpm) {
        this.danceMixer.setBPM(bpm);
    }
    leadIn(skinName) {
        let geo = this.character.getObjectByName("geo_discogal1");
        if (geo) {
            const material = geo.material;
            let texture = this.contentModel.getTexture(skinName);
            if (texture) {
                texture.encoding = THREE.sRGBEncoding;
                texture.flipY = false;
                texture.needsUpdate = true;
            }
            material.map = texture;
            material.transparent = true;
            material.opacity = this.opacity;
        }
        this.opacity = 0.0;
        this.danceMixer.playIdle();
        this.state = FollowerState.LeadIn;
        new TWEEN.Tween(this)
            .to({ opacity: 1.0 }, this.FADEIN_DURATION)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .start();
        const targetZ = this.position.z;
        this.position.z -= this.LEADIN_DISTANCE;
        this.enterZ = this.position.z;
        new TWEEN.Tween(this)
            .to({ enterZ: targetZ }, this.LEADIN_DURATION)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start()
            .onComplete(this.onEnterComplete.bind(this));
    }
    feverLeadIn(skinName) {
        let geo = this.character.getObjectByName("geo_discogal1");
        if (geo) {
            const material = geo.material;
            let texture = this.contentModel.getTexture(skinName);
            if (texture) {
                texture.encoding = THREE.sRGBEncoding;
                texture.flipY = false;
                texture.needsUpdate = true;
            }
            material.opacity = 1;
            material.map = texture;
        }
    }
    holdLeadIn(skinName) {
        let geo = this.character.getObjectByName("geo_discogal1");
        if (geo) {
            const material = geo.material;
            let texture = this.contentModel.getTexture(skinName);
            if (texture) {
                if (texture.encoding != THREE.sRGBEncoding) {
                    texture.encoding = THREE.sRGBEncoding;
                    texture.needsUpdate = true;
                }
                texture.flipY = false;
            }
            material.opacity = 1;
            material.map = texture;
        }
        this.setHoldingState();
    }
    setHoldingState() {
        this.visible = false;
        this.state = FollowerState.Holding;
    }
    startFever() {
        this.state = FollowerState.Fever;
        this.danceMixer.setIdle("purpleDownLeft");
        this.danceMixer.playNow("purpleDownLeft");
        this.onFeverMove();
    }
    onFeverMove() {
        if (this.state == FollowerState.Fever) {
            let rand = random_1.Random.next(5);
            if (rand == 0) {
                this.danceMixer.trigger("purpleDown");
            }
            else if (rand == 1) {
                this.danceMixer.trigger("greenUp");
            }
            else if (rand == 2) {
                this.danceMixer.trigger("cheer");
            }
            const feverDelay = this.FEVER_DELAY + random_1.Random.next(1000);
            this.timerID = window.setTimeout(this.onFeverMove.bind(this), feverDelay);
        }
    }
    doMove(moveData) {
        if (this.state == FollowerState.Dance) {
            const extraDelay = random_1.Random.random() * this.DANCE_DELAY;
            this.danceMixer.trigger(moveData.name, extraDelay);
        }
    }
    endGame() {
        this.state = FollowerState.EndGame;
        this.danceMixer.setIdle("waitIdle");
        this.danceMixer.play("waitIdle");
        this.onEndgameMove();
    }
    failGame() {
        this.state = FollowerState.EndGame;
        this.danceMixer.playFall();
    }
    onEnterComplete() {
        if (this.material) {
            this.material.transparent = false;
        }
        this.state = FollowerState.Dance;
    }
    onEndgameMove() {
        if (this.state == FollowerState.EndGame) {
            const move = this.moveModel.getRandomMove();
            if (move) {
                this.danceMixer.trigger(move.name);
            }
            this.timerID = window.setTimeout(this.onEndgameMove.bind(this), this.ENDGAME_DELAY);
        }
    }
    update(dt) {
        if (this.state != FollowerState.Holding) {
            this.danceMixer.update(dt);
        }
        if (this.state == FollowerState.LeadIn && this.material) {
            this.material.opacity = this.opacity;
            this.position.setZ(this.enterZ);
        }
    }
}
exports.default = FollowerView;
