"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const log = require("loglevel");
const swipe_model_1 = require("../../model/swipe_model");
const camera_mode_1 = require("../camera_mode");
const lighting_mode_1 = require("../lighting_mode");
const dance_mode_1 = require("./dance_mode");
const play_mode_1 = require("./play_mode");
class SwipeToBeginMode extends play_mode_1.PlayModeBase {
    constructor(playController) {
        super(playController);
        this.onSwipeStart = (event) => {
            this.playController.mode = new dance_mode_1.default(this.playController);
            this.playController.swipeModel.removeEventListener(swipe_model_1.SwipeEvents.Start, this.onSwipeStart);
            this.views.swipeToBeginView.fadeOut();
        };
        log.info('PlayMode: SwipeToBeginMode');
        this.playController.swipeModel.addEventListener(swipe_model_1.SwipeEvents.Start, this.onSwipeStart);
        this.playController.setCameraMode(camera_mode_1.CameraMode.Game);
        this.playController.setLightingMode(lighting_mode_1.LightingMode.Game);
        this.views.logoView.reset();
        this.views.logoView.danceMode();
        this.views.scoreView.reset();
        this.views.hudView.reset();
        this.views.swipeToBeginView.reset();
        this.views.discoRoadView.reset();
        this.views.discoRoadView.gameMode();
        this.views.discoRoadView.createGemsAndObstacles();
    }
    onScreenResize() {
        this.views.swipeToBeginView.onScreenResize();
        this.views.hudView.onScreenResize();
        this.views.scoreView.onScreenResize();
        this.views.logoView.onScreenResize();
    }
    render(gl) {
        gl.clear();
        gl.render(this.views.backgroundView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.discoRoadView, this.playController.camera3d);
        gl.render(this.views.influencerView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.scoreView, this.playController.camera2d);
        gl.render(this.views.hudView, this.playController.camera2d);
        gl.render(this.views.logoView, this.playController.camera2d);
        gl.render(this.views.swipeToBeginView, this.playController.camera2d);
    }
}
exports.default = SwipeToBeginMode;
