"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const log = require("loglevel");
const EMPTY_SLOT = Object.freeze(Object.create(null));
class Object3DPool {
    constructor(objectCreator) {
        this.pool = [];
        this.objectDesciption = "pool";
        this.objectCreator = objectCreator;
        this.nextFreeSlot = null;
    }
    use() {
        if (this.nextFreeSlot == null || this.nextFreeSlot == this.pool.length) {
            this.grow(this.pool.length || 5);
            log.warn(`ObjectPool: grow - adding new ${this.objectDesciption} objects to pool - ${this.pool.length}`);
        }
        if (this.nextFreeSlot != null) {
            let objToUse = this.pool[this.nextFreeSlot];
            this.pool[this.nextFreeSlot++] = EMPTY_SLOT;
            return objToUse;
        }
        return null;
    }
    recycle(obj) {
        if (this.nextFreeSlot == null || this.nextFreeSlot == -1) {
            this.pool[this.pool.length] = obj;
        }
        else {
            this.pool[--this.nextFreeSlot] = obj;
        }
    }
    grow(count = this.pool.length) {
        if (count > 0 && this.nextFreeSlot == null) {
            this.nextFreeSlot = 0;
        }
        if (count > 0) {
            const curLen = this.pool.length;
            this.pool.length += Number(count);
            for (let i = curLen; i < this.pool.length; i++) {
                // add new obj to pool
                this.pool[i] = this.objectCreator();
            }
        }
        return this.pool.length;
    }
    size() {
        return this.pool.length;
    }
    setDescription(description) {
        this.objectDesciption = description;
    }
}
exports.default = Object3DPool;
