"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const TWEEN = require("@tweenjs/tween.js");
class GameOverStar extends THREE.Group {
    constructor(contentModel) {
        super();
        this.starWidth = 0;
        this.starHeight = 0;
        this.isShown = false;
        this.contentModel = contentModel;
        this.emptySprite = this.createSprite('star-empty');
        this.add(this.emptySprite);
        this.starSprite = this.createSprite('star');
        this.starSprite.visible = false;
        this.add(this.starSprite);
    }
    get width() {
        return this.starWidth;
    }
    get height() {
        return this.starHeight;
    }
    reset() {
        this.starSprite.visible = false;
        this.isShown = false;
    }
    show() {
        if (!this.isShown) {
            this.isShown = true;
            this.starSprite.visible = true;
            this.starSprite.scale.setX(0.1 * this.starWidth);
            this.starSprite.scale.setY(0.1 * this.starHeight);
            new TWEEN.Tween(this.starSprite.scale)
                .to({ x: this.starWidth, y: this.starHeight }, GameOverStar.SHOW_DURATION)
                .easing(TWEEN.Easing.Exponential.Out)
                .start();
            let material = this.starSprite.material;
            material.opacity = 0.4;
            new TWEEN.Tween(this.starSprite.material)
                .to({ opacity: 1 }, GameOverStar.SHOW_DURATION)
                .easing(TWEEN.Easing.Cubic.Out)
                .start();
        }
    }
    createSprite(key) {
        const texture = this.contentModel.getTexture(key);
        const material = new THREE.SpriteMaterial({ map: texture });
        this.starWidth = material.map.image.width;
        this.starHeight = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, 0.5);
        sprite.scale.set(this.starWidth, this.starHeight, 1);
        sprite.renderOrder = 5;
        return sprite;
    }
}
exports.default = GameOverStar;
GameOverStar.SHOW_DURATION = 750;
