"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const log = require("loglevel");
const THREE = require("three");
const gem_model_1 = require("../../model/gem_model");
const move_model_1 = require("../../model/move_model");
const random_1 = require("../../model/random");
const swipe_model_1 = require("../../model/swipe_model");
const fever_view_1 = require("../../view/fever_view");
const camera_mode_1 = require("../camera_mode");
const game_over_mode_1 = require("./game_over_mode");
const play_mode_1 = require("./play_mode");
class FeverMode extends play_mode_1.PlayModeBase {
    constructor(playController, addDemoSets = false) {
        super(playController);
        this.gemType = gem_model_1.GemType.Red;
        this.enabledMove = swipe_model_1.SwipeDirection.None;
        this.completedSets = [];
        this.playerScorePosition = new THREE.Vector2(0, 0);
        this.SET_DELAY_DURATION = 1000;
        this.GAMEOVER_DELAY_DURATION = 2500;
        this.onSwipe = (event) => {
            if (this.playController.moveModel.canDoMove()) {
                const move = this.playController.moveModel.doMove(event.direction);
                if (move) {
                    if (event.direction == this.enabledMove) {
                        console.log(`%c   GOOD!   `, "color: white; background-color: green;");
                        this.playController.feverModel.finishMove(event.direction, true);
                        const completedMoves = this.playController.feverModel.getCompletedMoves();
                        const points = this.playController.scoreModel.tallyFeverPoints(completedMoves);
                        this.views.scoreView.awardPoints(points, this.playerScorePosition);
                        const moveItem = move;
                        const gemType = move_model_1.default.moveTypeToGemType(moveItem.type);
                        this.playController.soundModel.playDanceSFX(gemType, event.count);
                        this.views.feverView.completeMove(event.direction);
                    }
                    else {
                        console.log(`%c   MISS!   `, "color: white; background-color: red;");
                    }
                }
            }
        };
        this.onEnableMove = (event) => {
            this.enabledMove = event.direction;
        };
        this.onDisableMove = (event) => {
            this.enabledMove = swipe_model_1.SwipeDirection.None;
            this.playController.feverModel.finishMove(event.direction, false);
            if (this.playController.feverModel.hasFinishedMoves()) {
                this.nextSet();
            }
        };
        log.info('PlayMode: FeverMode');
        this.playController.setCameraMode(camera_mode_1.CameraMode.Fever);
        this.views.scoreView.reset();
        this.views.logoView.reset();
        this.views.logoView.feverMode();
        this.playController.levelModel.feverMode();
        this.views.feverRoadView.createFeverItems();
        if (addDemoSets) {
            for (let i = 0; i < 14; i++) {
                let followerData = this.playController.levelModel.followerModel.getNewFollower("");
                this.views.crowdView.addFeverFollower(followerData);
            }
        }
        this.views.crowdView.feverMode();
        this.views.influencerView.feverLeadIn();
        this.views.feverView.reset();
        this.playerScorePosition.set(this.playController.screenModel.width / 2, this.playController.screenModel.height / 2);
        this.addEvents();
        if (addDemoSets) {
            this.completedSets.push(gem_model_1.GemType.Red);
            this.completedSets.push(gem_model_1.GemType.Green);
            this.completedSets.push(gem_model_1.GemType.Blue);
            this.completedSets.push(gem_model_1.GemType.Purple);
        }
        else {
            this.completedSets = this.playController.moveModel.getCompletedSets();
        }
        this.playController.feverModel.setCompletedSets(this.completedSets);
        const feverKey = this.playController.soundModel.getFeverKey();
        const feverData = this.playController.contentModel.getSong(feverKey);
        this.playController.soundModel.transitionToFeverSong(feverData);
        this.playController.updateBPM(feverData.bpm);
        const lightingMode = this.playController.feverModel.getLightingMode();
        this.playController.setLightingMode(lightingMode);
        const randTransition = random_1.Random.range(1, 5);
        const transPlayer = this.playController.contentModel.getTonePlayer(`fever-transition-${randTransition}`);
        this.playController.soundModel.playSFX(transPlayer);
        this.views.feverView.leadIn();
        window.setTimeout(() => { this.prepareViewForSet(); }, 2000);
    }
    addEvents() {
        this.playController.swipeModel.addEventListener(swipe_model_1.SwipeEvents.Swipe, this.onSwipe);
        this.views.feverView.addEventListener(fever_view_1.FeverViewEvents.EnableMove, this.onEnableMove);
        this.views.feverView.addEventListener(fever_view_1.FeverViewEvents.DisableMove, this.onDisableMove);
    }
    removeEvents() {
        this.playController.swipeModel.removeEventListener(swipe_model_1.SwipeEvents.Swipe, this.onSwipe);
        this.views.feverView.removeEventListener(fever_view_1.FeverViewEvents.EnableMove, this.onEnableMove);
        this.views.feverView.removeEventListener(fever_view_1.FeverViewEvents.DisableMove, this.onDisableMove);
    }
    nextSet() {
        this.playController.feverModel.nextSet();
        if (this.playController.feverModel.hasUnfinishedSet()) {
            window.setTimeout(() => { this.prepareViewForSet(); }, this.SET_DELAY_DURATION);
        }
        else {
            log.info(`Game Over - complete`);
            this.removeEvents();
            this.playController.moveModel.moveMode = move_model_1.MoveMode.Disabled;
            this.views.feverView.leadOut();
            window.setTimeout(() => { this.gameOver(); }, this.GAMEOVER_DELAY_DURATION);
        }
    }
    gameOver() {
        this.playController.mode = new game_over_mode_1.default(this.playController);
        this.views.feverRoadView.reset();
    }
    prepareViewForSet() {
        const lightingMode = this.playController.feverModel.getLightingMode();
        this.playController.setLightingMode(lightingMode);
        this.playController.feverModel.clearMoves();
        this.gemType = this.playController.feverModel.currentSet;
        this.playController.moveModel.setMoveType(this.gemType);
        this.playController.moveModel.moveMode = move_model_1.MoveMode.Fever;
        const iconData = this.playController.moveModel.getIconData(this.gemType);
        this.views.feverView.resetIcons(iconData);
        const moveData = this.playController.feverModel.getFeverMoveData();
        this.views.feverView.setMoveData(moveData);
        this.views.feverView.start();
    }
    onScreenResize() {
        this.views.scoreView.onScreenResize();
        this.views.logoView.onScreenResize();
        this.views.feverView.onScreenResize();
        this.playerScorePosition.set(this.playController.screenModel.width / 2, this.playController.screenModel.height / 2);
    }
    render(gl) {
        gl.clear();
        //        gl.render(this.views.backgroundView, this.playController.camera3d);
        //        gl.clearDepth();
        gl.render(this.views.feverRoadView, this.playController.camera3d);
        gl.render(this.views.crowdView, this.playController.camera3d);
        gl.render(this.views.influencerView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.feverView, this.playController.camera2d);
        gl.render(this.views.logoView, this.playController.camera2d);
        gl.render(this.views.scoreView, this.playController.camera2d);
    }
}
exports.default = FeverMode;
