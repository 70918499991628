"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const TWEEN = require("@tweenjs/tween.js");
const loglevel_1 = require("loglevel");
const follower_model_1 = require("./follower_model");
const gem_model_1 = require("./gem_model");
const player_model_1 = require("./player_model");
const road_data_1 = require("./road_data");
class RoadVelocity extends THREE.Object3D {
    constructor() {
        super();
        this.value = 0;
        this.bpm = 120;
        this.setBPM(120);
    }
    get isStarted() {
        return this.value > 0;
    }
    setBPM(bpm) {
        this.bpm = bpm;
    }
    start() {
        const maxVelocity = RoadVelocity.MAX * (this.bpm / 120);
        new TWEEN.Tween(this)
            .to({ value: maxVelocity }, RoadVelocity.ACCELERATION_DURATION)
            .easing(TWEEN.Easing.Linear.None)
            .start();
    }
    stop() {
        new TWEEN.Tween(this)
            .to({ value: 0 }, RoadVelocity.ACCELERATION_DURATION)
            .easing(TWEEN.Easing.Linear.None)
            .start();
    }
}
RoadVelocity.MAX = 4;
RoadVelocity.ACCELERATION_DURATION = 500;
const FOLLOWER_POSITIONS = [
    new THREE.Vector2(-1.0, -2.0),
    new THREE.Vector2(1.0, -2.0),
    new THREE.Vector2(0.0, -3.0),
    new THREE.Vector2(-2.0, -3.5),
    new THREE.Vector2(2.0, -3.5),
    new THREE.Vector2(-1.5, -5.0),
    new THREE.Vector2(1.5, -5.0),
    new THREE.Vector2(-0.5, -5.5),
    new THREE.Vector2(0.5, -5.5),
    new THREE.Vector2(-2.0, -6.5),
    new THREE.Vector2(2.0, -6.5),
    new THREE.Vector2(-1.5, -7.0),
    new THREE.Vector2(1.5, -7.0),
    new THREE.Vector2(-0.5, -7.5),
    new THREE.Vector2(0.5, -7.5),
    new THREE.Vector2(-2.0, -8.5),
    new THREE.Vector2(2.0, -8.5),
    new THREE.Vector2(-1.5, -9.0),
    new THREE.Vector2(1.5, -9.0),
    new THREE.Vector2(-0.5, -9.5),
    new THREE.Vector2(0.5, -9.5),
    new THREE.Vector2(-2.0, -10.5),
    new THREE.Vector2(2.0, -10.5),
    new THREE.Vector2(-1.5, -11.0),
    new THREE.Vector2(1.5, -11.0),
    new THREE.Vector2(-0.5, -11.5),
    new THREE.Vector2(0.5, -11.5),
    new THREE.Vector2(-2.0, -12.5),
    new THREE.Vector2(2.0, -12.5),
    new THREE.Vector2(-1.5, -13.0),
    new THREE.Vector2(1.5, -13.0),
    new THREE.Vector2(-0.5, -13.5),
];
class LevelModel {
    constructor() {
        this.playerModel = new player_model_1.default();
        this.wallTime = 4;
        this.clock = new THREE.Clock();
        this._roadPosition = 0;
        this.roadVelocity = new RoadVelocity();
        this.gems = new Array();
        this.obstacles = new Array();
        loglevel_1.default.debug('LevelModel: constructor');
        this.followerModel = new follower_model_1.default(this);
        this.loadLevel("00 Fever");
    }
    get isWalking() {
        return this.roadVelocity.isStarted;
    }
    get playerZ() {
        return this.roadPosition + this.playerModel.position.z;
    }
    get roadPosition() {
        return this._roadPosition;
    }
    get roadMax() {
        if (this.currentLevelData) {
            return this.currentLevelData.length;
        }
        return 32;
    }
    reset() {
        loglevel_1.default.debug('LevelModel: reset');
        // reset child models
        this.playerModel.reset();
        this.followerModel.reset();
        // stop and reset road movement
        this.stopWalking();
        this._roadPosition = 0.0;
        this.roadVelocity.value = 0;
        // reset gems and obstacles
        for (const gem of this.gems) {
            gem.reset();
        }
        for (const obstacle of this.obstacles) {
            obstacle.reset();
        }
    }
    feverMode() {
        this.stopWalking();
        this._roadPosition = 0.0;
        this.roadVelocity.value = 0;
    }
    setBPM(bpm) {
        this.roadVelocity.setBPM(bpm);
    }
    // Binary search to find any item within epsilon units of the given z
    static findIndex(items, z, epsilon) {
        let start = 0;
        let end = items.length - 1;
        while (start <= end) {
            const mid = start + end >> 1;
            const itemZ = items[mid].position.z;
            if (Math.abs(itemZ - z) < epsilon) {
                return mid;
            }
            else if (itemZ < z) {
                start = mid + 1;
            }
            else {
                end = mid - 1;
            }
        }
        return -1;
    }
    static sortByZ(lhs, rhs) {
        return lhs.position.z - rhs.position.z;
    }
    static getXFromLane(lane) {
        const laneIndex = lane - Math.floor(LevelModel.NUM_LANES / 2);
        return LevelModel.LANE_WIDTH * laneIndex;
    }
    getCurrentLine() {
        return this.roadPosition / LevelModel.ROAD_ZSCALE;
    }
    handleGemCollision() {
        // First find any gem which is close to the player
        const playerZ = this.playerZ;
        let index = LevelModel.findIndex(this.gems, playerZ, LevelModel.GEM_RADIUS);
        if (index < 0) {
            return null;
        }
        // Walk back to the first gem the player could be touching
        while (index > 0 && Math.abs(this.gems[index].position.z - playerZ) < LevelModel.GEM_RADIUS) {
            index--;
        }
        // Walk through all gems looking for collisions until we exceed the gem radius
        const collisions = [];
        while (index < this.gems.length) {
            const gem = this.gems[index++];
            // Early out if we have exceeded the radius
            if (gem.position.z - playerZ > LevelModel.GEM_RADIUS) {
                break;
            }
            // Skip already-awarded gems
            if (gem.isCollecting || gem.hasCollected) {
                continue;
            }
            // Award this gem if it's within the radius
            const distanceSq = new THREE.Vector3(this.playerModel.position.x - gem.position.x, 0, playerZ - gem.position.z).lengthSq();
            if (distanceSq < LevelModel.GEM_RADIUS_SQ) {
                loglevel_1.default.debug(`LevelModel: handleGemCollision - collosion  gemType = ${gem.gemType}  position = ${gem.position.x},${gem.position.z}`);
                loglevel_1.default.info(`Gem Collected: type = ${gem.gemType}`);
                gem.collect();
                collisions.push(gem.gemType);
            }
        }
        return collisions;
    }
    handleObstacleCollision() {
        // First find any obstacle which is close to the player
        const playerZ = this.playerZ;
        let index = LevelModel.findIndex(this.obstacles, playerZ, LevelModel.OBSTACLE_RADIUS);
        if (index < 0) {
            return null;
        }
        // Walk back to the first obstacle the player could be touching
        while (index > 0 && Math.abs(this.obstacles[index].position.z - playerZ) < LevelModel.OBSTACLE_RADIUS) {
            index--;
        }
        // Walk through all obstacles looking for collisions until we exceed the gem radius
        const collisions = [];
        while (index < this.obstacles.length) {
            const obstacle = this.obstacles[index++];
            // Early out if we have exceeded the radius
            if (obstacle.position.z - playerZ > LevelModel.OBSTACLE_RADIUS) {
                break;
            }
            // check radius
            const distanceSq = new THREE.Vector3(this.playerModel.position.x - obstacle.position.x, 0, playerZ - obstacle.position.z).lengthSq();
            if (distanceSq < LevelModel.OBSTACLE_RADIUS_SQ) {
                loglevel_1.default.debug(`LevelModel: handleObstacleCollision - collision  position = ${obstacle.position.x},${obstacle.position.z}`);
                loglevel_1.default.info(`Obstacle Collision`);
                obstacle.collect();
                const collisionPosition = obstacle.position.clone();
                collisionPosition.z -= this.roadPosition;
                collisions.push(collisionPosition);
            }
        }
        return collisions;
    }
    startWalking() {
        loglevel_1.default.debug('LevelModel: startWalking');
        this.clock.stop();
        this.clock.start();
        this.roadVelocity.start();
    }
    stopWalking() {
        loglevel_1.default.debug('LevelModel: stopWalking');
        this.roadVelocity.stop();
    }
    getFollowerPosition(index) {
        return FOLLOWER_POSITIONS[index];
    }
    update() {
        const dt = this.clock.getDelta();
        this._roadPosition += this.roadVelocity.value * dt;
        this.playerModel.update();
    }
    getGemCollisions() {
        return this.handleGemCollision();
    }
    getObstacleCollisions() {
        return this.handleObstacleCollision();
    }
    loadLevel(key) {
        loglevel_1.default.info(`LevelModel: loadLevel - ${key} `);
        this.currentLevelData = road_data_1.default.get(key);
        if (this.currentLevelData) {
            const zOffset = 0;
            // add gems and obstacles
            let gems = [];
            let obstacles = [];
            for (let item of this.currentLevelData.roadData) {
                const x = LevelModel.getXFromLane(item.lane);
                let y = 2;
                const z = (item.line * LevelModel.ROAD_ZSCALE) + zOffset;
                let gemType = gem_model_1.GemType.Red;
                switch (item.type) {
                    case road_data_1.RoadItemType.PickupRed:
                        gemType = gem_model_1.GemType.Red;
                        break;
                    case road_data_1.RoadItemType.PickupGreen:
                        gemType = gem_model_1.GemType.Green;
                        break;
                    case road_data_1.RoadItemType.PickupPurple:
                        gemType = gem_model_1.GemType.Purple;
                        break;
                    case road_data_1.RoadItemType.PickupBlue:
                        gemType = gem_model_1.GemType.Blue;
                        break;
                    case road_data_1.RoadItemType.PickupBlack:
                        gemType = gem_model_1.GemType.Obstacle;
                        y = 0;
                        break;
                }
                let gem = new gem_model_1.default(gemType, new THREE.Vector3(x, y, z));
                if (gem.gemType == gem_model_1.GemType.Obstacle) {
                    obstacles.push(gem);
                }
                else {
                    gems.push(gem);
                }
            }
            this.gems = gems.sort(LevelModel.sortByZ);
            this.obstacles = obstacles.sort(LevelModel.sortByZ);
        }
        else {
            const errorMsg = `LevelModel: loadLevel unable to load level data for song ${key} `;
            loglevel_1.default.error(errorMsg);
            throw (errorMsg);
        }
    }
    getLevelRatings() {
        if (this.currentLevelData) {
            return this.currentLevelData?.ratings;
        }
        loglevel_1.default.error('LevelModel: getLevelRatings - currentLevelData not defined');
        return [];
    }
}
exports.default = LevelModel;
LevelModel.GEM_RADIUS = 2;
LevelModel.GEM_RADIUS_SQ = Math.pow(LevelModel.GEM_RADIUS, 2);
LevelModel.OBSTACLE_RADIUS = 0.5;
LevelModel.OBSTACLE_RADIUS_SQ = Math.pow(LevelModel.OBSTACLE_RADIUS, 2);
LevelModel.NUM_LANES = 5;
LevelModel.LANE_WIDTH = 2;
LevelModel.ROAD_ZSCALE = 2.0;
