"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const move_data_1 = require("../model/move_data");
const move_model_1 = require("../model/move_model");
const ftue_text_1 = require("./ftue_text");
const move_tracker_view_1 = require("./move_tracker_view");
const road_progress_view_1 = require("./road_progress_view");
class HUDView extends THREE.Scene {
    constructor(contentModel, screenModel, scoreModel) {
        super();
        this.trackerOutline = null;
        this.trackersCreated = false;
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        this.scoreModel = scoreModel;
        this.trackerGroup = new THREE.Group();
        this.add(this.trackerGroup);
        this.trackers = new Map();
        this.multipliers = new Map();
        this.roadProgress = new road_progress_view_1.default(this.contentModel, this.screenModel);
        this.add(this.roadProgress);
        this.onScreenResize();
    }
    reset() {
        if (!this.trackersCreated) {
            this.trackersCreated = true;
            const backgroundMaterial = new THREE.SpriteMaterial({ color: 0x182735, opacity: 0.85 });
            const backgroundSprite = new THREE.Sprite(backgroundMaterial);
            backgroundSprite.center.set(0.0, 1.0);
            backgroundSprite.scale.set(HUDView.TRACKERGROUP_WIDTH - 4, HUDView.TRACKERGROUP_HEIGHT - 4, 1);
            backgroundSprite.position.set(2, -2, 0);
            this.trackerGroup.add(backgroundSprite);
            const outlineTexture = this.contentModel.getTexture('tracker-outline');
            const outlineMaterial = new THREE.SpriteMaterial({ map: outlineTexture });
            this.trackerOutline = new THREE.Sprite(outlineMaterial);
            this.trackerOutline.center.set(0.0, 1.0);
            this.trackerOutline.scale.set(HUDView.TRACKERGROUP_WIDTH, HUDView.TRACKERGROUP_HEIGHT, 1);
            this.trackerGroup.add(this.trackerOutline);
            const trackerX = HUDView.TRACKERGROUP_WIDTH / 2;
            const yOffset = 135;
            let trackerY = -70;
            this.addTrackerAndMultiplier(move_data_1.MoveType.Red, 'tracker-icon-red', trackerX, trackerY);
            trackerY -= yOffset;
            this.addTrackerAndMultiplier(move_data_1.MoveType.Green, 'tracker-icon-green', trackerX, trackerY);
            trackerY -= yOffset;
            this.addTrackerAndMultiplier(move_data_1.MoveType.Blue, 'tracker-icon-blue', trackerX, trackerY);
            trackerY -= yOffset;
            this.addTrackerAndMultiplier(move_data_1.MoveType.Purple, 'tracker-icon-purple', trackerX, trackerY);
        }
        for (const [moveType, tracker] of this.trackers) {
            const gemType = move_model_1.default.moveTypeToGemType(moveType);
            const gemLevel = this.scoreModel.getGemLevel(gemType);
            tracker.reset(gemLevel);
        }
        for (const [moveType, multiplier] of this.multipliers) {
            const gemType = move_model_1.default.moveTypeToGemType(moveType);
            const gemLevel = this.scoreModel.getGemLevel(gemType);
            multiplier.setText("x0");
        }
        if (this.trackerOutline) {
            this.trackerOutline.material.color = new THREE.Color(0xcccccc);
        }
        this.roadProgress.reset();
        this.onScreenResize();
    }
    addTrackerAndMultiplier(moveType, iconKey, xPos, yPos) {
        const tracker = new move_tracker_view_1.default(moveType, iconKey, this.contentModel, this.screenModel, this.scoreModel);
        tracker.setPosition(xPos, yPos);
        this.trackerGroup.add(tracker);
        this.trackers.set(moveType, tracker);
        const textFontSize = 16;
        const textXOffset = 65;
        const textYOffset = -40;
        const multiplier = new ftue_text_1.default(this.contentModel, 0xffffff);
        multiplier.fontSize = textFontSize;
        multiplier.horizontal = ftue_text_1.TextJustification.Right;
        multiplier.setText("x0");
        multiplier.position.set(xPos + textXOffset, yPos + textYOffset, 0);
        this.trackerGroup.add(multiplier);
        this.multipliers.set(moveType, multiplier);
    }
    setGemLevel(gemType, gemLevel) {
        const moveType = move_model_1.default.gemTypeToMoveType(gemType);
        this.trackers.get(moveType)?.setGemLevel(gemLevel);
        for (const [key, tracker] of this.trackers) {
            tracker.setSelected(false);
        }
        this.trackers.get(moveType)?.setSelected(true);
        this.multipliers.get(moveType)?.setText(`x${gemLevel}`);
        if (this.trackerOutline) {
            const color = move_tracker_view_1.default.getColor(moveType);
            this.trackerOutline.material.color = new THREE.Color(color);
        }
    }
    moveCompleted(moveType, direction) {
        this.trackers.get(moveType)?.completeMove(direction);
    }
    setProgress(progress) {
        this.roadProgress.setProgress(progress);
    }
    onScreenResize() {
        const trackerScale = this.screenModel.calculateScale(0.3, HUDView.TRACKERGROUP_WIDTH, 0.33, HUDView.TRACKERGROUP_HEIGHT);
        this.trackerGroup.scale.set(trackerScale, trackerScale, 1);
        this.trackerGroup.position.setX(this.screenModel.width * 0.02);
        this.trackerGroup.position.setY(this.screenModel.height * 0.99);
        const progressScale = this.screenModel.calculateScale(0.02, this.roadProgress.width, 0.3, this.roadProgress.height);
        this.roadProgress.scale.set(progressScale, progressScale, 1);
        this.roadProgress.position.setX(this.screenModel.width * 0.98);
        this.roadProgress.position.setY(this.screenModel.height * 0.94);
    }
}
exports.default = HUDView;
HUDView.TRACKERGROUP_WIDTH = 152;
HUDView.TRACKERGROUP_HEIGHT = 546;
