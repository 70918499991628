"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextJustification = void 0;
const THREE = require("three");
var TextJustification;
(function (TextJustification) {
    TextJustification[TextJustification["Left"] = 0] = "Left";
    TextJustification[TextJustification["Top"] = 0] = "Top";
    TextJustification[TextJustification["Center"] = 1] = "Center";
    TextJustification[TextJustification["Right"] = 2] = "Right";
    TextJustification[TextJustification["Bottom"] = 2] = "Bottom";
})(TextJustification = exports.TextJustification || (exports.TextJustification = {}));
;
class FTUEText extends THREE.Group {
    constructor(contentModel, textColor, shadowColor = 0x000000) {
        super();
        this.textSize = 32;
        this.xScale = 0.75;
        this.shadowOffsetDistance = 3;
        this.hasShadow = true;
        this.text = "";
        this.textColor = 0xffffff;
        this.shadowColor = 0x000000;
        this.textMesh = null;
        this.textShadowMesh = null;
        this._textWidth = 0;
        this.horizontalJustification = TextJustification.Center;
        this.verticalJustification = TextJustification.Center;
        this.contentModel = contentModel;
        this.textColor = textColor;
        this.shadowColor = shadowColor;
    }
    get textWidth() {
        return this._textWidth;
    }
    get fontSize() {
        return this.textSize;
    }
    set fontSize(value) {
        this.textSize = value;
    }
    get hasDropShadow() {
        return this.hasShadow;
    }
    set hasDropShadow(value) {
        this.hasShadow = value;
        this.updateText();
    }
    get shadowOffset() {
        return this.textSize;
    }
    set shadowOffset(value) {
        this.shadowOffsetDistance = value;
        this.updateText();
    }
    get horizontal() {
        return this.horizontalJustification;
    }
    set horizontal(value) {
        this.horizontalJustification = value;
    }
    get vertical() {
        return this.verticalJustification;
    }
    set vertical(value) {
        this.verticalJustification = value;
    }
    set color(color) {
        this.textColor = color;
        this.updateText();
    }
    get material() {
        return this.textMesh.material;
    }
    get shadowMaterial() {
        return this.textShadowMesh.material;
    }
    reset() {
    }
    setName(name) {
        this.name = name;
        if (this.textMesh) {
            this.textMesh.name = name;
        }
        if (this.textShadowMesh) {
            this.textShadowMesh.name = name;
        }
    }
    setText(text) {
        this.text = text;
        this.updateText();
    }
    updateText() {
        if (this.textShadowMesh != null) {
            this.remove(this.textShadowMesh);
            this.textShadowMesh.geometry.dispose();
            if (this.textShadowMesh.material && !Array.isArray(this.textShadowMesh.material)) {
                this.textShadowMesh.material.dispose();
            }
            this.textShadowMesh = null;
        }
        if (this.textMesh != null) {
            this.remove(this.textMesh);
            this.textMesh.geometry.dispose();
            if (this.textMesh.material && !Array.isArray(this.textMesh.material)) {
                this.textMesh.material.dispose();
            }
            this.textMesh = null;
        }
        if (this.hasShadow) {
            this.textShadowMesh = this.createText(this.text, this.shadowColor);
            this.textShadowMesh.translateY(-this.shadowOffsetDistance);
            this.textShadowMesh.translateX(this.shadowOffsetDistance);
            this.add(this.textShadowMesh);
        }
        this.textMesh = this.createText(this.text, this.textColor);
        this.add(this.textMesh);
    }
    createText(points, color) {
        const geometry = new THREE.TextGeometry(points, {
            font: this.contentModel.getFont('gamefont'),
            size: this.textSize,
        });
        geometry.computeBoundingBox();
        const material = new THREE.MeshBasicMaterial({
            opacity: 1,
            side: THREE.DoubleSide,
            transparent: true,
            color: color
        });
        let text = new THREE.Mesh(geometry, material);
        let center = new THREE.Vector3();
        geometry.boundingBox.getCenter(center);
        switch (this.horizontalJustification) {
            case TextJustification.Left:
                // do nothing
                break;
            case TextJustification.Center:
                text.position.x -= ((center.x * this.xScale));
                break;
            case TextJustification.Right:
                text.position.x -= ((center.x * this.xScale) * 2);
                break;
        }
        switch (this.verticalJustification) {
            case TextJustification.Top:
                // do nothing
                break;
            case TextJustification.Center:
                text.position.y -= (center.y);
                break;
            case TextJustification.Bottom:
                text.position.y -= (center.y * 2);
                break;
        }
        text.scale.setX(this.xScale);
        this._textWidth = ((center.x * 2) * this.xScale);
        return text;
    }
}
exports.default = FTUEText;
