"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const Tone = require("tone");
const loglevel_1 = require("loglevel");
const gem_model_1 = require("./gem_model");
const swipe_model_1 = require("./swipe_model");
const random_1 = require("./random");
const lighting_mode_1 = require("../controller/lighting_mode");
const MoveBeatData = new Map([
    [swipe_model_1.SwipeDirection.Up, 2],
    [swipe_model_1.SwipeDirection.UpRight, 4],
    [swipe_model_1.SwipeDirection.Right, 1],
    [swipe_model_1.SwipeDirection.DownRight, 4],
    [swipe_model_1.SwipeDirection.Down, 2],
    [swipe_model_1.SwipeDirection.DownLeft, 4],
    [swipe_model_1.SwipeDirection.Left, 1],
    [swipe_model_1.SwipeDirection.UpLeft, 4],
]);
const FeverText = new Map([
    [gem_model_1.GemType.Red, "HOT SAUCE!"],
    [gem_model_1.GemType.Green, "BREAK IT DOWN!"],
    [gem_model_1.GemType.Blue, "IMPROVISE!"],
    [gem_model_1.GemType.Purple, "DANCE FEVER!"],
]);
const FeverAnnouce = new Map([
    [gem_model_1.GemType.Red, "hotsauce"],
    [gem_model_1.GemType.Green, "breakitdown"],
    [gem_model_1.GemType.Blue, "improvise"],
    [gem_model_1.GemType.Purple, "dancefever"],
]);
function toMS(seconds) {
    return Math.floor(seconds * 1000);
}
class FeverModel extends THREE.EventDispatcher {
    constructor() {
        super();
        this.completedSets = [];
        this.setIndex = -1;
        loglevel_1.default.debug('MoveModel: constructor');
        this.moves = new Map();
    }
    reset() {
        loglevel_1.default.debug('MoveModel: reset');
        this.setIndex = -1;
        this.completedSets = [];
        this.moves.clear();
    }
    setCompletedSets(gemTypes) {
        this.completedSets = [...gemTypes];
        random_1.Random.shuffleArray(this.completedSets);
        this.setIndex = 0;
    }
    hasUnfinishedSet() {
        return this.setIndex < this.completedSets.length;
    }
    get currentSet() {
        return this.completedSets[this.setIndex];
    }
    nextSet() {
        this.setIndex++;
    }
    getFeverText() {
        return FeverText.get(this.currentSet);
    }
    getAnnouceKey() {
        return FeverAnnouce.get(this.currentSet);
    }
    getFeverMoveData() {
        const directions = this.getRandomDirections();
        const beatSeconds = Tone.Transport.toSeconds("4n");
        const fadeInDuration = FeverModel.FadeInBeats * beatSeconds;
        const moveInDuration = FeverModel.MoveInBeats * beatSeconds;
        const triggerPlusMinus = FeverModel.TriggerPlusMinusBeats * beatSeconds;
        const bufferDuration = FeverModel.MoveBufferBeats * 0.5;
        const moveData = [];
        let danceEnd = 0;
        for (let dirIndex = 0; dirIndex < directions.length; dirIndex++) {
            const moveBeats = MoveBeatData.get(directions[dirIndex]);
            if (moveBeats === undefined) {
                throw 'FeverModel: getFeverMoveData - invalid beat data';
            }
            let fadeInStart = 0;
            let danceStart = 0;
            if (dirIndex == 0) {
                // first move calculate dance start from end of first
                // fade in and move
                danceStart = fadeInDuration + moveInDuration;
            }
            else {
                // subsequent moves dance start is based on previous move's
                // dance ending plus any buffer
                danceStart = danceEnd + bufferDuration;
                fadeInStart = danceStart - moveInDuration - fadeInDuration;
            }
            danceEnd = danceStart + (moveBeats * beatSeconds);
            const triggerStart = danceStart - triggerPlusMinus;
            const triggerEnd = danceStart + triggerPlusMinus;
            moveData.push({
                swipeDirection: directions[dirIndex],
                beats: moveBeats,
                fadeInStart: toMS(fadeInStart),
                fadeInDuration: toMS(fadeInDuration),
                moveInDuration: toMS(moveInDuration),
                triggerStart: toMS(triggerStart),
                triggerEnd: toMS(triggerEnd),
                fadeOutDuration: toMS(triggerPlusMinus)
            });
        }
        return moveData;
    }
    getRandomDirections() {
        const directions = [];
        for (const dir of Object.values(swipe_model_1.SwipeDirection)) {
            if (dir != swipe_model_1.SwipeDirection.None) {
                directions.push(dir);
            }
        }
        random_1.Random.shuffleArray(directions);
        return directions;
    }
    clearMoves() {
        this.moves.clear();
    }
    finishMove(swipeDirection, success) {
        if (!this.moves.has(swipeDirection)) {
            this.moves.set(swipeDirection, success);
        }
    }
    getCompletedMoves() {
        let completed = 0;
        for (let [direction, success] of this.moves) {
            if (success) {
                completed++;
            }
        }
        return completed;
    }
    hasFinishedMoves() {
        return this.moves.size == 8;
    }
    getLightingMode() {
        switch (this.currentSet) {
            case gem_model_1.GemType.Red:
                return lighting_mode_1.LightingMode.FeverRed;
            case gem_model_1.GemType.Green:
                return lighting_mode_1.LightingMode.FeverGreen;
            case gem_model_1.GemType.Blue:
                return lighting_mode_1.LightingMode.FeverBlue;
            case gem_model_1.GemType.Purple:
                return lighting_mode_1.LightingMode.FeverPurple;
        }
        throw ('FeverModel: getLightingMode - invalid gem type');
    }
}
exports.default = FeverModel;
FeverModel.FadeInBeats = 2;
FeverModel.MoveInBeats = 2;
FeverModel.TriggerPlusMinusBeats = 1.0;
FeverModel.MoveBufferBeats = 1.0;
