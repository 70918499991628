"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const loglevel_1 = require("loglevel");
const play_mode_1 = require("./play_mode");
const swipe_model_1 = require("../../model/swipe_model");
const game_over_view_1 = require("../../view/game_over_view");
const main_menu_mode_1 = require("./main_menu_mode");
const camera_mode_1 = require("../camera_mode");
const player_model_1 = require("../../model/player_model");
const lighting_mode_1 = require("../lighting_mode");
class GameOverMode extends play_mode_1.PlayModeBase {
    constructor(playController) {
        super(playController);
        this.onPointerDown = (event) => {
            loglevel_1.default.debug("GameOverMode: onPointerDown");
            this.views.gameOverView.onPointerDown(event.position);
        };
        this.onPointerMove = (event) => {
            this.views.gameOverView.onPointerMove(event.position);
        };
        this.onPointerUp = (event) => {
            loglevel_1.default.debug("GameOverMode: onPointerUp");
            this.views.gameOverView.onPointerUp();
        };
        this.onReplayPressed = () => {
            loglevel_1.default.info("GameOverMode: onReplayPressed");
            this.removeEvents();
            this.playController.resetGame();
            this.views.crowdView.reset();
            this.playController.mode = new main_menu_mode_1.default(this.playController);
        };
        this.onSharePressed = () => {
            loglevel_1.default.info("GameOverMode: onSharePressed");
            this.share();
        };
        loglevel_1.default.info('PlayMode: GameOverMode');
        this.addEvents();
        this.playController.levelModel.stopWalking();
        if (this.playController.levelModel.playerModel.getState() == player_model_1.PlayerState.Dancing) {
            // normal game over
            this.views.influencerView.gameOverMode();
            this.playController.setCameraMode(camera_mode_1.CameraMode.GameOver);
            this.playController.setLightingMode(lighting_mode_1.LightingMode.GameOver);
            this.views.crowdView.endGame();
            this.views.discoRoadView.sinkAllItems();
            this.views.discoRoadView.mainMenuMode();
        }
        else {
            // fail game over
            this.playController.setCameraMode(camera_mode_1.CameraMode.Fail);
            this.playController.setLightingMode(lighting_mode_1.LightingMode.Fail);
        }
        this.views.logoView.reset();
        this.views.logoView.menuMode();
        this.views.gameOverView.reset();
        this.views.gameOverView.setLevelRatings(this.playController.levelModel.getLevelRatings());
        this.views.gameOverView.setScore(this.playController.scoreModel.points);
        this.views.gameOverView.showShare(navigator.share != undefined);
    }
    addEvents() {
        this.playController.swipeModel.addEventListener(swipe_model_1.SwipeEvents.OnDown, this.onPointerDown);
        this.playController.swipeModel.addEventListener(swipe_model_1.SwipeEvents.OnMove, this.onPointerMove);
        this.playController.swipeModel.addEventListener(swipe_model_1.SwipeEvents.OnUp, this.onPointerUp);
        this.views.gameOverView.addEventListener(game_over_view_1.GameOverEvents.ReplayPressed, this.onReplayPressed);
        this.views.gameOverView.addEventListener(game_over_view_1.GameOverEvents.SharePressed, this.onSharePressed);
    }
    removeEvents() {
        this.playController.swipeModel.removeEventListener(swipe_model_1.SwipeEvents.OnDown, this.onPointerDown);
        this.playController.swipeModel.removeEventListener(swipe_model_1.SwipeEvents.OnMove, this.onPointerMove);
        this.playController.swipeModel.removeEventListener(swipe_model_1.SwipeEvents.OnUp, this.onPointerUp);
        this.views.gameOverView.removeEventListener(game_over_view_1.GameOverEvents.ReplayPressed, this.onReplayPressed);
        this.views.gameOverView.removeEventListener(game_over_view_1.GameOverEvents.SharePressed, this.onSharePressed);
    }
    async share() {
        const shareData = {
            title: 'Disco Loco 3D',
            text: 'Boogie down with Disco Loco 3D, only on TikTok',
            url: 'https://discoloco3d.ep.zynga.com'
        };
        try {
            await navigator.share(shareData);
            loglevel_1.default.info('GameOverMode: share - success');
        }
        catch (error) {
            loglevel_1.default.warn('GameOverMode: share - failure');
            loglevel_1.default.error(error.message);
        }
    }
    onScreenResize() {
        this.views.gameOverView.onScreenResize();
        this.views.scoreView.onScreenResize();
        this.views.logoView.onScreenResize();
    }
    render(gl) {
        this.playController.levelModel.update();
        gl.clear();
        gl.render(this.views.backgroundView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.discoRoadView, this.playController.camera3d);
        gl.render(this.views.influencerView, this.playController.camera3d);
        gl.render(this.views.crowdView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.gameOverView, this.playController.camera2d);
        gl.render(this.views.logoView, this.playController.camera2d);
    }
}
exports.default = GameOverMode;
