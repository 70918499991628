"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const loglevel_1 = require("loglevel");
const content_model_1 = require("../model/content_model");
const screen_model_1 = require("../model/screen_model");
const load_progress_1 = require("../view/load_progress");
const play_controller_1 = require("./play_controller");
class BootController {
    constructor(gl, screenModel) {
        this.gl = gl;
        this.screenModel = screenModel;
        this.camera = new THREE.OrthographicCamera(0, this.screenModel.width, 0, this.screenModel.height, 0, 1);
        this.contentModel = new content_model_1.default();
        this.loadProgress = new load_progress_1.default(this.contentModel, this.screenModel);
        this.onAnimation = () => {
            if (this.contentModel.isStageLoaded(content_model_1.ContentStage.Preload)) {
                loglevel_1.default.debug('BootController: onAnimation - content is done');
                loglevel_1.default.info('BootController: Assets loaded');
                this.destructor();
                new play_controller_1.default(this.gl, this.contentModel, this.screenModel);
                return;
            }
            this.gl.clear();
            //        this.gl.clearDepth();
            this.gl.render(this.loadProgress, this.camera);
        };
        this.onWindowResize = () => {
            loglevel_1.default.debug('BootController: onWindowResize');
            let canvasDimensions = screen_model_1.default.getCanvasDimensions(window.innerWidth, window.innerHeight);
            this.screenModel.width = this.camera.right = canvasDimensions.width;
            this.screenModel.height = this.camera.bottom = canvasDimensions.height;
            this.screenModel.margin = canvasDimensions.margin;
            if (canvasDimensions.margin > 0) {
                let canvasElement = this.gl.domElement;
                canvasElement.style.marginLeft = `${canvasDimensions.margin}px`;
            }
            this.camera.updateProjectionMatrix();
            this.gl.setSize(this.screenModel.width, this.screenModel.height);
            this.loadProgress.screenResize();
        };
        this.gl.setAnimationLoop(this.onAnimation);
        this.gl.setClearColor(0x5502c6);
        loglevel_1.default.debug('BootController: constructor');
        this.contentModel.loadContent(content_model_1.ContentStage.Preload);
        const versionElement = document.getElementById('version');
        if (versionElement) {
            versionElement.hidden = false;
            versionElement.innerHTML = `v${DL3D_VERSION}`;
        }
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    }
    destructor() {
        loglevel_1.default.debug('BootController: destructor');
        this.gl.setClearColor(0x000000);
        window.removeEventListener('resize', this.onWindowResize);
        const versionElement = document.getElementById('version');
        if (versionElement) {
            versionElement.hidden = true;
        }
        this.loadProgress.cleanUp();
    }
}
exports.default = BootController;
