"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const road_data_1 = require("../road_data");
// level 6 - with obstacles in TikTok - Disco Loco 3d - Level Design
const Level07 = {
    length: 168,
    ratings: [140000, 280000, 420000, 560000, 700000, 840000],
    roadData: [
        { type: road_data_1.RoadItemType.PickupRed, line: 8, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 9, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 9, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 10, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 10, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 12, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 13, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 13, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 14, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 14, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 16, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 17, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 17, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 18, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 18, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 20, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 20, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 20, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 21, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 21, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 22, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 22, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 24, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 25, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 28, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 31, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 31, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 31, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 31, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 32, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 33, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 36, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 39, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 39, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 39, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 39, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 40, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 41, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 42, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 44, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 45, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 46, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 47, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 47, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 47, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 47, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 48, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 49, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 50, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 52, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 53, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 54, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 55, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 55, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 55, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 56, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 57, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 58, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 60, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 61, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 62, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 63, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 63, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 63, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 64, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 65, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 66, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 68, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 69, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 70, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 71, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 71, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 72, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 72, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 72, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 73, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 73, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 76, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 76, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 77, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 77, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 79, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 79, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 79, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 80, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 80, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 81, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 81, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 82, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 82, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 83, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 84, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 84, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 85, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 85, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 86, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 86, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 87, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 87, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 88, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 88, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 89, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 89, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 91, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 91, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 92, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 92, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 92, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 93, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 93, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 95, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 95, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 96, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 96, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 97, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 97, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 98, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 98, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 100, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 100, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 101, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 101, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 102, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 102, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 103, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 103, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 104, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 105, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 106, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 106, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 107, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 108, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 109, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 110, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 111, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 111, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 111, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 112, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 113, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 114, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 115, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 115, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 116, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 117, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 118, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 119, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 119, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 120, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 121, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 122, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 123, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 124, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 124, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 125, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 126, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 127, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 128, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 129, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 130, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 131, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 132, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 133, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 133, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 134, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 135, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 135, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 136, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 136, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 136, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 137, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 137, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 138, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 138, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 139, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 139, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 141, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 141, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 141, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 141, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 144, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 144, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 144, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 145, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 145, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 146, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 146, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 147, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 147, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 149, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 149, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 149, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 149, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 152, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 152, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 152, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 153, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 153, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 154, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 154, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 155, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 155, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 156, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 156, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 157, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 157, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 159, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 160, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 160, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 161, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 161, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 162, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 162, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 163, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 163, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 164, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 164, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 165, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 165, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 4 },
    ]
};
exports.default = Level07;
