"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const TWEEN = require("@tweenjs/tween.js");
class GameOverRating extends THREE.Group {
    constructor(contentModel) {
        super();
        this.textWidth = 300;
        this.textHeight = 16;
        this.textSize = 16;
        this.textColor = 0xffffff;
        this.texts = [];
        this.ratings = ["SWEET!", "GROOVY!!", "AMAZING!!!", "SPECTACULAR!!!!", "UNBELIEVABLE!!!!!"];
        this.contentModel = contentModel;
        this.textGroup = new THREE.Group();
        this.textGroup.renderOrder = 4;
        this.add(this.textGroup);
        for (let rating of this.ratings) {
            let text = this.createText(rating);
            text.visible = false;
            this.texts.push(text);
            this.textGroup.add(text);
        }
        this.texts[4].visible = true;
    }
    get width() {
        return this.textWidth;
    }
    get height() {
        return this.textHeight;
    }
    reset() {
        for (let text of this.texts) {
            text.visible = false;
        }
    }
    show(index) {
        if (index >= 0 && index < this.texts.length) {
            for (let text of this.texts) {
                text.visible = false;
            }
            const text = this.texts[index];
            text.visible = true;
            this.textGroup.scale.set(0.2, 0.2, 1);
            const material = text.material;
            material.opacity = 0.2;
            new TWEEN.Tween(this.textGroup.scale)
                .to({ x: 1, y: 1 }, GameOverRating.DURATION)
                .easing(TWEEN.Easing.Elastic.Out)
                .start();
            new TWEEN.Tween(material)
                .to({ opacity: 1 }, GameOverRating.DURATION)
                .easing(TWEEN.Easing.Cubic.Out)
                .start();
        }
    }
    createText(ratingText) {
        const geometry = new THREE.TextGeometry(ratingText, {
            font: this.contentModel.getFont('gamefont'),
            size: this.textSize,
        });
        geometry.computeBoundingBox();
        const material = new THREE.MeshBasicMaterial({
            opacity: 1,
            side: THREE.DoubleSide,
            transparent: true,
            color: this.textColor
        });
        let text = new THREE.Mesh(geometry, material);
        let center = new THREE.Vector3();
        geometry.boundingBox.getCenter(center);
        text.position.x -= (center.x);
        text.position.y -= (center.y);
        return text;
    }
}
exports.default = GameOverRating;
GameOverRating.DURATION = 750;
