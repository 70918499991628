"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Tone = require("tone");
const loglevel_1 = require("loglevel");
const gem_model_1 = require("./gem_model");
const random_1 = require("./random");
class StingerPlayer {
    constructor(sfxChannel) {
        this.songKey = '';
        this.initialized = false;
        this.pianoSampler = null;
        this.guitarSampler = null;
        this.drumSampler = null;
        this.stringsSampler = null;
        this.gemSampler = null;
        this.currentScale = 'Cm';
        this.notes = [];
        this.gemNotes = [];
        this.scoreNotes = [];
        this.scoreDingTimer = -1;
        this.scoreIndex = 0;
        this.sfxChannel = sfxChannel;
        this.scaleData = new Map();
        // Minor Scales
        this.scaleData.set('Cm', ['C', 'D', 'Eb', 'F', 'G', 'Ab', 'Bb']);
        this.scaleData.set('C#m', ['C#', 'D#', 'E', 'F#', 'G#', 'A', 'B']);
        this.scaleData.set('Dm', ['D', 'E', 'F', 'G', 'A', 'Bb', 'C']);
        this.scaleData.set('Ebm', ['Eb', 'F', 'Gb', 'Ab', 'Bb', 'Cb', 'Db']);
        this.scaleData.set('Em', ['E', 'F#', 'G', 'A', 'B', 'C', 'D']);
        this.scaleData.set('Fm', ['F', 'G', 'Ab', 'Bb', 'C', 'Db', 'Eb']);
        this.scaleData.set('F#m', ['F#', 'G#', 'A', 'B', 'C#', 'D', 'E']);
        this.scaleData.set('Gm', ['G', 'A', 'Bb', 'C', 'D', 'Eb', 'F']);
        this.scaleData.set('Abm', ['Ab', 'Bb', 'Cb', 'Db', 'Eb', 'Fb', 'Gb']);
        this.scaleData.set('Am', ['A', 'B', 'C', 'D', 'E', 'F', 'G']);
        this.scaleData.set('Bbm', ['Bb', 'C', 'Db', 'Eb', 'F', 'Gb', 'Ab']);
        this.scaleData.set('Bm', ['B', 'C#', 'D', 'E', 'F#', 'G', 'A']);
        //Major Scales
        this.scaleData.set('C', ['C', 'D', 'E', 'F', 'G', 'A', 'B']);
        this.scaleData.set('C#', ['C#', 'D#', 'E#', 'F#', 'G#', 'A#', 'B#']);
        this.scaleData.set('D', ['D', 'E', 'F#', 'G', 'A', 'B', 'C#']);
        this.scaleData.set('Eb', ['Eb', 'F', 'G', 'Ab', 'Bb', 'C', 'D']);
        this.scaleData.set('F', ['F', 'G', 'A', 'Bb', 'C', 'D', 'E']);
        this.scaleData.set('F#', ['F#', 'G#', 'A#', 'B', 'C#', 'D#', 'E#']);
        this.scaleData.set('G', ['G', 'A', 'B', 'C', 'D', 'E', 'F#']);
        this.scaleData.set('Ab', ['Ab', 'Bb', 'C', 'Db', 'Eb', 'F', 'G']);
        this.scaleData.set('A', ['A', 'B', 'C#', 'D', 'E', 'F#', 'G#']);
        this.scaleData.set('Bb', ['Bb', 'C', 'D', 'Eb', 'F', 'G', 'A']);
        this.scaleData.set('B', ['B', 'C#', 'D#', 'E', 'F#', 'G#', 'A#']);
        this.gemData = new Map();
        // Minor Pentatonic
        this.gemData.set('Cm', ['C6', 'Eb6', 'F6', 'G6', 'Bb6']);
        this.gemData.set('C#m', ['C#6', 'E6', 'F#6', 'G#6', 'B6']);
        this.gemData.set('Dm', ['D6', 'F6', 'G6', 'A6', 'C7']);
        this.gemData.set('Ebm', ['Eb6', 'F6', 'Gb6', 'Ab6', 'Cb7']);
        this.gemData.set('Em', ['E6', 'G6', 'A6', 'B6', 'D7']);
        this.gemData.set('Fm', ['F6', 'Ab6', 'Bb6', 'C7', 'Eb7']);
        this.gemData.set('F#m', ['F#6', 'A6', 'B6', 'C#7', 'E7']);
        this.gemData.set('Gm', ['G6', 'Bb6', 'C7', 'D7', 'F7']);
        this.gemData.set('Abm', ['G#6', 'B6', 'C#7', 'D#7', 'F#7']);
        this.gemData.set('Am', ['A5', 'C6', 'D6', 'E6', 'G6']);
        this.gemData.set('Bbm', ['Bb5', 'Db6', 'Eb6', 'F6', 'Ab6']);
        this.gemData.set('Bm', ['B5', 'D6', 'E6', 'F#6', 'A6']);
        // Major Pentatonic
        this.gemData.set('C', ['C6', 'D6', 'E6', 'G6', 'A6']);
        this.gemData.set('C#', ['C#6', 'D#6', 'F6', 'G#6', 'A#6']);
        this.gemData.set('D', ['D6', 'E6', 'F#6', 'A6', 'B6']);
        this.gemData.set('Eb', ['Eb6', 'F6', 'G6', 'Bb6', 'C7']);
        this.gemData.set('E', ['E6', 'F#6', 'G6#', 'B6', 'C#7']);
        this.gemData.set('F', ['F6', 'G6', 'A6', 'C7', 'D7',]);
        this.gemData.set('F#', ['F#6', 'G#6', 'A#6', 'C#7', 'D#7']);
        this.gemData.set('G', ['G6', 'A6', 'B6', 'D7', 'E7',]);
        this.gemData.set('Ab', ['Ab6', 'Bb6', 'C7', 'Eb7', 'F7',]);
        this.gemData.set('A', ['A6', 'B6', 'C#7', 'E7', 'F#7',]);
        this.gemData.set('Bb', ['Bb6', 'C7', 'D7', 'F7', 'G7',]);
        this.gemData.set('B', ['B6', 'C#7', 'D#7', 'F#7', 'G#7',]);
    }
    initSamplers(contentModel) {
        let samplerData = contentModel.getSampler('strings');
        this.stringsSampler = samplerData.sampler;
        this.stringsSampler.volume.value = -6;
        this.stringsSampler.connect(this.sfxChannel);
        samplerData = contentModel.getSampler('piano');
        this.pianoSampler = samplerData.sampler;
        this.pianoSampler.volume.value = -8;
        this.pianoSampler.connect(this.sfxChannel);
        samplerData = contentModel.getSampler('guitar');
        this.guitarSampler = samplerData.sampler;
        this.guitarSampler.volume.value = -4;
        this.guitarSampler.connect(this.sfxChannel);
        samplerData = contentModel.getSampler('drumkit');
        this.drumSampler = samplerData.sampler;
        this.drumSampler.volume.value = -4;
        this.drumSampler.connect(this.sfxChannel);
        samplerData = contentModel.getSampler('gembell');
        this.gemSampler = samplerData.sampler;
        this.gemSampler.volume.value = -6;
        this.gemSampler.connect(this.sfxChannel);
        this.initialized = true;
    }
    setSong(songData) {
        this.currentScale = songData.scale;
        if (this.scaleData.has(this.currentScale)) {
            loglevel_1.default.debug(`StingerPlayer: setSong - scale set - ${songData.scale}`);
            this.notes = this.scaleData.get(this.currentScale);
        }
        else {
            loglevel_1.default.error(`StingerPlayer: setSong - scale not found - ${songData.scale}`);
        }
        if (this.gemData.has(this.currentScale)) {
            loglevel_1.default.debug(`StingerPlayer: setSong - gem notes set - ${songData.scale}`);
            this.gemNotes = this.gemData.get(this.currentScale);
        }
        else {
            loglevel_1.default.error(`StingerPlayer: setSong - scale not found - ${songData.scale}`);
        }
    }
    playDanceStinger(gemType, danceCount) {
        if (this.initialized) {
            switch (gemType) {
                case gem_model_1.GemType.Red:
                    this.playGuitarStinger();
                    break;
                case gem_model_1.GemType.Green:
                    this.playPianoStinger();
                    break;
                case gem_model_1.GemType.Blue:
                    this.playDrumStinger();
                    break;
                case gem_model_1.GemType.Purple:
                    this.playStringsStinger();
                    break;
            }
        }
    }
    playGemStinger(gemType) {
        if (this.gemSampler) {
            const noteIndex = random_1.Random.next(5);
            const note = this.gemNotes[noteIndex];
            const now = Tone.Transport.nextSubdivision("8n");
            this.gemSampler.triggerAttackRelease(note, "4n", now);
        }
    }
    getNote(interval, octave) {
        return `${this.notes[interval - 1]}${octave}`;
    }
    playStringsStinger() {
        const notes = this.scaleData.get(this.currentScale);
        if (notes && this.stringsSampler) {
            const note1 = this.getNote(5, 4);
            const note2 = this.getNote(1, 4);
            const now = Tone.Transport.nextSubdivision("16n");
            let delay = Tone.Transport.toSeconds("8n");
            const rndNum = random_1.Random.next(2);
            switch (rndNum) {
                case 0:
                    this.stringsSampler.triggerAttackRelease(note1, "4n", now);
                    this.stringsSampler.triggerAttackRelease(note2, "4n", now + delay);
                    break;
                case 1:
                    this.stringsSampler.triggerAttackRelease(note2, "4n", now);
                    this.stringsSampler.triggerAttackRelease(note1, "4n", now + delay);
                    break;
            }
        }
    }
    playGuitarStinger() {
        const notes = this.scaleData.get(this.currentScale);
        if (notes && this.guitarSampler) {
            const note1 = this.getNote(1, 3);
            const note2 = this.getNote(3, 3);
            const note3 = this.getNote(5, 3);
            const note4 = this.getNote(1, 4);
            const note5 = this.getNote(3, 4);
            const note6 = this.getNote(5, 4);
            const now = Tone.Transport.nextSubdivision("16n");
            const delay = Tone.Transport.toSeconds("32n");
            const rndNum = random_1.Random.next(3);
            switch (rndNum) {
                case 0:
                    this.guitarSampler.triggerAttackRelease(note1, "1n", now);
                    this.guitarSampler.triggerAttackRelease(note2, "1n", now + delay);
                    this.guitarSampler.triggerAttackRelease(note3, "1n", now + delay * 2);
                    this.guitarSampler.triggerAttackRelease(note4, "1n", now + delay * 3);
                    break;
                case 1:
                    this.guitarSampler.triggerAttackRelease(note4, "1n", now);
                    this.guitarSampler.triggerAttackRelease(note3, "1n", now + delay);
                    this.guitarSampler.triggerAttackRelease(note2, "1n", now + delay * 2);
                    this.guitarSampler.triggerAttackRelease(note1, "1n", now + delay * 3);
                    break;
                case 2:
                    this.guitarSampler.triggerAttackRelease(note1, "1n", now);
                    this.guitarSampler.triggerAttackRelease(note4, "1n", now + delay);
                    this.guitarSampler.triggerAttackRelease(note5, "1n", now + delay * 2);
                    this.guitarSampler.triggerAttackRelease(note6, "1n", now + delay * 3);
                    break;
            }
        }
    }
    playPianoStinger() {
        const notes = this.scaleData.get(this.currentScale);
        if (notes && this.pianoSampler) {
            const note1 = this.getNote(5, 3);
            const note2 = this.getNote(1, 4);
            const note3 = this.getNote(3, 4);
            const note4 = this.getNote(5, 4);
            const note5 = this.getNote(4, 4);
            const now = Tone.Transport.nextSubdivision("16n");
            let sixteenth = Tone.Transport.toSeconds("16n");
            let eigth = Tone.Transport.toSeconds("8n");
            const rndNum = random_1.Random.next(3);
            switch (rndNum) {
                case 0:
                    this.pianoSampler.triggerAttackRelease(note1, "1n", now);
                    this.pianoSampler.triggerAttackRelease(note2, "4n", now + sixteenth);
                    this.pianoSampler.triggerAttackRelease(note3, "4n", now + sixteenth);
                    this.pianoSampler.triggerAttackRelease(note2, "2n", now + eigth);
                    this.pianoSampler.triggerAttackRelease(note4, "2n", now + eigth);
                    break;
                case 1:
                    this.pianoSampler.triggerAttackRelease(note1, "1n", now);
                    this.pianoSampler.triggerAttackRelease(note2, "4n", now + sixteenth);
                    this.pianoSampler.triggerAttackRelease(note3, "4n", now + sixteenth);
                    this.pianoSampler.triggerAttackRelease(note2, "2n", now + eigth);
                    this.pianoSampler.triggerAttackRelease(note5, "2n", now + eigth);
                    break;
                case 2:
                    this.pianoSampler.triggerAttackRelease(note3, "1n", now);
                    this.pianoSampler.triggerAttackRelease(note1, "4n", now + sixteenth);
                    this.pianoSampler.triggerAttackRelease(note5, "4n", now + sixteenth);
                    this.pianoSampler.triggerAttackRelease(note1, "2n", now + eigth);
                    this.pianoSampler.triggerAttackRelease(note3, "2n", now + eigth);
                    break;
            }
        }
    }
    playDrumStinger() {
        if (this.drumSampler) {
            const clap = "C3";
            const cowbell = "D3";
            const closedHat = "E3";
            const openHat = "F3";
            const now = Tone.Transport.nextSubdivision("16n");
            let sixteenth = Tone.Transport.toSeconds("16n");
            let quarter = Tone.Transport.toSeconds("4n");
            let eigth = Tone.Transport.toSeconds("8n");
            const rndNum = random_1.Random.next(3);
            switch (rndNum) {
                case 0:
                    this.drumSampler.triggerAttackRelease(clap, "8n", now);
                    this.drumSampler.triggerAttackRelease(clap, "8n", now + sixteenth);
                    this.drumSampler.triggerAttackRelease(clap, "8n", now + quarter);
                    break;
                case 1:
                    this.drumSampler.triggerAttackRelease(cowbell, "8n", now);
                    this.drumSampler.triggerAttackRelease(cowbell, "8n", now + eigth);
                    this.drumSampler.triggerAttackRelease(cowbell, "8n", now + eigth * 2);
                    break;
                case 2:
                    this.drumSampler.triggerAttackRelease(closedHat, "16n", now);
                    this.drumSampler.triggerAttackRelease(closedHat, "16n", now + sixteenth);
                    this.drumSampler.triggerAttackRelease(closedHat, "16n", now + sixteenth * 2);
                    this.drumSampler.triggerAttackRelease(closedHat, "16n", now + sixteenth * 3);
                    this.drumSampler.triggerAttackRelease(closedHat, "16n", now + sixteenth * 4);
                    this.drumSampler.triggerAttackRelease(closedHat, "16n", now + sixteenth * 5);
                    this.drumSampler.triggerAttackRelease(closedHat, "16n", now + sixteenth * 6);
                    this.drumSampler.triggerAttackRelease(closedHat, "16n", now + sixteenth * 7);
                    this.drumSampler.triggerAttackRelease(openHat, "8n", now + quarter);
                    break;
            }
        }
    }
    startScoreDing() {
        this.scoreNotes = [];
        this.scoreNotes = [...this.gemNotes, this.gemNotes[3], this.gemNotes[2], this.gemNotes[1]];
        const transportNow = Tone.Transport.now();
        const transportNext = Tone.Transport.nextSubdivision("8n");
        const startTime = transportNext - transportNow;
        this.scoreIndex = 0;
        this.scoreDingTimer = Tone.Transport.scheduleRepeat(this.onScoreDing.bind(this), "16n", startTime);
        if (this.gemSampler) {
            this.gemSampler.release = 0.85;
            this.gemSampler.volume.value = -12;
        }
    }
    stopScoreDing() {
        if (this.scoreDingTimer > -1) {
            Tone.Transport.clear(this.scoreDingTimer);
            this.scoreDingTimer = -1;
        }
        if (this.gemSampler) {
            this.gemSampler.release = 0.1;
            this.gemSampler.volume.value = -6;
        }
    }
    onScoreDing() {
        if (this.gemSampler) {
            const note = this.scoreNotes[this.scoreIndex];
            this.gemSampler.triggerAttackRelease(note, "8n");
            this.scoreIndex++;
            this.scoreIndex %= this.scoreNotes.length;
        }
    }
    playCheatStinger() {
        if (this.gemSampler) {
            const now = Tone.Transport.now();
            let offset = 0.1;
            this.gemSampler.triggerAttackRelease("C6", "4n", now);
            this.gemSampler.triggerAttackRelease("G6", "4n", now + offset);
            this.gemSampler.triggerAttackRelease("C7", "4n", now + offset + offset);
        }
    }
}
exports.default = StingerPlayer;
