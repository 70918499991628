"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const loglevel_1 = require("loglevel");
const move_model_1 = require("../../model/move_model");
const swipe_model_1 = require("../../model/swipe_model");
const play_mode_1 = require("./play_mode");
const game_over_mode_1 = require("./game_over_mode");
const fail_mode_1 = require("./fail_mode");
const fever_mode_1 = require("./fever_mode");
class DanceMode extends play_mode_1.PlayModeBase {
    constructor(playController) {
        super(playController);
        this.onSwipe = (event) => {
            if (event.movement == swipe_model_1.MovementDirection.Right) {
                this.playController.levelModel.playerModel.moveRight();
            }
            else if (event.movement == swipe_model_1.MovementDirection.Left) {
                this.playController.levelModel.playerModel.moveLeft();
            }
            if (this.playController.moveModel.canDoMove()) {
                const move = this.playController.moveModel.doMove(event.direction);
                if (move) {
                    this.views.crowdView.doMove(move);
                }
            }
        };
        this.onMoveStarted = (event) => {
            const move = event.move;
            const movePoints = this.playController.scoreModel.tallyMovePoints(move);
            const playerPosition = this.playController.screenModel.position2D(this.playController.levelModel.playerModel.position, this.playController.camera3d);
            this.views.scoreView.awardPoints(movePoints, playerPosition);
            const moveItem = event.move;
            const gemType = move_model_1.default.moveTypeToGemType(moveItem.type);
            this.playController.soundModel.playDanceSFX(gemType, event.count);
        };
        this.onMoveCompleted = (event) => {
        };
        this.onUniqueMove = (event) => {
            const moveItem = event.move;
            this.views.hudView.moveCompleted(moveItem.type, moveItem.direction);
            const followerData = this.playController.levelModel.followerModel.getNewFollower(moveItem.name);
            this.views.crowdView.addFollower(followerData, 5);
            this.views.moveUnlockView.queueMove(moveItem);
        };
        this.onMoveSetComplete = (event) => {
            const move = event.move;
            const moveSetPoints = this.playController.scoreModel.tallyMoveSetPoints(move);
            const playerPosition = this.playController.screenModel.position2D(this.playController.levelModel.playerModel.position, this.playController.camera3d);
            this.views.scoreView.awardPoints(moveSetPoints, playerPosition);
        };
        loglevel_1.default.info('PlayMode: DanceMode');
        this.playController.levelModel.startWalking();
        this.views.influencerView.faceForward();
        this.playController.moveModel.moveMode = move_model_1.MoveMode.Gameplay;
        this.playController.moveModel.addEventListener(move_model_1.MoveEvents.MoveStarted, this.onMoveStarted);
        this.playController.moveModel.addEventListener(move_model_1.MoveEvents.MoveCompleted, this.onMoveCompleted);
        this.playController.moveModel.addEventListener(move_model_1.MoveEvents.UniqueMove, this.onUniqueMove);
        this.playController.moveModel.addEventListener(move_model_1.MoveEvents.MoveSetComplete, this.onMoveSetComplete);
        this.playController.swipeModel.addEventListener(swipe_model_1.SwipeEvents.Swipe, this.onSwipe);
        this.views.logoView.reset();
        this.views.moveUnlockView.reset();
    }
    destructor() {
        this.playController.swipeModel.removeEventListener(swipe_model_1.SwipeEvents.Swipe, this.onSwipe);
        this.playController.moveModel.removeEventListener(move_model_1.MoveEvents.MoveStarted, this.onMoveStarted);
        this.playController.moveModel.removeEventListener(move_model_1.MoveEvents.MoveCompleted, this.onMoveCompleted);
        this.playController.moveModel.removeEventListener(move_model_1.MoveEvents.UniqueMove, this.onUniqueMove);
        this.playController.moveModel.removeEventListener(move_model_1.MoveEvents.MoveSetComplete, this.onMoveSetComplete);
    }
    onScreenResize() {
        this.views.hudView.onScreenResize();
        this.views.logoView.onScreenResize();
        this.views.scoreView.onScreenResize();
        this.views.moveUnlockView.onScreenResize();
    }
    render(gl) {
        this.playController.levelModel.update();
        const gems = this.playController.levelModel.getGemCollisions();
        const obstacles = this.playController.levelModel.getObstacleCollisions();
        const roadLine = this.playController.levelModel.getCurrentLine();
        const roadProgress = roadLine / this.playController.levelModel.roadMax;
        this.views.hudView.setProgress(roadProgress);
        if (gems && gems.length) {
            const gemPoints = this.playController.scoreModel.tallyGemPoints(gems);
            const playerPosition = this.playController.screenModel.position2D(this.playController.levelModel.playerModel.position, this.playController.camera3d);
            this.views.scoreView.awardPoints(gemPoints, playerPosition);
            this.playController.moveModel.setMoveType(gems[gems.length - 1]);
            for (const gemType of gems) {
                const gemLevel = this.playController.scoreModel.getGemLevel(gemType);
                this.views.hudView.setGemLevel(gemType, gemLevel);
                this.playController.soundModel.playGemSFX(gemType);
            }
        }
        else if (obstacles && obstacles.length) {
            loglevel_1.default.info(`Game Over - fail`);
            this.destructor();
            this.playController.levelModel.playerModel.setObstaclePosition(obstacles[0]);
            this.playController.mode = new fail_mode_1.default(this.playController);
        }
        else if (this.playController.levelModel.isWalking) {
            const line = this.playController.levelModel.getCurrentLine();
            if (line >= this.playController.levelModel.roadMax) {
                loglevel_1.default.info(`Game Over - complete`);
                this.destructor();
                const completedMoveSets = this.playController.moveModel.getCompletedSets();
                if (completedMoveSets.length === 0) {
                    this.playController.mode = new game_over_mode_1.default(this.playController);
                }
                else {
                    this.views.discoRoadView.sinkAllItems();
                    this.playController.mode = new fever_mode_1.default(this.playController, false);
                }
            }
        }
        gl.clear();
        gl.render(this.views.backgroundView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.discoRoadView, this.playController.camera3d);
        gl.render(this.views.influencerView, this.playController.camera3d);
        gl.render(this.views.crowdView, this.playController.camera3d);
        gl.clearDepth();
        gl.render(this.views.scoreView, this.playController.camera2d);
        gl.render(this.views.hudView, this.playController.camera2d);
        gl.render(this.views.logoView, this.playController.camera2d);
        gl.render(this.views.swipeToBeginView, this.playController.camera2d);
        gl.render(this.views.moveUnlockView, this.playController.camera2d);
    }
}
exports.default = DanceMode;
