"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const road_data_1 = require("../road_data");
// TestLevel in TikTok - Disco Loco 3d - Level Design
const Level06 = {
    length: 168,
    ratings: [100000, 200000, 300000, 400000, 500000, 600000],
    roadData: [
        { type: road_data_1.RoadItemType.PickupRed, line: 8, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 8, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 9, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 9, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 10, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 13, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 13, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 13, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 13, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 13, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 16, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 16, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 16, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 17, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 17, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 18, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 18, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 21, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 21, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 21, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 21, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 24, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 25, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 25, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 26, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 26, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 29, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 29, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 30, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 30, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 31, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 31, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 32, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 32, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 33, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 34, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 34, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 37, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 37, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 39, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 39, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 39, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 43, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 43, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 44, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 44, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 45, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 48, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 48, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 49, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 49, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 50, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 50, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 51, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 51, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 52, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 53, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 53, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 56, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 56, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 58, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 58, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 58, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 62, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 62, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 63, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 63, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 65, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 65, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 66, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 66, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 67, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 68, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 69, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 71, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 73, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 73, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 75, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 75, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 77, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 78, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 79, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 79, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 83, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 83, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 83, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 88, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 89, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 89, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 90, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 90, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 93, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 93, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 93, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 93, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 93, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 96, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 96, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 96, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 97, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 97, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 98, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 98, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 101, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 101, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 101, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 101, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 104, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 104, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 105, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 105, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 106, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 109, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 109, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 110, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 110, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 111, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 111, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 112, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 112, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 113, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 114, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 114, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 116, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 119, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 119, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 119, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 123, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 124, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 124, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 125, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 125, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 128, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 128, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 129, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 129, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 130, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 130, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 131, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 131, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 132, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 133, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 133, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 136, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 136, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 138, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 138, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 138, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 141, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 141, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 142, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 142, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 143, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 145, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 145, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 146, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 146, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 147, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 148, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 149, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 151, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 153, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 153, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 155, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 155, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 157, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 158, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 160, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 160, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 163, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 163, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 163, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 3 },
    ]
};
exports.default = Level06;
