"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareButton = exports.ReplayButton = void 0;
const button_view_1 = require("./button_view");
class ReplayButton extends button_view_1.default {
    constructor(contentModel) {
        super('replay-button', 'replay-button', contentModel);
        if (this.buttonSprite) {
            this.buttonSprite.center.set(0.0, 0.5);
        }
    }
    onPointerDown() {
        this.scale.set(1.05, 1.05, 1);
        this.position.setX(this.width - (this.width * 1.03));
    }
    onPointerUp() {
        this.scale.set(1, 1, 1);
    }
}
exports.ReplayButton = ReplayButton;
class ShareButton extends button_view_1.default {
    constructor(contentModel) {
        super('share-button', 'share-button', contentModel);
        if (this.buttonSprite) {
            this.buttonSprite.center.set(0.0, 0.5);
        }
    }
    onPointerDown() {
        this.scale.set(1.05, 1.05, 1);
        this.position.setX(this.width - (this.width * 1.03));
    }
    onPointerUp() {
        this.scale.set(1, 1, 1);
    }
}
exports.ShareButton = ShareButton;
