"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const road_data_1 = require("../road_data");
// Basic level in TikTok - Disco Loco 3d - Level Design
const Level00 = {
    length: 168,
    ratings: [40000, 80000, 120000, 160000, 200000, 240000],
    roadData: [
        { type: road_data_1.RoadItemType.PickupRed, line: 8, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 8, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 8, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 9, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 10, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 11, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 12, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 20, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 24, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 24, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 32, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 36, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 40, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 48, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 48, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 48, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 49, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 50, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 51, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 52, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 64, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 64, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 64, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 72, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 80, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 80, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 80, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 80, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 88, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 88, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 88, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 89, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 90, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 91, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 92, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 96, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 96, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 100, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 104, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 104, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 112, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 112, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 112, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 120, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 124, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 124, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 124, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 128, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 128, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 128, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 129, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 130, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 131, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 132, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 136, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 136, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 140, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 140, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 140, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 144, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 144, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 144, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 148, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 152, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 152, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 152, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 156, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 160, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 160, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 160, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 164, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 164, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 164, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 4 },
    ]
};
exports.default = Level00;
