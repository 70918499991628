"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const loglevel_1 = require("loglevel");
const three_1 = require("three");
const camera_mode_1 = require("./camera_mode");
const modeData = new Map([
    [camera_mode_1.CameraMode.Main, {
            x: 0,
            y: 9.5,
            z: 13.5,
            xRotation: -30,
            fov: 80,
            inFront: true
        }],
    [camera_mode_1.CameraMode.Game, {
            x: 0,
            y: 11,
            z: -9.75,
            xRotation: 33,
            fov: 80,
            inFront: false
        }],
    [camera_mode_1.CameraMode.Fail, {
            x: 0,
            y: 17.5,
            z: 7.5,
            xRotation: -62,
            fov: 60,
            inFront: true
        }],
    [camera_mode_1.CameraMode.GameOver, {
            x: 0,
            y: 8.5,
            z: 12,
            xRotation: -29,
            fov: 60,
            inFront: true
        }],
    [camera_mode_1.CameraMode.Fever, {
            x: 0,
            y: 5,
            z: 11,
            xRotation: -12,
            fov: 60,
            inFront: true
        }],
]);
class GameCamera {
    constructor(screenModel) {
        this.overrideData = {
            x: 0,
            y: 9.5,
            z: 13.5,
            xRotation: -30,
            fov: 80,
            inFront: true
        };
        loglevel_1.default.debug('GameCamera: constructor');
        this.screenModel = screenModel;
        this._camera = new THREE.PerspectiveCamera(60, this.screenModel.width / this.screenModel.height, 1, 130);
        this.setCameraMode(camera_mode_1.CameraMode.Main);
    }
    get camera() {
        return this._camera;
    }
    onWindowResize() {
        loglevel_1.default.debug('GameCamera: onWindowResize');
        this._camera.aspect = this.screenModel.width / this.screenModel.height;
        this._camera.updateProjectionMatrix();
    }
    incrementZ(zInc) {
        this.overrideData.z += zInc;
        loglevel_1.default.info(`camera Z: ${this.overrideData.z}`);
        this.updateCamera(this.overrideData);
    }
    incrementY(yInc) {
        this.overrideData.y += yInc;
        loglevel_1.default.info(`camera Y: ${this.overrideData.y}`);
        this.updateCamera(this.overrideData);
    }
    incrementXRotation(xInc) {
        this.overrideData.xRotation += xInc;
        loglevel_1.default.info(`camera XRot: ${this.overrideData.xRotation}`);
        this.updateCamera(this.overrideData);
    }
    incrementFOV(fovInc) {
        this.overrideData.fov += fovInc;
        this.overrideData.fov = three_1.MathUtils.clamp(this.overrideData.fov, 5, 175);
        loglevel_1.default.info(`camera FOV: ${this.overrideData.fov}`);
        this.updateCamera(this.overrideData);
    }
    logParams() {
        loglevel_1.default.info(`camera Z: ${this.overrideData.z}  Y: ${this.overrideData.y}  XRot: ${this.overrideData.xRotation}  FOV: ${this.overrideData.fov}`);
    }
    setCameraMode(mode) {
        const cameraModeData = modeData.get(mode);
        if (!cameraModeData) {
            throw `GameCamera: setCameraMode invalid mode - ${mode}`;
        }
        this.updateCamera(cameraModeData);
        this.copyToOverride(cameraModeData);
    }
    updateCamera(cameraModeData) {
        let yRot = 0;
        if (!cameraModeData.inFront) {
            yRot = 180;
        }
        this._camera.position.set(cameraModeData.x, cameraModeData.y, cameraModeData.z);
        this._camera.setRotationFromEuler(new THREE.Euler(cameraModeData.xRotation * THREE.MathUtils.DEG2RAD, yRot * THREE.MathUtils.DEG2RAD, 0));
        this._camera.fov = cameraModeData.fov;
        this._camera.updateProjectionMatrix();
    }
    copyToOverride(cameraModeData) {
        this.overrideData.x = cameraModeData.x;
        this.overrideData.y = cameraModeData.y;
        this.overrideData.z = cameraModeData.z;
        this.overrideData.xRotation = cameraModeData.xRotation;
        this.overrideData.fov = cameraModeData.fov;
        this.overrideData.inFront = cameraModeData.inFront;
    }
}
exports.default = GameCamera;
