"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const road_data_1 = require("../road_data");
// Snakes level in TikTok - Disco Loco 3d - Level Design
const Level04 = {
    length: 168,
    ratings: [120000, 240000, 360000, 480000, 600000, 720000],
    roadData: [
        { type: road_data_1.RoadItemType.PickupPurple, line: 8, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 8, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 8, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 10, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 10, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 12, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 14, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 14, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 16, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 16, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 16, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 18, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 18, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 20, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 20, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 20, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 22, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 22, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 24, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 24, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 24, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 26, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 26, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 28, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 28, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 28, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 30, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 30, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 32, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 32, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 32, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 38, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 38, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 38, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 38, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 38, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 39, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 39, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 39, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 40, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 40, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 40, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 40, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 40, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 41, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 41, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 41, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 42, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 42, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 42, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 42, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 42, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 47, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 47, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 49, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 49, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 49, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 51, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 51, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 53, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 53, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 53, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 55, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 55, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 57, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 57, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 57, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 59, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 59, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 59, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 59, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 61, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 61, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 61, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 63, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 63, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 65, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 67, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 67, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 69, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 69, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 69, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 71, lane: 1 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 71, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 77, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 77, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 77, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 77, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 77, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 78, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 78, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 78, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 79, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 79, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 79, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 79, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 79, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 80, lane: 0 },
        { type: road_data_1.RoadItemType.PickupRed, line: 80, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 80, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 81, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 81, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 81, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 81, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 81, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 86, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 86, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 86, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 88, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 88, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 90, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 90, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 90, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 92, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 92, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 94, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 94, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 94, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 96, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 96, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 98, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 98, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 98, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 100, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 100, lane: 3 },
        { type: road_data_1.RoadItemType.PickupRed, line: 102, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 102, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 102, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 104, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 104, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 106, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 106, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 106, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 108, lane: 1 },
        { type: road_data_1.RoadItemType.PickupRed, line: 108, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 110, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 110, lane: 2 },
        { type: road_data_1.RoadItemType.PickupRed, line: 110, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 116, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 116, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 116, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 116, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 116, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 117, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 117, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 117, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 118, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 118, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 118, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 118, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 118, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 119, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 119, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 119, lane: 4 },
        { type: road_data_1.RoadItemType.PickupRed, line: 120, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 120, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 120, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 120, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 120, lane: 4 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 125, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 125, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 127, lane: 2 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 129, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 129, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 131, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 131, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 131, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 133, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 133, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 133, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 133, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 135, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 135, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 135, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 137, lane: 0 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 137, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 137, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 137, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 139, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 139, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 139, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 141, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 141, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 143, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 143, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 143, lane: 3 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 145, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 145, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 147, lane: 2 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 149, lane: 1 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 149, lane: 3 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 155, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 155, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 155, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 155, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 155, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 156, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 156, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 156, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 157, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 157, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 157, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 157, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 157, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 158, lane: 0 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 158, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 158, lane: 4 },
        { type: road_data_1.RoadItemType.PickupGreen, line: 159, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 159, lane: 1 },
        { type: road_data_1.RoadItemType.PickupPurple, line: 159, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 159, lane: 3 },
        { type: road_data_1.RoadItemType.PickupBlue, line: 159, lane: 4 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 0 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 2 },
        { type: road_data_1.RoadItemType.PickupBlack, line: 167, lane: 4 },
    ]
};
exports.default = Level04;
