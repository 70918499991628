"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const log = require("loglevel");
const ftue_text_1 = require("./ftue_text");
const three_1 = require("three");
const song_rating_heart_1 = require("./song_rating_heart");
class SongView extends THREE.Group {
    constructor(contentModel, screenModel) {
        super();
        this.songViewHeight = 250;
        this.iconSprite = null;
        this.iconMaterial = null;
        this.recordMaterial = null;
        this.recordAngle = 0;
        this.songTitle = null;
        this.songGenre = null;
        this.hearts = [];
        log.debug("SongView: constructor");
        this.contentModel = contentModel;
        this.screenModel = screenModel;
        this.recordGroup = this.createRecordGroup();
        this.recordGroup.position.setX(30);
        this.add(this.recordGroup);
        this.textGroup = this.createTextGroup();
        this.textGroup.position.setX(5);
        this.textGroup.position.setY(-40);
        this.add(this.textGroup);
        this.heartGroup = this.createStarGroup();
        this.heartGroup.position.setX(15);
        this.heartGroup.scale.set(0.5, 0.5, 1);
        this.heartGroup.position.setY(-85);
        this.add(this.heartGroup);
    }
    reset() {
    }
    setSong(songKey) {
        if (songKey.length == 0) {
            return;
        }
        const songData = this.contentModel.getSong(songKey);
        const songName = songData.key.substr(3).toUpperCase();
        this.songTitle?.setText(`${songName} -`);
        this.songGenre?.setText(songData.genre);
        let color = 0xffffff;
        switch (songData.genre) {
            case "SALSA":
                color = 0xc62a07;
                break;
            case "HIP HOP":
                color = 0x10a56a;
                break;
            case "JAZZ":
                color = 0x1db8ef;
                break;
            case "DISCO":
                color = 0xa54bd3;
                break;
        }
        for (let starIndex = 0; starIndex < this.hearts.length; starIndex++) {
            this.hearts[starIndex].reset();
            if (starIndex < songData.rating) {
                this.hearts[starIndex].show(new THREE.Color(color));
            }
        }
        if (this.iconMaterial) {
            const texture = this.contentModel.getTexture(songData.emoji);
            this.iconMaterial.map = texture;
        }
    }
    createRecordGroup() {
        const group = new THREE.Group();
        const recordSprite = this.createSprite('record', 0.8);
        this.recordMaterial = recordSprite.material;
        group.add(recordSprite);
        this.iconSprite = this.createSprite('icon_song_00', 0.4);
        this.iconMaterial = this.iconSprite.material;
        group.add(this.iconSprite);
        return group;
    }
    createTextGroup() {
        const group = new THREE.Group();
        this.songTitle = new ftue_text_1.default(this.contentModel, 0xffffff, 0x444444);
        this.songTitle.horizontal = ftue_text_1.TextJustification.Left;
        this.songTitle.shadowOffset = 2;
        this.songTitle.fontSize = 12;
        this.songTitle.setText("QUESO FUNDIDO ");
        group.add(this.songTitle);
        this.songGenre = new ftue_text_1.default(this.contentModel, 0xffffff, 0x444444);
        this.songGenre.horizontal = ftue_text_1.TextJustification.Left;
        this.songGenre.shadowOffset = 2;
        this.songGenre.fontSize = 12;
        this.songGenre.setText("DISCO");
        this.songGenre.position.setY(-20);
        group.add(this.songGenre);
        return group;
    }
    createStarGroup() {
        const group = new THREE.Group();
        for (let starIndex = 0; starIndex < 5; starIndex++) {
            const star = new song_rating_heart_1.default(this.contentModel);
            star.position.setX(starIndex * 40);
            this.hearts.push(star);
            group.add(star);
        }
        return group;
    }
    createSprite(key, scale) {
        const texture = this.contentModel.getTexture(key);
        const material = new THREE.SpriteMaterial({ map: texture });
        const width = material.map.image.width;
        const height = material.map.image.height;
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, 0.5);
        sprite.scale.set(width * scale, height * scale, 1);
        return sprite;
    }
    update(dt) {
        this.recordAngle += dt * (SongView.RECORD_ROTATION_SPEED * three_1.MathUtils.DEG2RAD);
        if (this.recordMaterial && this.iconMaterial) {
            this.recordMaterial.rotation = this.recordAngle;
            this.iconMaterial.rotation = this.recordAngle;
        }
    }
}
exports.default = SongView;
SongView.RECORD_ROTATION_SPEED = -45;
