"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const TWEEN = require("@tweenjs/tween.js");
const SkeletonUtils_js_1 = require("three/examples/jsm/utils/SkeletonUtils.js");
const dance_mixer_1 = require("./dance_mixer");
const lighting_mode_1 = require("../controller/lighting_mode");
var InfluencerMode;
(function (InfluencerMode) {
    InfluencerMode[InfluencerMode["MainMenu"] = 0] = "MainMenu";
    InfluencerMode[InfluencerMode["Dance"] = 1] = "Dance";
    InfluencerMode[InfluencerMode["GameOver"] = 2] = "GameOver";
    InfluencerMode[InfluencerMode["Fail"] = 3] = "Fail";
    InfluencerMode[InfluencerMode["Fever"] = 4] = "Fever";
})(InfluencerMode || (InfluencerMode = {}));
const colorWhite = new THREE.Color(0xffffff);
const colorRed = new THREE.Color(0xc41d26);
const colorGreen = new THREE.Color(0x078c59);
const colorBlue = new THREE.Color(0x1295b3);
const colorPurple = new THREE.Color(0xa126ff);
const colorAmbient = new THREE.Color(0xbdeffe);
const colorBacklight = new THREE.Color(0xc049fe);
const colorFrontlight2 = new THREE.Color(0x3163ee);
const colorFrontlight2Game = new THREE.Color(0x3163ee);
const colorFrontlight1Main = new THREE.Color(0xbdeffe);
const colorFrontlight1Fever = new THREE.Color(0xffffff);
const colorFrontlight1Game = new THREE.Color(0xbdeffe);
const colorFrontlight2Main = new THREE.Color(0x3163ee);
class InfluencerView extends THREE.Scene {
    constructor(contentModel, playerModel, moveModel, gl) {
        super();
        this.contentModel = contentModel;
        this.playerModel = playerModel;
        this.moveModel = moveModel;
        this.gl = gl;
        this.clock = new THREE.Clock();
        this.influencerMode = InfluencerMode.MainMenu;
        this.animsLoaded = false;
        this.leadingIn = false;
        this.enterZ = 0;
        this.opacity = 0;
        this.FADEIN_DURATION = 2000;
        this.LEADIN_DURATION = 1000;
        this.LEADIN_DISTANCE = 20;
        const gltf = this.contentModel.getMesh('discogal');
        this.character = this.add(SkeletonUtils_js_1.SkeletonUtils.clone(gltf.scene));
        let geo = this.character.getObjectByName("geo_discogal1");
        if (geo) {
            let mat = geo.material;
            this.material = mat.clone();
            const skinName = this.playerModel.getSkinName();
            let texture = this.contentModel.getTexture(`gal-${skinName}-color`);
            if (texture) {
                if (texture.encoding != THREE.sRGBEncoding) {
                    texture.encoding = THREE.sRGBEncoding;
                    texture.needsUpdate = true;
                }
                texture.flipY = false;
                this.material.map = texture;
            }
            let roughnessTexture = this.contentModel.getTexture(`gal-${skinName}-roughness`);
            if (roughnessTexture) {
                roughnessTexture.flipY = false;
                this.material.roughnessMap = roughnessTexture;
            }
            let metalnessTexture = this.contentModel.getTexture(`gal-${skinName}-metalness`);
            if (metalnessTexture) {
                metalnessTexture.flipY = false;
                this.material.metalnessMap = metalnessTexture;
            }
            let pmremGenerator = new THREE.PMREMGenerator(gl);
            pmremGenerator.compileEquirectangularShader();
            const hdr = pmremGenerator.fromEquirectangular(contentModel.getDataTexture('dancer-hdr'));
            if (hdr) {
                this.material.envMap = hdr.texture;
                this.material.envMapIntensity = 1;
            }
            this.material.roughness = 1;
            this.material.metalness = 1;
            geo.material = this.material;
        }
        const s = 4.8;
        this.character.scale.set(s, s, s);
        this.character.quaternion.setFromEuler(new THREE.Euler(0, THREE.MathUtils.degToRad(0), 0));
        //        this.character.translateY(-0.44);
        /*
        const debugCircle = new Mesh(new CircleGeometry());
        debugCircle.scale.set(1/s, 1/s, 1/s);
        debugCircle.translateY(0.1);
        debugCircle.rotateX(MathUtils.degToRad(-90));
        this.character.add(debugCircle);
        */
        this.danceMixer = new dance_mixer_1.default(this.character, this.contentModel);
        this.moveModel.setPlayerDanceMixer(this.danceMixer);
        this.danceMixer.addAnims();
        this.danceMixer.playNow("waitIdle");
        // const spotlightTarget = new THREE.Object3D();
        // spotlightTarget.position.set(0, 4, 0);
        // this.add(spotlightTarget);
        // this.spotlight = new THREE.SpotLight(0xff00ff, 8, 20, -4);
        // this.spotlight.position.set(1, 15, -4);
        // this.spotlight.target = spotlightTarget;
        // this.spotlight.updateMatrix();
        // const st = 3.5;
        // this.spotlightAnim = new THREE.AnimationMixer(this.spotlight);
        // this.spotlightAnim.clipAction(new THREE.AnimationClip(undefined, undefined, [
        //     new THREE.ColorKeyframeTrack('.color', [0, st, 2 * st, 3 * st], [
        //         1, 0, 1,
        //         1, 1, 0,
        //         0, 1, 1,
        //         0, 0, 1,
        //     ], THREE.InterpolateDiscrete),
        // ])).play();
        // // this.add(new SpotLightHelper(this.spotlight));
        // this.add(this.spotlight);
        //const light = new THREE.HemisphereLight(0xffffff, 0x333333, 0.75);
        //this.add(light);
        this.ambientLight = new THREE.AmbientLight(colorAmbient, 0.45); //.45
        this.add(this.ambientLight);
        this.frontLight1 = new THREE.DirectionalLight(colorFrontlight1Main, 0.7); //.55
        this.frontLight1.position.set(3, 4, -3);
        this.frontLight1.target.position.set(5, 0, -28);
        this.add(this.frontLight1);
        this.add(this.frontLight1.target);
        this.frontLight2 = new THREE.DirectionalLight(colorFrontlight2, 5.0); //5
        this.frontLight2.position.set(3, 4, 3);
        this.frontLight2.target.position.set(95, -50, 18);
        this.add(this.frontLight2);
        this.add(this.frontLight2.target);
        this.backLight1 = new THREE.DirectionalLight(colorBacklight, 1.75); //1.5
        this.backLight1.position.set(-3, 2, 3);
        this.backLight1.target.position.set(-3, 4, 50);
        this.add(this.backLight1);
        this.add(this.backLight1.target);
        this.backLight2 = new THREE.DirectionalLight(colorBacklight, 1);
        this.backLight2.position.set(3, 2, 3);
        this.backLight2.target.position.set(0, 4, 0);
        this.add(this.backLight2);
        this.add(this.backLight2.target);
        this.backLight2.visible = false;
        this.onBeforeRender = this.preRender.bind(this);
    }
    loadAnims() {
        this.danceMixer.addAnims();
    }
    fallDown() {
    }
    faceForward() {
        this.danceMixer.playIdle();
    }
    faceCamera() {
    }
    mainMenuMode() {
        this.influencerMode = InfluencerMode.MainMenu;
        this.position.setX(0.75);
        this.position.setY(3);
        this.position.setZ(10.5);
        this.rotation.set(0, 0, 0);
        this.rotateOnAxis(new THREE.Vector3(1, 0, 0), -30 * THREE.MathUtils.DEG2RAD);
        this.rotateOnAxis(new THREE.Vector3(0, 1, 0), -30 * THREE.MathUtils.DEG2RAD);
        this.danceMixer.setIdle("idle");
        this.danceMixer.playNow("idlebounce");
    }
    danceMode() {
        this.influencerMode = InfluencerMode.Dance;
        this.position.setX(0);
        this.position.setY(0);
        this.position.setZ(0);
        this.rotation.set(0, 0, 0);
        this.danceMixer.play("waitIdle");
    }
    gameOverMode() {
        this.influencerMode = InfluencerMode.GameOver;
        this.position.setX(0);
        this.rotation.set(0, 0, 0);
        this.danceMixer.play("waitIdle");
    }
    failMode() {
        this.influencerMode = InfluencerMode.Fail;
    }
    feverLeadIn() {
        this.influencerMode = InfluencerMode.Fever;
        this.opacity = 0.0;
        this.danceMixer.playIdle();
        this.leadingIn = true;
        this.position.setX(0.15);
        this.position.setY(0.25);
        this.position.setZ(2.25);
        if (this.material) {
            this.material.transparent = true;
        }
        new TWEEN.Tween(this)
            .to({ opacity: 1.0 }, this.FADEIN_DURATION)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .start();
        const targetZ = this.position.z;
        this.position.z -= this.LEADIN_DISTANCE;
        this.enterZ = this.position.z;
        new TWEEN.Tween(this)
            .to({ enterZ: targetZ }, this.LEADIN_DURATION)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start()
            .onComplete(() => {
            this.leadingIn = false;
            this.material.transparent = false;
            this.danceMixer.setIdle("waitIdle");
            this.danceMixer.play("waitIdle");
            new TWEEN.Tween(this.rotation)
                .to({ x: -0.25, y: -0.5 }, 500)
                .easing(TWEEN.Easing.Quadratic.Out)
                .start();
        });
    }
    moveLightsToFront() {
        this.frontLight1.position.set(3, 4, 3);
        this.backLight1.position.set(-3, 2, -3);
        this.backLight2.position.set(3, 2, -3);
    }
    moveLightsToRear() {
        this.frontLight1.position.set(3, 4, -3);
        this.backLight1.position.set(-3, 2, 3);
        this.backLight2.position.set(3, 2, 3);
    }
    updateSkin() {
        let geo = this.character.getObjectByName("geo_discogal1");
        if (geo) {
            let mat = geo.material;
            const skinName = this.playerModel.getSkinName();
            let texture = this.contentModel.getTexture(`gal-${skinName}-color`);
            if (texture) {
                texture.flipY = false;
                if (texture.encoding != THREE.sRGBEncoding) {
                    texture.encoding = THREE.sRGBEncoding;
                    texture.needsUpdate = true;
                }
                mat.map = texture;
            }
            let roughnessTexture = this.contentModel.getTexture(`gal-${skinName}-roughness`);
            if (roughnessTexture) {
                roughnessTexture.flipY = false;
                mat.roughnessMap = roughnessTexture;
            }
            let metalnessTexture = this.contentModel.getTexture(`gal-${skinName}-metalness`);
            if (metalnessTexture) {
                metalnessTexture.flipY = false;
                mat.metalnessMap = metalnessTexture;
            }
        }
    }
    setLightingMode(mode) {
        switch (mode) {
            case lighting_mode_1.LightingMode.Main:
                this.setLightsForMain();
                break;
            case lighting_mode_1.LightingMode.Game:
                this.setLightsForGame();
                break;
            case lighting_mode_1.LightingMode.Fail:
                this.setLightsForFail();
                break;
            case lighting_mode_1.LightingMode.GameOver:
                this.setLightsForGameOver();
                break;
            case lighting_mode_1.LightingMode.FeverRed:
                this.setLightsForFever(colorRed);
                break;
            case lighting_mode_1.LightingMode.FeverGreen:
                this.setLightsForFever(colorGreen);
                break;
            case lighting_mode_1.LightingMode.FeverBlue:
                this.setLightsForFever(colorBlue);
                break;
            case lighting_mode_1.LightingMode.FeverPurple:
                this.setLightsForFever(colorPurple);
                break;
        }
    }
    setLightsForMain() {
        this.ambientLight.visible = true;
        this.ambientLight.intensity = 0.45;
        this.ambientLight.color = colorAmbient;
        this.frontLight1.visible = true;
        this.frontLight1.color = colorFrontlight1Main;
        this.frontLight1.position.set(3, 4, -3);
        this.frontLight1.target.position.set(5, 0, -28);
        this.frontLight1.intensity = 0.7;
        this.frontLight2.visible = true;
        this.frontLight2.color = colorFrontlight2Main;
        this.frontLight2.position.set(3, 4, 3);
        this.frontLight2.target.position.set(95, -50, 18);
        this.frontLight2.intensity = 5;
        this.backLight1.visible = true;
        this.backLight1.color = colorBacklight;
        this.backLight1.position.set(-3, 2, 3);
        this.backLight1.target.position.set(-3, 4, 50);
        this.backLight2.visible = false;
    }
    setLightsForGame() {
        this.ambientLight.visible = true;
        this.ambientLight.intensity = 0.45;
        this.ambientLight.color = colorAmbient;
        this.frontLight1.visible = true;
        this.frontLight1.color = colorFrontlight1Game;
        this.frontLight1.position.set(3, 4, -3);
        this.frontLight1.target.position.set(-15, 2, 10);
        this.frontLight1.intensity = 1;
        this.frontLight2.visible = true;
        this.frontLight2.color = colorFrontlight2Game;
        this.frontLight2.position.set(3, 4, -3);
        this.frontLight2.target.position.set(15, -50, 40);
        this.frontLight2.intensity = 0.75;
        this.backLight1.visible = true;
        this.backLight1.color = colorBacklight;
        this.backLight1.position.set(-3, 2, 3);
        this.backLight1.target.position.set(0, 4, -2);
        this.backLight1.intensity = 5;
        this.backLight2.visible = true;
        this.backLight2.color = colorBacklight;
        this.backLight2.position.set(3, 1, -3);
        this.backLight2.target.position.set(-80, 20, -80);
        this.backLight2.intensity = 3;
    }
    setLightsForGameOver() {
        this.ambientLight.visible = true;
        this.ambientLight.intensity = 0.45;
        this.ambientLight.color = colorAmbient;
        this.frontLight1.visible = true;
        this.frontLight1.color = colorFrontlight1Game;
        this.frontLight1.position.set(3, 4, 3);
        this.frontLight1.target.position.set(0, 2, 0);
        this.frontLight1.intensity = 1.5;
        this.backLight1.visible = true;
        this.backLight1.intensity = 0.5;
        this.backLight1.color = colorWhite;
        this.backLight1.position.set(-3, 2, -3);
        this.backLight1.target.position.set(0, 4, 0);
        this.frontLight2.visible = false;
        this.backLight2.visible = false;
    }
    setLightsForFail() {
        this.ambientLight.visible = true;
        this.ambientLight.intensity = 0.2;
        this.ambientLight.color = colorWhite;
        this.frontLight1.visible = true;
        this.frontLight1.color = colorFrontlight1Game;
        this.frontLight1.position.set(3, 4, -3);
        this.frontLight1.target.position.set(0, 2, 0);
        this.frontLight1.intensity = 1.5;
        this.backLight1.visible = true;
        this.backLight1.intensity = 0.5;
        this.backLight1.color = colorWhite;
        this.backLight1.position.set(-3, 2, 3);
        this.backLight1.target.position.set(0, 4, 0);
        this.frontLight2.visible = false;
        this.backLight2.visible = false;
    }
    setLightsForFever(color) {
        this.frontLight1.visible = true;
        this.frontLight1.color = colorFrontlight1Fever;
        this.frontLight1.position.set(-6, 10, 0);
        this.frontLight1.target.position.set(5, 4, 2);
        this.frontLight1.intensity = 1; //2
        //this.frontLight2.visible = false;
        this.ambientLight.visible = true;
        this.ambientLight.intensity = 0.3825;
        this.ambientLight.color = colorWhite;
        this.backLight1.visible = true;
        this.backLight1.color = color;
        this.backLight1.intensity = .6; //.35
        this.backLight1.position.set(-3, 4, -3);
        this.backLight1.target.position.set(0, 3, 0);
        this.backLight2.visible = true;
        this.backLight2.color = color;
        this.backLight2.intensity = .6;
        this.backLight2.position.set(2, 10, 0);
        this.backLight2.target.position.set(3, 10, 0);
    }
    preRender(gl, scene, camera, renderTarget) {
        const dt = this.clock.getDelta();
        this.danceMixer.update(dt);
        if (this.influencerMode == InfluencerMode.Dance) {
            this.character.position.copy(this.playerModel.position);
        }
        if (this.leadingIn && this.material) {
            this.material.opacity = this.opacity;
            this.position.setZ(this.enterZ);
        }
        //this.spotlightAnim.update(dt);
    }
}
exports.default = InfluencerView;
