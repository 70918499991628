"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const loglevel_1 = require("loglevel");
const GLTFLoader_js_1 = require("three/examples/jsm/loaders/GLTFLoader.js");
class MeshLoader {
    constructor() {
        this.meshes = new Map();
        this._completion = 0;
        this.numAssets = 0;
        this.assetsLoaded = 0;
        this.loader = new GLTFLoader_js_1.GLTFLoader();
        this.clock = new THREE.Clock();
    }
    get isCompleted() {
        return (this._completion == 1);
    }
    get completion() {
        return this._completion;
    }
    getKeys() {
        let keys = [...this.meshes.keys()];
        keys.sort();
        return keys;
    }
    getMesh(key) {
        let mesh = this.meshes.get(key);
        if (!mesh) {
            loglevel_1.default.error(`MeshLoader: getMesh - key not found ${key}`);
            throw `MeshLoader: getMesh - key not found ${key}`;
        }
        else {
            return mesh;
        }
    }
    async load(contentData) {
        loglevel_1.default.info('MeshLoader: load - loading meshes');
        this._completion = 0;
        this.clock.start();
        if (contentData.meshes) {
            this._completion = 0;
            this.numAssets = contentData.meshes.length;
            this.assetsLoaded = 0;
            for (let item of contentData.meshes) {
                if (this.isDataItemValid(item)) {
                    this.loadMesh(item.key, item.fileName);
                }
            }
        }
        else {
            this._completion = 1;
            this.clock.stop();
            loglevel_1.default.info(`MeshLoader: load - meshes loaded - ${this.clock.getElapsedTime().toFixed(3)}s`);
        }
    }
    async loadMesh(key, fileName) {
        try {
            let mesh = await this.loader.loadAsync(fileName);
            this.meshes.set(key, mesh);
            this.updateCompletion();
        }
        catch (error) {
            loglevel_1.default.error(`MeshLoader: loadMesh - unable to load mesh: ${key} - ${fileName}`, error);
        }
    }
    updateCompletion() {
        this.assetsLoaded++;
        this._completion = this.assetsLoaded / this.numAssets;
        if (this._completion == 1) {
            this.clock.stop();
            loglevel_1.default.info(`MeshLoader: meshes loaded - ${this.numAssets} assets - ${this.clock.getElapsedTime().toFixed(3)}s`);
        }
    }
    isDataItemValid(item) {
        if (!item.key || !item.fileName) {
            loglevel_1.default.warn(`MeshLoader: invalid <mesh> - key: ${item.key}  -  fileName: ${item.fileName}`);
            return false;
        }
        return true;
    }
}
exports.default = MeshLoader;
