"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const TWEEN = require("@tweenjs/tween.js");
const ftue_text_1 = require("./ftue_text");
class FeverBanner extends THREE.Group {
    constructor(contentModel) {
        super();
        this.bannerWidth = 375;
        this.bannerHeight = 100;
        this.tweenDuration = 1000;
        this.bannerRotation = 12 * THREE.MathUtils.DEG2RAD;
        this.backgroundRotation = 8 * THREE.MathUtils.DEG2RAD;
        this.contentModel = contentModel;
        this.bannerSprite = this.createBannerSprite();
        this.add(this.bannerSprite);
        this.backgroundText = new ftue_text_1.default(this.contentModel, 0xffffff, 0x5c65f2);
        this.backgroundText.setRotationFromEuler(new THREE.Euler(0, 0, this.backgroundRotation));
        this.backgroundText.fontSize = 40;
        this.backgroundText.hasDropShadow = false;
        this.add(this.backgroundText);
        this.bannerText = new ftue_text_1.default(this.contentModel, 0xffffff, 0x5c65f2);
        this.bannerText.fontSize = 34;
        //this.bannerText.setText( bannerString );
        this.add(this.bannerText);
        this.setRotationFromEuler(new THREE.Euler(0, 0, this.bannerRotation));
    }
    createBannerSprite() {
        const texture = this.contentModel.getTexture("small-white");
        const material = new THREE.SpriteMaterial({
            map: texture,
            color: 0x786fe4,
            opacity: 0,
            rotation: this.bannerRotation
        });
        let sprite = new THREE.Sprite(material);
        sprite.center.set(0.5, 0.5);
        sprite.scale.set(this.bannerWidth * 1.5, this.bannerHeight, 1);
        return sprite;
    }
    updateScale(screenModel) {
        const widthScale = screenModel.calculateWidthScale(1.0, this.bannerWidth);
        const heightScale = screenModel.calculateHeightScale(0.15, this.bannerHeight);
        this.scale.setX(widthScale);
        this.scale.setY(heightScale);
    }
    display(text) {
        let material = this.bannerSprite.material;
        material.opacity = 0;
        new TWEEN.Tween(material)
            .to({ opacity: 1 }, this.tweenDuration)
            .easing(TWEEN.Easing.Cubic.Out)
            .repeat(1)
            .repeatDelay(this.tweenDuration)
            .yoyo(true)
            .start();
        this.backgroundText.setText(text);
        const backMaterial = this.backgroundText.material;
        backMaterial.opacity = 0.0;
        new TWEEN.Tween(backMaterial)
            .to({ opacity: 0.2 }, this.tweenDuration)
            .easing(TWEEN.Easing.Cubic.Out)
            .repeat(1)
            .repeatDelay(this.tweenDuration)
            .yoyo(true)
            .start();
        this.bannerText.setText(text);
        this.bannerText.position.setX(-this.bannerWidth);
        const moveIn = new TWEEN.Tween(this.bannerText.position)
            .to({ x: 0 }, this.tweenDuration)
            .delay(this.tweenDuration / 2)
            .easing(TWEEN.Easing.Cubic.Out);
        const moveOut = new TWEEN.Tween(this.bannerText.position)
            .to({ x: this.bannerWidth * 2 }, this.tweenDuration)
            .easing(TWEEN.Easing.Cubic.In);
        moveIn.chain(moveOut);
        moveIn.start();
    }
    getDisplayDuration() {
        return this.tweenDuration * 2;
    }
}
exports.default = FeverBanner;
