"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoveType = void 0;
const swipe_model_1 = require("./swipe_model");
var MoveType;
(function (MoveType) {
    MoveType[MoveType["Red"] = 0] = "Red";
    MoveType[MoveType["Green"] = 1] = "Green";
    MoveType[MoveType["Blue"] = 2] = "Blue";
    MoveType[MoveType["Purple"] = 3] = "Purple";
    MoveType[MoveType["None"] = 4] = "None";
})(MoveType = exports.MoveType || (exports.MoveType = {}));
let MoveData = [
    { type: MoveType.Red, name: "redUp", direction: swipe_model_1.SwipeDirection.Up, score: 200, commonName: "KISS", iconKey: "emoji-kiss" },
    { type: MoveType.Red, name: "redUpRight", direction: swipe_model_1.SwipeDirection.UpRight, score: 400, commonName: "ROSE", iconKey: "emoji-rose" },
    { type: MoveType.Red, name: "redRight", direction: swipe_model_1.SwipeDirection.Right, score: 100, commonName: "LOVE", iconKey: "emoji-love" },
    { type: MoveType.Red, name: "redDownRight", direction: swipe_model_1.SwipeDirection.DownRight, score: 400, commonName: "SALSA", iconKey: "emoji-salsa" },
    { type: MoveType.Red, name: "redDown", direction: swipe_model_1.SwipeDirection.Down, score: 200, commonName: "CHERRIES", iconKey: "emoji-cherries" },
    { type: MoveType.Red, name: "redDownLeft", direction: swipe_model_1.SwipeDirection.DownLeft, score: 400, commonName: "CHILI", iconKey: "emoji-chili" },
    { type: MoveType.Red, name: "redLeft", direction: swipe_model_1.SwipeDirection.Left, score: 100, commonName: "HEELS", iconKey: "emoji-heels" },
    { type: MoveType.Red, name: "redUpLeft", direction: swipe_model_1.SwipeDirection.UpLeft, score: 400, commonName: "KITTY", iconKey: "emoji-kitty" },
    { type: MoveType.Green, name: "greenUp", direction: swipe_model_1.SwipeDirection.Up, score: 200, commonName: "TENNIS", iconKey: "emoji-tennis" },
    { type: MoveType.Green, name: "greenUpRight", direction: swipe_model_1.SwipeDirection.UpRight, score: 400, commonName: "LEAF", iconKey: "emoji-leaf" },
    { type: MoveType.Green, name: "greenRight", direction: swipe_model_1.SwipeDirection.Right, score: 100, commonName: "ENVY", iconKey: "emoji-envy" },
    { type: MoveType.Green, name: "greenDownRight", direction: swipe_model_1.SwipeDirection.DownRight, score: 400, commonName: "HIP HOP", iconKey: "emoji-hiphop" },
    { type: MoveType.Green, name: "greenDown", direction: swipe_model_1.SwipeDirection.Down, score: 200, commonName: "KIWI", iconKey: "emoji-kiwi" },
    { type: MoveType.Green, name: "greenDownLeft", direction: swipe_model_1.SwipeDirection.DownLeft, score: 400, commonName: "DRAGON", iconKey: "emoji-dragon" },
    { type: MoveType.Green, name: "greenLeft", direction: swipe_model_1.SwipeDirection.Left, score: 100, commonName: "LUCKY", iconKey: "emoji-lucky" },
    { type: MoveType.Green, name: "greenUpLeft", direction: swipe_model_1.SwipeDirection.UpLeft, score: 400, commonName: "BEETLE", iconKey: "emoji-beetle" },
    { type: MoveType.Blue, name: "blueUp", direction: swipe_model_1.SwipeDirection.Up, score: 200, commonName: "DIAMOND", iconKey: "emoji-diamond" },
    { type: MoveType.Blue, name: "blueUpRight", direction: swipe_model_1.SwipeDirection.UpRight, score: 400, commonName: "JEANS", iconKey: "emoji-jeans" },
    { type: MoveType.Blue, name: "blueRight", direction: swipe_model_1.SwipeDirection.Right, score: 100, commonName: "JOY", iconKey: "emoji-joy" },
    { type: MoveType.Blue, name: "blueDownRight", direction: swipe_model_1.SwipeDirection.DownRight, score: 400, commonName: "JAZZ", iconKey: "emoji-jazz" },
    { type: MoveType.Blue, name: "blueDown", direction: swipe_model_1.SwipeDirection.Down, score: 200, commonName: "SWEAT", iconKey: "emoji-sweat" },
    { type: MoveType.Blue, name: "blueDownLeft", direction: swipe_model_1.SwipeDirection.DownLeft, score: 400, commonName: "FISH", iconKey: "emoji-fish" },
    { type: MoveType.Blue, name: "blueLeft", direction: swipe_model_1.SwipeDirection.Left, score: 100, commonName: "CAP", iconKey: "emoji-cap" },
    { type: MoveType.Blue, name: "blueUpLeft", direction: swipe_model_1.SwipeDirection.UpLeft, score: 400, commonName: "FACEPALM", iconKey: "emoji-facepalm" },
    { type: MoveType.Purple, name: "purpleUp", direction: swipe_model_1.SwipeDirection.Up, score: 200, commonName: "MONSTER", iconKey: "emoji-monster" },
    { type: MoveType.Purple, name: "purpleUpRight", direction: swipe_model_1.SwipeDirection.UpRight, score: 400, commonName: "GRAPES", iconKey: "emoji-grapes" },
    { type: MoveType.Purple, name: "purpleRight", direction: swipe_model_1.SwipeDirection.Right, score: 100, commonName: "PASSION", iconKey: "emoji-passion" },
    { type: MoveType.Purple, name: "purpleDownRight", direction: swipe_model_1.SwipeDirection.DownRight, score: 400, commonName: "DISCO", iconKey: "emoji-disco" },
    { type: MoveType.Purple, name: "purpleDown", direction: swipe_model_1.SwipeDirection.Down, score: 200, commonName: "RAIN", iconKey: "emoji-rain" },
    { type: MoveType.Purple, name: "purpleDownLeft", direction: swipe_model_1.SwipeDirection.DownLeft, score: 400, commonName: "UNICORN", iconKey: "emoji-unicorn" },
    { type: MoveType.Purple, name: "purpleLeft", direction: swipe_model_1.SwipeDirection.Left, score: 100, commonName: "CRYSTAL", iconKey: "emoji-crystal" },
    { type: MoveType.Purple, name: "purpleUpLeft", direction: swipe_model_1.SwipeDirection.UpLeft, score: 400, commonName: "OCTOPUS", iconKey: "emoji-octopus" },
];
exports.default = MoveData;
