"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayModeBase = void 0;
class PlayModeBase {
    constructor(playController) {
        this.playController = playController;
        this.views = this.playController.views;
    }
    onScreenResize() {
    }
    render(gl) {
    }
}
exports.PlayModeBase = PlayModeBase;
