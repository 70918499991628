"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = require("three");
const three_1 = require("three");
const lighting_mode_1 = require("../controller/lighting_mode");
const gem_model_1 = require("../model/gem_model");
const random_1 = require("../model/random");
const gem_pool_1 = require("./gem_pool");
const obstacle_pool_1 = require("./obstacle_pool");
function flip(a0, a1, a2, a3, a4) {
    return [
        a4,
        a3,
        a2,
        a1,
        a0,
    ];
}
function pattern(input) {
    return input.split(' ')
        .map(chr => {
        return random_1.Random.next(16);
        switch (chr) {
            case '0': return 9;
            case '1': return 10;
            case 'B': return 4;
            case 'b': return 0;
            case 'C': return 7;
            case 'c': return 3;
            case 'G': return 5;
            case 'g': return 1;
            case 'O': return 12;
            case 'o': return 8;
            case 'P': return 15;
            case 'p': return 11;
            case 'R': return 13;
            case 'r': return 14;
            case 'Y': return 6;
            case 'y': return 2;
            default: throw new Error();
        }
    });
}
class DiscoRoadView extends THREE.Scene {
    constructor(gl, contentModel, levelModel) {
        super();
        this.gl = gl;
        this.contentModel = contentModel;
        this.levelModel = levelModel;
        this.patternIndex = 0;
        this.gemAngle = 0;
        this.clock = new THREE.Clock();
        this.roadObjects = [];
        this.gemAnim = new THREE.AnimationMixer(this);
        this.items = new THREE.Group();
        this.patternAnim = new THREE.AnimationMixer(this);
        //this.fog = new FogExp2(0x860581, 0.015);
        //this.fog = new THREE.Fog(0xa203ff, 20, 65);
        //this.fog = new Fog(0x9215da, 25, 55);
        this.gemAnim.clipAction(new THREE.AnimationClip(undefined, undefined, [
            new THREE.NumberKeyframeTrack('.gemAngle', [0, 3], [0, THREE.MathUtils.degToRad(360)]),
        ])).play();
        this.patternAnim.clipAction(new THREE.AnimationClip(undefined, undefined, [new THREE.NumberKeyframeTrack('.patternIndex', [
                0 * DiscoRoadView.PATTERN_TIME,
                1 * DiscoRoadView.PATTERN_TIME,
                2 * DiscoRoadView.PATTERN_TIME,
                3 * DiscoRoadView.PATTERN_TIME,
                4 * DiscoRoadView.PATTERN_TIME,
                5 * DiscoRoadView.PATTERN_TIME,
                6 * DiscoRoadView.PATTERN_TIME,
                7 * DiscoRoadView.PATTERN_TIME,
                8 * DiscoRoadView.PATTERN_TIME,
                9 * DiscoRoadView.PATTERN_TIME,
            ], [
                0,
                1,
                2,
                1,
                3,
                2,
                4,
                0,
                2,
                1,
            ], THREE.InterpolateDiscrete)])).play();
        const size2 = DiscoRoadView.PATTERN_SIZE * DiscoRoadView.PATTERN_SIZE;
        const roadGeometry = new THREE.BufferGeometry();
        roadGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(18 * DiscoRoadView.PATTERNS * size2), 3));
        roadGeometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(12 * DiscoRoadView.PATTERNS * size2), 2, true));
        let roadTexture = this.contentModel.getTexture('square');
        if (roadTexture) {
            roadTexture.flipY = false;
            roadTexture.needsUpdate = true;
        }
        this.road = new THREE.Mesh(roadGeometry, new THREE.MeshBasicMaterial({
            map: roadTexture,
            side: THREE.DoubleSide,
            color: 0xffffff,
        }));
        this.road.material.map.minFilter = THREE.NearestFilter;
        this.road.material.map.magFilter = THREE.NearestFilter;
        // this.road.scale.set(2, 1, 2);
        // this.road.position.setX(-2.5);
        this.add(this.road);
        const ambientLight = new THREE.AmbientLight(0x444444);
        this.add(ambientLight);
        this.directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        this.directionalLight.target = new THREE.Object3D();
        this.directionalLight.position.set(0, 10, -10);
        this.directionalLight.target.position.set(0, 2, 0);
        this.add(this.directionalLight);
        this.onBeforeRender = this.preRender.bind(this);
    }
    initializeObjectPools() {
        // initialize gem and obstacle pools
        obstacle_pool_1.default.Instance.initialize(this.contentModel);
        gem_pool_1.default.Instance.initialize(this.contentModel, this.gl);
    }
    createGemsAndObstacles() {
        // add gems and obstacle meshes from the current level
        for (const gemModel of this.levelModel.gems) {
            const gem = gem_pool_1.default.Instance.use(gemModel.gemType);
            if (gem) {
                gem.userData = gemModel;
                gem.position.set(gemModel.position.x, gemModel.position.y, gemModel.position.z);
                gem.visible = true;
                this.items.add(gem);
                this.roadObjects.push(gem);
            }
            else {
                throw ('DiscoRoadView: Unable to obtain gem mesh group');
            }
        }
        for (const gemModel of this.levelModel.obstacles) {
            const obstacle = obstacle_pool_1.default.Instance.use();
            if (obstacle) {
                obstacle.userData = gemModel;
                obstacle.position.set(gemModel.position.x, gemModel.position.y, gemModel.position.z);
                obstacle.visible = true;
                this.items.add(obstacle);
                this.roadObjects.push(obstacle);
            }
            else {
                throw ('DiscoRoadView: Unable to obtain obstacle mesh group');
            }
        }
        this.add(this.items);
    }
    mainMenuMode() {
        this.position.setZ(-80);
        this.fog = new THREE.Fog(0xbdeffe, 17, 55);
    }
    gameMode() {
        this.position.setZ(0);
        this.fog = new THREE.Fog(0xbdeffe, 15, 70);
    }
    reset() {
        for (const roadObject of this.roadObjects) {
            // remove the mesh from the group
            this.items.remove(roadObject);
            // recycle the mesh back into the pools
            const gemModel = roadObject.userData;
            if (gemModel.gemType == gem_model_1.GemType.Obstacle) {
                obstacle_pool_1.default.Instance.recycle(roadObject);
            }
            else {
                gem_pool_1.default.Instance.recycle(gemModel.gemType, roadObject);
            }
        }
        this.roadObjects = [];
    }
    sinkAllItems() {
        for (const roadObject of this.roadObjects) {
            const gemModel = roadObject.userData;
            if (gemModel.isReady) {
                gemModel.sink();
            }
        }
    }
    // used to help find good random patterns
    // public logPatterns() {
    //     for ( let i = 0; i < 3; i++ ) {
    //         log.info( `Pattern - ${i}` )
    //         for ( let y = 0; y < 5; y++ ) {
    //             let patString = "[";
    //             for ( let x = 0; x < 5; x++ ) {
    //                 patString += `${DiscoRoadView.PATTERN[i][y][x]}, `
    //             }
    //             patString += "],"
    //             log.info( patString );
    //         }
    //     }
    // }
    setLightingMode(mode) {
        switch (mode) {
            case lighting_mode_1.LightingMode.Main:
                this.directionalLight.position.set(0, 10, 10);
                break;
            case lighting_mode_1.LightingMode.Game:
                this.directionalLight.position.set(0, 10, -10);
                break;
            case lighting_mode_1.LightingMode.Fail:
                this.directionalLight.position.set(0, 10, 10);
                break;
        }
    }
    preRender(gl, scene, camera, renderTarget) {
        const dt = this.clock.getDelta();
        this.gemAnim.update(dt);
        this.patternAnim.update(dt);
        this.items.position.set(0, 0, -this.levelModel.roadPosition);
        const gemEuler = new THREE.Euler(DiscoRoadView.GEM_TILT, this.gemAngle, 0);
        const gemAnimatingEuler = new THREE.Euler(0, this.gemAngle * -5, 0);
        for (const roadObject of this.roadObjects) {
            const gemModel = roadObject.userData;
            if (gemModel.isReady) {
                const itemZ = gemModel.position.z - this.levelModel.roadPosition;
                if (itemZ < DiscoRoadView.GEM_SINK_Z) {
                    gemModel.sink();
                }
            }
            if (gemModel.isCollecting || gemModel.isSinking) {
                roadObject.position.setY(gemModel.position.y);
            }
            roadObject.visible = gemModel.isVisible;
            if (gemModel.isGem) {
                if (gemModel.isCollecting || gemModel.isSinking) {
                    roadObject.quaternion.setFromEuler(gemAnimatingEuler);
                }
                else {
                    roadObject.quaternion.setFromEuler(gemEuler);
                }
            }
        }
        const pattern = DiscoRoadView.PATTERN[this.patternIndex];
        const position = this.road.geometry.attributes.position;
        const texture = this.road.geometry.attributes.uv;
        const offsetX = -5; //DiscoRoadView.PATTERN_SIZE * -0.5;
        let i = 0;
        for (let p = 0; p < DiscoRoadView.PATTERNS; ++p) {
            const offsetZ = p * DiscoRoadView.PATTERN_SIZE * 2
                - ((this.levelModel.roadPosition % 10) + 10) % 10
                - DiscoRoadView.PATTERN_SIZE
                - 10;
            for (let z = 0; z < DiscoRoadView.PATTERN_SIZE; ++z) {
                const patternRow = pattern[z];
                for (let x = 0; x < DiscoRoadView.PATTERN_SIZE; ++x) {
                    const minX = x * 2 + offsetX;
                    const maxX = minX + 2;
                    const minZ = z * 2 + offsetZ;
                    const maxZ = minZ + 2;
                    const tile = patternRow[x];
                    const minU = DiscoRoadView.PATTERN_MIN_U[tile] - ((x === 0) ? 0.01 : 0);
                    const maxU = DiscoRoadView.PATTERN_MAX_U[tile] + ((x === 4) ? 0.01 : 0);
                    const minV = DiscoRoadView.PATTERN_MIN_V[tile] + 0.1;
                    const maxV = DiscoRoadView.PATTERN_MAX_V[tile] - 0.1;
                    position.setXYZ(i, minX, 0, minZ);
                    texture.setXY(i++, minU, minV);
                    position.setXYZ(i, minX, 0, maxZ);
                    texture.setXY(i++, minU, maxV);
                    position.setXYZ(i, maxX, 0, minZ);
                    texture.setXY(i++, maxU, minV);
                    position.setXYZ(i, minX, 0, maxZ);
                    texture.setXY(i++, minU, maxV);
                    position.setXYZ(i, maxX, 0, maxZ);
                    texture.setXY(i++, maxU, maxV);
                    position.setXYZ(i, maxX, 0, minZ);
                    texture.setXY(i++, maxU, minV);
                }
            }
        }
        position.needsUpdate = true;
        texture.needsUpdate = true;
        // this.road.geometry.computeBoundingBox();
        // this.road.geometry.computeBoundingSphere();
    }
}
exports.default = DiscoRoadView;
DiscoRoadView.PATTERN = [
    [
        [12, 10, 15, 4, 11],
        [4, 15, 1, 8, 8],
        [5, 3, 4, 1, 5],
        [7, 2, 3, 8, 14],
        [5, 11, 13, 4, 10],
    ],
    [
        [4, 2, 13, 13, 15],
        [6, 15, 11, 2, 8],
        [5, 11, 15, 10, 4],
        [5, 7, 9, 2, 8],
        [15, 0, 14, 7, 9],
    ],
    [
        [13, 15, 6, 12, 8],
        [8, 2, 13, 0, 3],
        [3, 14, 4, 5, 9],
        [6, 10, 9, 9, 8],
        [7, 14, 8, 1, 6],
    ],
    [
        [2, 4, 12, 6, 11],
        [12, 5, 6, 12, 12],
        [6, 1, 6, 0, 1],
        [5, 3, 3, 8, 9],
        [7, 2, 2, 14, 8],
    ],
    [
        [12, 9, 2, 4, 5],
        [4, 10, 13, 3, 15],
        [8, 9, 8, 7, 9],
        [9, 6, 2, 8, 13],
        [0, 11, 1, 3, 0],
    ],
    // flip(
    //     pattern('0 0 0 0 0'),
    //     pattern('0 1 1 1 0'),
    //     pattern('0 1 r 1 0'),
    //     pattern('0 1 1 1 0'),
    //     pattern('0 0 0 0 0'),
    // ),
];
DiscoRoadView.PATTERN_MIN_U = [
    0.00, 0.25, 0.50, 0.75,
    0.00, 0.25, 0.50, 0.75,
    0.00, 0.25, 0.50, 0.75,
    0.00, 0.25, 0.50, 0.75,
];
DiscoRoadView.PATTERN_MAX_U = [
    0.25, 0.50, 0.75, 1.00,
    0.25, 0.50, 0.75, 1.00,
    0.25, 0.50, 0.75, 1.00,
    0.25, 0.50, 0.75, 1.00,
];
DiscoRoadView.PATTERN_MIN_V = [
    0.75, 0.75, 0.75, 0.75,
    0.50, 0.50, 0.50, 0.50,
    0.25, 0.25, 0.25, 0.25,
    0.00, 0.00, 0.00, 0.00,
];
DiscoRoadView.PATTERN_MAX_V = [
    1.00, 1.00, 1.00, 1.00,
    0.75, 0.75, 0.75, 0.75,
    0.50, 0.50, 0.50, 0.50,
    0.25, 0.25, 0.25, 0.25,
];
DiscoRoadView.PATTERN_SIZE = 5;
DiscoRoadView.PATTERN_TIME = 0.75;
DiscoRoadView.PATTERNS = 13;
DiscoRoadView.GEM_TILT = 20 * three_1.MathUtils.DEG2RAD;
DiscoRoadView.GEM_SINK_Z = -3;
